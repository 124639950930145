<!-- SkeletonScreen.vue -->  
<template>
    <div>
      <!-- background-color: #F6F4F0; -->
      <div class="row pl-0 pr-0 mt-3" style="width: 100%;margin: 0;">
        <div class="col-md-12 pl-0 pr-0">
          <!-- 模拟侧边栏 -->
          <div class="skeleton-wrapper mb-3">
            <div class="skeleton-item" style="height: 32vh; width: 100%; background-color: white; border-radius: 4px;">
              <div class="skeleton-item mt-2 ml-3" style="height: 5vh; width: 90%; background: #F6F4F0; border-radius: 12px;"></div>
              <div class="skeleton-item mt-4 ml-3" style="height: 5vh; width: 40%; background: #F6F4F0; border-radius: 12px;"></div>
              <div class="skeleton-item mt-4 ml-3" style="height: 5vh; width: 70%; background: #F6F4F0; border-radius: 12px;"></div>
              <div class="skeleton-item mt-4 ml-3" style="height: 5vh; width: 20%; background: #F6F4F0; border-radius: 12px;"></div>
            </div>
          </div>
        </div>
  
      </div>
    </div>
  </template>  
      
    <script>
  export default {
    name: "SkeletonScreen",
  };
  </script>  
      
    <style scoped>
  .skeleton-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .skeleton-item {
    width: 100%; /* 根据需要调整 */
    height: 100%; /* 根据需要调整 */
    background-color: #e9ecef;
    border-radius: 4px; /* 圆角 */
    animation: pulse 1.5s ease-in-out infinite;
  }
  
  @keyframes pulse {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.3;
    }
    100% {
      opacity: 1;
    }
  }
  </style>