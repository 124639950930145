<template>
  <div class="col-md-12 col-sm-12 col-lg-12 col-xl-12 pl-0 pr-0 sjbdt">

    <skeleton-screen v-if="!dataLoaded"></skeleton-screen>

    <div v-if="dataLoaded" class="col-md-12 col-sm-12 col-lg-12 col-xl-12 baise yuanjiao  pb-3 gundt mb-3 heggs" style="font-size: calc(7px + 0.4vw);border-radius: 12px 12px 12px 12px; height: 87vh;overflow-y: auto;" @scroll="handleScroll">
      <div style="width: 100;height: 1rem;" v-if="aaaa"></div>
      <empty v-if="show"></empty>
      <div v-if="!show">
        <div class="topg d-flex align-items-center mt-3 mb-3">
          <img v-if="dataLoaded" @click="fanhui(0)" class=" curpo dcbd mr-3" style="width: calc(25px + 0.4vw);" src="@/img/login/icon_fanhui@2x.png" alt="" />
          <div class="curpo sjbzt" style="font-size: calc(11px + 0.4vw);">{{ name }} - {{ $t("捐款芳名录") }}</div>
        </div>
        <div class="col-md-12 yinc mb-3 pl-0 pr-0 yuanjiao d-flex justify-content-between align-items-center pt-3 pb-3" style="background: #FFFAF0;">
          <div class="col-md-1  yuanjiao">
            {{ $t('头像') }}
          </div>
          <div class="col-md-1 yuanjiao">
            {{ $t('捐款主题') }}
          </div>
          <div class="col-md-2 yuanjiao">
            {{ $t('名称') }}
          </div>
          <div class="col-md-2 yuanjiao">
            {{ $t('捐款额度') }}
          </div>
          <div class="col-md-2 yuanjiao">
            {{ $t('捐款时间') }}
          </div>
        </div>
        <div v-for="(item, index) in list" :key="index" class="col-md-12 yinc mb-3 pl-0 pr-0 pt-2 pb-2 yuanjiao d-flex justify-content-between align-items-center" :style="{ background : index % 2 == 0 ? '' :'#F6F4F0' }">
          <div class="col-md-1 yuanjiao">
            <img :src="item.avatar" style="width: 50px;height: 50px;border-radius: 50%;object-fit: cover;" alt="">
          </div>
          <div class="col-md-1 yuanjiao">
            {{ item.donor_type== 1 ? $t('个人') : $t('企业') }}
          </div>
          <div class="col-md-2 yuanjiao">
            {{ item.name }}
          </div>
          <div class="col-md-2 yuanjiao">
            {{ item.type == 1 ?   item.amount + '元' + '/' + $t('定期') + item.period_total_times + $t('个月')  : $t('单笔') + item.amount + '元' }}
          </div>
          <div class="col-md-2 yuanjiao" style="color: #999999;">
            {{ timeFilter(item.donate_time) }}
          </div>
        </div>
        <!-- 手机版 -->
         
        <div v-for="(item, index) in list" :key="index" class="aaaa mb-3 yuanjiao p-3 " style="background: #F6F4F0;">
          <div>
            <div class="sjbzt" style="font-weight: 600;">{{ item.name }}</div>
            <div class="mt-3 mb-3" style="background: #E5E5E5;height: 1px;"></div>
            <div class="d-flex justify-content-between mb-1">
              <div class=" text-center pl-0 pr-2 sjbzt" style="width: 50%;text-align: left !important;font-weight: 600;">
                {{ $t('riqi') }}
              </div>
              <div class=" text-center pl-0 pr-0 sjbzt" style="width: 50%;text-align: left !important;font-weight: 600;">
                {{ $t('捐款主题') }}
              </div>
            </div>
            <div class="d-flex justify-content-between mb-3">
              <div class=" text-center pl-0 pr-2 sjbxzt" style="width: 50%;text-align: left !important;font-weight: 600;">
                {{ timeFilter(item.donate_time) }}
              </div>
              <div class=" text-center pl-0 pr-0 sjbxzt" style="font-weight: 600;width: 50%;text-align: left !important;">
                {{ item.donor_type== 1 ? $t('个人') : $t('企业') }}
              </div>
            </div>
            <!-- <div class="col-md-2 yuanjiao">
            {{ $t('捐款额度') }}
          </div> -->
            <div class="d-flex justify-content-between mb-1">
              <div class=" text-center pl-0 pr-2 sjbzt" style="width: 50%;text-align: left !important;font-weight: 600;">
                {{ $t('捐款额度') }}
              </div>
              <div class=" text-center pl-0 pr-0 sjbzt" style="width: 50%;text-align: left !important;font-weight: 600;">

              </div>
            </div>
            <div class="d-flex justify-content-between mb-3">
              <div class=" text-center pl-0 pr-2 sjbxzt" style="width: 50%;text-align: left !important;font-weight: 600;">
                {{ item.type == 1 ?   item.amount + '元' + '/' + $t('定期') + item.period_total_times + $t('个月')  : $t('单笔') + item.amount + '元' }}
              </div>
              <div class=" text-center pl-0 pr-0 sjbxzt" style="font-weight: 600;width: 50%;text-align: left !important;">

              </div>
            </div>

            <div class="sjbzt mb-2" style="font-weight: 600;">{{ $t('头像') }}</div>
            <div>
              <img class="yuanjiao " style="height: calc(120px + 0.4vw);object-fit: cover;width:calc(120px + 0.4vw);" :src="item.avatar" alt="" />
            </div>
          </div>
        </div>
        <!-- 加载更多提示 -->
        <div class="d-flex justify-content-center">
          <div v-if="list.length == 0" style="margin-top: 15vh;">
            <empty></empty>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>
    
    <script>
import { EventBus } from "@/views/event-bus.js";
import empty from "../../empty/empty.vue";
import { donate_list } from "../../../request/jkhd/jkhd";
import { formatTimestamp } from "@/utils/common.js";
import SkeletonScreen from "./SkeletonScreen/zzsySkeletonScreen.vue";
export default {
  watch: {
    // 监听路由对象中的path属性，它包含了当前的路由地址
    "$route.path": function (newPath, oldPath) {
      console.log(newPath);
      // 当二级路由发生变化时，这里的代码会被执行
      if (newPath.startsWith("/")) {
        // 这里处理二级路由变化的逻辑
        this.dingw();
      }
    },
  },
  components: {
    SkeletonScreen,
    empty,
  },
  data() {
    return {
      dataLoaded: false,
      list: [],
      page: 1,
      show: false,
      isLoadMore: false,
      limit: "",
      name: "",
    };
  },
  created() {},
  mounted() {
    this.getdonation();
  },
  computed: {},
  methods: {
    // 时间转换
    timeFilter(stringTime) {
      return formatTimestamp(stringTime * 1000);
    },
    handleScroll(event) {
      const target = event.target;
      const scrollDistance =
        target.scrollHeight - target.scrollTop - target.clientHeight;
      if (scrollDistance < 10) {
        if (!this.isLoadMore) {
          //此处判断，上锁，防止重复请求
          this.isLoadMore = true;
          this.page += 1;
          this.getdonation();
        }
      }
    },
    getdonation() {
      const data = {
        page: this.page,
        id: this.$route.params.zjsy,
      };
      donate_list(data).then((res) => {
        if (res.code == 200) {
          this.dataLoaded = true;
          this.name = res.data.name;
          this.limit = res.data.per_page;
          if (res.data.total == 0) {
            this.ishow = true;
          } else {
            this.ishow = false;
          }
          if (this.page * this.limit >= res.data.total) {
            if (this.page == 1) {
              this.list = res.data.data;
            } else {
              this.list.push(...res.data.data);
            }
            this.isLoadMore = true;
          } else {
            this.list.push(...res.data.data);
            this.isLoadMore = false;
          }
        }
      });
    },
    fanhui(e) {
      this.$router.go(-1);
      // console.log(localStorage.getItem("redirect"));
      // if (localStorage.getItem("redirect")) {
      //   this.$router.push(localStorage.getItem("redirect"));
      //   EventBus.$emit("tthdfhs", "1");
      // } else {
      //   this.$router.push("/donation/" + this.$route.params.zjsy);
      //   EventBus.$emit("tthdfhs", "1");
      // }
    },
  },
};
</script>
    
    <style scoped>
@media screen and (max-width: 750px) {
  .asdasd {
    width: calc(60px + 0.4vw) !important;
    height: calc(60px + 0.4vw) !important;
  }
}

@media screen and (max-width: 991px) {
  .sjbdt{
    padding-top: 1rem;
  }
  .heggs {
    height: 75vh !important;
  }
  .aaaa {
    display: block !important;
  }
  .sjbzt {
    font-size: calc(11px + 0.4vw) !important;
  }
  .sjbxzt {
    font-size: calc(9px + 0.4vw) !important;
  }
  .yinc {
    display: none !important;
  }
  .topg {
    margin-top: 1rem !important;
  }
}
.aaaa {
  display: none;
}
.dcbd:hover {
  transform: scale(1.06);
}
.gundt::-webkit-scrollbar {
  display: none;
}
</style>