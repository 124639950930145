<template>
	<div>
		<div class="" style="display: flex;flex-direction: column;align-items: center; margin-top:40px;">
			<div class="" style="text-align: center;">
				<img class="mb-3 img" src="../../img/kong.png" alt="" >
				<div style="width: 100%;text-align: center;font-size: calc(6px + 0.4vw);color: #999999;" class="sjbxzt">{{ $t("暂无内容~") }}</div>
			</div>
		
		</div>
	</div>
</template>

<script>
	export default {
		name: "empty",
	
		data() {
			return {

			};
		}
	}
</script>

<style>
@media screen and (max-width: 1200px) {
	.sjbxzt {
    font-size: calc(9px + 0.4vw) !important;
  }
	.img {
    width: calc(200px + 0.5vw) !important;
    height: calc(200px + 0.5vw) !important;
  }
}
</style>