<!-- SkeletonScreen.vue -->
<template>
	<div>
		<!-- background-color: #F6F4F0; -->
		<div class="row pl-0 pr-0 sjtop" style="width: 100%;margin: 0;">
			<div class="col-md-12 pl-0 pr-0">
				<!-- 模拟侧边栏 -->
				<div class="skeleton-wrapper mb-3">
					<div class="skeleton-item pt-3"
						style="height: 45vh; width: 100%; background-color: white; border-radius: 4px;">
						<div class="skeleton-item "
							style="height: 41vh; width: 95%; background: #F6F4F0; border-radius: 12px;margin: auto;">
						</div>
					</div>
				</div>
				<div class="skeleton-wrapper">
					<div class="skeleton-item pt-2 d-flex justify-content-between"
						style="height: 15vh; width: 100%; background-color: #F6F4F0; border-radius: 4px;">
						<div class="skeleton-item mr-3"
							style="height: 11vh; width: 18%; background: white; border-radius: 12px;">
							   
							</div>
						<div class="skeleton-item mr-3"
							style="height: 11vh; width: 18%; background: white; border-radius: 12px;"></div>
						<div class="skeleton-item mr-3"
							style="height: 11vh; width: 18%; background: white; border-radius: 12px;"></div>
						<div class="skeleton-item mr-3"
							style="height: 11vh; width: 18%; background: white; border-radius: 12px;"></div>
						<div class="skeleton-item mr-3"
							style="height: 11vh; width: 18%; background: white; border-radius: 12px;"></div>
					</div>
				</div>
				<div class="skeleton-wrapper mb-3">
					<div class="skeleton-item baise yuanjiao pt-3 d-flex justify-content-between"
						style="height: 45vh; width: 100%; background-color: white; border-radius: 4px;">
						<div class="skeleton-item ml-3"
							style="height: 25vh; width: 24%; background: #F6F4F0; border-radius: 12px;">
						</div>
						<div class="skeleton-item ml-3"
							style="height: 25vh; width: 24%; background: #F6F4F0; border-radius: 12px;">
						</div>
						<div class="skeleton-item ml-3"
							style="height: 25vh; width: 24%; background: #F6F4F0; border-radius: 12px;">
						</div>
						<div class="skeleton-item ml-3 mr-3"
							style="height: 25vh; width: 24%; background: #F6F4F0; border-radius: 12px;">
						</div>
					</div>
				</div>
				<!-- <div class="skeleton-wrapper">
		  <div class="skeleton-item p-2 d-flex " style="height: 35vh; width: 100%; background-color: white; border-radius: 4px;">
			  <div class="skeleton-item mt-4 ml-3" style="height: 30vh; width: 48%; background: #F6F4F0; border-radius: 12px;"></div>
			   <div class="skeleton-item mt-4 ml-3" style="height: 30vh; width: 48%; background: #F6F4F0; border-radius: 12px;"></div>
		  </div>
		  <div class="skeleton-item p-2 d-flex " style="height: 50vh; width: 100%; background-color: white; border-radius: 4px;">
		  		<div class="skeleton-item mt-4 ml-3" style="height: 30vh; width: 48%; background: #F6F4F0; border-radius: 12px;"></div>
		  		<div class="skeleton-item mt-4 ml-3" style="height: 30vh; width: 48%; background: #F6F4F0; border-radius: 12px;"></div>
		  </div>
		</div> -->
			</div>

		</div>
	</div>
</template>

<script>
	export default {
		name: "SkeletonScreen",
	};
</script>

<style scoped>
@media screen and (max-width: 991px) {
	.sjtop {
    margin-top: 1rem !important;
  }
}
	.skeleton-wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.skeleton-item {
		width: 100%;
		/* 根据需要调整 */
		height: 100%;
		/* 根据需要调整 */
		background-color: #e9ecef;
		border-radius: 4px;
		/* 圆角 */
		animation: pulse 1.5s ease-in-out infinite;
	}

	@keyframes pulse {
		0% {
			opacity: 1;
		}

		50% {
			opacity: 0.3;
		}

		100% {
			opacity: 1;
		}
	}
</style>