<template>
  <div class="col-md-12 col-sm-12 col-lg-12 col-xl-12 pl-0 pr-0">
    <skeleton-screen v-if="!dataLoaded"></skeleton-screen>

    <div v-if="dataLoaded" class="col-md-12 col-sm-12 col-lg-12 col-xl-12 baise pt-3 pb-3 element" style="font-size: calc(7px + 0.4vw);border-radius: 0 12px 12px 12px;">
      <Empty v-if="ishow"></Empty>

      <div v-else>
        <div v-if="show == 1">
          <div v-for="(item, index) in list" :key="index" class="col-md-12 p-3 mb-3 pl-0 pr-0 yuanjiao d-flex justify-content-between align-items-center" style="background: #F6F4F0;">

            <div>
              <div class="mb-2 sjbzt" style="font-size: calc(7px + 0.4vw);font-weight: 700;">{{ item.title }}</div>
              <div class="sjbxzt" style="font-size: calc(6px + 0.4vw);color: #999999;">{{ item.introduction }}</div>
            </div>
            <button :disabled="isLoading" @click="getshow(item.id)" class="pl-3 pr-3 pt-1 pb-1 yuanjiao ckqbb curpo annys sjbxzt" style="font-size: calc(5px + 0.4vw);background: #FFD672;border: none;">
              {{$t('预约共运')}}
            </button>
          </div>
        </div>
      </div>
      <gyrwxq v-if="show == 2" @custom='custom' :id="this.id"></gyrwxq>
    </div>

  </div>
</template>

<script>
import { EventBus } from "@/views/event-bus.js";
import Empty from "@/components/empty/empty.vue";
import { todelivery } from "@/request/gongyun/gongyun.js";
import SkeletonScreen from "../wmuc/SkeletonScreen/zzsySkeletonScreen.vue";
import gyrwxq from "./gyrwxq.vue";
export default {
  components: {
    SkeletonScreen,
    gyrwxq,
    Empty,
  },
  data() {
    return {
      show: 1,
      dataLoaded: false,
      isLoading: false,
      list: [],
      page: 1,
      organization_id: "",
      limit: 20,
      isLoadMore: false,
      id: "",
      ishow: false,
    };
  },
  created() {
    EventBus.$on("hsttshare-car", (msg) => {
      // 执行方法，处理接收到的数据
      // this.message = msg;
      // 如果需要，可以在这里调用其他方法
      if (!this.isLoadMore) {
          //此处判断，上锁，防止重复请求
          this.isLoadMore = true;
          this.page += 1;
          this.getgong();
        }
    });
  },
  beforeDestroy() {
    EventBus.$off("hsttshare-car");
  },
  mounted() {
    this.organization_id = this.$route.params.id;
    // 模拟数据加载
    this.getgong();
  },
  computed: {},
  methods: {
    getshow(id) {
      this.show = 2;
      this.id = id;
    },
    // handleScroll(event) {
    //   const target = event.target;
    //   const scrollDistance =
    //     target.scrollHeight - target.scrollTop - target.clientHeight;
    //   if (scrollDistance < 10) {
    //     if (!this.isLoadMore) {
    //       //此处判断，上锁，防止重复请求
    //       this.isLoadMore = true;
    //       this.page += 1;
    //       this.getgong();
    //     }
    //   }
    // },
    getgong() {
      this.isLoading = true;
      const data = {
        organization_id: this.organization_id,
        page: this.page,
      };
      todelivery(data)
        .then((res) => {
          if (res.code == 200) {
            this.dataLoaded = true;
          }
          this.limit = res.data.per_page;
          if (res.data.total == 0) {
            this.ishow = true;
          } else {
            this.ishow = false;
          }
          if (this.page * this.limit >= res.data.total) {
            if (this.page == 1) {
              this.list = res.data.data;
            } else {
              this.list.push(...res.data.data);
            }
            this.isLoadMore = true;
          } else {
            this.list.push(...res.data.data);
            this.isLoadMore = false;
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    custom() {
      this.show = 1;
    },
    fanhui(e) {
      this.$emit("custom", e);
    },
  },
};
</script>

<style scoped>
@media screen and (max-width: 991px) {
  .sjbzt {
    font-size: calc(11px + 0.4vw) !important;
  }
  .sjbxzt {
    font-size: calc(9px + 0.4vw) !important;
  }
}
.dcbd:hover {
  transform: scale(1.2);
}

.ckqbb:hover {
  transform: scale(1.001);
  /* 悬浮时放大1.1倍 */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.element::-webkit-scrollbar {
  display: none;
}
</style>