<template>
  <div>
    <div v-show="emsl == 0">
      <!-- 骨架屏 -->
      <skeleton-screen v-show="!dataLoaded"></skeleton-screen>
      <div v-if="dataLoaded" class=" col-md-12 col-sm-12 col-lg-12 col-xl-12  pl-0 pr-0 ml-0 pr-0 lieb heggs" @scroll="handleScroll"  style="overflow-y: auto;">
        <div v-if="show == 0" style="width: 100%;">
          <div class="sjyinc" style="width: 100%;">
            <div style="width: 100%;">
              <div @click="fczff()" class="position-absolute curpo" style="z-index: 9; color: white;left: 7%;top: 3%;width: 93%;">
                <div class="col-md-5 pl-0 pr-0 mb-2" style="font-size: calc(10px + 0.4vw);">{{
									hot.small_title }}
                </div>
                <div class="mb-2 col-md-5 pl-0 pr-0" style="font-size: calc(15px + 0.4vw);font-weight: 700;">{{ hot.title }}</div>
                <div class="col-md-5 pl-0 pr-0" style="font-size: calc(8px + 0.4vw);font-weight: 500;">{{ hot.sub_title }}</div>
                <div class="ckqbb yuanjiao pt-2 pb-2 mt-2" style="width: 25%;background-color: #FFD672;text-align: center;font-size: calc(7px + 0.4vw);color: #000;">
                  {{ $t('查看详情') }}
                </div>
              </div>
              <!-- <img @click="fczff()" class="col-sm-12 pl-0 pr-0 curpo"
								src="@/img/details/pic_jkhd_banner@2x.png"
								style="border: 1px solid pink; width: 100%;min-height: 200px;" alt="" /> -->
              <div class="curpo" style="width: 100%;">

                <img @click="fczff()" class=" curpo yuanjiao" :src="hot.image" style=" width: 100%;height: 20vh;object-fit: cover;" alt="" />

              </div>

              <div class=" mt-3 d-flex justify-content-around align-items-center" style="width: 100%;">
                <div style="width: 31%;" v-for="(item, index) in remark" :key="index">
                  <div class=" yuanjiao baise p-2 d-flex align-items-center">
                    <img style="width: calc(25px + 0.4vw);" src="@/img/details/icon_juhd_juankuanshuoming@2x.png" alt="" />
                    <div class="ml-2">
                      <div style="font-size: calc(8px + 0.4vw);font-weight: 600;">{{ item.title }}
                      </div>
                      <div style="font-size: calc(6px + 0.4vw);">{{ item.description }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="yinc col-sm-12 pl-0 pr-0 d-flex justify-content-between">
          <div class="col-md-12 col-sm-12 col-xl-9 pl-0 pr-0">
            <div class="col-md-12 pl-0 pr-0">
              <div @click="fczff()" class=" pl-0 pr-0 position-absolute curpo" style="z-index: 99; color: white;left: 7%;top: 13%;width: 93%;">
                <div class="col-md-5 pl-0 pr-0 mb-2" style="font-size: calc(10px + 0.4vw);">{{
									hot.small_title }}
                </div>
                <div class="mb-2 col-md-5 pl-0 pr-0" style="font-size: calc(22px + 0.4vw);font-weight: 700;">{{ hot.title }}</div>
                <div class="col-md-5 pl-0 pr-0" style="font-size: calc(15px + 0.4vw);font-weight: 500;">{{ hot.sub_title }}</div>
                <div class="col-sm-2 pl-0 pr-0 ckqbb yuanjiao pt-3 pb-3 mt-5" style="background-color: #FFD672;text-align: center;font-size: calc(7px + 0.4vw);color: #000;">
                  {{ $t('查看详情') }}
                </div>
              </div>
              <div class="col-sm-12 pl-0 pr-0 curpo" style="">

                <img @click="fczff()" class=" curpo yuanjiao" :src="hot.image" style=" width: 100%;height: 300px;object-fit: cover;" alt="" />

              </div>

              <div class=" mt-3 d-flex pl-0 pr-0 justify-content-around align-items-center" style="width: 100%;">
                <div class=" pl-0 pr-0 " v-for="(item, index) in remark" :key="index" style="width: 32%;">
                  <div class=" yuanjiao baise p-3 d-flex align-items-center">
                    <img style="width: calc(30px + 0.5vw);" src="@/img/details/icon_juhd_juankuanshuoming@2x.png" alt="" />
                    <div class="ml-2">
                      <div style="font-size: calc(8px + 0.4vw);font-weight: 600;">{{ item.title }}
                      </div>
                      <div style="font-size: calc(6px + 0.4vw);">{{ item.description }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="yuanjiao col-xl-3 yinc ml-0 pr-0">
            <div class="baise col-md-12 pb-2 yuanjiao pl-0 pr-0  mb-3" style="height: 400px;">
          <div class="mb-2 d-flex justify-content-between ml-3 mr-3 pt-3" style="font-size: calc(8px + 0.4vw); font-weight: 500">
            <div>{{ $t("资金用途") }}</div>
            <!-- <div class="ckq curpo" style="color: #ffd672">
              {{ $t("查看全部") }}
            </div> -->
          </div>
          <div class="mb-2 ml-3 mr-3 col-md-11" style="height: 1px; background: #e5e5e5"></div>
          <div v-show="index <= 4" @click="zjsyqk(item.donation_id)" v-for="(item, index) in used" :key="index" class="d-flex  justify-content-between ckqbb curpo align-items-center mb-2 xunafuxgio pl-3 pr-3 pt-2 pb-2 yuanjiao">
            <div class="d-flex justify-content-between align-items-center">
              <img class="mr-2" style="width: calc(35px + 0.5vw);height: calc(35px + 0.4vw);border-radius: 50%;object-fit: cover;" :src="item.image" alt="" />
              <div>
                <div class="ellipsis" style="font-size: calc(7px + 0.4vw);">
                  {{ item.donation_name }}
                </div>
                <div class="d-flex align-items-center mt-1">
                    <div class="d-flex mr-2 align-items-center" style="font-size: calc(6px + 0.4vw);color: #666666;">
                        <img style="width: calc(7px + 0.5vw);height: calc(7px + 0.5vw);" src="@/img/daohangl/icon_jkhd_qian@2x.png" alt="" />
                        <div class="ml-1">{{ item.amount ? item.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }}</div>
                      </div>
                  <div class="d-flex mr-3 align-items-center" style="font-size: calc(6px + 0.4vw); color: #666666">
                    <img style="
                          width: calc(7px + 0.5vw);
                          height: calc(7px + 0.5vw);
                        " src="@/img/icon_jkhd_shijain@2x.png" alt="" />
                    <div class="ml-1">{{ timeFilter(item.create_time) }}</div>
                  </div>
                </div>
              </div>
            </div>
            <img style="width: calc(15px + 0.5vw)" src="@/img/daohangl/icon_hssy_hdlb_xiangqing@2x.png" alt="" />
          </div>
        </div>
          </div>
        </div>
        </div>
        <juank ref="fczdf" @custom='custom' @emslfun='emslfun' :allList="donation" :ids="isids"></juank>
        <b-modal v-model="modalShow" modal-class="custom-modal-widths"  :centered="true" hide-header hide-footer>
         <div style="width: 100%;" class="p-2">
          <div class="d-flex p-0" style="width: 100%;justify-content: space-between;">
                <div class="tkd">{{ $t("计费对象:") }}</div>
                <div class="tkd" style="margin-right: 4px;">{{ $t("订单号:") }}</div>
          </div>
          <div class="d-flex p-0 mt-2" style="width: 100%;justify-content: space-between;">
                <div class="tkd">{{ tkdsj.name }}</div>
                <div class="tkx">{{ tkdsj.order_no }}</div>
          </div>
          <div class="d-flex p-0 mt-2" style="width: 100%;justify-content: space-between;align-items: center;">
            <div>
              <div class="tkx" style="text-align: left">{{ $t("地址：") }}{{ tkdsj.address }}</div>
              <div class="tkx mt-2" style="text-align: left">Email：{{ tkdsj.email }}</div>
              <div class="tkx mt-2">{{ $t("手机号码：") }}{{ tkdsj.phone }}</div>
            </div>
            <div>
              <div class="tkd" style="text-align: right;margin-right: 4px">{{ $t("发票日期:") }}</div>
              <div class="tkx mt-2" style="text-align: right">{{ timeFilter(tkdsj.pay_time) }}</div>
            </div>
          </div>
          <div class="mt-4 tkd" >{{ $t("订单摘要") }}</div>
          <div class="mt-2 d-flex" style="width: 100%;">
              <div class="xiaod" style="width: 30%;">{{ $t("项目") }}</div>
              <div class="xiaod" style="width: 30%;">{{ $t("金额") }}</div>
              <div class="xiaod" style="width: 20%;">{{ $t("期数") }}</div>
              <div class="xiaod" style="text-align: right;width: 20%;">{{ $t("总额") }}</div>
          </div>
          <div class="bdxian mt-2 mb-2"></div>
          <div class="d-flex" style="width: 100%;">
              <div class="tkx" style="width: 30%;">{{ tkdsj.donation_name }}</div>
              <div class="tkx" style="width: 30%;">NT$ {{ tkdsj.installment_amount }}</div>
              <div class="tkx" style="width: 20%;">{{ tkdsj.period }}</div>
              <div class="tkx" style="text-align: right;width: 20%;">NT$ {{ tkdsj.amount }}</div>
          </div>
          <div class="mt-4" style="text-align: right;">
            <span @click="modalShow=false" class="curpo ckqbb pt-2 pb-2 pr-5 pl-5 yuanjiao" style="font-size: calc(8px + 0.4vw);background:#FFD672;">{{ $t("关闭") }}</span>
          </div>
         </div>
      </b-modal>
      </div>
    </div>
    <ckqb ref="inmn" v-if="emsl == 1" @xindfanhui='xindfanhui'></ckqb>
  </div>
</template>

<script>
import { donation_list,period_result } from "@/request/aapi.js";
import { formatTimestamp } from "@/utils/common.js";
import SkeletonScreen from "./SkeletonScreen/jkhdSkeletonScreen.vue";
import juank from "@/components/jkhd/juankuan.vue";
import ckqb from "@/components/ckqb.vue";
export default {
  components: {
    SkeletonScreen,
    juank,
    ckqb,
  },
  data() {
    return {
      emsl: 0,
      show: 0,
      modalShow:false,
      dataLoaded: false,
      isDropdownOpen: false, // 控制Dropdown的显示
      istoggleDropdownm: false,
      isLoading: false,
      jhuxa: this.$t("全部"),
      tdjhuxa: this.$t("团队类型"),
      items: [
        {
          name: 1,
        },
        {
          name: 1,
        },
        {
          name: 1,
        },
        {
          name: 1,
        },
        {
          name: 1,
        },
        {
          name: 1,
        },
      ],
      hdlb: [{}, {}, {}, {}, {}],
      donation: [],
      hot: [],
      tkdsj:{},
      remark: [],
      used: [],
      isids: null,
    };
  },
  mounted() {
    // 在这里加载你的数据
    this.getDonation_list();
    if(this.$route.query.order_no){
      this.period_result()
    }
  },
  methods: {
    zjsyqk(e){
      this.$router.push({
        name: "zzsy",
        params: {
          isT: 2,
          zjsy: e,
          judge: "1",
        },
      });
    },
    handleScroll(event) {
      const target = event.target;
      const scrollDistance =
        target.scrollHeight - target.scrollTop - target.clientHeight;
      if (scrollDistance < 10) {
        // 当滚动条距离底部小于10px时，认为是滚动到底部
        this.$refs.fczdf.gundong();
      }
    },
     // 时间转换
     timeFilter(stringTime) {
      return formatTimestamp(stringTime * 1000);
    },
    async period_result(){
       const { data } = await period_result({order_no:this.$route.query.order_no})
       this.tkdsj=data.info;
       this.modalShow=true;
    },
    chakanxq(e) {
      this.$refs.fczdf.smdckxq(e);
    },
    // 捐款首页
    getDonation_list() {
      donation_list().then((res) => {
        // console.log(res, "首页")
        if (res.code == 200) {
          this.dataLoaded = true;
          this.donation = res.data.donation;
          this.hot = res.data.hot;
          this.remark = res.data.remark;
          this.used = res.data.used;
        }
      });
    },

    funs(e) {
      this.$router.push("/members/point-logs");
    },
    xindfanhui(e) {
      this.emsl = e;
    },
    emslfun(e) {
      console.log(e, "2222222");
      if (e == 1) {
        setTimeout(() => {
          this.$refs.inmn.getInfo();
        }, 1000);
      }
      this.emsl = e;
    },

    fczff(a) {
      this.isids = this.hot.id;
      // this.$refs.fczdf.fun(1);
      this.$router.push({
          path: "/donation/" + this.hot.id,
          params: {
            isT: 1,
          },
        });
    },
    custom(e) {
      this.show = e;
    },
    tiaozhuan() {
      this.$router.push("/groups/groupdetails");
    },
    xuanz(e) {
      this.jhuxa = e;
      this.isDropdownOpen = false;
    },
    tdxuanz(e) {
      this.tdjhuxa = e;
      this.istoggleDropdownm = false;
    },
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
      // 如果需要，可以在这里添加Bootstrap Dropdown的额外处理逻辑
    },
    toggleDropdownm() {
      this.istoggleDropdownm = !this.istoggleDropdownm;
      // 如果需要，可以在这里添加Bootstrap Dropdown的额外处理逻辑
    },
  },
};
</script>
<style >
.custom-modal-widths .modal-dialog {
    position: relative;
    max-width: 50% !important;
    margin: auto;
    pointer-events: none;
}
@media screen and (max-width: 995px) {
  .custom-modal-widths .modal-dialog {
    position: relative;
    max-width: 90% !important;
    margin: auto;
    pointer-events: none;
}
}
</style>
<style scoped lang="scss">
@media screen and (max-width: 995px) {
  .sjyinc {
    display: block !important;
  }
  .yinc {
    display: none !important;
  }
  .heggs {
    margin-top: 1rem !important;
  }
  .heggs {
    height: 79vh !important;
  }
}
.heggs {
  height: 89.5vh;
}
.sjyinc {
  display: none;
}
.annys {
  font-size: calc(6px + 0.4vw);
  background: #ffd672;
  width: 144px;
  margin: auto;
  border: none;
}

.inppt {
  background: white;
  border: none;
  border-radius: 13px;
  font-size: calc(7px + 0.4vw);
  padding-top: 22px;
  padding-bottom: 22px;
}

.zhankai {
  background: #ffebd2;
  text-align: center;
  border-radius: 12px 12px 12px 12px;
  border: none;
  font-weight: 400;
  font-size: calc(6px + 0.4vw);
  color: #1a1a1a;
}

.form-control {
  height: calc(15px + 0.5vw) !important;
}

.sxbj {
  padding-top: calc(15px + 0.5vw);
  padding-bottom: calc(15px + 0.5vw);
}

.ckqbb:hover {
  transform: scale(1.001);
  /* 悬浮时放大1.1倍 */
  // padding: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.ckq:hover {
  transform: scale(1.001);
  /* 悬浮时放大1.1倍 */
  font-weight: 800;
}

.sbxfbd:hover {
  transform: scale(1.1);
}
.dropdown-menu {
  min-width: calc(130px + 0.5vw) !important;
  font-size: calc(8px + 0.4vw) !important;
}
.xlcd {
  display: block;
  background: #ffebd2;
  border: none;
}
.ckqbb:hover {
  transform: scale(1.001);
  /* 悬浮时放大1.1倍 */
  // padding: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
/* 隐藏水平滚动条 */
.lieb::-webkit-scrollbar {
  display: none;
}

.ellipsis-multiline {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dydpd {
  padding-top: calc(7px + 0.4vw);
  padding-bottom: calc(7px + 0.4vw);
  padding-left: calc(10px + 0.5vw);
  padding-right: calc(10px + 0.5vw);
}
.dropdown-item:hover {
  background-color: #ffebd2;
  color: #b66d00;
  border-radius: 12px;
}
.tkd{
  font-size: calc(10px + 0.4vw);
  font-weight: 700;
}
.tkx{
  font-size: calc(7px + 0.4vw);
  color: #666666;
}
.xiaod{
  font-size: calc(8px + 0.4vw);
  font-weight: 700;
  width: 25%;
}
.bdxian{
  height: 1px;
  width: 100%;
  background: #1A1A1A;
}
</style>