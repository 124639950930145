<template>
	<!-- 顶部 -->
	<div class="col-md-12 pl-0 pr-0 yuanjiao baise p-3 d-flex justify-content-between align-items-center parent mb-4 sjiduanbju">
		<!-- 普通用户 -->
		<ptyh v-if='show==0'></ptyh>
		<!-- 企业用户 -->
		<qyyh v-if='show==1'></qyyh>
		<!-- 团体组织信息 -->
		<tdzzxx v-if='show==2'></tdzzxx>
	</div>
</template>

<script>
	import ptyh from '../details/zhbd/ptyh.vue'
	import qyyh from '../details/zhbd/qyyh.vue'
	import tdzzxx from '../details/zhbd/tdzzxx.vue'
	export default {
		components: {
			ptyh,
			qyyh,
			tdzzxx
		},
		data() {
			return {
				show: 0,
			};
		},
		created() {
			if(localStorage.getItem("token")){
				// 通过身份判断展示组织机构信息
			const a = localStorage.getItem("type")
			if (a == 2) {
				this.show = 2
			} else if (a == 3) {
				this.show = 1
			} else {
				this.show = 0
			}
			}
			
		},
		mounted() {

		},
		methods: {

		},
	};
</script>

<style scoped lang="scss">
@media screen and (max-width: 991px) {
	.sjiduanbju{
		margin-top: 1rem !important;
	}
}
</style>