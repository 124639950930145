<template>
  <div>
    <!-- 骨架屏 -->
    <skeleton-screen v-show="!dataLoaded"></skeleton-screen>
    <div v-if="dataLoaded" class="col-md-12 col-sm-12 col-lg-12 col-xl-12 d-flex pl-0 pr-0 ml-0 mt-3 justify-content-between">
      <div class="col-sm-12 col-xl-12 pl-0 pr-0 lieb" >
        <div class="baise col-md-12" style="border-radius: 12px 12px 12px 12px;">
          <div class="col-md-12 pl-0 pr-0 pb-3">
            <div class="pt-3 pb-3 d-flex justify-content-between align-items-center">
              <img v-if="toxiang == ''" class="mr-3 sjtxtp" style="width: calc(35px + 0.5vw);height: calc(35px + 0.5vw);border-radius: 50%;" src="@/img/details/pic_grzx_beijingzhuangshitu@2x.png" alt="" />
              <img v-else class="mr-3 sjtxtp" style="width: calc(35px + 0.5vw);height: calc(35px + 0.5vw);border-radius: 50%;" :src="toxiang" alt="" />
              <input v-model="nzxsmn" class="form-control srk mr-3 srkjul sjbxzt" style="height: calc(40px + 0.4vw);background: #FFFDF8" type="text" :placeholder="$t('你在想什么？')" plaintext='true' aria-label="Username" aria-describedby="basic-addon1">
              <div v-if="sctpsp" style="width: calc(120px + 0.4vw);" class="d-flex align-items-center p-3 fbtw curpo tpxfbd sjibanb" @click="favtiewen()">
                <img class="mr-1 widtshh" src="@/img/daohangl/btn_hssy_fbtw@2x.png" alt="" />
                <div class="fbtw-zi">{{ $t("发布帖文") }}</div>
              </div>
              <div v-else style="width: calc(120px + 0.4vw);background-color: #E5E5E5" class="d-flex align-items-center p-3 fbtw sjibanb">
                <img class="mr-1 widtshh" src="@/img/daohangl/btn_hssy_fbtw@2x.png" alt="" />
                <div class="fbtw-zi">{{ $t("发布帖文") }}</div>
              </div>
              <div v-if="sctpsp" style="width: calc(165px + 0.4vw);text-align: center" class=" pl-2 pb-2 pt-1 pr-1 fbtw curpo  aaaaa" @click="favtiewen()">
                <img class="mr-1 mt-1 widtshh sjfjtp" src="@/img/daohangl/btn_hssy_fbtw@2x.png" alt="" />
                <span class="fbtw-zi sjbxzt">{{ $t("发布帖文") }}</span>
              </div>
              <div v-else style="width: calc(165px + 0.4vw);text-align: center;background-color: #E5E5E5" class=" pl-2 pb-2 pt-1 pr-1 fbtw curpo  aaaaa">
                <img class="mr-1 mt-1 widtshh sjfjtp" src="@/img/daohangl/btn_hssy_fbtw@2x.png" alt="" />
                <span class="fbtw-zi sjbxzt">{{ $t("发布帖文") }}</span>
              </div>
            </div>
            <div class="col-sm-12 mb-3" style="height: 1px;background: #E5E5E5;"></div>
            <div class="d-flex align-items-center">
              <div class="curpo d-flex mr-3" @click="fbtpshuz()">
                <input ref="fbtp" type="file" @change="fbtpimage" style="display: none;">
                <img class="mr-2 sjwidtshhs widtshhs" src="@/img/daohangl/icon_hssy_fbtw_tupian@2x.png" alt="" />
                <div class="sjbxzt" style="font-size: calc(5px + 0.4vw);">{{ $t("发布图片") }}</div>
              </div>
              <div class="curpo d-flex" @click="fbspshuz()">
                <input ref="fbsp" type="file" @change="fbspinput" style="display: none;">
                <img class="mr-2 sjwidtshhs widtshhs" src="@/img/daohangl/icon_hssy_fbtw_yingpian@2x.png" alt="" />
                <div class="sjbxzt" style="font-size: calc(5px + 0.4vw);">{{ $t("发布影片") }}</div>
              </div>
            </div>
            <div class="col-sm-12 pl-0 pr-0 d-flex" style="flex-wrap: wrap;">
              <div class="col-sm-2 mt-3" v-for="(item, index) in fbtplist" :key="index" style="position: relative;">
                <img style="width: 100%;height: calc(70px + 0.5vw);border-radius: 12px;object-fit: cover;" :src="item.src" alt="">
                <img class="curpo" @click="shanctp(index)" style="position: absolute;width: calc(12px + 0.5vw);right: 10%;" src="@/img/grzx/icon_guanbitupian@2x.png" alt="">
              </div>
              <div class="col-sm-2 mt-3" v-for="(item, index) in fbsplist" :key="index" style="position: relative;">
                <video style="width: 100%;height: calc(70px + 0.5vw);border-radius: 12px;" alt="">
                  <source :src="item.src" type="video/mp4">
                  <source :src="item.src" type="video/mov">
                  <source :src="item.src" type="video/avi">
                  <source :src="item.src" type="video/mkv">
                  <source :src="item.src" type="video/flv">
                  <source :src="item.src" type="video/hevc">
                  <source :src="item.src" type="video/3gp">
                </video>
                <img class="curpo" @click="fbsplist = [], fbspathplist = []" style="position: absolute;width: calc(12px + 0.5vw);right: 10%;" src="@/img/grzx/icon_guanbitupian@2x.png" alt="">
              </div>
            </div>
          </div>
        </div>
        <!-- 列表 -->
        <!-- 列表 -->
        <div class="col-md-12 pl-0 pr-0 mt-3">
          <div v-for="(item, index) in info.moment.data" :key="index" class="col-md-12 mb-3 pb-3 yuanjiao baise pt-3">
            <div class="col-sm-12 pl-0 pr-0 d-flex align-items-center" style="justify-content: space-between;">
              <div class="d-flex align-items-center">
                <img class="curpo sjtxtp mr-3" @click="hnsbui(item.user_id,item.user_slug)" style="width: calc(35px + 0.5vw);height: calc(35px + 0.5vw);border-radius: 50%;" :src="item.avatar ? item.avatar : ''" alt="" />
                <div>
                  <div style="font-size: calc(6px + 0.5vw);font-weight: 500;"> <span class="curpo sjbzt" @click="hnsbui(item.user_id,item.user_slug)">{{ item.nickname }}</span>
                    <!-- 判断如果是别的活动啥玩意的显示这玩意 -->
                    <span class="ml-2 curpo sjbxzt" @click="fcasa(item)">{{ item.activity_id >0 ?  $t("在活动")  + item.activity_name + $t("中发布了更新") : item.organization_id >0 ? item.organization_identity ==2? $t("在团体") + item.organization_name + $t("中发布了更新") : $t("在企业") + item.organization_name + $t("中发布了更新") : '' }}</span>
                  </div>
                  <div @click="hnsbui(item.user_id,item.user_slug)" class="curpo sjbxzt" style="font-size: calc(5px + 0.5vw);color: #666666;">
                    {{ timeFilter(item.create_time) }}</div>
                </div>
              </div>
              <div v-if="item.is_owner == 1">
                <div class="mb-2" @click="tpsdin(item.id)"><img class="ddd curpo ckqb" src="@/img/shanc.png" alt=""></div>
                <div @click="qbudsc(item.id, index)" v-if="scshow && scdid==item.id" class="position-absolute d-flex pt-3 pb-3 pl-4 pr-4 align-items-center yinchez yuanjiao curpo ckqbb">
                  <img class="yinchezimg mr-1" src="@/img/zzdsc.png" alt="">
                  <div class="shanc">{{ $t("删除") }}</div>
                </div>
              </div>
            </div>
            <div class="mt-3 mb-3  sjbzt" style="line-height: calc(13px + 0.5vw);font-size: calc(6px + 0.5vw);">
              {{ item.content }}
            </div>
            <!-- 图片 -->
            <div class="gallery">
              <div v-if="item.image.length == 1" style="width: 100%;">
                <div class="gallery-item" v-show="item.image.length == 1" v-for="(img, index) in item.image" :key="index">
                  <div :class="index == 1 ? 'image-container' : ''">

                    <img @click="showMedia(index, item.image)" style="width: 100%;height: calc(500px + 0.5vw);object-fit: cover;" v-if="img.type == 'image'" class="curpo tpxfbd yuanjiao pgnjksa" :src="img.src">
                    <video @click="showMedia(index, item.image)" style="width: 100%;height: calc(500px + 0.5vw)" v-else lass="curpo tpxfbd" controls class="fullscreen-video yuanjiao pgnjksa">
                      <source :src="img.src" type="video/mp4">
                      <source :src="img.src" type="video/mov">
                      <source :src="img.src" type="video/avi">
                      <source :src="img.src" type="video/mkv">
                      <source :src="img.src" type="video/flv">
                      <source :src="img.src" type="video/hevc">
                      <source :src="img.src" type="video/3gp">
                    </video>
                  </div>
                </div>
              </div>
              <div v-if="item.image.length == 2" style="width: 100%;display: flex;justify-content: space-between;">
                <div style="width: 49.3%;" class="sjbkd" v-for="(img, index) in item.image" :key="index">
                  <div :class="index == 1 ? 'image-container' : ''">
                    <img @click="showMedia(index, item.image)" style="width: 100%;height: calc(400px + 0.5vw);object-fit: cover;" v-if="img.type == 'image'" class="curpo tpxfbd yuanjiao sfdvx" :src="img.src">
                    <video @click="showMedia(index, item.image)" style="width: 100%;height: calc(400px + 0.5vw)" v-else lass="curpo tpxfbd" controls class="fullscreen-video yuanjiao sfdvx">
                      <source :src="img.src" type="video/mp4">
                      <source :src="img.src" type="video/mov">
                      <source :src="img.src" type="video/avi">
                      <source :src="img.src" type="video/mkv">
                      <source :src="img.src" type="video/flv">
                      <source :src="img.src" type="video/hevc">
                      <source :src="img.src" type="video/3gp">
                    </video>
                  </div>
                </div>
              </div>
              <div v-if="item.image.length == 3" style="width: 100%;">
                <div class="gallery-item mb-3 sjmb" v-show="index == 0" v-for="(img, index) in item.image" :key="index">
                  <div :class="index == 1 ? 'image-container' : ''">
                    <img @click="showMedia(index, item.image)" style="width: 100%;height: calc(400px + 0.5vw);object-fit: cover;" v-if="img.type == 'image'" class="curpo tpxfbd yuanjiao sfdvx" :src="img.src">
                    <video @click="showMedia(index, item.image)" style="width: 100%;height: calc(400px + 0.5vw)" v-else lass="curpo tpxfbd" controls class="fullscreen-video yuanjiao sfdvx">
                      <source :src="img.src" type="video/mp4">
                      <source :src="img.src" type="video/mov">
                      <source :src="img.src" type="video/avi">
                      <source :src="img.src" type="video/mkv">
                      <source :src="img.src" type="video/flv">
                      <source :src="img.src" type="video/hevc">
                      <source :src="img.src" type="video/3gp">
                    </video>
                  </div>
                </div>
                <div style="width: 100%;display: flex;justify-content: space-between;">
                  <div style="width: 49.3%;" class="sjbkd" v-show="index != 0" v-for="(img, index) in item.image" :key="index">
                    <div :class="index == 1 ? 'image-container' : ''">
                      <img @click="showMedia(index, item.image)" style="width: 100%;height: calc(250px + 0.5vw);object-fit: cover;" v-if="img.type == 'image'" class="curpo tpxfbd yuanjiao sjitp" :src="img.src">
                      <video @click="showMedia(index, item.image)" style="width: 100%;height: calc(250px + 0.5vw)" v-else lass="curpo tpxfbd" controls class="fullscreen-video yuanjiao sjitp">
                        <source :src="img.src" type="video/mp4">
                        <source :src="img.src" type="video/mov">
                        <source :src="img.src" type="video/avi">
                        <source :src="img.src" type="video/mkv">
                        <source :src="img.src" type="video/flv">
                        <source :src="img.src" type="video/hevc">
                        <source :src="img.src" type="video/3gp">
                      </video>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="item.image.length == 4" style="width: 100%;">
                <div class="mb-3 sjmb" style="width: 100%;display: flex;justify-content: space-between;">
                  <div style="width: 49.3%;" class="sjbkd" v-show="index < 2" v-for="(img, index) in item.image" :key="index">
                    <div :class="index == 1 ? 'image-container' : ''">
                      <img @click="showMedia(index, item.image)" style="width: 100%;height: calc(350px + 0.5vw);object-fit: cover;" v-if="img.type == 'image'" class="curpo tpxfbd yuanjiao sjitp" :src="img.src">
                      <video @click="showMedia(index, item.image)" style="width: 100%;height: calc(350px + 0.5vw)" v-else lass="curpo tpxfbd" controls class="fullscreen-video yuanjiao sjitp">
                        <source :src="img.src" type="video/mp4">
                        <source :src="img.src" type="video/mov">
                        <source :src="img.src" type="video/avi">
                        <source :src="img.src" type="video/mkv">
                        <source :src="img.src" type="video/flv">
                        <source :src="img.src" type="video/hevc">
                        <source :src="img.src" type="video/3gp">
                      </video>
                    </div>
                  </div>
                </div>
                <div style="width: 100%;display: flex;justify-content: space-between;">
                  <div style="width: 49.3%;" class="sjbkd" v-show="index >= 2" v-for="(img, index) in item.image" :key="index">
                    <div :class="index == 1 ? 'image-container' : ''">
                      <img @click="showMedia(index, item.image)" style="width: 100%;height: calc(350px + 0.5vw);object-fit: cover;" v-if="img.type == 'image'" class="curpo tpxfbd yuanjiao sjitp" :src="img.src">
                      <video @click="showMedia(index, item.image)" style="width: 100%;height: calc(350px + 0.5vw)" v-else lass="curpo tpxfbd" controls class="fullscreen-video yuanjiao sjitp">
                        <source :src="img.src" type="video/mp4">
                        <source :src="img.src" type="video/mov">
                        <source :src="img.src" type="video/avi">
                        <source :src="img.src" type="video/mkv">
                        <source :src="img.src" type="video/flv">
                        <source :src="img.src" type="video/hevc">
                        <source :src="img.src" type="video/3gp">
                      </video>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="item.image.length >= 5" style="width: 100%;display: flex;justify-content: space-between;">
                <div style="width: 49.3%;" class="sjbkd">
                  <div style="width: 100%;" class="mb-3 sjmb" v-show="index <= 1" v-for="(img, index) in item.image" :key="index">
                    <div style="width: 100%;" :class="index == 4 ? 'image-container' : ''">
                      <img @click="showMedia(index, item.image)" style="width: 100%;height: calc(400px + 0.5vw);object-fit: cover;" v-if="img.type == 'image'" class="curpo tpxfbd yuanjiao  sjitp" :src="img.src">
                      <video @click="showMedia(index, item.image)" style="width: 100%;height: calc(400px + 0.5vw)" v-else lass="curpo tpxfbd" controls class="fullscreen-video yuanjiao sjitp">
                        <source :src="img.src" type="video/mp4">
                        <source :src="img.src" type="video/mov">
                        <source :src="img.src" type="video/avi">
                        <source :src="img.src" type="video/mkv">
                        <source :src="img.src" type="video/flv">
                        <source :src="img.src" type="video/hevc">
                        <source :src="img.src" type="video/3gp">
                      </video>
                    </div>
                  </div>
                </div>
                <div style="width: 49.3%;" class="sjbkd">
                  <div style="width: 100%;" class="mb-3 sjmb" v-show="index > 1 && index <= 4" v-for="(img, index) in item.image" :key="index">
                    <div style="width: 100%;" :class="index == 4 ? 'image-container' : ''">
                      <img @click="showMedia(index, item.image)" style="width: 100%;height: calc(257px + 0.5vw);object-fit: cover;" v-if="img.type == 'image'" class="curpo tpxfbd yuanjiao sjtpr" :src="img.src">
                      <video @click="showMedia(index, item.image)" style="width: 100%;height: calc(257px + 0.5vw)" v-else lass="curpo tpxfbd" controls class="fullscreen-video yuanjiao sjtpr">
                        <source :src="img.src" type="video/mp4">
                        <source :src="img.src" type="video/mov">
                        <source :src="img.src" type="video/avi">
                        <source :src="img.src" type="video/mkv">
                        <source :src="img.src" type="video/flv">
                        <source :src="img.src" type="video/hevc">
                        <source :src="img.src" type="video/3gp">
                      </video>
                      <div @click="showMedia(index, item.image)" v-if="item.image.length > 5 && index==4" class="overlay curpo yuanjiao tpxfbd" style="width: 100%;color: white;display: flex;align-items: center;justify-content: space-around;" :class="index == 4 ? 'last-image' : ''">
                        <div style="font-size: calc(7px + 0.5vw);">+
                          {{ item.image.length - 5 }}</div>
                      </div>
                    </div>

                  </div>
                </div>

              </div>

            </div>
            <div v-if="isFullscreen" class="fullscreen-modal">
              <template v-if="currentMediaType === 'image'">
                <img :src="mediaList[currentIndex].src" alt="fullscreen" class="fullscreen-image" />
              </template>
              <template v-else-if="currentMediaType === 'video'">
                <video controls class="fullscreen-video">
                  <source :src="mediaList[currentIndex].src" type="video/mp4">
                  <source :src="mediaList[currentIndex].src" type="video/mov">
                  <source :src="mediaList[currentIndex].src" type="video/avi">
                  <source :src="mediaList[currentIndex].src" type="video/mkv">
                  <source :src="mediaList[currentIndex].src" type="video/flv">
                  <source :src="mediaList[currentIndex].src" type="video/hevc">
                  <source :src="mediaList[currentIndex].src" type="video/3gp">
                </video>
              </template>
              <img class="curpo" @click="prevMedia(currentIndex)" style="width: calc(30px + 0.5vw);height: calc(30px + 0.5vw);position: absolute;left: 5%;top: 50%;" src="@/img/daohangl/cb19415c55cd84d9d1ec84d64975476.png">
              <img class="curpo" @click="nextMedia" style="width: calc(30px + 0.5vw);height: calc(30px + 0.5vw);position: absolute;right: 5%;top: 50%;" src="@/img/daohangl/450d77812abbc1eeb69e7b12b85d1b2.png">
              <img class="curpo" @click="closeFullscreen" style="width: calc(30px + 0.5vw);height: calc(30px + 0.5vw);position: absolute;right: 5%;top: 5%;" src="@/img/details/cha.png">
            </div>
            <div class="d-flex justify-content-between align-items-center mt-2 ml-2">
              <div v-if="item.like" style="width: 60%;">
                <img v-for="(sss, mmm) in item.like" :key="mmm" class="mr-2" style="width: calc(20px + 0.5vw);margin-left: -10px;" :src="sss.like_type.image" alt="" />
                <span v-if="item.like.length > 0" class="sjbxzt" style="font-size: calc(6px + 0.4vw);color: #666666;">
                  <span v-for="(sss, mmm) in item.like" :key="mmm">{{ sss.user_nickname }}{{ $t("和")
										}}</span> {{ $t("其他") }}
                  {{ item.like_num ?
										item.like_num - 1
										: 0 }} {{ $t("人做出反应") }}</span>
                <span v-else class="ml-2 sjbxzt" style="font-size: calc(6px + 0.4vw);color: #666666;">{{
									$t("还未有人做出反应") }}</span>
              </div>
              <!-- 展开收回 -->
              <div v-if="item.isExpanded && gyiasdid == item.id" @click="expandfun(index, item.id, '', 0)" class="zhankai zjpadding d-flex pt-2 pb-2 pl-3 pr-3 align-items-center curpo tpxfbd">
                <div class="mr-2 sjbxzt" style="font-size: calc(5px + 0.5vw);"> {{ $t("留言收回") }}</div>
                <img class="xiaojiant" style="width: calc(7px + 0.4vw);height: calc(7px + 0.4vw);" src="@/img/daohangl/icon_hssy_zk@2x.png" alt="" />
              </div>
              <div v-else @click="expandfun(index, item.id, '', 1), pldsjuijjm = $t('发表评论'), plyjid = '', gyiasdid = item.id, qientids = '', gyiasdid = item.id" class="zhankai zjpadding d-flex pt-2 pb-2 pl-3 pr-3 align-items-center curpo tpxfbd">
                <div class="mr-2 sjbxzt" style="font-size: calc(5px + 0.5vw);">{{ $t("留言展开") }}</div>
                <img class="xiaojiant" style="width: calc(7px + 0.4vw);height: calc(7px + 0.4vw);" src="@/img/daohangl/icon_hssy_zk@2x.png" alt="" />
              </div>
            </div>
            <div class="mt-3 mb-2" style="height: 1px;width: 100%;background: #E5E5E5;"></div>
            <!-- 爱心留言分享 -->
            <div class="d-flex align-items-center">
              <!-- 赞图片 -->
              <button v-if="!item.like_type" ref="activeElement" @click.stop="handleClickOnElement" @click="hover = true, gyiasdid = item.id" style="border: none;background: none;position: relative;">
                <div class="d-flex mr-3 curpo ckqb align-items-center">
                  <img class="mr-1 zlxtop" style="width: calc(15px + 0.5vw);" src="@/img/icon_weidianzan@2x.png" alt="" />
                  <div class="sjbxzt" style="font-size: calc(6px + 0.4vw);color: #666666;">{{ $t("赞") }}</div>
                </div>
                <button @mouseleave="hover = false" v-if="hover && item.id == gyiasdid" class="d-flex align-items-center mr-3 pt-1 pb-1 pl-3 pr-3 pt-3 pb-3 position-absolute" style="background: #FFFAF0;border-radius:12px;border: none;bottom: -75px;z-index: 999;">
                  <div v-for="(ism, inde) in xthhh" :key="inde">
                    <img class="mr-2 jiantofangda curpo" @click="dianzhan(item.id, ism.id, ism)" style="width: calc(27px + 0.4vw);height:calc(27px + 0.4vw);" :src="ism.image" alt="" />
                  </div>
                </button>
              </button>
              <div v-else class="d-flex mr-3 curpo ckqb align-items-center" @click="dianzhan(item.id)">
                <img class="mr-1 zlxtops" style="width: calc(15px + 0.5vw);" :src="item.like_type.image" alt="" />
                <div class="sjbxzt" style="font-size: calc(6px + 0.4vw);color: #666666;">{{ item.like_type.name }}
                </div>
              </div>

              <div class="d-flex mr-3 curpo ckqb align-items-center" @click="pldsjuijjm = $t('发表评论'), plyjid = '', gyiasdid = item.id, qientids = ''">
                <img class="mr-1 zlxtop" style="width: calc(15px + 0.5vw);" src="@/img/daohangl/icon_hssy_twly@2x.png" alt="" />
                <div class="sjbxzt" style="font-size: calc(6px + 0.4vw);color: #666666;">{{ $t("留言") }}</div>
              </div>
              <div class="d-flex curpo ckqb align-items-center mr-3">
                <img class="mr-1 zlxtop" style="width: calc(15px + 0.5vw);" src="@/img/daohangl/icon_hssy_twfx@2x.png" alt="" />
                <div class="sjbxzt" style="font-size: calc(6px + 0.4vw);color: #666666;">{{ $t("分享") }}</div>
              </div>
              <!-- <div class="d-flex curpo ckqbb align-items-center">
                <div v-if="item.is_owner == 1" @click="qbudsc(item.id, index)" style="font-size: calc(6px + 0.4vw);color: #666666;">
                  {{ $t("删除") }}
                </div>
              </div> -->
            </div>
            <!-- 列表聊天那块 -->
            <div class="pt-2 mt-2 ltdgdt" v-if="item.isExpanded && item.id == gyiasdid">
              <div class="mb-3" v-for="(lunt, inexlu) in item.pllist" :key="inexlu">
                <div class="d-flex align-items-center" style="justify-content: space-between">
                  <div class="d-flex align-items-center">
                    <img class="mr-2" style="width: calc(30px + 0.6vw);height: calc(30px + 0.6vw);border-radius: 50%;" :src="lunt.avatar ? lunt.avatar : ''" alt="" />
                    <div class="mr-2 sjbzt" style="font-size: calc(7px + 0.5vw);font-weight: 500;">
                      {{ lunt.nickname }}</div>
                    <div class="mt-1 sjbxzt" style="font-size: calc(5px + 0.4vw);">
                      {{ timeFilter(lunt.create_time) }}</div>
                  </div>
                  <div v-if="lunt.is_owner == 1" style="position: relative;" class="mr-3">
                    <div class="mb-2" @click="tpsdinyiji(lunt.id)"><img class="ddd curpo ckqb" src="@/img/shanc.png" alt=""></div>
                    <div @click="scpljibdz(item.id, lunt.id, index)" v-if="scshowyiji && scdidyiji==lunt.id" class="position-absolute d-flex pt-3 pb-3 pl-4 pr-4 align-items-center yinchez yuanjiao curpo ckqbb">
                      <img class="yinchezimg mr-1" src="@/img/zzdsc.png" alt="">
                      <div class="shanc sjbxzt">{{ $t("删除") }}</div>
                    </div>
                  </div>
                </div>
                <div class="mt-2" style="margin-left: calc(22px + 0.6vw);">
                  <div class="p-3 yuanjiao mr-3 sjbxzt" style="background: #F6F4F0;width: auto;font-size: calc(7px + 0.4vw);font-weight: 500;">{{
											lunt.content }}</div>
                  <div class="d-flex mt-3 align-items-center" style="color: #666666;">
                    <div @click="pljibdz(item.id, lunt.id, index)" class="mr-3 srkjul curpo d-flex ckqb align-items-center sjbxzt" style="font-size: calc(6px + 0.4vw);">
                      <img v-if="lunt.is_like == 0" class="zlxtop mr-1" style="width: calc(10px + 0.5vw);" src="@/img/daohangl/icon_hssy_tepl_wdz@2x.png" alt="" />
                      <img v-else style="width: calc(10px + 0.5vw);" class="zlxtop mr-1" src="@/img/daohangl/icon_hssy_dz@2x.png" alt="">
                      <div>{{ $t('喜欢') }}</div>
                    </div>
                    <div @click="pldsjuijjm = $t('回复') + lunt.nickname + $t('的评论'), plyjid = lunt.id, qientids = ''" class="mr-3 curpo ckqb sjbxzt" style="font-size: calc(6px + 0.4vw);color: #FAB1AB;">{{ $t("回复") }}
                    </div>
                  </div>
                </div>
                <div class="mt-3" v-for="(qient, iasd) in lunt.children" :key="iasd" style="margin-left: calc(22px + 0.6vw);">
                  <div class="d-flex align-items-center" style="justify-content: space-between">
                    <div class="d-flex align-items-center">
                      <img class="mr-2" style="width: calc(30px + 0.6vw);height: calc(30px + 0.6vw);border-radius: 50%;" :src="qient.avatar ? qient.avatar : ''" alt="" />
                      <div class="mr-2 sjbzt" style="font-size: calc(7px + 0.5vw);font-weight: 500;">
                        {{ qient.nickname }}
                      </div>
                      <div class="sjbxzt mt-1" style="font-size: calc(5px + 0.4vw);">{{ timeFilter(qient.create_time) }}
                      </div>
                    </div>
                    <div v-if="qient.is_owner == 1" style="position: relative;" class="mr-3">
                      <div class="mb-2" @click="tpsdinerji(qient.id)"><img class="ddd curpo ckqb" src="@/img/shanc.png" alt=""></div>
                      <div @click="scpljibdz(item.id, qient.id, index)" v-if="scshowerji && scdiderji==qient.id" class="position-absolute d-flex pt-3 pb-3 pl-4 pr-4 align-items-center yinchez yuanjiao curpo ckqbb">
                        <img class="yinchezimg mr-1" src="@/img/zzdsc.png" alt="">
                        <div class="shanc">{{ $t("删除") }}</div>
                      </div>
                    </div>
                  </div>
                  <div class="mt-3" style="margin-left: calc(22px + 0.6vw);;">
                    <div class="p-3 yuanjiao mr-3 sjbxzt" style="background: #F6F4F0;width: auto;font-size: calc(7px + 0.4vw);font-weight: 500;">{{
												qient.content
											}}</div>
                    <div class="d-flex mt-4 align-items-center" style="color: #666666;">
                      <div @click="pljibdz(item.id, qient.id, index)" class="mr-3 curpo sjbxzt d-flex ckqb align-items-center" style="font-size: calc(6px + 0.4vw);">
                        <img v-if="qient.is_like == 0" class="zlxtop mr-1" style="width: calc(10px + 0.5vw);" src="@/img/daohangl/icon_hssy_tepl_wdz@2x.png" alt="" />
                        <img v-else class="zlxtop mr-1" style="width: calc(10px + 0.5vw);" src="@/img/daohangl/icon_hssy_dz@2x.png" alt="">
                        <div>{{ $t("喜欢") }}</div>
                      </div>
                      <div @click="pldsjuijjm = $t('回复') + qient.nickname + $t('的评论'), qientids = qient.user_id, plyjid = lunt.id" class="mr-3 curpo sjbxzt ckqb" style="font-size: calc(6px + 0.4vw);color: #FAB1AB;">{{ $t("回复") }}
                      </div>
                      <!-- <div @click="scpljibdz(item.id, qient.id, index)" v-if="qient.is_owner == 1" class="mr-3 curpo ckqbb" style="font-size: calc(6px + 0.4vw);">{{
													$t("删除") }}
                      </div> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 发表评论 -->
            <div v-if="item.id == gyiasdid" class="d-flex align-items-center yuanjiao mt-3 mb-1" style="position: relative;">
              <img class="dingwd zlxtops position-absolute" style="left: 10px;z-index: 9;" src="@/img/daohangl/pic_hssy_twtx@2x.png" alt="" />
              <input v-model="liebfbpl" class="form-control col-md-12 pl-5 pr-5 sjbzt" :placeholder="pldsjuijjm" style="background: #F6F4F0;border: none;border-radius: 13px;font-size: calc(6px + 0.4vw);padding-top: 22px;padding-bottom: 22px;" type="text" plaintext='true' aria-label="Username" aria-describedby="basic-addon1">
              <img @click="expandfun(index, item.id, 'v')" class="dingwd zlxtops position-absolute curpo jiantofangda" style="right: 10px;z-index: 9;" src="@/img/daohangl/icon_hssy_twfb@2x.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/views/event-bus.js";
import SkeletonScreen from "./SkeletonScreen/guanywmSkeletonScreen.vue";
import { common_base, image, moment_list } from "@/request/api.js";
import {
  update_avatar,
  update_cover,
  video,
  momentssend,
  momentbase,
  momentlike,
  message_list,
  momentmessage_list,
  moment_message_like,
  moment_message,
  moment_del,
} from "@/request/grzx/index.js";

import { formatTimestamp } from "@/utils/common.js";
export default {
  components: {
    SkeletonScreen,
  },
  data() {
    return {
      scdid: "",
      scshow: false,
      scdidyiji: "",
      scshowyiji: false,
      scdiderji: "",
      scshowerji: false,
      sctpsp: true,
      toxiang: "",
      gjisDropdownOpen: false,
      guojiaid: "",
      hdxw: "",
      guojialist: [],
      id: "",
      dataLoaded: false,
      gyiasdid: "",
      qientids: "",
      plyjid: "",
      pldsjuijjm: "发表评论",
      liebfbpl: "",
      xthhh: null,
      // 发布图片的
      fbtplist: [],
      fbtpathplist: [],
      asdjiio:"",
      // 发布视频
      fbsplist: [],
      hover: false,
      fbspathplist: [],
      mediaList: [],
      nzxsmn: "",
      currentIndex: null,
      currentMediaType: "",
      isFullscreen: false,
      fmimageUrl: "",
      fmimageFile: "",
      beijfmimageUrl: "",
      beijfmimageFile: "",
      info: {
        moment: {
          data: [],
        },
      },
      activity: [],
      task: [],
      currentPage: 1,
      pageSize: 20, // 每页显示的数量
      last_page: "",
      tou: true,
    };
  },
  mounted() {
      this.asdjiio=this.$route.params.is_member;
      this.id = this.$route.params.id;
      this.common_base();
      this.my_center();
      this.momentbase();
      document.addEventListener("click", this.handleClickOutside);
    // 模拟数据加载
  },
  beforeDestroy() {
    // 在组件销毁前移除事件监听器
    document.removeEventListener("click", this.handleClickOutside);
    EventBus.$off("ttchuanrusffb");
    EventBus.$off("hsttthe_wall");
  },
  created() {
    EventBus.$on("hsttthe_wall", (msg) => {
      console.log(123)
      // 执行方法，处理接收到的数据
      // this.message = msg;
      // 如果需要，可以在这里调用其他方法
      if (this.currentPage < this.last_page) {
          this.currentPage++;
          this.my_center();
        }
    });
    EventBus.$on("ttchuanrusffb", (msg) => {
      console.log(msg,'msg')
      this.asdjiio=msg;
    });
  },
  computed: {
    visibleMoments() {
      const start = this.currentPage * this.pageSize;
      const end = start + this.pageSize;
      return this.info.moment.data.slice(start, end);
    },
  },
  methods: {
    // handleScroll(event) {
    //   const target = event.target;
    //   const scrollDistance =
    //     target.scrollHeight - target.scrollTop - target.clientHeight;
    //   if (scrollDistance < 10) {
    //     // 当滚动条距离底部小于10px时，认为是滚动到底部
    //     if (this.currentPage < this.last_page) {
    //       this.currentPage++;
    //       this.my_center();
    //     }
    //   }
    // },
    handleClickOnElement(event) {
      // 阻止事件冒泡到文档
      event.stopPropagation();
      // 在这里处理当前元素的点击事件（如果需要）
      console.log("点击了当前元素");
    },
    handleClickOutside(event) {
      // 检查点击是否发生在当前元素上
      if (this.$refs.activeElement) {
        // 如果不是，则改变状态
        this.hover = false;
      }
    },
    // 点击头像进行跳转
    hnsbui(e,v){
      if (e == localStorage.getItem("user_id")) {
				this.$router.push("/member/" + v);
			} else {
				this.$router.push("/members/" + v);
			}
    },
    tpsdin(e) {
      this.scshowyiji = false;
      this.scshowerji = false;
      if (this.scdid == e) {
        this.scshow = !this.scshow;
      } else {
        this.scshow = true;
      }
      this.scdid = e;
    },
    tpsdinyiji(e) {
      this.scshow = false;
      this.scshowerji = false;
      if (this.scdidyiji == e) {
        this.scshowyiji = !this.scshowyiji;
      } else {
        this.scshowyiji = true;
      }
      this.scdidyiji = e;
    },
    tpsdinerji(e) {
      this.scshow = false;
      this.scshowyiji = false;
      if (this.scdiderji == e) {
        this.scshowerji = !this.scshowerji;
      } else {
        this.scshowerji = true;
      }
      this.scdiderji = e;
    },
    async common_base() {
      const { data } = await common_base({
        is_coin: 0,
        is_user: 1,
        is_activity: 0,
        is_task: 0,
      });
      this.toxiang = data.user.avatar;
      this.tou = false;
    },
    async gjxuanz(e, v) {
      this.hdxw = e;
      this.guojiaid = v;
      this.gjisDropdownOpen = false;
    },
    gjtoggleDropdown() {
      this.gjisDropdownOpen = !this.gjisDropdownOpen;
      // 如果需要，可以在这里添加Bootstrap Dropdown的额外处理逻辑
    },
    funs() {
      this.$router.push("/activity");
    },
    // 点击最大的帖子删除
    async qbudsc(e, v) {
      const { code } = await moment_del({
        moment_id: e,
      });
      if (code == 200) {
        this.$bvToast.toast(this.$t("删除成功"), {
          title: this.$t("login.notice"),
          autoHideDelay: 2000,
          delay: 5000,
          appendToast: true,
          variant: "success",
        });
        this.info.moment.data.splice(v, 1);
        EventBus.$emit("call-sibling-b-methods", "Hello from Sibling As!");
      }
    },
    async scpljibdz(e, v, f) {
      const { code } = await moment_message({
        moment_id: e,
        message_id: v,
      });
      if (code == 200) {
        this.$bvToast.toast(this.$t("删除成功"), {
          title: this.$t("login.notice"),
          autoHideDelay: 2000,
          delay: 5000,
          appendToast: true,
          variant: "success",
        });
      }
      this.expandfun(f, e, "", 1);
    },
    // 评论赞
    async pljibdz(e, v, f) {
      const {} = await moment_message_like({
        moment_id: e,
        message_id: v,
      });
      this.expandfun(f, e, "", 1);
    },
    // 赞
    async dianzhan(e, v, m) {
      // console.log(m);
      if (v) {
        const { code } = await momentlike({
          moment_id: e,
          type_id: v,
        });
        if (code == 200) {
          for (let i = 0; i < this.info.moment.data.length; i++) {
            if (this.info.moment.data[i].id == e) {
              this.info.moment.data[i].like_type = {
                image: m.image,
                name: m.name,
              };
              this.info.moment.data[i].like.push({
                user_id: localStorage.getItem("user_id"),
                user_nickname: localStorage.getItem("nickname"),
                like_type: {
                  image: m.image,
                },
              });
            }
          }
        }
      } else {
        const { code } = await momentlike({
          moment_id: e,
          type_id: v,
        });
        if (code == 200) {
          for (let i = 0; i < this.info.moment.data.length; i++) {
            if (this.info.moment.data[i].id == e) {
              this.info.moment.data[i].like_type = null;
              for (let j = 0; j < this.info.moment.data[i].like.length; j++) {
                if (
                  this.info.moment.data[i].like[j].user_id ==
                  localStorage.getItem("user_id")
                ) {
                  this.info.moment.data[i].like.splice(j, 1);
                }
              }
            }
          }
        }
      }

      this.hover = false;
    },
    async momentbase() {
      const { data } = await momentbase();
      // console.log(data, "indexs");
      this.xthhh = data.like_type;
    },
    // 涂鸦墙发布
    async favtiewen() {
      if(this.asdjiio==1){

      }else{
        this.$bvToast.toast(this.$t("您还未加入该团体！"), {
          title: this.$t("login.warning"),
          autoHideDelay: 2000,
          delay: 5000,
          appendToast: true,
          variant: "danger",
        });
        return;
      }
      const { data, msg, code } = await momentssend({
        organization_id: this.id,
        activity_id: "",
        action_id: "",
        content: this.nzxsmn,
        image: this.fbtpathplist,
        video: this.fbspathplist.join(","),
      });
      if (code == 200) {
        this.nzxsmn = "";
        this.fbtpathplist = [];
        this.fbspathplist = [];
        this.fbsplist = [];
        this.fbtplist = [];
        this.page = 1;
        this.info.moment.data = [];
        this.my_center();
        this.$bvToast.toast(this.$t("发布成功"), {
          title: this.$t("login.notice"),
          autoHideDelay: 2000,
          delay: 5000,
          appendToast: true,
          variant: "success",
        });
      } else {
        this.$bvToast.toast(msg, {
          title: this.$t("login.warning"),
          autoHideDelay: 2000,
          delay: 5000,
          appendToast: true,
          variant: "danger",
        });
      }
    },
    fbspshuz() {
      this.$refs.fbsp.click();
    },
    async fbspinput(e) {
      this.sctpsp = false;
      if (this.fbsplist.length == 1) {
        this.sctpsp = true;
        return;
      }
      const { data, msg, code } = await video({
        file: e.target.files[0],
        type:'groups-photo',
          organization_id:this.id
      });
      if (code == 200) {
        this.fbspathplist.push(data.path);
        this.fbsplist.push({
          src: data.url,
        });
        this.sctpsp = true;
      } else {
        this.$bvToast.toast(msg, {
          title: this.$t("login.warning"),
          autoHideDelay: 2000,
          delay: 5000,
          appendToast: true,
          variant: "danger",
        });
        this.sctpsp = true;
      }
    },
    async fbtpshuz() {
      // 触发隐藏的input的点击事件
      this.$refs.fbtp.click();
    },
    async fbtpimage(e) {
      this.sctpsp = false;
      if (this.fbtplist.length == 10) {
        this.sctpsp = true;
        return;
      }
      const { data, msg, code } = await image({
        file: e.target.files[0],
        type:'groups-photo',
          organization_id:this.id
      });
      if (code == 200) {
        this.fbtpathplist.push(data.path);
        this.fbtplist.push({
          src: data.url,
        });
        this.sctpsp = true;
      } else {
        this.$bvToast.toast(msg, {
          title: this.$t("login.warning"),
          autoHideDelay: 2000,
          delay: 5000,
          appendToast: true,
          variant: "danger",
        });
        this.sctpsp = true;
      }
    },
    // 删除图片
    shanctp(e) {
      this.fbtplist.splice(e, 1);
      this.fbtpathplist.splice(e, 1);
    },
    showMedia(index, v) {
      this.mediaList = v;
      this.currentIndex = index;
      this.currentMediaType = this.mediaList[index].type;
      this.isFullscreen = true;
    },
    prevMedia() {
      if (this.currentIndex > 0) {
        this.currentIndex--;
        this.currentMediaType = this.mediaList[this.currentIndex].type;
      }
    },
    nextMedia() {
      if (this.currentIndex < this.mediaList.length - 1) {
        this.currentIndex++;
        this.currentMediaType = this.mediaList[this.currentIndex].type;
      }
    },
    closeFullscreen() {
      this.isFullscreen = false;
    },
    // 时间转换
    timeFilter(stringTime) {
      return formatTimestamp(stringTime * 1000);
    },
    // if (this.currentPage < this.last_page) {
    //       this.currentPage++;
    //       this.my_center();
    //     }
    

    async my_center() {
      const { data, msg,code } = await moment_list({
        page: this.currentPage,
        organization_id: this.id,
      });
      if(code==200){
        this.dataLoaded = true;
      }
      this.last_page = data.last_page;
      this.info = {
        moment: {
          data: [...this.info.moment.data, ...data.data],
        },
      };
      EventBus.$emit("call-sibling-b-methods", "Hello from Sibling As!");
      let that = this;
      // if (this.currentPage > 1) {
      //   for (let i = 0; i < that.info.moment.data.length; i++) {
      //     that.info.moment.data[i].image = that.info.moment.data[i].image
      //       .filter((item) => item.type == "image")
      //       .map((item) => item.src);
      //   }
      // }
      for (let i = 0; i < that.info.moment.data.length; i++) {
        let imsg = [];
        if (this.info.moment.data[i].video[0]) {
          imsg.push({
            type: "video",
            src: this.info.moment.data[i].video[0],
          });
        }
        for (let j = 0; j < that.info.moment.data[i].image.length; j++) {
          if (!Object.keys(that.info.moment.data[i].image[j]).includes("src")) {
            imsg.push({
              type: "image",
              src: that.info.moment.data[i].image[j],
            });
          } else {
            imsg.push(that.info.moment.data[i].image[j])
          }
        }
        that.info.moment.data[i].image = imsg;
      }
    },
    custom() {
      this.show = 0;
    },
    // 点击头像
    beijfmtriggerFileInput() {
      // 触发隐藏的input的点击事件
      this.$refs.beijfmfileInput.click();
    },
    async beijfmonFileChange(e) {
      const { data } = await image({
        file: e.target.files[0],
      });
      const {} = await update_cover({
        image: data.path,
      });
      this.my_center();
    },
    expandfun(index, id, v, f) {
      if (v) {
        if (this.liebfbpl == "") {
          this.$bvToast.toast("评论不能为空哦~", {
            title: this.$t("login.warning"),
            autoHideDelay: 2000,
            delay: 5000,
            appendToast: true,
            variant: "danger",
          });
          return;
        }
        momentmessage_list({
          moment_id: id,
          content: this.liebfbpl,
          message_id: this.plyjid,
          at_user_id: this.qientids,
        }).then((res) => {
          if (res.code == 200) {
            this.liebfbpl = "";
            message_list({
              moment_id: id,
            }).then((res) => {
              this.$set(this.info.moment.data, index, {
                ...this.info.moment.data[index],
                pllist: res.data.data,
              });
              this.$set(this.info.moment.data, index, {
                ...this.info.moment.data[index],
                isExpanded: true,
              });
            });
          }
        });
      } else {
        message_list({
          moment_id: id,
        }).then((res) => {
          this.$set(this.info.moment.data, index, {
            ...this.info.moment.data[index],
            pllist: res.data.data,
          });
          this.$set(this.info.moment.data, index, {
            ...this.info.moment.data[index],
            isExpanded: f == 0 ? false : true,
          });
        });
      }
    },
    showPreview(index) {
      this.currentIndex = index; // 设置当前预览的图片索引
      VuePhotoPreview.show(); // 显示图片预览
    },
    closePreview() {
      VuePhotoPreview.hide(); // 关闭图片预览
    },
  },
};
</script>
<style scoped lang="scss">
@media screen and (max-width: 992px) {
  .sjihhiuui {
    margin-top: 2vh !important;
  }
  .sjbkd1 {
    width: 62% !important;
  }
  .sjbkd2 {
    width: 38% !important;
  }
  .sjduanbjtp {
    height: calc(160px + 0.4vw) !important;
  }
  .mar {
    margin-right: 10px !important;
  }
}
@media screen and (max-width: 692px) {
  .sjbdkd {
    width: calc(80px + 0.4vw) !important;
    height: calc(80px + 0.4vw) !important;
    margin-top: -45px !important;
  }
}

@media screen and (max-width: 1200px) {
  .xiaojiant {
    width: calc(11px + 0.4vw) !important;
    height: calc(11px + 0.4vw) !important;
  }
  .zlxtop {
    width: calc(18px + 0.4vw) !important;
    height: calc(18px + 0.4vw) !important;
  }
  .zlxtops {
    width: calc(24px + 0.4vw) !important;
    height: calc(24px + 0.4vw) !important;
  }
  .zjpadding {
    padding: 10px 15px 10px 15px !important;
  }
  .pgnjksa {
    height: calc(270px + 0.4vw) !important;
  }
  .sjtxtp {
    margin-right: 10px !important;
    width: calc(40px + 0.4vw) !important;
    height: calc(40px + 0.4vw) !important;
  }
  .sjfjtp {
    width: calc(14px + 0.4vw) !important;
  }
  .srkjul {
    margin-right: 10px !important;
  }
  .sjbzt {
    font-size: calc(11px + 0.4vw) !important;
  }
  .sjbxzt {
    font-size: calc(9px + 0.4vw) !important;
  }
  .sjbkd {
    width: 49% !important;
  }
  .sjmb {
    margin-bottom: 1vh !important;
  }
  .yinc {
    display: none !important;
  }
  .sjibanb {
    display: none !important;
  }
  .aaaaa {
    display: block !important;
  }
  .sjihhiuui {
    height: 89.3vh !important;
    // margin-top: 2vh !important;
  }
  .sjtpr {
    height: calc(130px + 0.4vw) !important;
  }
  .sjitp {
    height: calc(203px + 0.4vw) !important;
  }
  .sfdvx {
    height: calc(203px + 0.4vw) !important;
  }
  .sjwidtshhs {
    width: calc(16px + 0.4vw) !important;
    height: calc(16px + 0.4vw) !important;
  }
}
.aaaaa {
  display: none;
}

.gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  /* 根据需要调整间距 */
}
.widtshh {
  width: calc(10px + 0.4vw);
}
.widtshhs {
  width: calc(10px + 0.4vw);
  height: calc(10px + 0.4vw);
}
.gallery-item {
  flex: 1 1 200px;
  /* 宽度、增长因子、基础宽度 */
  // margin: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
  text-align: center;
  /* 确保图片居中 */
}

.gallery-item img {
  width: 100%;
  /* 让图片宽度充满容器 */
  height: auto;
  /* 保持图片比例 */
}

/* 覆盖输入框被选中时的样式 */
.form-control:focus {
  /* 清除默认的边框颜色或阴影 */
  border-color: #ced4da !important;
  /* 假设你想要它恢复到非选中状态的颜色 */
  box-shadow: none !important;
  /* 清除默认的聚焦阴影 */
  /* 你可以添加更多的样式规则来进一步自定义你的输入框 */
}

.srk {
  border: 1px dashed #ffd672;
  border-radius: 12px;
  font-size: calc(6px + 0.5vw);
}

.fbtw {
  background: #ffd672;
  border-radius: 12px 12px 12px 12px;

  .fbtw-zi {
    font-weight: 500;
    font-size: calc(5px + 0.4vw);
  }
}

.zhankai {
  background: #f6f4f0;
  text-align: center;
  border-radius: 12px 12px 12px 12px;
}

/* 隐藏水平滚动条 */
.lieb::-webkit-scrollbar {
  display: none;
}

.dingwd {
  width: calc(20px + 0.5vw);
  height: calc(20px + 0.5vw);
}

.ltdgdt {
  max-height: calc(300px + 0.5vw);
  overflow-y: auto;
}

/* 滚动条整体部分 */
.ltdgdt::-webkit-scrollbar {
  width: 8px;
  /* 滚动条的宽度 */
  height: 8px;
  /* 垂直滚动条的宽度 */
}

/* 滚动条滑块 */
.ltdgdt::-webkit-scrollbar-thumb {
  background-color: #888;
  /* 滑块颜色 */
  border-radius: 20px;
  /* 滑块边角圆滑度 */
  border: 3px solid transparent;
  /* 滑块边框，设置为透明 */
  background-clip: content-box;
  /* 使得边框内不填充背景色 */
}

/* 滚动条轨道 */
.ltdgdt::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  /* 轨道颜色 */
  border-radius: 10px;
  /* 轨道边角圆滑度 */
}

// .xunafuxgio{}
.xunafuxgio:hover {
  transform: scale(1.001);
  /* 悬浮时放大1.1倍 */
  border-radius: 12px;
  // padding: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  /* 悬浮时添加阴影效果 */
}

.ckqbb:hover {
  font-weight: 700;
  transform: scale(1.001);
}

.tpxfbd:hover {
  transform: scale(1.015);
  /* 悬浮时放大1.1倍 */
  // padding: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  /* 悬浮时添加阴影效果 */
}

.jiantofangda:hover {
  transform: scale(1.2);
}

.fullscreen-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.fullscreen-image {
  max-width: 90%;
  max-height: 90%;
}

.thumbnail {
  margin: 10px;
  cursor: pointer;
}

.image-container {
  position: relative;
  display: inline-block;
  /* 或者其他适合你的布局方式 */
  display: flex;
  align-items: center;
}

.image-container img {
  display: block;
  /* 防止图片下方有空隙 */
  width: 100%;
  /* 或者其他尺寸 */
  height: auto;
}

.image-container .overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  /* 黑色半透明遮罩 */
  /* 你可以在这里添加其他样式，比如阴影效果，但通常遮罩层不需要阴影 */
}

.image-container .overlays {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* 你可以在这里添加其他样式，比如阴影效果，但通常遮罩层不需要阴影 */
}

/* 隐藏水平滚动条 */
.xlcd::-webkit-scrollbar {
  display: none;
}

.xuazz:hover {
  background: #ffebd2;
}

.zhankai {
  background: #ffebd2;
  text-align: center;
  border-radius: 12px 12px 0 0;
  border: none;
  font-weight: 400;
  font-size: calc(6px + 0.4vw);
  color: #1a1a1a;
}
.shanc {
  width: calc(30px + 0.4vw);
  font-size: calc(6px + 0.4vw);
}
.yinchez {
  top: 90%;
  right: 0;
  background: #fffaf0;
  z-index: 9999999999999999999999;
}
.yinchezimg {
  width: calc(20px + 0.4vw);
  height: calc(20px + 0.4vw);
}
.ddd {
  width: calc(25px + 0.4vw);
  height: calc(25px + 0.4vw);
}
.ckqbb:hover {
  transform: scale(1.001);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.ckqb:hover {
  transform: scale(1.101);
}
</style>