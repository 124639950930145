import request from './index.js'

// 捐款-捐款首页
export const donation_list = (params) => request.get("/donation/home", { params });

// 捐款-详情
export const donation_Deta = (params) => request.get("/donation/detail", { params });

// 捐款-活动列表
export const donation_ActiveLis = (params) => request.get("/donation/list", { params });

// 好事企业-企业列表
export const company_Lis = (params) => request.get("/company/list", { params });

// 公共-获取基本信息
export const activ_Lis = (params) => request.get("/common/base", { params });

// 好事企业-企业详情
export const comp_deta = (params) => request.get("/company/detail", { params });


// 好事企业-企业成员
export const comp_member = (params) => request.get("/company/member", { params });

// 好事企业-加入企业
export const comp_join = (params) => request.post("/company/join", params);

// 好事企业-退出企业
export const comp_leav = (params) => request.post("/company/leave", params);


// 好事团体-物资造册
export const team_grounp = (params) => request.get("/group/material", { params });

// 好事团体-物资造册上传
export const team_grounpupload = (params) => request.post("/group/material_upload", params);

// 好事团体-排行榜
export const team_grounprank = (params) => request.post("/group/ranking", params);

// 公共-上传文件
export const team_file = (params) => request.post("/upload/file", params);

// 排行榜
export const group_ranking = (params) => request.post("/group/ranking", params);

// 功德榜-功德榜
export const merit_list = (params) => request.get("/merit/list", { params });
// 功德榜-志工证明
export const voluntee = (params) => request.post("/merit/volunteer", params);
// 捐款订单信息
export const period_result = (params) => request.post("/donation/period_result", params);
// 银行转账方式捐款
export const donation_donate_bank = (params) => request.post("/donation/donate_bank", params);
// 捐款记录基本信息
export const merit_donate_base = (params) => request.get("/merit/donate_base", {params});
// 捐款记录
export const merit_donate = (params) => request.post("/merit/donate", params);
