<template>
  <div>
    <div style="height: 88.5vh;overflow-y: auto;" class="parent yinc">
      <!-- 骨架屏 -->
      <skeleton-screen v-if="!dataLoaded"></skeleton-screen>
      <div v-if="dataLoaded" class="col-md-12 pl-0 pr-0">
        <div v-if="show == 0" class="col-md-12 pl-0 pr-0 yinc">
          <div class="col-sm-12 pl-0 pr-0 d-flex justify-content-between align-items-center">
            <div class="d-flex align-items-center" style="font-size: calc(7px + 0.5vw);">
              <img v-if="dataLoaded" @click="xindfanhui(0)" class="curpo dcbd mr-2" style="width: calc(25px + 0.4vw);" src="@/img/login/icon_fanhui@2x.png" alt="" />
              <div class="mr-2" style="font-weight: 600;">{{ $t('捐款团体列表') }}</div>
              <!-- <div style="color: #999999;">{{ $t('金额最高、人数最多、有交代资金使用、时间排序的筛选') }} </div> -->
            </div>
            <div class="mr-3">
              <div class="pl-0 pr-0 position-relative col-md-12">
                <button class="d-flex ckqbb zhankai dydpd pt-2 pl-2 pr-2 pb-2 pl-0 pr-0" type="button" id="dropdownMenuButton" @click="toggleDropdown()">
                  <div class="d-flex align-items-center justify-content-between col-md-12">
                    <div style="font-size: calc(6px + 0.4vw);">{{ jhuxa ? jhuxa : '' }}
                    </div>
                    <div class="ml-3"><img style="width: calc(7px + 0.4vw);" src="@/img/daohangl/icon_xlcd_xljt@2x (1).png" alt="" /></div>
                  </div>
                </button>
                <div class="dropdown-menu yuanjiao xlcd position-absolute  text-center" aria-labelledby="dropdownMenuButton" v-show="isDropdownOpen" style="border-radius:7px;background: #FFFAF0;left: -25%;">
                  <a class="dropdown-item xuazz yuanjiao mb-2" :style="{ 'background': jhuxa == item.text ? '#FFD672' : '' }" @click="xuanz(item)" href="#" v-for="(item, index) in options" :key="index">{{ item.text }}</a>

                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 pl-0 pr-0 position-relative d-flex pl-0 pr-0 justify-content-between align-items-center">
            <div style="height: 80vh;overflow-y: auto;" class="col-md-12 parent pl-0 pr-0" @scroll="handleScroll">
              <div class="row col-md-12 pl-0 pr-0 mt-3">
                <div class="col-sm-3 pl-0 pr-0 pl-3" v-for="(item, index) in infos" :key="index">
                  <div class="col-sm-12 baise mb-3 ckqbb p-3 yuanjiao pl-0 pr-0 curpo" style="width: 100%;text-align: center;">
                    <!-- <img @click="fun(1)" style="width: 100%" src="@/img/daohangl/pic_hssy_tw_one@2x.png"
										alt="" /> -->
                    <img @click="fun(1, item.id)" class="yuanjiao" style="width: 100%;height:calc(210px + 0.4vw);object-fit: cover;" :src="item.image" alt="" />
                    <div @click="fun(1, item.id)" class="mt-3" style="font-size: calc(8px + 0.4vw);font-weight: 700;"><span style="color: #FF797A;">{{ item.name }}</span> </div>
                    <div @click="fun(1, item.id)" class="mt-2 ellipsis col-md-12 pl-0 pr-0 mb-2 col-sm-12 col-lg-9" style="font-size: calc(6px + 0.4vw);width: calc(200px + 0.5vw);margin: auto;">
                      {{ item.organization_name }}
                    </div>
                    <div @click="fun(1, item.id)" class="col-sm-12 d-flex justify-content-between pl-0 pr-0" style="width: 100%;">
                      <div class="col-sm-4 text-center pl-0 pr-0" style="font-size: calc(5px + 0.4vw);">
                        <div style="color: #999999;">{{ $t('已使用捐款') }}</div>
                        <div style="font-weight: 500;">{{ item.used_amount ? item.used_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }}</div>
                      </div>
                      <div class="col-sm-4 text-center pl-0 pr-0" style="font-size: calc(5px + 0.4vw);">
                        <div style="color: #999999;">{{ $t('已收到捐款') }}</div>
                        <div style="font-weight: 500;">{{ item.received_amount ? item.received_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }}</div>
                      </div>
                      <div class="col-sm-4 text-center pl-0 pr-0" style="font-size: calc(5px + 0.4vw);">
                        <div style="color: #999999;">{{ $t('累计捐款人数') }}</div>
                        <div style="font-weight: 500;">{{ item.donor_num ? item.donor_num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }}</div>
                      </div>
                    </div>
                    <div class="col-sm-12 pl-0 pr-0 d-flex justify-content-between mt-3 align-items-center" style="width: 100%;">
                      <div @click="fun(2,item.id)" class="col-sm-4 pl-0 pr-0 ckqbb curpo d-flex yuanjiao pl-2 pr-2 mr-2 pt-2 pb-2 align-items-center" style="background: #FDDDC9;text-align: center;border-radius: 15px;">
                        <div style="font-size: calc(4px + 0.4vw);text-align: center;width: 100%;">
                          {{ $t('资金使用') }}</div>
                        <img style="width: calc(2px + 0.2vw);height:calc(3px + 0.3vw);" src="@/img/details/icon_right@2x.png" alt="" />
                      </div>
                      <!-- 动态进度条 -->
                      <div @click="fun(1, item.id)" class="yuanjiao col-sm-8 pl-0 pr-0" style="background-color: #F6F4F0;height: calc(15px + 0.4vw);">
                        <div class="progress-bar yuanjiao jdtt" role="progressbar" :style="{'width': item.received_amount ?  Math.floor(item.used_amount / item.received_amount * 100) + '%' : 0 + '%'}" aria-valuemin="0" aria-valuemax="100"></div>
                        <div style="position: absolute;right: 5%;top: 0;">{{ item.received_amount ? Math.floor(item.used_amount / item.received_amount  * 100) : 0 }}%</div>

                      </div>

                    </div>
                    <div class="col-sm-12 pl-0 pr-0 d-flex justify-content-between mt-3 align-items-center">
                      <div @click="fun(1, item.id)" class="mr-2 col-sm-4 pl-0 pr-0 ckqbb curpo d-flex yuanjiao pl-2 pr-2 pt-2 pb-2 align-items-center" style="background: #F6F4F0;">
                        <div class="mr-1" style="font-size: calc(6px + 0.4vw);text-align: center;width: 100%;">
                          {{ $t('查看详情') }}</div>
                      </div>
                      <!-- 动态进度条 -->
                      <div @click="fun(3, item.id)" class="col-sm-8 pl-0 pr-0 ckqbb yuanjiao pt-2 pb-2" style="background-color: #FFD672;text-align: center;font-size: calc(7px + 0.4vw);">
                        {{ $t('捐款') }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
        <div v-if="show == 0" class="col-md-12 pl-0 pr-0 sjyinc mt-5">
          <div class="d-flex justify-content-between align-items-center" style="width: 100%;">
            <div class="d-flex align-items-center" style="font-size: calc(7px + 0.5vw);">
              <img v-if="dataLoaded" @click="xindfanhui(0)" class="curpo dcbd mr-2" style="width: calc(25px + 0.4vw);" src="@/img/login/icon_fanhui@2x.png" alt="" />
              <div class="mr-2" style="font-weight: 600;">{{ $t('捐款团体列表') }}</div>
              <!-- <div style="color: #999999;">{{ $t('金额最高、人数最多、有交代资金使用、时间排序的筛选') }} </div> -->
            </div>
            <div class="mr-3">
              <div class="pl-0 pr-0 position-relative col-md-12">
                <button class="d-flex ckqbb zhankai dydpd pt-2 pl-2 pr-2 pb-2 pl-0 pr-0" type="button" id="dropdownMenuButton" @click="toggleDropdown()">
                  <div class="d-flex align-items-center justify-content-between col-md-12">
                    <div style="font-size: calc(6px + 0.4vw);">{{ jhuxa ? jhuxa : '' }}
                    </div>
                    <div class="ml-3"><img style="width: calc(7px + 0.4vw);" src="@/img/daohangl/icon_xlcd_xljt@2x (1).png" alt="" /></div>
                  </div>
                </button>
                <div class="dropdown-menu yuanjiao xlcd position-absolute  text-center" aria-labelledby="dropdownMenuButton" v-show="isDropdownOpen" style="border-radius:7px;background: #FFFAF0;left: -25%;">
                  <a class="dropdown-item xuazz yuanjiao mb-2" :style="{ 'background': jhuxa == item.text ? '#FFD672' : '' }" @click="xuanz(item)" href="#" v-for="(item, index) in options" :key="index">{{ item.text }}</a>

                </div>
              </div>
            </div>
          </div>
          <div class="position-relative justify-content-between align-items-center" style="width: 100%;">
            <div style="height: 80vh;overflow-y: auto;" class="col-md-12 parent pl-0 pr-0" @scroll="handleScroll">
              <div class="mt-3" style="display: flex;justify-content: space-between;flex-wrap: wrap;">
                <div style="width: 49%;" v-for="(item, index) in infos" :key="index">
                  <div class="col-sm-12 baise mb-3 ckqbb p-2 yuanjiao pl-0 pr-0 curpo" style="width: 100%;text-align: center;">
                    <img @click="fun(1, item.id)" class="yuanjiao" style="width: 100%;height:calc(160px + 0.4vw);object-fit: cover;" :src="item.image" alt="" />
                    <div @click="fun(1, item.id)" class="mt-3" style="font-size: calc(8px + 0.4vw);font-weight: 700;"><span style="color: #FF797A;">{{ item.name }}</span> </div>
                    <div @click="fun(1, item.id)" class="mt-2 ellipsis col-md-12 pl-0 pr-0 mb-2 col-sm-12 col-lg-9" style="font-size: calc(6px + 0.4vw);width: calc(200px + 0.5vw);margin: auto;">
                      {{ item.organization_name }}
                    </div>
                    <div @click="fun(1, item.id)" class="col-sm-12 d-flex justify-content-between pl-0 pr-0" style="width: 100%;">
                      <div class="col-sm-4 text-center pl-0 pr-0" style="font-size: calc(5px + 0.4vw);">
                        <div style="color: #999999;">{{ $t('已使用捐款') }}</div>
                        <div style="font-weight: 500;">{{ item.used_amount ? item.used_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }}</div>
                      </div>
                      <div class="col-sm-4 text-center pl-0 pr-0" style="font-size: calc(5px + 0.4vw);">
                        <div style="color: #999999;">{{ $t('已收到捐款') }}</div>
                        <div style="font-weight: 500;">{{ item.received_amount ? item.received_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }}</div>
                      </div>
                      <div class="col-sm-4 text-center pl-0 pr-0" style="font-size: calc(5px + 0.4vw);">
                        <div style="color: #999999;">{{ $t('累计捐款人数') }}</div>
                        <div style="font-weight: 500;">{{ item.donor_num ? item.donor_num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }}</div>
                      </div>
                    </div>
                    <div class="col-sm-12 pl-0 pr-0 d-flex justify-content-between mt-3 align-items-center" style="width: 100%;">
                      <div @click="fun(2,item.id)" class="col-sm-4 pl-0 pr-0 ckqbb curpo d-flex yuanjiao pl-2 pr-2 mr-2 pt-2 pb-2 align-items-center" style="background: #FDDDC9;text-align: center;border-radius: 15px;">
                        <div style="font-size: calc(4px + 0.4vw);text-align: center;width: 100%;">
                          {{ $t('资金使用') }}</div>
                        <img style="width: calc(2px + 0.2vw);height:calc(3px + 0.3vw);" src="@/img/details/icon_right@2x.png" alt="" />
                      </div>
                      <!-- 动态进度条 -->
                      <div @click="fun(1, item.id)" class="yuanjiao col-sm-8 pl-0 pr-0" style="background-color: #F6F4F0;height: calc(15px + 0.4vw);">
                        <div class="progress-bar yuanjiao jdtt" role="progressbar" :style="{'width': item.received_amount ?  Math.floor(item.used_amount / item.received_amount * 100) + '%' : 0 + '%'}" aria-valuemin="0" aria-valuemax="100"></div>
                        <div style="position: absolute;right: 5%;top: 0;">{{ item.received_amount ? Math.floor(item.used_amount / item.received_amount  * 100) : 0 }}%</div>

                      </div>

                    </div>
                    <div class="col-sm-12 pl-0 pr-0 d-flex justify-content-between mt-3 align-items-center">
                      <div @click="fun(1, item.id)" class="mr-2 col-sm-4 pl-0 pr-0 ckqbb curpo d-flex yuanjiao pl-2 pr-2 pt-2 pb-2 align-items-center" style="background: #F6F4F0;">
                        <div class="mr-1" style="font-size: calc(6px + 0.4vw);text-align: center;width: 100%;">
                          {{ $t('查看详情') }}</div>
                      </div>
                      <!-- 动态进度条 -->
                      <div @click="fun(3, item.id)" class="col-sm-8 pl-0 pr-0 ckqbb yuanjiao pt-2 pb-2" style="background-color: #FFD672;text-align: center;font-size: calc(7px + 0.4vw);">
                        {{ $t('捐款') }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <ckxq v-if="show == 1" @custom='custom' :ckidss="idss" :istype="isT"></ckxq>
      <zzsy v-if="show == 2" @custom='custom' :ckidss="idss"></zzsy>
      <juank v-if="show == 3" :isid="idds" @custom='custom'></juank>
    </div>
    <div style="height: 98.2vh;overflow-y: auto;" class="parent sjyinc">
      <!-- 骨架屏 -->
      <skeleton-screen v-if="!dataLoaded"></skeleton-screen>
      <div v-if="dataLoaded" class="col-md-12 pl-0 pr-0">
        <div v-if="show == 0" class="col-md-12 pl-0 pr-0 yinc">
          <div class="col-sm-12 pl-0 pr-0 d-flex justify-content-between align-items-center">
            <div class="d-flex align-items-center" style="font-size: calc(7px + 0.5vw);">
              <img v-if="dataLoaded" @click="xindfanhui(0)" class="curpo dcbd mr-2" style="width: calc(25px + 0.4vw);" src="@/img/login/icon_fanhui@2x.png" alt="" />
              <div class="mr-2" style="font-weight: 600;">{{ $t('捐款团体列表') }}</div>
              <!-- <div style="color: #999999;">{{ $t('金额最高、人数最多、有交代资金使用、时间排序的筛选') }} </div> -->
            </div>
            <div class="mr-3">
              <div class="pl-0 pr-0 position-relative col-md-12">
                <button class="d-flex ckqbb zhankai dydpd pt-2 pl-2 pr-2 pb-2 pl-0 pr-0" type="button" id="dropdownMenuButton" @click="toggleDropdown()">
                  <div class="d-flex align-items-center justify-content-between col-md-12">
                    <div style="font-size: calc(6px + 0.4vw);">{{ jhuxa ? jhuxa : '' }}
                    </div>
                    <div class="ml-3"><img style="width: calc(7px + 0.4vw);" src="@/img/daohangl/icon_xlcd_xljt@2x (1).png" alt="" /></div>
                  </div>
                </button>
                <div class="dropdown-menu yuanjiao xlcd position-absolute  text-center" aria-labelledby="dropdownMenuButton" v-show="isDropdownOpen" style="border-radius:7px;background: #FFFAF0;left: -25%;">
                  <a class="dropdown-item xuazz yuanjiao mb-2" :style="{ 'background': jhuxa == item.text ? '#FFD672' : '' }" @click="xuanz(item)" href="#" v-for="(item, index) in options" :key="index">{{ item.text }}</a>

                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 pl-0 pr-0 position-relative d-flex pl-0 pr-0 justify-content-between align-items-center">
            <div style="height: 80vh;overflow-y: auto;" class="col-md-12 parent pl-0 pr-0" @scroll="handleScroll">
              <div class="row col-md-12 pl-0 pr-0 mt-3">
                <div class="col-sm-3 pl-0 pr-0 pl-3" v-for="(item, index) in infos" :key="index">
                  <div class="col-sm-12 baise mb-3 ckqbb p-3 yuanjiao pl-0 pr-0 curpo" style="width: 100%;text-align: center;">
                    <!-- <img @click="fun(1)" style="width: 100%" src="@/img/daohangl/pic_hssy_tw_one@2x.png"
										alt="" /> -->
                    <img @click="fun(1, item.id)" class="yuanjiao" style="width: 100%;height:calc(210px + 0.4vw);object-fit: cover;" :src="item.image" alt="" />
                    <div @click="fun(1, item.id)" class="mt-3" style="font-size: calc(8px + 0.4vw);font-weight: 700;"><span style="color: #FF797A;">{{ item.name }}</span> </div>
                    <div @click="fun(1, item.id)" class="mt-2 ellipsis col-md-12 pl-0 pr-0 mb-2 col-sm-12 col-lg-9" style="font-size: calc(6px + 0.4vw);width: calc(200px + 0.5vw);margin: auto;">
                      {{ item.organization_name }}
                    </div>
                    <div @click="fun(1, item.id)" class="col-sm-12 d-flex justify-content-between pl-0 pr-0" style="width: 100%;">
                      <div class="col-sm-4 text-center pl-0 pr-0" style="font-size: calc(5px + 0.4vw);">
                        <div style="color: #999999;">{{ $t('已使用捐款') }}</div>
                        <div style="font-weight: 500;">{{ item.used_amount ? item.used_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }}</div>
                      </div>
                      <div class="col-sm-4 text-center pl-0 pr-0" style="font-size: calc(5px + 0.4vw);">
                        <div style="color: #999999;">{{ $t('已收到捐款') }}</div>
                        <div style="font-weight: 500;">{{ item.received_amount ? item.received_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }}</div>
                      </div>
                      <div class="col-sm-4 text-center pl-0 pr-0" style="font-size: calc(5px + 0.4vw);">
                        <div style="color: #999999;">{{ $t('累计捐款人数') }}</div>
                        <div style="font-weight: 500;">{{ item.donor_num ? item.donor_num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }}</div>
                      </div>
                    </div>
                    <div class="col-sm-12 pl-0 pr-0 d-flex justify-content-between mt-3 align-items-center" style="width: 100%;">
                      <div @click="fun(2,item.id)" class="col-sm-4 pl-0 pr-0 ckqbb curpo d-flex yuanjiao pl-2 pr-2 mr-2 pt-2 pb-2 align-items-center" style="background: #FDDDC9;text-align: center;border-radius: 15px;">
                        <div style="font-size: calc(4px + 0.4vw);text-align: center;width: 100%;">
                          {{ $t('资金使用') }}</div>
                        <img style="width: calc(2px + 0.2vw);height:calc(3px + 0.3vw);" src="@/img/details/icon_right@2x.png" alt="" />
                      </div>
                      <!-- 动态进度条 -->
                      <div @click="fun(1, item.id)" class="yuanjiao col-sm-8 pl-0 pr-0" style="background-color: #F6F4F0;height: calc(15px + 0.4vw);">
                        <div class="progress-bar yuanjiao jdtt" role="progressbar" :style="{'width': item.received_amount ?  Math.floor(item.used_amount / item.received_amount * 100) + '%' : 0 + '%'}" aria-valuemin="0" aria-valuemax="100"></div>
                        <div style="position: absolute;right: 5%;top: 0;font-size: 7px;">{{ item.received_amount ? Math.floor(item.used_amount / item.received_amount  * 100) : 0 }}%</div>

                      </div>

                    </div>
                    <div class="col-sm-12 pl-0 pr-0 d-flex justify-content-between mt-3 align-items-center">
                      <div @click="fun(1, item.id)" class="mr-2 col-sm-4 pl-0 pr-0 ckqbb curpo d-flex yuanjiao pl-2 pr-2 pt-2 pb-2 align-items-center" style="background: #F6F4F0;">
                        <div class="mr-1" style="font-size: calc(6px + 0.4vw);text-align: center;width: 100%;">
                          {{ $t('查看详情') }}</div>
                      </div>
                      <!-- 动态进度条 -->
                      <div @click="fun(3, item.id)" class="col-sm-8 pl-0 pr-0 ckqbb yuanjiao pt-2 pb-2" style="background-color: #FFD672;text-align: center;font-size: calc(7px + 0.4vw);">
                        {{ $t('捐款') }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
        <div v-if="show == 0" class="col-md-12 pl-0 pr-0 sjyinc mt-5">
          <div class="d-flex justify-content-between align-items-center" style="width: 100%;">
            <div class="d-flex align-items-center" style="font-size: calc(7px + 0.5vw);">
              <img v-if="dataLoaded" @click="xindfanhui(0)" class="curpo dcbd mr-2" style="width: calc(25px + 0.4vw);" src="@/img/login/icon_fanhui@2x.png" alt="" />
              <div class="mr-2" style="font-weight: 600;">{{ $t('捐款团体列表') }}</div>
              <!-- <div style="color: #999999;">{{ $t('金额最高、人数最多、有交代资金使用、时间排序的筛选') }} </div> -->
            </div>
            <div class="mr-3">
              <div class="pl-0 pr-0 position-relative col-md-12">
                <button class="d-flex ckqbb zhankai dydpd pt-2 pl-2 pr-2 pb-2 pl-0 pr-0" type="button" id="dropdownMenuButton" @click="toggleDropdown()">
                  <div class="d-flex align-items-center justify-content-between col-md-12">
                    <div style="font-size: calc(6px + 0.4vw);">{{ jhuxa ? jhuxa : '' }}
                    </div>
                    <div class="ml-3"><img style="width: calc(7px + 0.4vw);" src="@/img/daohangl/icon_xlcd_xljt@2x (1).png" alt="" /></div>
                  </div>
                </button>
                <div class="dropdown-menu yuanjiao xlcd position-absolute  text-center" aria-labelledby="dropdownMenuButton" v-show="isDropdownOpen" style="border-radius:7px;background: #FFFAF0;left: -25%;">
                  <a class="dropdown-item xuazz yuanjiao mb-2" :style="{ 'background': jhuxa == item.text ? '#FFD672' : '' }" @click="xuanz(item)" href="#" v-for="(item, index) in options" :key="index">{{ item.text }}</a>

                </div>
              </div>
            </div>
          </div>
          <div class="position-relative justify-content-between align-items-center" style="width: 100%;">
            <div style="height: 88vh;overflow-y: auto;" class="col-md-12 parent pl-0 pr-0" @scroll="handleScroll">
              <div class="mt-3" style="display: flex;justify-content: space-between;flex-wrap: wrap;">
                <div style="width: 49%;" v-for="(item, index) in infos" :key="index">
                  <div class="col-sm-12 baise mb-3 ckqbb p-2 yuanjiao pl-0 pr-0 curpo" style="width: 100%;text-align: center;">
                    <img @click="fun(1, item.id)" class="yuanjiao" style="width: 100%;height:calc(160px + 0.4vw);object-fit: cover;" :src="item.image" alt="" />
                    <div @click="fun(1, item.id)" class="mt-3" style="font-size: calc(8px + 0.4vw);font-weight: 700;"><span style="color: #FF797A;">{{ item.name }}</span> </div>
                    <div @click="fun(1, item.id)" class="mt-2 ellipsis col-md-12 pl-0 pr-0 mb-2 col-sm-12 col-lg-9" style="font-size: calc(6px + 0.4vw);width: calc(200px + 0.5vw);margin: auto;">
                      {{ item.organization_name }}
                    </div>
                    <div @click="fun(1, item.id)" class="col-sm-12 d-flex justify-content-between pl-0 pr-0" style="width: 100%;">
                      <div class="col-sm-4 text-center pl-0 pr-0" style="font-size: calc(5px + 0.4vw);">
                        <div style="color: #999999;">{{ $t('已使用捐款') }}</div>
                        <div style="font-weight: 500;">{{ item.used_amount ? item.used_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }}</div>
                      </div>
                      <div class="col-sm-4 text-center pl-0 pr-0" style="font-size: calc(5px + 0.4vw);">
                        <div style="color: #999999;">{{ $t('已收到捐款') }}</div>
                        <div style="font-weight: 500;">{{ item.received_amount ? item.received_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }}</div>
                      </div>
                      <div class="col-sm-4 text-center pl-0 pr-0" style="font-size: calc(5px + 0.4vw);">
                        <div style="color: #999999;">{{ $t('累计捐款人数') }}</div>
                        <div style="font-weight: 500;">{{ item.donor_num ? item.donor_num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }}</div>
                      </div>
                    </div>
                    <div class="col-sm-12 pl-0 pr-0 d-flex justify-content-between mt-3 align-items-center" style="width: 100%;">
                      <div @click="fun(2,item.id)" class="col-sm-4 pl-0 pr-0 ckqbb curpo d-flex yuanjiao pl-2 pr-2 mr-2 pt-2 pb-2 align-items-center" style="background: #FDDDC9;text-align: center;border-radius: 15px;">
                        <div style="font-size: calc(4px + 0.4vw);text-align: center;width: 100%;">
                          {{ $t('资金使用') }}</div>
                        <img style="width: calc(2px + 0.2vw);height:calc(3px + 0.3vw);" src="@/img/details/icon_right@2x.png" alt="" />
                      </div>
                      <!-- 动态进度条 -->
                      <div @click="fun(1, item.id)" class="yuanjiao col-sm-8 pl-0 pr-0" style="background-color: #F6F4F0;height: calc(15px + 0.4vw);">
                        <div class="progress-bar yuanjiao jdtt" role="progressbar" :style="{'width': item.received_amount ?  Math.floor(item.used_amount / item.received_amount * 100) + '%' : 0 + '%'}" aria-valuemin="0" aria-valuemax="100"></div>
                        <div style="position: absolute;right: 5%;top: 0;font-size: calc(10px + 0.4vw);">{{ item.received_amount ? Math.floor(item.used_amount / item.received_amount  * 100) : 0 }}%</div>

                      </div>

                    </div>
                    <div class="col-sm-12 pl-0 pr-0 d-flex justify-content-between mt-3 align-items-center">
                      <div @click="fun(1, item.id)" class="mr-2 col-sm-4 pl-0 pr-0 ckqbb curpo d-flex yuanjiao pl-2 pr-2 pt-2 pb-2 align-items-center" style="background: #F6F4F0;">
                        <div class="mr-1" style="font-size: calc(6px + 0.4vw);text-align: center;width: 100%;">
                          {{ $t('查看详情') }}</div>
                      </div>
                      <!-- 动态进度条 -->
                      <div @click="fun(3, item.id)" class="col-sm-8 pl-0 pr-0 ckqbb yuanjiao pt-2 pb-2" style="background-color: #FFD672;text-align: center;font-size: calc(7px + 0.4vw);">
                        {{ $t('捐款') }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <ckxq v-if="show == 1" @custom='custom' :ckidss="idss" :istype="isT"></ckxq>
      <zzsy v-if="show == 2" @custom='custom' :ckidss="idss"></zzsy>
      <juank v-if="show == 3" :isid="idds" @custom='custom'></juank>
    </div>
  </div>
</template>

<script>
import { donation_ActiveLis } from "@/request/aapi.js";

import SkeletonScreen from "./jkhd/SkeletonScreen/xinSkeletonScreen.vue";
import ckxq from "./jkhd/wmuc/ckxq.vue";
import zzsy from "./jkhd/wmuc/zzsy.vue";
import juank from "./jkhd/wmuc/juank.vue";
export default {
  components: {
    SkeletonScreen,
    ckxq,
    zzsy,
    juank,
  },
  data() {
    return {
      jhuxa: this.$t("请选择排序类型"),
      dataLoaded: true,
      show: 0,
      isDropdownOpen: false,
      progress: 60,
      items: [
        {
          name: 1,
        },
        {
          name: 2,
        },
        {
          name: 3,
        },
        {
          name: 4,
        },
        {
          name: 5,
        },
        {
          name: 6,
        },
        {
          name: 7,
        },
        {
          name: 8,
        },
      ],

      // 组织机构id
      organization_id: null,
      order: "",
      infos: [],
      selected: null,
      options: [
        // { value: null, text: '请选择排序类型' },
        {
          value: "received_amount_desc",
          text: this.$t("金额最高"),
        },
        {
          value: "donor_num_desc",
          text: this.$t("人数最多"),
        },
        {
          value: "is_use_desc",
          text: this.$t("有交代资金用途"),
        },
        {
          value: "create_time_asc",
          text: this.$t("创建时间升序"),
        },
        {
          value: "create_time_desc",
          text: this.$t("创建时间降序"),
        },
      ],

      // 每页显示行数
      perPage: 10,
      // 当前页码
      currentPage: 1,
      last_page: "",
    };
  },

  created() {
    this.updateVisibleItems();
  },
  mounted() {
    // this.getInfo()
    // 模拟数据加载
    // setTimeout(() => {
    // 	this.dataLoaded = true;
    // 	// 在这里加载你的数据
    // }, 2000);
  },

  computed: {},
  methods: {
    handleScroll(event) {
      const target = event.target;
      const scrollDistance =
        target.scrollHeight - target.scrollTop - target.clientHeight;
      if (scrollDistance < 10) {
        // 当滚动条距离底部小于10px时，认为是滚动到底部
        if (this.currentPage < this.last_page) {
          this.currentPage++;
          this.getInfo();
        }
      }
    },
    // 选择排序后触发
    isChan() {
      this.getInfo();
    },
    // 点击分页
    // 处理页码点击事件
    handlePageChange(newPage) {
      // newPage 是新的页码值
      // console.log(`用户点击了第 ${newPage} 页`);

      this.currentPage = newPage;
      this.getInfo();
    },

    getInfo() {
      let data = {
        page: this.currentPage,
        order: this.selected,
        organization_id: this.organization_id,
      };
      donation_ActiveLis(data).then((res) => {
        // console.log(res, "泪飙")
        if (res.code == 200) {
          this.last_page = res.data.last_page;
          this.infos = [...this.infos, ...res.data.data];
          this.perPage = res.data.per_page;
        }
      });
    },

    xuanz(e) {
      this.infos = [];
      this.jhuxa = e.text;
      this.selected = e.value;
      this.isDropdownOpen = false;
      this.getInfo();
    },
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
      // 如果需要，可以在这里添加Bootstrap Dropdown的额外处理逻辑
    },
    // 详情等页面返回按钮
    xindfanhui(e) {
      this.$emit("xindfanhui", e);

      this.jhuxa = this.$t("请选择排序类型");
      this.selected = "";
      this.isDropdownOpen = false;
    },

    custom(e) {
      this.show = e;
    },

    fun(e, a) {
      this.show = e;
      if (e == 1 && a) {
        this.isT = 1;
        this.idss = a;
      } else {
        this.isT = 2;
        this.idss = a;
      }

      if (e == 3 && a) {
        this.idds = a;
      }
    },
    scrollLeft() {
      if (this.currentIndex > 0) {
        this.dataLoaded = false;
        setTimeout(() => {
          this.currentIndex--;
          this.updateVisibleItems();
        }, 2000);
      }
    },
    scrollRight() {
      const maxIndex = Math.floor((this.items.length - 1) / 4);
      if (this.currentIndex < maxIndex) {
        this.dataLoaded = false;
        setTimeout(() => {
          this.currentIndex++;
          this.updateVisibleItems();
        }, 2000);
      }
    },
    updateVisibleItems() {
      // 计算当前应该显示的列表项索引范围
      const start = this.currentIndex * 4;
      const end = Math.min(start + 4, this.items.length);
      // 分组显示，每组四个
      this.visibleItems = Array.from(
        {
          length: Math.ceil((end - start) / 4),
        },
        (_, i) => this.items.slice(start + i * 4, start + (i + 1) * 4)
      );
      this.dataLoaded = true;
    },
  },
};
</script>

<style scoped lang="scss">
@media screen and (max-width: 995px) {
  .sjyinc {
    display: block !important;
  }
  .yinc {
    display: none !important;
  }
}
.sjyinc {
  display: none;
}
.ckqbb:hover {
  transform: scale(1.001);
  /* 悬浮时放大1.1倍 */
  // padding: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.dcbd:hover {
  transform: scale(1.2);
  /* 悬浮时放大1.1倍 */
  // padding: 12px;
}

.jdtt {
  height: 100%;
  background: #fab1ab;
  color: #1a1a1a;
  text-align: right;
  font-size: calc(6px + 0.4vw);
}

.fangda:hover {
  transform: scale(1.02);
}

.xlcd {
  display: block;
  background: #ffebd2;
  border: none;
}
.dropdown-menu {
  min-width: calc(25px + 0.5vw) !important;
}
.xuazz:hover {
  background: #ffebd2;
}

.zhankai {
  background: #ffebd2;
  text-align: center;
  border-radius: 8px;
  border: none;
  font-weight: 400;
  font-size: calc(6px + 0.4vw);
  color: #1a1a1a;
}

.parent::-webkit-scrollbar {
  display: none;
}

.custom-pagination ::v-deep .page-item.active .page-link {
  background-color: rgb(255, 214, 114);
  border-color: rgb(255, 214, 114);
  color: #000;
}
</style>