<template>
  <div>
    <div class="d-flex align-items-center yuanjiao baise pl-3 pr-3 pt-3 pb-3 mt-3 position-relative">
      <img v-if="show == 2 || show == 3" @click="fun()" style="width:25px;" src="@/img/login/icon_fanhui@2x.png">
      <img v-if="show == 1" style="width:25px;" src="@/img/zhufanhui.png">
      <div class="d-flex align-items-center position-absolute" style="left: 36%;">
        <img class="mr-2" style="width:40px;" src="@/img/daohangl/pic_hssy_logo@2x.png" alt="" />
        <div style="font-size: calc(12px + 0.5vw);font-weight: 500;">{{ $t('好事道') }}</div>
      </div>
    </div>
    <div v-if="show == 1" class="parent baise yuanjiao mt-3 mb-3 p-3 heggt">
      <div @click="fmtriggerFileInput" class="pl-0 pr-0 position-relative curpo" style="width: calc(80px + 0.4vw);height: calc(80px + 0.4vw);margin: auto;">
        <input ref="fmfileInput" type="file" @change="fmonFileChange" style="display: none;">
        <img class="sjbdkd" style="border-radius: 50%;width: 100%;height: 100%;" :src="fmimageUrl" alt="" />
        <img class="position-absolute jiantofangda" style="width: calc(25px + 0.4vw);right: 0;bottom: 0;" src="@/img/grzx/icon_grzx_txqiehuan@2x.png" alt="" />
      </div>
      <div class="text-center mingcddax mt-2">{{ info.nickname }}</div>
      <div class="text-center">
        <div class="mb-2 mt-3 ztnjj">{{ $t("請您參與") }}</div>
        <div class="xian"></div>
      </div>
      <div class="text-center pt-2 pb-2 pl-3 pr-3 wxts mt-3 yuanjiao">
        {{ $t("温馨提示：填寫後將獲得") }}50{{ $t("好人積分") }}
      </div>
      <div class="d-flex" style="justify-content: flex-end;">
        <div @click="songchu(1)" class="pl-2 pr-2 pt-1 pb-1 mt-3 tiaog">{{ $t("跳過") }}</div>
      </div>
      <!-- <div @click="tiaoguo1(2)" class="pt-2 pb-2 yuanjiao anbutton text-center mt-3">{{ $t("提交問卷") }}</div> -->
    </div>
    <div v-if="show == 2" class="parent baise yuanjiao mt-3 mb-3 p-3 heggt">
      <div @click="fmtriggerFileInput" class="pl-0 pr-0 position-relative curpo" style="width: calc(80px + 0.4vw);height: calc(80px + 0.4vw);margin: auto;">
        <input ref="fmfileInput" type="file" @change="fmonFileChange" style="display: none;">
        <img class="sjbdkd" style="border-radius: 50%;width: 100%;height: 100%;" :src="fmimageUrl" alt="" />
        <img class="position-absolute jiantofangda" style="width: calc(25px + 0.4vw);right: 0;bottom: 0;" src="@/img/grzx/icon_grzx_txqiehuan@2x.png" alt="" />
      </div>
      <div class="text-center mt-2 mb-3 mingcddax">{{ info.nickname }}</div>
      <div class="d-flex justify-content-between">
        <div class="pt-2 pb-2 text-center yuanjiao kd3" style="background: #FCD7D5;">
          <div class="jiaz">{{ mals.activity_num > 10000 ? (mals.activity_num / 10000).toFixed(1) + 'w' :
            mals.activity_num }}</div>
          <div class="hsjiaz">{{ $t("活動數量") }}</div>
        </div>
        <div class="pt-2 pb-2 text-center yuanjiao kd3" style="background: #FFEBD2;">
          <div class="jiaz">{{ mals.good_coin > 10000 ? (mals.good_coin / 10000).toFixed(1) + 'w' : mals.good_coin }}
          </div>
          <div class="hsjiaz d-flex justify-content-center" style="margin: auto;">
            <img class="mr-1" style="width: 15px;height: 15px;" src="@/img/daohangl/pic_hssy_haorenbi@2x.png" alt="" />
            <div>{{ $t("好人積分") }}</div>
          </div>
        </div>
        <div class="pt-2 pb-2 text-center yuanjiao kd3" style="background: #FDDDC9;">
          <div class="jiaz">{{ mals.carbon_coin > 10000 ? (mals.carbon_coin / 10000).toFixed(1) + 'w' : mals.carbon_coin
            }}</div>
          <div class="hsjiaz d-flex justify-content-center" style="margin: auto;">
            <img class="mr-1" style="width: 15px;height: 15px;" src="@/img/daohangl/pic_hssy_tanbi@2x.png" alt="" />
            <div>{{ $t("碳積分") }}</div>
          </div>
        </div>
      </div>
      <div class="text-center pt-2 pb-2 pl-3 pr-3 wxts mt-3 yuanjiao" v-if="mals.is_volunteer == 1">
        {{ $t("溫馨提示：親愛的") }}{{ info.nickname }}{{ $t("員工") }},{{ $t("因志工假規定，請務必上傳露臉的活動出席照片作為證明，以利HR後續銷假流程（備注：如針對活動有心得想進行分享，合作團體將會獎勵你") }}{{ mals.sign_good_coin }}{{ $t('點好人積分，感謝你的幫忙與建議）') }}
      </div>
      <div class="text-center pt-2 pb-2 pl-3 pr-3 wxts mt-3 yuanjiao" v-else>
        {{ $t("溫馨提示：發佈活動心得，將獲得") }}{{ mals.sign_good_coin }}{{ $t("好人積分（剩餘") }}{{ mals.activity_times - mals.moment_num
        }}{{ $t("次）") }}
      </div>
      <div class="form-group mt-3">
        <label class="d-flex justify-content-between align-items-center" style="font-weight: 500;font-size: 15px;">
          <div>{{ $t("活動心得") }}</div>
          <div @click="xindtg()" class="pl-2 pr-2 pt-1 pb-1 tiaog">{{ $t("跳過") }}</div>
        </label>
        <div>
          <b-form-textarea class="sjbxzt" style="border: none;background: #F6F4F0;font-size: 15px; height: 160px !important;" :disabled='isLoading' id="textarea" v-model="form.fbxd" :placeholder="$t('請輸入活動心得…')" rows="3" max-rows="6" no-resize></b-form-textarea>
        </div>
      </div>
      <!-- 活动照片 -->
      <label style="font-weight: 500;font-size: 15px;">{{ $t('活动照片') }}</label>
      <div class='d-flex' style="width: 100%;flex-wrap: wrap;">
        <div v-show="hdzpimageUrl" v-for="(item, index) in hdzpimageUrl" :key="index" class="mb-2 position-relative sjbdkd" style="width: 100%;">
          <img class="yuanjiao" style="width: 100%;height: calc(150px + 1vw);object-fit: cover;" :src="item" alt="" />
          <!-- 每一个图片的删除按钮 -->
          <img @click="hdzpsczgzmsc(index)" class="position-absolute curpo dcbd" style="width: calc(15px + 0.4vw);right: 3%;top: 3%;" src="@/img/grzx/icon_guanbitupian@2x.png" alt="" />
        </div>
        <div class="mb-2 sjbdkd" style="width: 100%;">
          <div class="d-flex justify-content-center position-relative" style="height: calc(150px + 1vw);background: #F6F4F0;border-radius: 12px;">
            <div class="sjbxzt" style="font-size: 15px;margin-top: 10%;color: #999999;">
              {{ $t('login.placeholderArchiveMessage') }}
            </div>
            <input ref="hdzpfileInput" type="file" @change="hdzponFileChange" style="display: none;">
            <b-button block class="btn denglu position-absolute" @click="hdzptriggerFileInput" style="width: 60%;height: 35px;bottom: 25%;font-size: 12px;" squared><span>{{ $t('login.xzndda')
                }}</span></b-button>
          </div>
        </div>
      </div>
      <div @click="songchu(2)" class="pt-2 pb-2 yuanjiao anbutton text-center mt-3">{{ $t("送出") }}</div>
    </div>
  </div>
</template>

<script>
import {} from "@/request/api.js";
import { EventBus } from "@/views/event-bus.js";
import { update_avatar, my_center } from "@/request/grzx/index.js";
import { image, qrcode_sign, qrcode_send_moment } from "@/request/api.js";
import { timeFilter, formatTimestamp } from "@/utils/common.js";
export default {
  components: {},
  data() {
    return {
      show: 2,
      id: "",
      fmimageUrl: "",
      info: {},
      form: {
        fbxd: "",
      },
      hdzpimageUrl: [],
      hdzpimageUrlpath: [],
      mals: {},
    };
  },
  mounted() {},
  methods: {
    msg(e) {
      this.id = e;
      this.qiandao(e);
      // 模拟数据加载
      this.ged();
    },
    async qiandao(e) {
      const { data, code } = await qrcode_sign({ activity_id: e });
      if (code == 200) {
        this.mals = data.data;
        if (this.mals.is_sign_in == 1) {
          this.$bvToast.toast(
            this.$t("報到成功，已為您發放") +
              this.mals.sign_good_coin +
              this.$t("好人積分"),
            {
              title: this.$t("login.notice"),
              autoHideDelay: 2000,
              delay: 5000,
              appendToast: true,
              variant: "success",
            }
          );
        }
      }
    },
    async ged() {
      const { data, msg } = await my_center();
      this.fmimageUrl = data.info.avatar;
      this.info = data.info;
    },
    xindtg() {
      let route = this.$route.path;
      if (this.mals.is_questionnaire == 1) {
        setTimeout(() => {
          if (this.mals.is_complete == 0) {
            this.$router.push("/member/" + this.info.nickname);
          } else {
            let f = "";
            if (route.includes("/groups")) {
              f = "groups";
            } else {
              f = "enterprises";
            }
            this.$router.push(
              "/activity/" + f + "/" + e.slug + "/" + e.id + "/info"
            );
          }
        }, 1000);
      } else {
        this.show = 1;
      }
    },
    async songchu(e) {
      let route = this.$route.path;
      if (e == 1) {
        if (this.mals.is_complete == 0) {
          this.$router.push("/member/" + this.info.nickname);
        } else {
          let f = "";
          if (route.includes("/groups")) {
            f = "groups";
          } else {
            f = "enterprises";
          }
          this.$router.push(
            "/activity/" + f + "/" + e.slug + "/" + e.id + "/info"
          );
        }
      } else {
        const { data, code } = await qrcode_send_moment({
          activity_id: this.id,
          content: this.form.fbxd,
          image: this.hdzpimageUrlpath,
        });
        if (code == 200) {
          if (this.mals.activity_times != this.mals.moment_num) {
            if (this.mals.is_volunteer == 1) {
              this.$bvToast.toast(
                this.$t("報到成功，已按志工標準為您發放") +
                  this.mals.moment_good_coin +
                  this.$t("好人積分"),
                {
                  title: this.$t("login.notice"),
                  autoHideDelay: 2000,
                  delay: 5000,
                  appendToast: true,
                  variant: "success",
                }
              );
            } else {
              this.$bvToast.toast(
                this.$t("報到成功，已為您發放") +
                  this.mals.moment_good_coin +
                  this.$t("好人積分"),
                {
                  title: this.$t("login.notice"),
                  autoHideDelay: 2000,
                  delay: 5000,
                  appendToast: true,
                  variant: "success",
                }
              );
            }
          }
          // @click="songchu(1)"
          if (this.mals.is_questionnaire == 1) {
            setTimeout(() => {
              if (this.mals.is_complete == 0) {
                this.$router.push("/member/" + this.info.nickname);
              } else {
                let f = "";
                if (route.includes("/groups")) {
                  f = "groups";
                } else {
                  f = "enterprises";
                }
                this.$router.push(
                  "/activity/" + f + "/" + e.slug + "/" + e.id + "/info"
                );
              }
            }, 1000);
          } else {
            this.show = 1;
          }
        }
      }
    },
    hdzptriggerFileInput() {
      // 触发隐藏的input的点击事件
      this.$refs.hdzpfileInput.click();
    },
    // 上传活动照片
    hdzpsczgzmsc(index) {
      this.hdzpimageUrl.splice(index, 1);
      this.hdzpimageUrlpath.splice(index, 1);
    },
    async hdzponFileChange(e) {
      const { data, msg, code } = await image({
        file: e.target.files[0],
        type: "members-volunteer-cert",
      });
      if (code == 200) {
        this.hdzpimageUrlpath.push(data.path);
        this.hdzpimageUrl.push(data.url);
      } else {
        this.$bvToast.toast(msg, {
          title: this.$t("login.warning"),
          autoHideDelay: 2000,
          delay: 5000,
          appendToast: true,
          variant: "danger",
        });
      }
    },
    // 点击头像
    fmtriggerFileInput() {
      // 触发隐藏的input的点击事件
      this.$refs.fmfileInput.click();
    },
    async fmonFileChange(e) {
      const { data } = await image({
        file: e.target.files[0],
        type: "members-avatar",
      });
      const { code } = await update_avatar({
        image: data.path,
      });
      if (code == 200) {
        this.$bvToast.toast(this.$t("修改成功"), {
          title: this.$t("login.notice"),
          autoHideDelay: 2000,
          delay: 5000,
          appendToast: true,
          variant: "success",
        });
        EventBus.$emit("call-sibling-b-method", "Hello from Sibling A!");
        this.ged();
      }
    },
    yhzcd(e) {},
    fun() {
      if (this.show == 3) {
        this.show = 2;
      } else {
        this.show = 1;
        this.activeid = "";
      }
    },
  },
};
</script>
<style scoped lang="scss">
.anbutton {
  background: #ffd672;
  font-size: 14px;
}

.yzm {
  height: 27px;
  line-height: 27px;
  text-align: center;
  font-weight: 400;
  font-size: 12px;
  color: #1a1a1a;
  background: #ffd672;
  border-radius: 12px;
  padding: 0 12px;
}

.srkzt {
  font-size: 13px;
}

.hsjiaz {
  font-size: 11px;
  color: #666666;
}

.jiaz {
  font-size: 15px;
  font-weight: 600;
}

.srkxzt {
  font-size: 11px;
}

.yhxyi {
  font-size: calc(10px);

  span {
    color: #b66d00;
  }
}

.wxts {
  background: #fef7f6;
  color: #ff797a;
  font-size: 13px;
}

.tiaog {
  background: #e5e5e5;
  border-radius: 6px;
  color: #666666;
  font-size: 12px;
}

.kd3 {
  width: 32.5%;
}

.xian {
  width: 30%;
  height: 2px;
  background: #ffd672;
  margin: auto;
}

.ckhdxq {
  background: #ffeab8;
  font-size: 13px;
  width: 55%;
  margin: auto;
}

.ztnjj {
  font-size: 14px;
  font-weight: 600;
}

.mingcddax {
  font-size: 16px;
  font-weight: 700;
}

.content {
  font-size: calc(10px + 0.4vw);
  line-height: calc(20px + 0.5vw);
}

.heggt {
  height: 76vh;
  overflow-y: auto;
  width: 100%;
}

.swkd {
  font-size: 15px;
  font-weight: 700;
}

.sysr {
  font-size: 12px;
  color: #b66d00;
}

.denglu {
  background: #ffd672;
  border: none !important;
  height: 48px;
  color: #1a1a1a;
  font-weight: 500;
  border-radius: 12px !important;
}

.denglu:hover {
  background: #ffd672;
  border: none !important;
  height: 48px;
  color: #1a1a1a;
  font-weight: 500;
  transform: scale(1.001);
  /* 悬浮时放大1.1倍 */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
</style>