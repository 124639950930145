import axios from 'axios';
import router from '../router';

export const url = "https://api.gddao.com/api"
// export const url = "https://haoshidao.demo.benfusite.com/api"
// export const url = "http://192.168.0.106:1001/api"
// export const url = "https://team-api.gddao.com/api"

// 创建axios实例
const request = axios.create({
	// baseURL: process.env.VUE_APP_BASE_API, // api的base_url
	baseURL: url,
	timeout: 50000 // 请求超时时间
});

// 请求拦截器
request.interceptors.request.use(
	config => {
		let token = localStorage.getItem("token");
		let lang = localStorage.getItem('language') || 'cht';
		// let lang = 'cht';
		config.headers['Authorization'] = token;
		config.headers['Language'] = lang;
		config.headers['Content-Type'] = 'multipart/form-data'
		// 可以在这里添加请求头等信息
		return config;
	},
	error => {
		// 请求错误处理
		Promise.reject(error);
	}
);

// 响应拦截器
request.interceptors.response.use(
	response => {
		// 处理没有token返回登录
		
		if (response.data.code == 401) {
			router.push("/login")
			localStorage.setItem('previousRoutePath', this.$router.currentRoute.matched)
			return;
		} else if(response.data.code == 403){
			router.push("/login")
			localStorage.setItem('previousRoutePath', this.$router.currentRoute.matched)
			
			return;
		}else if(response.data.code == 404){
			router.push("/login")
			localStorage.setItem('previousRoutePath', this.$router.currentRoute.matched)
			
			return;
		}else if(response.data.code == 405){
			return;
		}
		else if(response.data.code == 406){
			router.push("/login")
			localStorage.setItem('previousRoutePath', this.$router.currentRoute.matched)
			
			return;
		}
		else if(response.data.code == 407){
			router.push("/login")
			localStorage.setItem('previousRoutePath', this.$router.currentRoute.matched)
			
			return;
		}
		// 对响应数据做处理，例如只返回data部分
		const res = response.data;
		// 根据返回的状态码做相应处理，例如401未授权等
		return res;
	},
	error => {
		// 响应错误处理
		return Promise.reject(error);
	}
);

export default request;