<template>
  <!-- 顶部 -->
  <div class="col-md-12 d-flex justify-content-between align-items-center pt-3 pb-3 pl-0 pr-0 xiaop">
    <div class="d-flex align-items-center ml-3" style="cursor: pointer;"  @click="indexPath">
      <img class="mr-2" style="width:52px;" src="@/img/daohangl/pic_hssy_logo@2x.png" alt=""  />
      <div style="font-size: calc(12px + 0.5vw);font-weight: 500;">{{ $t('好事道') }}</div>
    </div>
    <div class="d-flex align-items-center mr-3">
      <div @click="funs()" class="d-flex align-items-center mr-3 pt-1 pb-1 pl-2 pr-5 curpo ckqbb" style="background: #FFEBD2;border-radius:12px;">
        <img class="mr-2" style="width: 36px;font-size: calc(9px + 0.4vw);font-weight: 500;" src="@/img/daohangl/pic_hssy_haorenbi@2x.png" alt="" />
        <div class="">
          <!-- <div style="font-size: calc(8px + 0.4vw);">{{ $t('总发行好人币') }}</div> -->
          <div style="font-size: calc(8px + 0.4vw);">{{ $t('平台总发好人积分') }}</div>

          <div style="font-size: calc(8px + 0.4vw);font-weight: 700;">{{ coin.good_coin ? coin.good_coin.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }}</div>
        </div>
      </div>
      <div @click="funs()" class="d-flex align-items-center mr-3 pt-1 pb-1 pl-2 pr-5 curpo ckqbb" style="background: #FDDDC9;border-radius:12px;">
        <img class="mr-2" style="width: 36px;font-size: calc(9px + 0.4vw);font-weight: 500;" src="@/img/daohangl/pic_hssy_tanbi@2x.png" alt="" />
        <div class="">
          <!-- <div style="font-size: calc(8px + 0.4vw);">{{ $t('总发行碳币') }}</div> -->
          <div style="font-size: calc(8px + 0.4vw);">{{ $t('平台总发碳积分') }}</div>

          <div style="font-size: calc(8px + 0.4vw);font-weight: 700;">{{ coin.carbon_coin ? coin.carbon_coin.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }}</div>
        </div>
      </div>
      <div class="xian mr-4"></div>
      <button v-if="token" class="d-flex align-items-center pt-1 pb-1 pr-0" style="background: #FFFAF0;border-radius:12px;border: none;width: calc(200px + 0.5vw);" @mouseover="hover = true" @mouseleave="hover = false">
        <img v-if="!hover" class="mr-2 ml-2 curpo" style="width: calc(40px + 0.5vw);height: calc(40px + 0.5vw);font-size: calc(9px + 0.4vw);font-weight: 500;border-radius: 50%;" :src="user.avatar || '@/img/daohangl/pic_hssy_zdh_touxiang@2x.png'" alt="" />
        <div v-if="!hover" class="curpo" style="text-align: left;">
          <div class="" style="font-size: calc(6px + 0.4vw);">{{ user.nickname }}</div>
          <div class="" style="font-size: calc(6px + 0.4vw);font-weight: 700;">{{ user.username }}</div>
        </div>
        <button @mouseover="hover = true" @mouseleave="hover = false" v-if="hover" class="" style="height: calc(40px + 0.5vw);background: #FFFAF0;border-radius:12px;border: none;z-index: 999;display: flex;align-items: center;">
          <div class="d-flex align-items-center curpo mr-2 mixsz" :style="{'margin-left':user.member_admin.length ? '' :'40%'}" @click="nonetoken()">
            <img style="width: calc(23px + 0.4vw);" class="mr-1 " src="@/img/daohangl/icon_tcdl@2x.png" alt="" />
            <div class="" style="font-size: calc(5px + 0.4vw);width: 55px;">{{ $t('退出登录') }}</div>
          </div>
          <div v-if="user.member_admin.length" class="d-flex align-items-center curpo mixsz" @click="guanliht()">
            <img style="width: calc(23px + 0.4vw);" class="mr-1 " src="@/img/icon_sy_glht@2x.png" alt="" />
            <div class="" style="font-size: calc(5px + 0.4vw);width: 55px;">{{ $t('前往后台') }}</div>
          </div>
        </button>
      </button>
      <div v-else class="d-flex align-items-center curpo" @click="fun('/login')">
        <div class="mr-4">
          <img class="mr-2" style="width: 38px;font-size: 24px;font-weight: 500;" src="@/img/daohangl/pic_hssy_wlgtouxiang@2x.png" alt="" />
          <span style="font-size: calc(8px + 0.4vw);">{{ $t('未登录') }}</span>
        </div>
        <b-button class="dengluann">{{ $t('common.login') }}</b-button>
      </div>

    </div>
    <!-- 加入团体弹框 -->
    <b-modal v-model="modalShow" modal-class="custom-modal-width" :centered='true' hide-header hide-footer>
      <div style="width: 100%;text-align: center;" class="pb-2 position-relative">
        <img @click="modalShow = false" class="position-absolute curpo dcbd" style="width: calc(15px + 0.5vw);right: 20px;top: -20px;" src="@/img/details/cha.png" alt="" />
        <div class="mb-3 mt-4" style="font-size: calc(9px + 0.4vw);font-weight: 700;">{{ $t('请选择管理员账号')
							}}</div>
        <button class="d-flex ckqbb zhankai col-sm-12 dydpd pt-2 pb-2 pl-0 pr-0" type="button" id="dropdownMenuButton" @click="gjtoggleDropdown()">
          <div class="d-flex align-items-center justify-content-between col-md-12">
            <div style="font-size: calc(6px + 0.4vw);">
              {{ organization_name ?  organization_name : $t('请选择') }}
            </div>
            <div>
              <!-- {{ $t('国家') }} -->
              <img class="ml-2" style="width: calc(7px + 0.4vw);" src="@/img/daohangl/icon_xlcd_xljt@2x (1).png" alt="" />
            </div>
          </div>
        </button>
        <!-- Dropdown内容，使用v-show控制显示 -->
        <div class="dropdown-menu yuanjiao xlcd position-absolute col-sm-12 text-center" aria-labelledby="dropdownMenuButton" v-show="gjisDropdownOpen" style="border-radius:7px;background: #FFFAF0;top: 45%;">
          <a v-for="(item, index) in user.member_admin" :key="index" class="dropdown-item xuazz yuanjiao mb-2" :style="{ 'background': business_number == item.business_number ? '#FFD672' : '' }" @click="gjxuanz(item.organization_name, item.business_number)" href="#"> {{ item.organization_name }}</a>
        </div>
        <div style="height: calc(10px + 1vw);"></div>
        <button @click="qrbulletframe()" class="pl-5 pr-5 pt-3 pb-3 yuanjiao ckqbb curpo annys" style="font-size: calc(6px + 0.4vw);background: #FFD672;border: none;">
          {{ $t('login.confirm') }}
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { EventBus } from "@/views/event-bus.js";
export default {
  components: {},
  data() {
    return {
      modalShow: false,
      gjisDropdownOpen: false,
      business_number: "",
      organization_name: "",
      hover: false,
      token: localStorage.getItem("token"),
    };
  },
  props: {
    // 代币信息
    coin: {
      type: Object,
      default: {},
    },
    // 用户信息
    user: {
      type: Object,
      default: {},
    },
  },
  created(){
		EventBus.$on("token", (msg) => {
      // 执行方法，处理接收到的数据
	  console.log(123)
      // 如果需要，可以在这里调用其他方法
      this.token= localStorage.getItem("token")
    });
	},
  mounted() {},
  methods: {
    indexPath() {
      this.$router.push("/");
    },
    async gjxuanz(e, v) {
      this.organization_name = e;
      this.business_number = v;
      this.gjisDropdownOpen = false;
    },
    gjtoggleDropdown() {
      this.gjisDropdownOpen = !this.gjisDropdownOpen;
      // 如果需要，可以在这里添加Bootstrap Dropdown的额外处理逻辑
    },
    guanliht() {
      if (this.user.member_admin.length == 1) {
        window.location.href =
          "https://api.gddao.com/user/login/web?business_number=" +
          this.user.member_admin[0].business_number +
          "&auth=" +
          localStorage.getItem("token");
      } else {
        this.modalShow = true;
      }
    },
    qrbulletframe() {
      window.location.href =
        "https://api.gddao.com/user/login/web?business_number=" +
        this.business_number +
        "&auth=" +
        localStorage.getItem("token");
    },
    // 跳转登录
    fun(e) {
      this.$router.push(e);
    },
    funs() {
      if (this.$route.path == "/members/point-logs") {
        return;
      }
      this.$router.push("/members/point-logs");
    },
    // 删除token地方退出登录
    nonetoken() {
      localStorage.removeItem("token");
      localStorage.removeItem("user_id");
      localStorage.setItem('previousRoutePath', this.$router.currentRoute)
      this.$router.push("/login");
    },
  },
};
</script>

<style scoped lang="scss">
@media screen and (max-width: 995px) {
  .xiaop {
    display: none !important;
  }
}

.dengluann {
  width: calc(64px + 1vw);
  background: #ffd672;
  border-radius: 12px;
  text-align: center;
  font-size: calc(8px + 0.4vw);
  color: #1a1a1a;
  border: none;
}

.dengluann:hover {
  background-color: #ffd672;
  font-weight: 600;
  color: #1a1a1a;
  /* 自定义的悬浮背景色 */
  box-shadow: 0 0px 6px rgba(0, 0, 0.1, 0.2);
  /* 默认情况下Bootstrap可能已经添加了阴影，这里可以调整或添加 */
}

.xian {
  width: 2px;
  height: 38px;
  background: #f6f4f0;
}

.ckqbb:hover {
  transform: scale(1.001);
  /* 悬浮时放大1.1倍 */
  // padding: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.mixsz:hover {
  transform: scale(1.07);
  font-weight: bold;
}
.xlcd {
  display: block;
  background: #ffebd2;
  border: none;
}

/* 隐藏水平滚动条 */
.xlcd::-webkit-scrollbar {
  display: none;
}
.zhankai {
  background: #ffebd2;
  text-align: center;
  border-radius: 12px 12px 12px 12px;
  border: none;
  font-weight: 400;
  font-size: calc(6px + 0.4vw);
  color: #1a1a1a;
}
</style>