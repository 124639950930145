<template>
	<div class="col-md-12 pl-0 pr-0">
		<div class="col-md-12 yuanjiao pt-3 pb-3 pl-0 pr-0"
			style="font-size: calc(7px + 0.4vw);line-height: calc(15px + 0.5vw);">
			<div class="col-md-12 d-flex pl-0 pr-0">
				<div @click="fun(1)" class="pt-3 pb-3 curpo qieh sjbzt"
					:style="{ 'background': show == 1 ? '#FFFFFF' : '#FFFAF0', 'font-weight': show == 1 ? '700' : '500', 'color': show == 1 ? '' : '#999999' }">
					{{ $t('申请共运') }}</div>
				<div @click="fun(2)" class="pt-3 pb-3 curpo qieh sjbzt"
					:style="{ 'background': show == 2 ? '#FFFFFF' : '#FFFAF0', 'font-weight': show == 2 ? '700' : '500', 'color': show == 2 ? '' : '#999999' }">
					{{ $t('共运任务') }}</div>
			</div>
			<sqgy v-if="show == 1"></sqgy>
			<gyrw v-if="show == 2"></gyrw>
		</div>
	</div>
</template>

<script>
import sqgy from './gongyun/sqgy.vue'
import gyrw from './gongyun/gyrw.vue'

export default {
	components: {
		sqgy,
		gyrw
	},
	data() {
		return {
			show: 1,
			isLoading: false,
		};
	},
	created() {

	},
	mounted() {

	},
	computed: {

	},
	methods: {
		fun(e) {
			this.show = e
		}
	},
};
</script>

<style scoped>
@media screen and (max-width: 991px) {
  .sjbzt {
    font-size: calc(11px + 0.4vw) !important;
  }
  .sjbxzt {
    font-size: calc(9px + 0.4vw) !important;
  }
}
.dcbd:hover {
	transform: scale(1.2);
}

.qieh {
	width: calc(100px + 0.4vw);
	text-align: center;
	border-radius: 12px 12px 0 0;
}
</style>