<template>
  <div>
    <!-- 骨架屏 -->
    <skeleton-screen v-if="!dataLoaded"></skeleton-screen>
    <div v-if="dataLoaded" class="mb-2 d-flex justify-content-between pcxians">
      <div class="mt-3 sjjkdax" v-if="show == 0" style="font-size: calc(8px + 0.4vw);font-weight: 600;">{{ $t('捐款团体列表') }}</div>
      <div class="pl-0 pr-0 position-relative mt-3 d-flex justify-content-between align-items-center">
        <div class="pl-0 pr-0 position-relative d-flex mr-3 align-items-center sjbsrkkd yuanjiao">
          <input v-model="keywords" @input="searchFn" class="form-control sxbj inppt pr-5 sjbxzt" type="text" :placeholder="this.$t('搜寻')" plaintext="true" aria-label="Username" aria-describedby="basic-addon1" />
          <img @click="searchFn" style="right: 20px; width: calc(10px + 0.4vw)" class="position-absolute curpo sbxfbd" src="@/img/daohangl/icon_hssy_sousuo@2x.png" alt="" />
        </div>
        <div @click="cjsj()" :style="{background:sxiajt == 1 ? '#FFEBD2':sxiajt == 2 ? '#FFEBD2':'#E5E5E5'}" class="pl-2 pr-2 mr-3 pt-2 pb-2 d-flex align-items-center curpo yuanjiao" style="font-size: calc(7px + 0.4vw);">
          <div class="mr-2">{{ $t("创建时间") }}</div>
          <img v-if="sxiajt==1" style="width: calc(10px + 0.4vw);height: calc(10px + 0.4vw);" src="@/img/icon_sx_shang@2x (1).png" alt="">
          <img v-if="sxiajt==2" style="width: calc(10px + 0.4vw);height: calc(10px + 0.4vw);" src="@/img/icon_sx_shang@2x (2).png" alt="">
          <img v-if="sxiajt!=2 && sxiajt!=1" style="width: calc(10px + 0.4vw);height: calc(10px + 0.4vw);" src="@/img/icon_sx_shang@2x (2).png" alt="">
        </div>
        <div @click="pjfs()" :style="{background:sxiajt == 3 ? '#FFEBD2':sxiajt == 4 ? '#FFEBD2':'#E5E5E5'}" class="pl-2 mr-3 pr-2 pt-2 pb-2 d-flex align-items-center curpo yuanjiao" style="font-size: calc(7px + 0.4vw);">
          <div class="mr-2">{{ $t("评价分数") }}</div>
          <img v-if="sxiajt==3" style="width: calc(10px + 0.4vw);height: calc(10px + 0.4vw);" src="@/img/icon_sx_shang@2x (1).png" alt="">
          <img v-if="sxiajt==4" style="width: calc(10px + 0.4vw);height: calc(10px + 0.4vw);" src="@/img/icon_sx_shang@2x (2).png" alt="">
          <img v-if="sxiajt!=3 && sxiajt!=4" style="width: calc(10px + 0.4vw);height: calc(10px + 0.4vw);" src="@/img/icon_sx_shang@2x (2).png" alt="">
        </div>
        <div @click="zjyt()" :style="{background:sxiajt == 5 ? '#FFEBD2':sxiajt == 6 ? '#FFEBD2':'#E5E5E5'}" class="pl-2 pr-2 mr-3 pt-2 pb-2 d-flex align-items-center curpo yuanjiao" style="font-size: calc(7px + 0.4vw);">
          <div class="mr-2">{{ $t("资金用途") }}</div>
          <img v-if="sxiajt==5" style="width: calc(10px + 0.4vw);height: calc(10px + 0.4vw);" src="@/img/icon_sx_shang@2x (1).png" alt="">
          <img v-if="sxiajt==6" style="width: calc(10px + 0.4vw);height: calc(10px + 0.4vw);" src="@/img/icon_sx_shang@2x (2).png" alt="">
          <img v-if="sxiajt!=5 && sxiajt!=6" style="width: calc(10px + 0.4vw);height: calc(10px + 0.4vw);" src="@/img/icon_sx_shang@2x (2).png" alt="">
        </div>
        <div @click="cyrs()" :style="{background:sxiajt == 7 ? '#FFEBD2':sxiajt == 8 ? '#FFEBD2':'#E5E5E5'}" class="pl-2 pr-2 mr-3 pt-2 pb-2 d-flex align-items-center curpo yuanjiao" style="font-size: calc(7px + 0.4vw);">
          <div class="mr-2">{{ $t("参与人数") }}</div>
          <img v-if="sxiajt==7" style="width: calc(10px + 0.4vw);height: calc(10px + 0.4vw);" src="@/img/icon_sx_shang@2x (1).png" alt="">
          <img v-if="sxiajt==8" style="width: calc(10px + 0.4vw);height: calc(10px + 0.4vw);" src="@/img/icon_sx_shang@2x (2).png" alt="">
          <img v-if="sxiajt!=7 && sxiajt!=8" style="width: calc(10px + 0.4vw);height: calc(10px + 0.4vw);" src="@/img/icon_sx_shang@2x (2).png" alt="">
        </div>
      </div>
    </div>
    <div v-if="dataLoaded" class="mb-2 sjixians">
      <div class="d-flex justify-content-between align-items-center">
        <div class="mt-3 sjjkdax" v-if="show == 0" style="font-size: calc(8px + 0.4vw);font-weight: 600;">{{ $t('捐款团体列表') }}</div>
        <div class="pl-0 mt-3 pr-0 position-relative d-flex  align-items-center sjbsrkkd yuanjiao">
          <input v-model="keywords" @input="searchFn" class="form-control sxbj inppt pr-5 sjbxzt" type="text" :placeholder="this.$t('搜寻')" plaintext="true" aria-label="Username" aria-describedby="basic-addon1" />
          <img @click="searchFn" style="right: 20px; width: calc(10px + 0.4vw)" class="position-absolute curpo sbxfbd" src="@/img/daohangl/icon_hssy_sousuo@2x.png" alt="" />
        </div>
      </div>
      <div class="pl-0 pr-0 position-relative mt-3 d-flex justify-content-center align-items-center mb-3">
        <div @click="cjsj()" :style="{background:sxiajt == 1 ? '#FFEBD2':sxiajt == 2 ? '#FFEBD2':'#E5E5E5'}" class="pl-2 sjbxzt pr-2 mr-2 pt-2 pb-2 d-flex align-items-center curpo yuanjiao" style="font-size: calc(7px + 0.4vw);">
          <div class="mr-1">{{ $t("创建时间") }}</div>
          <img v-if="sxiajt==1" style="width: calc(10px + 0.4vw);height: calc(10px + 0.4vw);" src="@/img/icon_sx_shang@2x (1).png" alt="">
          <img v-if="sxiajt==2" style="width: calc(10px + 0.4vw);height: calc(10px + 0.4vw);" src="@/img/icon_sx_shang@2x (2).png" alt="">
          <img v-if="sxiajt!=2 && sxiajt!=1" style="width: calc(10px + 0.4vw);height: calc(10px + 0.4vw);" src="@/img/icon_sx_shang@2x (2).png" alt="">
        </div>
        <div @click="pjfs()" :style="{background:sxiajt == 3 ? '#FFEBD2':sxiajt == 4 ? '#FFEBD2':'#E5E5E5'}" class="pl-2 sjbxzt mr-2 pr-2 pt-2 pb-2 d-flex align-items-center curpo yuanjiao" style="font-size: calc(7px + 0.4vw);">
          <div class="mr-1">{{ $t("评价分数") }}</div>
          <img v-if="sxiajt==3" style="width: calc(10px + 0.4vw);height: calc(10px + 0.4vw);" src="@/img/icon_sx_shang@2x (1).png" alt="">
          <img v-if="sxiajt==4" style="width: calc(10px + 0.4vw);height: calc(10px + 0.4vw);" src="@/img/icon_sx_shang@2x (2).png" alt="">
          <img v-if="sxiajt!=3 && sxiajt!=4" style="width: calc(10px + 0.4vw);height: calc(10px + 0.4vw);" src="@/img/icon_sx_shang@2x (2).png" alt="">
        </div>
        <div @click="zjyt()" :style="{background:sxiajt == 5 ? '#FFEBD2':sxiajt == 6 ? '#FFEBD2':'#E5E5E5'}" class="pl-2 sjbxzt pr-2 mr-2 pt-2 pb-2 d-flex align-items-center curpo yuanjiao" style="font-size: calc(7px + 0.4vw);">
          <div class="mr-1">{{ $t("资金用途") }}</div>
          <img v-if="sxiajt==5" style="width: calc(10px + 0.4vw);height: calc(10px + 0.4vw);" src="@/img/icon_sx_shang@2x (1).png" alt="">
          <img v-if="sxiajt==6" style="width: calc(10px + 0.4vw);height: calc(10px + 0.4vw);" src="@/img/icon_sx_shang@2x (2).png" alt="">
          <img v-if="sxiajt!=5 && sxiajt!=6" style="width: calc(10px + 0.4vw);height: calc(10px + 0.4vw);" src="@/img/icon_sx_shang@2x (2).png" alt="">
        </div>
        <div @click="cyrs()" :style="{background:sxiajt == 7 ? '#FFEBD2':sxiajt == 8 ? '#FFEBD2':'#E5E5E5'}" class="pl-2 pr-2 sjbxzt pt-2 pb-2 d-flex align-items-center curpo yuanjiao" style="font-size: calc(7px + 0.4vw);">
          <div class="mr-1">{{ $t("参与人数") }}</div>
          <img v-if="sxiajt==7" style="width: calc(10px + 0.4vw);height: calc(10px + 0.4vw);" src="@/img/icon_sx_shang@2x (1).png" alt="">
          <img v-if="sxiajt==8" style="width: calc(10px + 0.4vw);height: calc(10px + 0.4vw);" src="@/img/icon_sx_shang@2x (2).png" alt="">
          <img v-if="sxiajt!=7 && sxiajt!=8" style="width: calc(10px + 0.4vw);height: calc(10px + 0.4vw);" src="@/img/icon_sx_shang@2x (2).png" alt="">
        </div>
      </div>
    </div>
    <div v-if="dataLoaded" class="col-md-12 pl-0 pr-0">
      <div v-if="show == 0" class="yinc col-md-12 pl-0 pr-0">
        <div class="col-md-12 pl-0 pr-0  d-flex pl-0 pr-0 ">
          <div class="col-md-12 pl-0 pr-0">
            <div class="mt-1 d-flex lieb" style="flex-wrap: wrap;gap: 1%;width: 100%;">
              <div class="sjliebkd" style="width: 24%;" v-for="(item, index) in items " :key="index">
                <div class="col-sm-12 baise mb-3 ckqbb p-3 yuanjiao pl-0 pr-0 curpo" style="width: 100%;text-align: center;">
                  <img @click="fun(1, item.id)" class="yuanjiao" style="width: 100%;height:calc(210px + 0.4vw);object-fit: cover;" :src="item.image" alt="" />
                  <div @click="fun(1, item.id)" class="mt-3" style="font-size: calc(8px + 0.4vw);font-weight: 700;"><span style="color: #FF797A;">{{ item.name }}</span> </div>
                  <div @click="fun(1, item.id)" class="mt-2 ellipsis col-md-12 pl-0 pr-0 mb-2 col-sm-12 col-lg-9" style="font-size: calc(6px + 0.4vw);width: calc(180px + 0.5vw);margin: auto;">
                    {{ item.organization_name }}
                  </div>
                  <div @click="fun(1, item.id)" class="pl-3 pr-3 pt-2 pb-2 d-flex justify-content-between align-items-center yuanjiao mb-3" style="font-size: calc(6px + 0.4vw);background: #FFEBD2;">
                    <div>{{ $t('捐款总额') }}</div>
                    <div style="font-weight: 600;">{{ item.received_amount ? item.received_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }}</div>
                  </div>
                  <div @click="fun(1, item.id)" class="col-sm-12 d-flex justify-content-between pl-0 pr-0" style="width: 100%;">
                    <div class="col-sm-4 text-center pl-0 pr-0" style="font-size: calc(5px + 0.4vw);">
                      <div style="color: #999999;">{{ $t('个人捐款金额') }}</div>
                      <div style="font-weight: 500;">{{ item.personal_amount ? item.personal_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }}</div>
                    </div>
                    <div class="col-sm-4 text-center pl-0 pr-0" style="font-size: calc(5px + 0.4vw);">
                      <div style="color: #999999;">{{ $t('企業捐款金額') }}</div>
                      <div style="font-weight: 500;">{{ item.enterprise_amount ? item.enterprise_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }}</div>
                    </div>
                    <div class="col-sm-4 text-center pl-0 pr-0" style="font-size: calc(5px + 0.4vw);">
                      <div style="color: #999999;">{{ $t('累计捐款人数') }}</div>
                      <div style="font-weight: 500;">{{ item.donor_num ? item.donor_num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }}</div>
                    </div>
                  </div>
                  <div @click="zjsyl(item.id)" class="col-sm-12 pl-3 pr-3 pt-2 pb-2 mt-3 yuanjiao" style="width: 100%;background: #FFFAF0">
                    <!-- 动态进度条 -->
                    <div class="d-flex justify-content-between align-items-center mb-2" style="font-size: calc(6px + 0.4vw);">
                      <div>{{ $t('资金使用率') }}</div>
                      <div style="color: #B66D00;">{{ $t('去评价') }}<img class="ml-1 mb-1" style="width: calc(2px + 0.2vw);height:calc(3px + 0.3vw);" src="@/img/icon_wupingjia@2x.png" alt="" /></div>
                    </div>
                    <div class="yuanjiao col-sm-12 pl-0 pr-0" style="background-color: #F6F4F0;height: calc(10px + 0.4vw);">
                      <div class="progress-bar yuanjiao jdtt" role="progressbar" :style="{'width': item.received_amount ?  Math.floor(item.used_amount / item.received_amount * 100) + '%' : 0 + '%'}" aria-valuemin="0" aria-valuemax="100"></div>
                      <div style="position: absolute;right: 5%;top: 0;font-size: calc(6px + 0.4vw);">{{ item.received_amount ? Math.floor(item.used_amount /item.received_amount  * 100) : 0 }}%</div>
                    </div>
                    <div class="d-flex mt-2" style="font-size: calc(6px + 0.4vw);">
                      <div class="mr-3">{{ $t('用户评分') }} <span style="color: #FF797A;font-weight: 500;">{{ item.user_rating }}</span></div>
                      <div>{{ $t('捐款人评分') }} <span style="color: #FF797A;font-weight: 500;">{{ item.donor_rating }}</span></div>
                    </div>
                  </div>
                  <div class="col-sm-12 pl-0 pr-0 d-flex justify-content-between mt-3 align-items-center">
                    <div @click="fun(3, item.id)" class="mr-2 col-sm-5 pl-0 pr-0 ckqbb curpo d-flex yuanjiao pl-2 pr-2 pt-2 pb-2 align-items-center" style="background: #F6F4F0;">
                      <div class="mr-1" style="font-size: calc(6px + 0.4vw);text-align: center;width: 100%;">
                        {{ $t('捐款名册') }}</div>
                    </div>
                    <!-- 动态进度条 -->
                    <div @click="fun(1, item.id)" class="col-sm-7 pl-0 pr-0 ckqbb yuanjiao pt-2 pb-2" style="background-color: #FFD672;text-align: center;font-size: calc(7px + 0.4vw);">
                      {{ $t('立即支持') }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-center" style="margin: auto">
                <div v-if="items.length == 0" style="margin-bottom: 10vh;">
                  <empty></empty>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 手机 -->
      <div v-if="show == 0" class="aaaa" style="width: 100%;">
        <div class="lieb mt-0" style="display: flex;flex-wrap: wrap;gap: 1%;width: 100%;">
          <div style="width: 100%;" v-for="(items, index) in items " :key="index">
            <div class="col-sm-12 baise mb-3 ckqbb p-3 yuanjiao pl-0 pr-0 curpo" style="width: 100%;text-align: center;">
              <img @click="fun(1, items.id)" class="yuanjiao" style="width: 100%;height:calc(210px + 0.4vw);object-fit: cover;" :src="items.image" alt="" />
              <div @click="fun(1, items.id)" class="mt-3 sjbzt" style="font-size: calc(8px + 0.4vw);font-weight: 700;"><span style="color: #FF797A;">{{ items.name }}</span> </div>
              <div @click="fun(1, items.id)" class="mt-2 ellipsis col-md-12 pl-0 pr-0 mb-2 col-sm-12 sjbzt col-lg-9" style="font-size: calc(6px + 0.4vw);width: calc(180px + 0.5vw);margin: auto;">
                {{ items.organization_name }}
              </div>
              <div @click="fun(1, items.id)" class="pl-3 sjbzt pr-3 pt-2 pb-2 d-flex justify-content-between align-items-center yuanjiao mb-3" style="font-size: calc(6px + 0.4vw);background: #FFEBD2;">
                <div>{{ $t('捐款总额') }}</div>
                <div style="font-weight: 600;">{{ items.received_amount ? items.received_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }}</div>
              </div>
              <div @click="fun(1, items.id)" class="col-sm-12 d-flex justify-content-between pl-0 pr-0" style="width: 100%;">
                <div class="col-sm-4 text-center pl-0 pr-0 sjbxzt" style="font-size: calc(5px + 0.4vw);">
                  <div style="color: #999999;">{{ $t('个人捐款金额') }}</div>
                  <div style="font-weight: 500;">{{ items.personal_amount ? items.personal_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }}</div>
                </div>
                <div class="col-sm-4 text-center pl-0 pr-0 sjbxzt" style="font-size: calc(5px + 0.4vw);">
                  <div style="color: #999999;">{{ $t('企業捐款金額') }}</div>
                  <div style="font-weight: 500;">{{ items.enterprise_amount ? items.enterprise_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }}</div>
                </div>
                <div class="col-sm-4 text-center pl-0 pr-0 sjbxzt" style="font-size: calc(5px + 0.4vw);">
                  <div style="color: #999999;">{{ $t('累计捐款人数') }}</div>
                  <div style="font-weight: 500;">{{ items.donor_num ? items.donor_num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }}</div>
                </div>
              </div>
              <div @click="zjsyl(items.id)" class="col-sm-12 pl-3 pr-3 pt-2 pb-2 mt-3 yuanjiao" style="width: 100%;background: #FFFAF0">
                <!-- 动态进度条 -->
                <div class="d-flex justify-content-between align-items-center mb-2 sjbzt">
                  <div>{{ $t('资金使用率') }}</div>
                  <div style="color: #B66D00;">{{ $t('去评价') }}<img class="ml-1 mb-1" style="width: calc(2px + 0.2vw);height:calc(3px + 0.3vw);" src="@/img/icon_wupingjia@2x.png" alt="" /></div>
                </div>
                <div class="yuanjiao col-sm-12 pl-0 pr-0" style="background-color: #F6F4F0;height: calc(15px + 0.4vw);">
                  <div class="progress-bar yuanjiao jdtt" role="progressbar" :style="{'width': items.received_amount ?  Math.floor(items.used_amount / items.received_amount * 100) + '%' : 0 + '%'}" aria-valuemin="0" aria-valuemax="100"></div>
                  <div style="position: absolute;right: 5%;top: 0;font-size: calc(6px + 0.4vw);" class="sjbxzt">{{ items.received_amount ? Math.floor(items.used_amount /items.received_amount  * 100) : 0 }}%</div>
                </div>
                <div class="d-flex mt-2 sjbzt" style="font-size: calc(6px + 0.4vw);">
                  <div class="mr-3">{{ $t('用户评分') }} <span style="color: #FF797A;font-weight: 500;">{{ items.user_rating }}</span></div>
                  <div>{{ $t('捐款人评分') }} <span style="color: #FF797A;font-weight: 500;">{{ items.donor_rating }}</span></div>
                </div>
              </div>
              <div class="col-sm-12 pl-0 pr-0 d-flex justify-content-between mt-3 align-items-center">
                <div @click="fun(3, items.id)" class="mr-2  ckqbb curpo d-flex yuanjiao pl-2 pr-2 pt-2 pb-2 align-items-center" style="background: #F6F4F0;width:40%;">
                  <div class="mr-1 sjbzt" style="font-size: calc(6px + 0.4vw);text-align: center;width: 100%;">
                    {{ $t('捐款名册') }}</div>
                </div>
                <!-- 动态进度条 -->
                <div @click="fun(1, items.id)" class="col-sm-8 sjbzt pl-0 pr-0 ckqbb yuanjiao pt-2 pb-2" style="background-color: #FFD672;text-align: center;font-size: calc(7px + 0.4vw);">
                  {{ $t('立即支持') }}
                </div>
              </div>
            </div>

          </div>
          <div class="d-flex justify-content-center" style="margin: auto">
            <div v-if="items.length == 0" style="margin-bottom: 10vh;">
              <empty></empty>
            </div>
          </div>
        </div>

      </div>
    </div>
    <router-view />
    <juank v-if="show == 3" :isid="idds" @custom='custom'></juank>
  </div>
</template>

<script>
import { EventBus } from "@/views/event-bus.js";
import { donation_ActiveLis } from "@/request/aapi.js";
import SkeletonScreen from "./SkeletonScreen/xinSkeletonScreen.vue";
import juank from "./wmuc/juank.vue";
import empty from "@/components/empty/empty.vue";
import router from "@/router";
export default {
  components: {
    SkeletonScreen,
    empty,
    juank,
  },
  data() {
    return {
      jhuxa: this.$t("请选择排序类型"),
      isDropdownOpen: false,
      keywords: "",
      selected: "",
      dataLoaded: false,
      show: 0,
      sxiajt: "",
      currentIndex: 0, // 当前显示的列表索引
      // 用于存储当前应该显示的列表项（按每四个一组分组）
      visibleItems: [],
      progress: 60,
      items: [],
      idss: null,
      isT: null,
      idds: null,
      currentPage: 1,
    };
  },
  props: {
    ids: {
      type: Number,
      default: null,
    },
  },

  created() {
    this.updateVisibleItems();
    EventBus.$on("tthdfhs", (msg) => {
      // 执行方法，处理接收到的数据
      // this.message = msg;
      // 如果需要，可以在这里调用其他方法
      this.show = 0;
      this.$emit("custom", 0);
    });
  },
  beforeDestroy() {
    // 组件销毁前移除监听器，防止内存泄漏
    EventBus.$off("tthdfhs");
  },
  mounted() {
    if (this.$route.path.includes("/donate-use")) {
      this.$emit("custom", 2);
      this.show = 2;
    } else if (this.$route.path.includes("/donation/")) {
      this.$emit("custom", 1);
      this.show = 1;
    } else if (this.$route.path.includes("/donation")) {
    }
  },
  computed: {},
  methods: {
    cjsj() {
      let b = this.sxiajt == 2 ? 1 : this.sxiajt == 1 ? 2 : 2;
      this.sxiajt = b;
      let a =
        this.sxiajt == 1
          ? "create_time_asc"
          : this.sxiajt == 2
          ? "create_time_desc"
          : "create_time_desc";
      this.selected = a;
      this.items = [];
      this.currentPage = 1;
      this.updateVisibleItems();
    },
    pjfs() {
      let b = this.sxiajt == 4 ? 3 : this.sxiajt == 3 ? 4 : 4;
      this.sxiajt = b;
      let a =
        this.sxiajt == 3
          ? "donor_rating_asc"
          : this.sxiajt == 4
          ? "donor_rating_desc"
          : "donor_rating_desc";
      this.selected = a;
      this.items = [];
      this.currentPage = 1;
      this.updateVisibleItems();
    },
    zjyt() {
      let b = this.sxiajt == 6 ? 5 : this.sxiajt == 5 ? 6 : 6;
      this.sxiajt = b;
      let a =
        this.sxiajt == 5
          ? "used_amount_asc"
          : this.sxiajt == 6
          ? "used_amount_desc"
          : "used_amount_desc";
      this.selected = a;
      this.items = [];
      this.currentPage = 1;
      this.updateVisibleItems();
    },
    cyrs() {
      let b = this.sxiajt == 8 ? 7 : this.sxiajt == 7 ? 8 : 8;
      this.sxiajt = b;
      let a =
        this.sxiajt == 7
          ? "donor_num_asc"
          : this.sxiajt == 8
          ? "donor_num_desc"
          : "donor_num_desc";
      this.selected = a;
      this.items = [];
      this.currentPage = 1;
      this.updateVisibleItems();
    },
    // 搜寻
    searchFn() {
      this.items = [];
      this.currentPage = 1;
      this.updateVisibleItems();
    },
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
      // 如果需要，可以在这里添加Bootstrap Dropdown的额外处理逻辑
    },
    gundong() {
      console.log(123);
      if (this.currentPage < this.last_page) {
        this.currentPage += 1;
        this.updateVisibleItems();
      }
    },

    updateVisibleItems() {
      // 计算当前应该显示的列表项索引范围
      const data = {
        page: this.currentPage,
        order: this.selected,
        keywords: this.keywords,
      };
      donation_ActiveLis(data).then((res) => {
        if (res.code == 200) {
          this.dataLoaded = true;
        }
        this.last_page = res.data.last_page;
        this.totalPages = res.data.total;
        if (this.totalPages == 0) {
          this.ishow = true;
        } else {
          this.ishow = false;
        }
        this.items = [...this.items, ...res.data.data];
      });
    },
    // 查看全部跳转
    emsl() {
      this.$emit("emslfun", 1);
      // this.$refs..getInfo()
    },
    smdckxq(e) {
      this.fun(1, e);
    },
    // 详情等页面返回按钮
    custom(e) {
      this.show = e;
      this.$emit("custom", e);
    },
    zjsyl(e) {
      this.$router.push({
        path: "/donation/" + e + "/donate-comment"
      });
    },
    fun(e, a) {
      this.show = e;
      if (e == 1 && a) {
        this.isT = 1;
        this.idss = a;
        this.$router.push({
          path: "/donation/" + a,
          params: {
            isT: 1,
          },
        });
        localStorage.setItem("tiaozhuan", "jkhd");
      } else if (e == 3 && a) {
        this.$router.push({
          name: "jkmc",
          params: {
            isT: 2,
            zjsy: a,
          },
        });
      }
      this.$emit("custom", e);
    },
  },
};
</script>

<style scoped lang="scss">
@media screen and (max-width: 1200px) {
  .sjliebkd {
    width: 49% !important;
  }
  .sjyinc {
    display: block !important;
  }
}
@media screen and (max-width: 995px) {
  .sjjkdax {
    font-size: calc(13px + 0.4vw) !important;
  }
  .sjixians {
    display: block !important;
  }
  .pcxians {
    display: none !important;
  }
}
@media screen and (max-width: 750px) {
  .sjbzt {
    font-size: calc(11px + 0.4vw) !important;
  }
  .sjbxzt {
    font-size: calc(9px + 0.4vw) !important;
  }
  .aaaa {
    display: block !important;
  }
  .yinc {
    display: none !important;
  }
}
.sjixians {
  display: none;
}
.aaaa {
  display: none;
}
.sjyinc {
  display: none;
}
.sxbj {
  padding-top: calc(15px + 0.5vw);
  padding-bottom: calc(15px + 0.5vw);
}
.form-control {
  height: calc(15px + 0.5vw) !important;
}
.ckqbb:hover {
  transform: scale(1.001);
  /* 悬浮时放大1.1倍 */
  // padding: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.inppt {
  background: white;
  border: none;
  border-radius: 13px;
  font-size: calc(7px + 0.4vw);
}
.dcbd:hover {
  transform: scale(1.2);
  /* 悬浮时放大1.1倍 */
  // padding: 12px;
}
.lieb::-webkit-scrollbar {
  display: none;
}
.jdtt {
  height: 100%;
  background: #fab1ab;
  color: #1a1a1a;
  text-align: right;
  font-size: calc(6px + 0.4vw);
}

.fangda:hover {
  transform: scale(1.02);
}
.xlcd {
  display: block;
  background: #ffebd2;
  border: none;
}

.xuazz:hover {
  background: #ffebd2;
}
.dropdown-menu {
  min-width: calc(65px + 0.5vw) !important;
}
.zhankai {
  background: #ffebd2;
  text-align: center;
  border-radius: 8px;
  border: none;
  font-weight: 400;
  font-size: calc(6px + 0.4vw);
  color: #1a1a1a;
}

.parent::-webkit-scrollbar {
  display: none;
}

.custom-pagination ::v-deep .page-item.active .page-link {
  background-color: rgb(255, 214, 114);
  border-color: rgb(255, 214, 114);
  color: #000;
}
</style>