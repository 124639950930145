<template>
  <div>
    <div class="d-flex align-items-center yuanjiao baise pl-3 pr-3 pt-3 pb-3 mt-3 position-relative">
      <img v-if="show == 2 || show == 3" @click="fun()" style="width:25px;" src="@/img/login/icon_fanhui@2x.png">
      <img v-if="show == 1" style="width:25px;" src="@/img/zhufanhui.png">
      <div class="d-flex align-items-center position-absolute" style="left: 36%;">
        <img class="mr-2" style="width:40px;" src="@/img/daohangl/pic_hssy_logo@2x.png" alt="" />
        <div style="font-size: calc(12px + 0.5vw);font-weight: 500;">{{ $t('好事道') }}</div>
      </div>
    </div>
    <div v-if="show != 3" class="parent baise yuanjiao mt-3 mb-3 p-3 heggt">
      <div class="text-center">
        <div class="yuanjiao p-3" style="background: #FFFAF0;">
          <img style="width: 45%;height: 55px;" :src="organization_logo" alt="">
          <div class="mt-3 swkd" v-if="show == 1">{{ organization_name }}</div>
          <div class="mt-3 swkd" v-if="show == 2">{{ name }}</div>
          <div class="mt-3" style="font-size: 14px;color: #666666;display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 2;overflow: hidden;text-overflow: ellipsis;">
            {{ organization_introduction }}</div>
          <!--  -->
          <div v-if="acinfo.maximum_user > 0">
            <div class="mt-3 mb-2 swkd" v-if="show == 2">{{ acinfo.sign_num }}<span style="color: #666666;">/{{
              acinfo.maximum_user }}</span></div>
            <div class="mt-2 sysr" v-if="show == 2 && acinfo.sign_num == acinfo.maximum_user">{{ $t("當前活動人數已達上限") }}</div>
          </div>
          <!--  -->
          <div @click="yhzcd(3)" v-if="show == 2" class="pt-2 pb-2 mt-3 ckhdxq yuanjiao">{{ $t("查看活動詳情") }}</div>
        </div>
        <div v-if="show == 1" class="mb-2 mt-3 ztnjj">{{ $t("請選擇您要參與的活動") }}</div>
        <div v-if="show == 2" class="mb-2 mt-3 ztnjj">{{ $t("請您參與") }}</div>
        <div v-if="show == 1 || show == 2" class="xian"></div>
        <div v-if="show == 2" class="mt-3">
          <form class="text-left">
            <!-- 手机号 -->
            <div class="form-group">
              <label class="srkzt" style="font-weight: 500;"><span style="color: red;">*</span>{{ $t("手机号") }}</label>
              <div class="position-relative">
                <input class="form-control srkxzt" style="background: #F6F4F0;border: none;padding-top: 20px;padding-bottom: 20px;" v-model="form.phone" @input="validatePhone()" type="text" :placeholder="$t('请输入手机号')">
                <small class="form-text sjbxzt" style="color: red;" v-if="phoneError">{{ phoneError }}</small>
                <div class="position-absolute yzm curpo pb-1" v-if="yzmqr == 1" style="right: 1rem;top: 6px;">
                  <div v-show="phonetimeTrue" @click="phoneobtainCode()">
                    {{ $t('login.getCaptchaButton') }}
                  </div>
                  <div v-show="!phonetimeTrue">
                    {{ phonetime }}{{ $t('login.resend_countdown') }}
                  </div>
                </div>
              </div>
            </div>
            <!-- 验证码 -->
            <div class="form-group">
              <label class="srkzt" style="font-weight: 500;"><span style="color: red;">*</span>{{
                $t('login.captchaLabel')
                }}</label>
              <div class="position-relative">
                <b-form-input class="form-control srkxzt mb-3" @input="yzmsd" style="background: #F6F4F0;border: none;padding-top: 20px;padding-bottom: 20px;" v-model="form.phoneuse" type="number" :placeholder="$t('login.captchaPlaceholder')">
                </b-form-input>
                <div v-if="yzmqr != 1" class="position-absolute yzm pb-1" style="background: #FAB1AB;right: 1rem;top: 6px;">
                  {{ $t("验证码正确") }}
                </div>
              </div>
            </div>
          </form>
          <!-- 是否勾选协议 -->
          <div class="mt-3 d-flex align-items-center mb-3">
            <img v-if="agreement" @click="agreement = !agreement" class="curpo mr-2" style="width: calc(13px + 0.4vw);" src="../../img/login/icon_dl_xy_xz@2x.png" alt="" />
            <img v-else @click="agreement = !agreement" class="curpo mr-2" style="width: calc(13px + 0.4vw);" src="../../img/login/icon_dl_xy_wxz@2x.png" alt="" />
            <div class="yhxyi curpo" style="font-size: 11px;"><span style="color: #000;" @click="agreement = !agreement">{{
              $t('login.policyText1') }}</span> <span class="curpo" @click="yhzcd(0)">{{ $t('login.policyLink1')
                }}</span>
              {{ $t('login.policyText2') }}<span class="curpo" @click="yhzcd(1)">{{ $t('login.policyLink2') }}</span>
            </div>
          </div>
          <div @click="jlogin()" v-if="acinfo.sign_num == acinfo.maximum_user && acinfo.maximum_user > 0" class="pt-2 pb-2 yuanjiao anbutton">{{ $t("註冊好事道") }}</div>
          <div @click="jlogin()" v-else class="pt-2 pb-2 yuanjiao anbutton">{{ $t("現場報到") }}</div>
        </div>
      </div>
      <div v-if="show == 1" class="mt-3">
        <div class="pl-0 pr-0">
          <div @click="djcydhd(item)" v-for="(item, index) in activity" :key="index" :style="{ background: item.id == activeid ? '#FFF4DB' : '#F6F4F0' }" class="d-flex curpo justify-content-between align-items-center mb-2 pl-3 pr-3 pt-3 pb-3 yuanjiao">
            <div style="font-size: calc(14px + 0.4vw);font-weight: 500;">
              {{ item.name }}
            </div>
            <img style="width: 20px;" src="@/img/daohangl/icon_hssy_hdlb_xiangqing@2x.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div v-if="show == 3" class="parent baise yuanjiao mt-3 mb-3 heggt">
      <div class="col-md-12 col-sm-12 col-lg-12 col-xl-12 baise yuanjiao pt-3 pb-3 content">
        <div style="text-align: center;width: 100%;font-size: calc(13px);font-weight: 600;" class="mb-3">
          {{ title }}</div>
        <div v-if="aboutUs.content" class="col-md-12 pl-0 pr-0" v-html="aboutUs.content"></div>
        <div v-else>
          <empty></empty>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {} from "@/request/api.js";
import {
  user_agreement,
  privacy_policy,
  group_about,
  company_about,
  qrcode_detail,
  sendcode,
  check_sms,
  sms_login,
} from "@/request/api.js";
import { timeFilter, formatTimestamp } from "@/utils/common.js";
export default {
  components: {},
  data() {
    return {
      aboutUs: {
        content: "",
      },
      title: "",
      activity: [],
      organization_logo:"",
      activeid: "",
      acinfo: {},
      phoneError: "",
      phonetimeTrue: true, //判断验证码
      form: {
        phone: "",
        phoneuse: "",
      },
      phonetime: "",
      yzmqr: 1,
      organization_name: "",
      organization_introduction: "",
      agreement: false, // 控制按钮是否选中协议
      show: 1, //1是选择活动，如果活动数是1直接到2，2是需要获取验证，如果后台无设置人数限制不显示，验证码和报道按钮弹出，如果当前用户注册显示登录，如没显示注册，已注册用户直接跳转活动涂鸦墙，未注册个人中心
      name: "", //活动名称
    };
  },
  mounted() {
    // 模拟数据加载
    this.qrcode_detail();
  },
  methods: {
    async qrcode_detail() {
      const { data, code } = await qrcode_detail({
        organization_slug: this.$route.params.slug,
      });
      if (code == 200) {
        if (data.data.activity.length == 1) {
          this.activity = data.data.activity;
          this.organization_name = data.data.organization_name;
          this.organization_introduction = data.data.organization_introduction;
          this.organization_logo=data.data.organization_logo;
          this.djcydhd(this.activity[0]);
          return;
        }
        this.activity = data.data.activity;
        this.organization_introduction = data.data.organization_introduction;
        this.organization_name = data.data.organization_name;
        this.organization_logo=data.data.organization_logo;
      }
    },
    djcydhd(e) {
      this.acinfo = e;
      this.activeid = e.id;
      this.name=e.name;
      this.show = 2;
      this.phoneError = "";
      this.phonetimeTrue = true;
      this.form.phone = "";
      this.form.phoneuse = "";
      this.phonetime = "";
    },
    async jlogin() {
      if (this.agreement == false) {
        this.$bvToast.toast(this.$t("login.agree_to_terms"), {
          title: this.$t("login.warning"),
          autoHideDelay: 2000,
          delay: 5000,
          appendToast: true,
          variant: "danger",
        });
        this.isLoading = false;
        return;
      } else if (this.phoneError != "") {
        return;
      } else if (this.form.phone == "") {
        this.phoneError = this.$t("login.phoneNumberPlaceholder");
        return;
      } else if (this.form.phoneuse == "") {
        this.$bvToast.toast(this.$t("login.captchaPlaceholder"), {
          title: this.$t("login.warning"),
          variant: "danger",
          autoHideDelay: 5000,
        });
        return;
      }
      const { msg, data, code } = await sms_login({
        phone: this.form.phone,
        code: this.form.phoneuse,
      });
      if (code == 200) {
        localStorage.setItem("token", data.token);
        localStorage.setItem("type", data.user.identity);
        localStorage.setItem("user_id", data.user.id);
        if (data.user.nickname) {
          localStorage.setItem("nickname", data.user.nickname);
        }
        this.$emit("custom", { name: 2, activeid: this.activeid });
      } else {
        this.$bvToast.toast(msg, {
          title: this.$t("login.warning"),
          variant: "danger",
          autoHideDelay: 5000,
        });
      }
    },
    yhzcd(e) {
      this.show = 3;
      if (e == 0) {
        this.user_agreement();
      } else if (e == 1) {
        this.privacy_policy();
      } else if (e == 3) {
        // 获取关于我们
        this.getGroup_about();
      }
    },
    getGroup_about() {
      let route = this.$route.path;
      let c = route.includes("enterprises") ? company_about : group_about;
      c({
        id: this.activeid,
      }).then((res) => {
        this.aboutUs.content = res.data.info;
        this.title = "";
      });
    },
    async privacy_policy() {
      const { data, code } = await privacy_policy({});
      if (data.data) {
        this.aboutUs.content = data.data.content;
        this.title = data.data.title;
      }
    },
    async user_agreement() {
      const { data, code } = await user_agreement({});
      if (data.data) {
        this.aboutUs.content = data.data.content;
        this.title = data.data.title;
      }
    },
    // 校验验证码
    validatePhone() {
      this.form.phoneuse = "";
      this.yzmqr = 1;
      this.phonetimeTrue = true;
      const regex = /^09[0-9]{8}$/;
      if (!regex.test(this.form.phone)) {
        this.phoneError = this.$t("⼿機號瑪格式錯誤");
      } else {
        this.phoneError = "";
      }
      if (this.form.phone == "") {
        this.phoneError = "";
      }
    },
    async yzmsd(e) {
      this.yzmqr = 1;
      let numberArray = e.split("").map((char) => parseInt(char));
      console.log(numberArray.length);
      if (numberArray.length >= "6") {
        setTimeout(() => {
          this.form.phoneuse = e.slice(0, 6);
          check_sms({ phone: this.form.phone, code: this.form.phoneuse }).then(
            (res) => {
              if (res.code == 200) {
                this.$bvToast.toast(this.$t("验证码正确"), {
                  title: this.$t("login.notice"),
                  autoHideDelay: 2000,
                  delay: 5000,
                  appendToast: true,
                  variant: "success",
                });
                this.yzmqr = 0;
              } else {
                this.$bvToast.toast(this.$t("验证码错误"), {
                  title: this.$t("login.warning"),
                  variant: "danger",
                  autoHideDelay: 5000,
                });
                this.form.phoneuse = "";
                this.yzmqr = 1;
                this.phonetimeTrue = true;
              }
            }
          );
        }, 100);
      }
    },
    // 获取验证码
    async phoneobtainCode() {
      if (this.phoneError != "") {
        return;
      } else if (this.form.phone == "") {
        this.$bvToast.toast(this.$t("请输入手机号"), {
          title: this.$t("login.warning"),
          variant: "danger",
          autoHideDelay: 5000,
        });
        return;
      }

      const { msg, code } = await sendcode({
        phone: this.form.phone,
      });
      if (code == 200) {
        this.$bvToast.toast(this.$t("login.verification_success"), {
          title: this.$t("login.notice"),
          autoHideDelay: 2000,
          delay: 5000,
          appendToast: true,
          variant: "success",
        });
        this.phoneacquire();
      } else {
        this.$bvToast.toast(msg, {
          title: this.$t("login.warning"),
          autoHideDelay: 2000,
          delay: 5000,
          appendToast: true,
          variant: "danger",
        });
      }
    },
    // 验证码获取成功
    phoneacquire() {
      this.phonetimeTrue = false;
      this.phonetime = 60;
      var setTimeoutS = setInterval(() => {
        this.phonetime--;
        if (this.phonetime <= 0) {
          clearInterval(setTimeoutS);
          this.phonetimeTrue = true;
        }
      }, 1000);
    },
    fun() {
      if (this.show == 3) {
        this.show = 2;
      } else {
        this.show = 1;
        this.activeid = "";
      }
    },
    ckhdxq() {
      let route = this.$route.path;
      //  activity  enterprises    groups
      if (route.includes("enterprises")) {
        // this.$router.push('/activity/enterprises/asdjaskdj/12')
      } else {
        // this.$router.push('/activity/groups/asdjaskdj/12')
      }
    },
  },
};
</script>
<style scoped lang="scss">
.anbutton {
  background: #ffd672;
  font-size: 14px;
}

.yzm {
  height: 27px;
  line-height: 27px;
  text-align: center;
  font-weight: 400;
  font-size: 12px;
  color: #1a1a1a;
  background: #ffd672;
  border-radius: 12px;
  padding: 0 12px;
}

.srkzt {
  font-size: 15px;
}

.srkxzt {
  font-size: 13px;
}

.yhxyi {
  font-size: 12px;

  span {
    color: #b66d00;
  }
}

.xian {
  width: 30%;
  height: 2px;
  background: #ffd672;
  margin: auto;
}

.ckhdxq {
  background: #ffeab8;
  font-size: 13px;
  width: 55%;
  margin: auto;
}

.ztnjj {
  font-size: 15px;
  font-weight: 600;
}

.content {
  font-size: calc(10px + 0.4vw);
  line-height: calc(20px + 0.5vw);
}

.heggt {
  height: 76vh;
  overflow-y: auto;
  width: 100%;
}

.swkd {
  font-size: 17px;
  font-weight: 700;
}

.sysr {
  font-size: 12px;
  color: #b66d00;
}
</style>
