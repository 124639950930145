<!-- SkeletonScreen.vue -->
<template>
	<div>
		<!-- background-color: #F6F4F0; -->
		<div class="row pl-0 pr-0" style="width: 100%;margin: 0;">
			<div class="col-md-12 pl-0 pr-0">
				<!-- 模拟侧边栏 -->
				<div class="skeleton-wrapper pl-0 pr-0">
					<div class="skeleton-item d-flex justify-content-between"
						style="background: #F6F4F0;">
						<div class="skeleton-item" 
							style="height: 63vh; width: 75%; background: white; border-radius: 0 12px 12px 12px;">
						</div>
						<div class="skeleton-item"
							style="height: 37vh; width: 23%; background: white; border-radius: 12px;">
						</div>
					</div>
					
				</div>
			</div>

		</div>
	</div>
</template>

<script>
	export default {
		name: "SkeletonScreen",
	};
</script>

<style scoped>
	.skeleton-wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.skeleton-item {
		width: 100%;
		/* 根据需要调整 */
		height: 100%;
		/* 根据需要调整 */
		background-color: #e9ecef;
		border-radius: 4px;
		/* 圆角 */
		animation: pulse 1.5s ease-in-out infinite;
	}

	@keyframes pulse {
		0% {
			opacity: 1;
		}

		50% {
			opacity: 0.3;
		}

		100% {
			opacity: 1;
		}
	}
</style>