<template>
  <div>
    <!-- 骨架屏 -->
    <skeleton-screen v-show="!dataLoaded"></skeleton-screen>
    <div v-if="dataLoaded" @scroll="handleScroll" style="height: 88.7vh;overflow-y: auto" class="heggs lieb col-md-12 col-sm-12 col-lg-12 col-xl-12 d-flex pl-0 pr-0 ml-0 justify-content-between">
      <div class="col-md-12 col-sm-12 col-xl-9 pl-0 pr-2 ybyc lieb heggs">
        <div class="col-md-12 pl-0 pr-0 sjitop">
          <div class="baise yuanjiao col-sm-12 pt-3 pb-2">
            <div class="mb-3 sjtoto" style="font-size: calc(12px + 0.4vw);font-weight: 600;">{{ info.data.name }} {{ $t('资金使用情况')}}</div>

            <div class="">
              <div class="d-flex justify-content-between  sjdxsyc">
                <div class="wduasad" style="width: 53%;">
                  <div class="d-flex justify-content-between">
                    <img class="yuanjiao" style="width: 49%;height:calc(180px + 0.4vw);object-fit: cover;" :src="info.data.image" alt="">
                    <img class="yuanjiao" style="width: 49%;height:calc(180px + 0.4vw);object-fit: cover;" :src="info.data.image2" alt="">
                  </div>
                  <div class="mt-3" style="font-size: calc(6px + 0.4vw);line-height: 20px;  display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 3;overflow: hidden;text-overflow: ellipsis;">{{ info.data.description }}</div>

                </div>
                <div class="wduasad" style="width: 45%;">
                  <div class="d-flex justify-content-between align-items-center" style="position: relative;">
                    <!-- 饼图 -->
                    <div ref="chart" style="width: 50%;height: 180px;"></div>
                    <!-- <div class="text-center" style="position: absolute;left: 4rem;">
                    <div>{{ $t('使用率')}}</div>
                    <div>{{ zjdsyl }}</div>
                  </div> -->
                    <div style="width: 50%;">
                      <div class="yuanjiao pt-2 pb-2 pl-3 pr-3 sjbzt" style="background: #FFEBD2;font-size: calc(8px + 0.4vw);">
                        <div>{{ $t('捐款总额') }} <span style="font-weight: 600;">{{ info.data.received_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</span> </div>
                      </div>
                      <div class="ml-3 pt-3 pb-3 pl-3" style="border-left: 1px solid #FFEBD2;font-size: calc(6px + 0.4vw);color: #666666;">
                        <div class="mb-3 sjbxzt">{{ $t('个人捐款金额') }} <span style="font-weight: 600;color: #000;font-size: calc(8px + 0.4vw);">{{ info.data.personal_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</span></div>
                        <div class="mb-3 sjbxzt">{{ $t('企业捐款金额') }} <span style="font-weight: 600;color: #000;font-size: calc(8px + 0.4vw);">{{ info.data.enterprise_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</span></div>
                      </div>
                      <div class="yuanjiao pt-2 pb-2 pl-3 pr-3" style="background: #FCD7D5;font-size: calc(8px + 0.4vw);">
                        <div class="sjbzt">{{ $t('使用金额') }} <span style="font-weight: 600;">{{ info.data.used_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</span> </div>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex align-items-center mt-5" style="font-size: calc(8px + 0.4vw);">
                    <div class="mr-1 sjbzt mb-1">{{ $t('用户评分')}} </div>
                    <div style="visibility: hidden;">12</div>
                    <img class="mr-1" style="width: 20px;height: 20px;" v-for="n in xsstars(info.data.user_rating)" :key="n" src="@/img/xzx.png">
                    <img class="mr-1" style="width: 20px;height: 20px;" v-for="item in jktyyhpfsz" :key="item.n" src="@/img/wxzx.png">
                    <div class="ml-2 mr-1" style="color: #FF797A;font-weight: 600;"> {{ info.data.user_rating }}</div>
                    <div class="sjbzt"> {{ '(' + info.data.user_review_num  }}{{ $t('人已评价') + ')' }}</div>
                    <!-- 悬浮弹框 -->
                    <div class="tooltip-container" @click="showTooltip = !showTooltip,showTooltips=false" style="position: relative;">
                      <img class="ml-1 curpo mt-1" curpo style="width: 20px;height: 20px;" src="@/img/icon_gyxx_linglun_tishi@2x.png" alt="">
                      <div v-if="showTooltip" class="pl-3 pr-3 pt-2 pb-2 yuanjiao dianjitc">
                        {{ $t('公式：普通用户（未捐款）评分星数的平均分影响力均等') + ')' }}
                      </div>
                    </div>

                  </div>
                  <div class="d-flex align-items-center mt-3 " style="font-size: calc(8px + 0.4vw);">
                    <div class="mr-1 sjbzt mb-1">{{ $t('捐款人评分')}} </div>
                    <img class="mr-1" style="width: 20px;height: 20px;" v-for="n in xsstars(info.data.donor_rating)" :key="n" src="@/img/xzx.png">
                    <img class="mr-1" style="width: 20px;height: 20px;" v-for="item in jkrpfsz" :key="item.n" src="@/img/wxzx.png">
                    <div class="ml-2 mr-1" style="color: #FF797A;font-weight: 600;"> {{ info.data.donor_rating }}</div>
                    <div class="sjbzt"> {{ '(' + info.data.donor_review_num  }}{{ $t('人已评价') + ')' }}</div>
                    <!-- 悬浮弹框 -->
                    <div class="tooltip-container" @click="showTooltips = !showTooltips,showTooltip=false" style="position: relative;">
                      <img class="ml-1 curpo mt-1" curpo style="width: 20px;height: 20px;" src="@/img/icon_gyxx_linglun_tishi@2x.png" alt="">
                      <div v-if="showTooltips" class="pl-3 pr-3 pt-2 pb-2 yuanjiao dianjitc">
                        {{ $t('公式：加权平均分=捐款用户评分星数*（用户捐款总額/总金额）捐款总额越大，影响力越大') + ')' }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button @click="lijizc()" class="pl-4 pr-4 pt-3 pb-3 yuanjiao curpo ckqbb mt-3 mb-3 sjbxzt" style="font-size: calc(6px + 0.4vw);background: #FAB1AB;border: none;color: #000;">
              {{ $t('立即支持') }}
            </button>
          </div>
          <div class="baise aaaa col-md-12 pb-2 curpo yuanjiao pl-0 pr-0 mt-3 mb-3" @click="zjsyqk()">
            <div class="mb-2 d-flex justify-content-between ml-3 mr-3 pt-3 sjbzt" style="font-size: calc(8px + 0.4vw); font-weight: 500">
              <div>{{ $t("资金用途") }}</div>
              <div class="ckq curpo sjbzt" style="color: #ffd672">
                {{ $t("查看全部") }}
              </div>
            </div>
            <div v-if="info.used.length" class="mb-2 ml-3 mr-3 col-md-11" style="height: 1px; background: #e5e5e5"></div>
            <div v-show="index <= 4" v-for="(item, index) in info.used" :key="index" class="d-flex  justify-content-between align-items-center mb-2 xunafuxgio pl-3 pr-3 pt-2 pb-2 yuanjiao">
              <div class="d-flex justify-content-between align-items-center">
                <img class="mr-2" style="width: calc(35px + 0.5vw);height: calc(35px + 0.4vw);border-radius: 50%;object-fit: cover;" :src="item.image" alt="" />
                <div>
                  <div class="ellipsis sjbzt" style="font-size: calc(7px + 0.4vw);">
                    {{ item.title }}
                  </div>
                  <div class="d-flex align-items-center mt-1">
                    <div class="d-flex mr-2 align-items-center" style="font-size: calc(6px + 0.4vw);color: #666666;">
                      <img style="width: calc(7px + 0.5vw);height: calc(7px + 0.5vw);" src="@/img/daohangl/icon_jkhd_qian@2x.png" alt="" />
                      <div class="ml-1 sjbxzt">{{ item.amount ? item.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }}</div>
                    </div>
                    <div class="d-flex mr-3 align-items-center" style="font-size: calc(6px + 0.4vw); color: #666666">
                      <img style="
                          width: calc(7px + 0.5vw);
                          height: calc(7px + 0.5vw);
                        " src="@/img/icon_jkhd_shijain@2x.png" alt="" />
                      <div class="ml-1 sjbxzt">{{ timeFilter(item.create_time) }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <img style="width: calc(15px + 0.5vw)" src="@/img/daohangl/icon_hssy_hdlb_xiangqing@2x.png" alt="" /> -->
            </div>
          </div>
          <div class="baise mt-3 yuanjiao col-sm-12 pt-3 pb-2">
            <div class="mb-3 sjtoto" style="font-size: calc(10px + 0.4vw);">{{ $t('资金使用评价') }}{{ info.my_donate_amount > 0 ? $t('（捐款人）') : ''}}</div>
            <div class="mb-2 sjbzt" style="font-size: calc(8px + 0.4vw);">{{ $t('评价分数') }}</div>
            <div class="rating">
              <span v-for="(star, index) in stars" :key="index" :class="star.class" @click="selectStar(index + 1)"></span>
            </div>
            <div class="form-group mt-2">
              <label class="sjbzt" style="font-weight: 500;font-size: calc(8px + 0.4vw);"> <span style="color: red;">*</span>{{ $t('捐款反馈') }}</label>
              <div>
                <b-form-textarea style="border: none;background: #F6F4F0;font-size: calc(7px + 0.4vw);" class="sjbzt" id="textarea" v-model="hdfk" :placeholder="$t('请输入活动反馈')" rows="3" max-rows="6" no-resize></b-form-textarea>
              </div>
              <button @click="fabufankui()" class="pl-4 pr-4 pt-3 pb-3 yuanjiao curpo ckqbb mt-4 sjbxzt" style="font-size: calc(6px + 0.4vw);background: #FFD672;border: none;color: #000;">
                {{ $t('发布评价') }}
              </button>
            </div>
          </div>
          <div class="col-sm-12 pl-0 pr-0">
            <div v-for="(item, index) in list" :key="index" class="baise mt-3 yuanjiao col-sm-12 pt-3 pb-3 mb-3">
              <div class='d-flex justify-content-between col-sm-12 pl-0 pr-0'>
                <div class="d-flex align-items-center">
                  <div class="mr-3"><img style="width: calc(40px + 0.5vw);border-radius: 50%;object-fit: cover;height: calc(40px + 0.5vw);" :src="item.avatar" alt="" /></div>
                  <div>
                    <div class="sjbzt d-flex" style="font-size: calc(8px + 0.4vw);">
                      <div>{{ item.nickname }}</div>
                      <div class="pl-2 pr-2 pt-1 pb-1 yuanjiao ml-3 sjbxzt" v-if="item.type==1" style="background: #FFEAB8;font-size: calc(5px + 0.4vw);">{{ $t('普通用户') }}</div>
                      <div class="pl-2 pr-2 pt-1 pb-1 yuanjiao ml-3 sjbxzt" v-if="item.type==2" style="background: #FCD7D5;font-size: calc(5px + 0.4vw);">{{ $t('捐款人') }}</div>
                    </div>
                    <div class="sjbxzt" style="font-size: calc(6px + 0.4vw);color: #666666;">
                      {{ timeFilter(item.create_time) }}</div>
                  </div>
                </div>
                <!-- 判断是不是自己是：删除 -->
                <div class="rating position-relative" style="text-align: right;">
                  <div v-if="userid==item.user_id" class="mb-2" @click="scshow = !scshow,asdhujid=item.id"><img class="ddd curpo ckqb" src="@/img/shanc.png" alt=""></div>
                  <img class="sjbdxx" style="width: 30px;height: 30px;" v-for="n in xsstars(item.rating)" :key="n" src="@/img/xzx.png">
                  <div @click="shanchuann(item.id,index)" v-if="scshow&&asdhujid==item.id" class="position-absolute d-flex pt-3 pb-3 pl-4 pr-4 align-items-center yinchez yuanjiao curpo ckqbb">
                    <img class="yinchezimg mr-1" src="@/img/zzdsc.png" alt="">
                    <div class="shanc sjbxzt" style="width: 30px;">{{ $t("删除") }}</div>
                  </div>
                </div>
              </div>
              <div class="mt-3 mb-3 sjbxzt" style="font-size: calc(8px + 0.4vw);">
                {{ item.content }}
              </div>
              <div v-if="item.reply.length!=0" style="width: 100%;height: 1px;background: #E5E5E5;"></div>
              <div class="mt-3 mb-3" v-for="(com, incex) in item.reply" :key="incex">
                <div class="d-flex align-items-center">
                  <img class="mr-2" style="width: calc(30px + 0.6vw);height: calc(30px + 0.6vw);border-radius: 50%;object-fit: cover;" :src="com.avatar" alt="" />
                  <div class="mr-2 sjbzt" style="font-size: calc(7px + 0.5vw);font-weight: 500;">{{ com.username }}
                  </div>
                  <div class="sjbxzt" style="font-size: calc(5px + 0.4vw);"> {{ timeFilter(com.create_time) }}</div>
                  <div class="pl-2 pr-2 pt-1 pb-1 yuanjiao ml-3 sjbxzt" style="background: #29DEA4;font-size: calc(5px + 0.4vw);">{{ $t('管理员') }}</div>
                </div>
                <div class="mt-4" style="margin-left: calc(22px + 0.6vw);">
                  <span class="p-3 yuanjiao sjbxzt" style="background: #F6F4F0;width: auto;font-size: calc(7px + 0.5vw);font-weight: 500;">{{
									com.content }}</span>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-center">
              <div v-if="list.length == 0" style="margin-top: 15vh;">
                <empty></empty>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="yuanjiao col-xl-3 yinc ml-0 pr-0 pl-1">
        <div class="baise col-md-12 pb-2 curpo yuanjiao pl-0 pr-0  mb-3" @click="zjsyqk()" style="height: 400px;">
          <div class="mb-2 d-flex justify-content-between ml-3 mr-3 pt-3" style="font-size: calc(8px + 0.4vw); font-weight: 500">
            <div>{{ $t("资金用途") }}</div>
            <div class="ckq curpo" style="color: #ffd672">
              {{ $t("查看全部") }}
            </div>
          </div>
          <div class="mb-2 ml-3 mr-3 col-md-11" style="height: 1px; background: #e5e5e5"></div>
          <div v-show="index <= 4" v-for="(item, index) in info.used" :key="index" class="d-flex  justify-content-between align-items-center mb-2 xunafuxgio pl-3 pr-3 pt-2 pb-2 yuanjiao">
            <div class="d-flex justify-content-between align-items-center">
              <img class="mr-2" style="width: calc(35px + 0.5vw);height: calc(35px + 0.4vw);border-radius: 50%;object-fit: cover;" :src="item.image" alt="" />
              <div>
                <div class="ellipsis" style="font-size: calc(7px + 0.4vw);">
                  {{ item.title }}
                </div>
                <div class="d-flex align-items-center mt-1">
                  <div class="d-flex mr-2 align-items-center" style="font-size: calc(6px + 0.4vw);color: #666666;">
                    <img style="width: calc(7px + 0.5vw);height: calc(7px + 0.5vw);" src="@/img/daohangl/icon_jkhd_qian@2x.png" alt="" />
                    <div class="ml-1">{{ item.amount ? item.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }}</div>
                  </div>
                  <div class="d-flex mr-3 align-items-center" style="font-size: calc(6px + 0.4vw); color: #666666">
                    <img style="
                          width: calc(7px + 0.5vw);
                          height: calc(7px + 0.5vw);
                        " src="@/img/icon_jkhd_shijain@2x.png" alt="" />
                    <div class="ml-1">{{ timeFilter(item.create_time) }}</div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <img style="width: calc(15px + 0.5vw)" src="@/img/daohangl/icon_hssy_hdlb_xiangqing@2x.png" alt="" /> -->
          </div>
        </div>
        <div class="baise col-md-12 pb-2 yuanjiao pl-0 pr-0 mb-3 curpo" @click="jkfml()" style="height: 400px;">
          <div class="mb-2 d-flex justify-content-between ml-3 mr-3 pt-3" style="font-size: calc(8px + 0.4vw); font-weight: 500">
            <div>{{ $t("捐款芳名录") }}</div>
            <div class="ckq curpo" style="color: #666666">
              {{ $t("累计捐款人次") }} <span style="color: #1A1A1A;">{{ info.donate_num }}</span>
            </div>
          </div>
          <div class="mb-2 ml-3 mr-3 col-md-11" style="height: 1px; background: #e5e5e5"></div>
          <div v-show="index <= 4" v-for="(item, index) in info.donate" :key="index" class="d-flex col-md-12 justify-content-between align-items-center mb-2 xunafuxgio pl-3 pr-3 pt-2 pb-2 yuanjiao" style="width: 100%;">
            <div class="pl-0 d-flex pr-0" style="width: 100%;">
              <div style="width: 20%;"><img class="mr-2" style="width: calc(35px + 0.5vw);height: calc(35px + 0.4vw);border-radius: 50%;object-fit: cover;" :src="item.avatar" alt="" /></div>
              <div style="width: 80%;" class="d-flex justify-content-between align-items-center">
                <div>
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="ellipsis" style="font-size: calc(5px + 0.4vw);">
                      {{ item.nickname }}
                    </div>
                  </div>
                  <div class="d-flex align-items-center mt-1">
                    <div class="d-flex mr-3 align-items-center" style="font-size: calc(6px + 0.4vw); color: #666666">
                      <img style="
                          width: calc(7px + 0.5vw);
                          height: calc(7px + 0.5vw);
                        " src="@/img/icon_jkhd_shijain@2x.png" alt="" />
                      <div class="ml-1">{{ timeFilter(item.pay_time) }}</div>
                    </div>
                  </div>
                </div>
                <div class="text-right">
                  <div style="font-size: calc(5px + 0.4vw);color: #B66D00;">{{ item.type==1?$t("定期捐款") : $t("单笔捐款")}} </div>
                  <div style="font-size: calc(5px + 0.4vw);color: #B66D00;">{{ item.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }} NT$</div>
                </div>
              </div>
            </div>
            <!-- <img style="width: calc(15px + 0.5vw)" src="@/img/daohangl/icon_hssy_hdlb_xiangqing@2x.png" alt="" /> -->
          </div>
        </div>
        <div class="baise col-md-12 pb-2 yuanjiao pl-0 pr-0 mb-3" style="height: 430px;">
          <div class="mb-2 d-flex justify-content-between ml-3 mr-3 pt-3" style="font-size: calc(8px + 0.4vw); font-weight: 500">
            <div>{{ $t("我的捐款历史") }}</div>
            <div class="ckq curpo" style="color: #ffd672" @click="funs()">
              {{ $t("查看全部") }}
            </div>
          </div>
          <div class="mb-2 ml-3 mr-3 col-md-11" style="height: 1px; background: #e5e5e5"></div>
          <div class="mb-2 pl-3 ml-2 mr-2 pr-3 pt-2 pb-2 yuanjiao d-flex justify-content-between" style="background: #FFEBD2;font-size: calc(7px + 0.4vw);">
            <div>{{ $t("捐款总额") }}</div>
            <div style="font-weight: 600;">{{ info.my_donate_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</div>
          </div>
          <div v-show="index <= 4" @click="funs(item)" v-for="(item, index) in info.my_donate" :key="index" class="d-flex curpo ckqbb justify-content-between align-items-center mb-2 xunafuxgio pl-3 pr-3 pt-2 pb-2 yuanjiao">
            <div class="d-flex justify-content-between align-items-center">
              <img class="mr-2" style="width: calc(35px + 0.5vw);height: calc(35px + 0.4vw);border-radius: 50%;object-fit: cover;" :src="item.avatar" alt="" />
              <div>
                <div class="ellipsis" style="font-size: calc(7px + 0.4vw);">
                  {{ item.type==1?$t("定期捐款") : $t("单笔捐款")}} <span style="color: #B66D00;">{{ item.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }} NT$</span>
                </div>
                <div class="d-flex align-items-center mt-1">
                  <div class="d-flex mr-3 align-items-center" style="font-size: calc(6px + 0.4vw); color: #666666">
                    <img style="
                          width: calc(7px + 0.5vw);
                          height: calc(7px + 0.5vw);
                        " src="@/img/icon_jkhd_shijain@2x.png" alt="" />
                    <div class="ml-1">{{ timeFilter(item.pay_time) }}</div>
                  </div>
                </div>
              </div>
            </div>
            <img style="width: calc(15px + 0.5vw)" src="@/img/daohangl/icon_hssy_hdlb_xiangqing@2x.png" alt="" />
          </div>
        </div>
      </div>

    </div>
  </div>
</template>
  
  <script>
import * as echarts from "echarts";
import SkeletonScreen from "@/views/index/SkeletonScreen/groupSkeletonScreen.vue";
import {} from "@/request/api.js";
import { formatTimestamp } from "@/utils/common.js";
import {
  donation_review_list,
  donation_submit_review,
  donation_review_del,
  review_base_detail,
} from "@/request/hshd/index.js";

export default {
  components: {
    SkeletonScreen,
  },
  data() {
    return {
      jkrpfsz: [],
      jktyyhpfsz: [],
      dataLoaded: false,
      id: "",
      showTooltips: false,
      showTooltip: false,
      userid: localStorage.getItem("user_id"),
      scshow: false,
      asdhujid: "",
      dataLoaded: false,
      rating: 1, // 初始评分为1，确保至少有一颗星被点亮
      maxStars: 5,
      hdfk: "",
      page: 1,
      list: [],
      info: {},
      chart: null,
      zjdsyl: "",
    };
  },
  created() {
    this.id = this.$route.params.zjsy;
    this.review_list();
    this.review_base_detail();
  },
  mounted() {
    setTimeout(() => {
      this.initChart();
    }, 600);
    // 模拟数据加载
  },
  computed: {
    stars() {
      let stars = [];
      for (let i = 0; i < this.maxStars; i++) {
        stars.push({
          class: i < this.rating ? "star-on" : "star-off",
        });
      }
      return stars;
    },
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
  },
  watch: {
    // 监听窗口大小变化，调整图表大小
    $route(to, from) {
      this.resizeChart();
    },
  },
  methods: {
    initChart() {
      // 基于准备好的dom，初始化echarts实例
      this.chart = echarts.init(this.$refs.chart);
      // 指定图表的配置项和数据
      const option = {
        title: {
          text: this.$t("使用率") + this.zjdsyl,
          left: "center",
          top: "center",
        },
        series: [
          {
            type: "pie",
            radius: ["60%", "84%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center",
            },
            labelLine: {
              show: false,
            },
            itemStyle: {
              normal: {
                color: function (params) {
                  const colorList = ["#FAB1AB", "#FCD7D5"];
                  return colorList[params.dataIndex];
                },
              },
            },
            data: [
              {
                value: this.zjdsyl.replace(/\%/g, ""),
                itemStyle: { color: "#FAB1AB" },
              }, // 圆环颜色
              {
                value: 100 - this.zjdsyl.replace(/\%/g, ""),
                itemStyle: { color: "#FCD7D5" },
              }, // 背景颜色
            ],
            label: {
              show: false,
              formatter: "{b}: {d}%", // 显示百分比
            },
          },
        ],
      };

      // 使用刚指定的配置项和数据显示图表。
      this.chart.setOption(option);
    },
    resizeChart() {
      if (this.chart) {
        this.chart.resize();
      }
    },
    lijizc() {
      this.$router.push({
        path: "/donation/" + this.id,
      });
    },
    zjsyqk() {
      this.$router.push({
        name: "zzsy",
        params: {
          isT: 2,
          zjsy: this.id,
          judge: "1",
        },
      });
    },
    jkfml() {
      this.$router.push({
        name: "jkmc",
        params: {
          isT: 2,
          zjsy: this.id,
          judge: "1",
        },
      });
    },
    funs(item) {
      this.$router.push({
        name: "hsgdb",
        params: {
          isT: 1,
          id: item ? item.id : "",
        },
      });
      //  this.$router.push('/members/point-logs')
    },
    async review_base_detail() {
      const { data, code } = await review_base_detail({ id: this.id });
      if (code == 200) {
        this.info = data;
        let m = this.info.data.donor_rating;
        let s = this.info.data.user_rating;
        if (
          this.info.data.used_amount == 0 ||
          this.info.data.received_amount == 0
        ) {
          this.zjdsyl = 0 + "%";
        } else {
          if (
            this.info.data.used_amount < 0 ||
            this.info.data.received_amount < 0
          ) {
            this.zjdsyl = 100 + "%";
          } else {
            this.zjdsyl =
              Math.round(
                (this.info.data.used_amount / this.info.data.received_amount) *
                  100
              ) + "%";
          }
        }

        if (parseInt(m) >= 0 && parseInt(m) < 1) {
          this.jkrpfsz = [{ n: 1 }, { n: 2 }, { n: 3 }, { n: 4 }, { n: 5 }];
        } else if (parseInt(m) >= 1 && parseInt(m) < 2) {
          this.jkrpfsz = [{ n: 1 }, { n: 2 }, { n: 3 }, { n: 4 }];
        } else if (parseInt(m) >= 2 && parseInt(m) < 3) {
          this.jkrpfsz = [{ n: 1 }, { n: 2 }, { n: 3 }];
        } else if (parseInt(m) >= 3 && parseInt(m) < 4) {
          this.jkrpfsz = [{ n: 1 }, { n: 2 }];
        } else if (parseInt(m) >= 4 && parseInt(m) < 5) {
          this.jkrpfsz = [{ n: 1 }];
        }

        if (parseInt(s) >= 0 && parseInt(s) < 1) {
          this.jktyyhpfsz = [{ n: 1 }, { n: 2 }, { n: 3 }, { n: 4 }, { n: 5 }];
        } else if (parseInt(s) >= 1 && parseInt(s) < 2) {
          this.jktyyhpfsz = [{ n: 1 }, { n: 2 }, { n: 3 }, { n: 4 }];
        } else if (parseInt(s) >= 2 && parseInt(s) < 3) {
          this.jktyyhpfsz = [{ n: 1 }, { n: 2 }, { n: 3 }];
        } else if (parseInt(s) >= 3 && parseInt(s) < 4) {
          this.jktyyhpfsz = [{ n: 1 }, { n: 2 }];
        } else if (parseInt(s) >= 4 && parseInt(s) < 5) {
          this.jktyyhpfsz = [{ n: 1 }];
        }
      }
    },
    handleScroll(event) {
      const target = event.target;
      const scrollDistance =
        target.scrollHeight - target.scrollTop - target.clientHeight;
      if (scrollDistance < 10) {
        // 当滚动条距离底部小于10px时，认为是滚动到底部
        if (this.page < this.last_page) {
          this.page++;
          this.review_list();
        }
      }
    },
    async shanchuann(e, v) {
      const { code } = await donation_review_del({ review_id: e });
      if (code == 200) {
        this.list.splice(v, 1);
        this.$bvToast.toast(this.$t("删除成功"), {
          title: this.$t("login.warning"),
          autoHideDelay: 2000,
          delay: 5000,
          appendToast: true,
          variant: "danger",
        });
      }
    },
    // 时间转换
    timeFilter(stringTime) {
      return formatTimestamp(stringTime * 1000);
    },
    // 用于回显的
    xsstars(e) {
      return Array.from(
        {
          length: e,
        },
        (_, i) => i + 1
      );
    },
    selectStar(index) {
      if (index >= 1 && index <= this.maxStars) {
        this.rating = index;
      }
    },
    async review_list() {
      const { code, data } = await donation_review_list({
        id: this.id,
        page: this.page,
      });
      if (code == 200) {
        this.dataLoaded = true;
      }
      this.last_page = data.last_page;
      this.list = [...this.list, ...data.data];
    },
    async fabufankui() {
      if (this.hdfk == "") {
        this.$bvToast.toast(this.$t("请输入"), {
          title: this.$t("login.warning"),
          autoHideDelay: 2000,
          delay: 5000,
          appendToast: true,
          variant: "danger",
        });
        return;
      }
      const { code, msg } = await donation_submit_review({
        id: this.id,
        rating: this.rating,
        content: this.hdfk,
      });
      if (code == 200) {
        this.hdfk = "";
        this.page = 1;
        this.list = [];
        this.review_list();
      } else {
        this.$bvToast.toast(msg, {
          title: this.$t("login.warning"),
          autoHideDelay: 2000,
          delay: 5000,
          appendToast: true,
          variant: "danger",
        });
      }
    },
  },
};
</script>
  <style scoped lang="scss">
@media (min-width: 376px) {
  .modal-dialog {
    // max-width: calc(100px + 0.5vw) !important;
    margin: 1.75rem auto;
  }
}
</style>
  <style scoped lang="scss">
@media screen and (max-width: 1208px) {
  .yinc {
    display: none !important;
  }

  .ybyc {
    padding-right: 0px !important;
  }
  .sjbzt {
    font-size: calc(11px + 0.4vw) !important;
  }
  .sjbxzt {
    font-size: calc(9px + 0.4vw) !important;
  }
  
}
@media (max-width: 995px) {
  .sjbdxx{
    width: 23px !important;
    height: 23px !important;
  }
  .sjdxsyc {
    display: block !important;
  }
  .wduasad {
    width: 100% !important;
  }
  .aaaa {
    display: block !important;
  }
  .yyss {
    display: none !important;
  }
  .sjtoto {
    font-size: calc(13px + 0.4vw) !important;
  }
  .sjitop {
    margin-top: 1rem !important;
  }
  .heggs {
    height: 81vh !important;
  }
}
@media (max-width: 800px) {
  .sjitop {
    margin-top: 1rem !important;
  }
  .sjibanyihang {
    width: 100% !important;
  }
  .sjbsrkkd {
    width: 70% !important;
  }
  // .dianjitc {
  //   position: absolute !important;
  //   background: #ffeab8 !important;
  //   min-width: 200px !important;
  //   z-index: 999999 !important;
  //   right: 30px !important;
  //   bottom: 0px !important;
  //   font-size: calc(8px + 0.4vw) !important;
  // }
}

.aaaa {
  display: none;
}
.dianjitc {
  position: absolute;
  background: #ffeab8;
  min-width: 200px;
  z-index: 999999;
  right: 20px;
  bottom: 20px;
  font-size: calc(8px + 0.4vw);
}
.form-control {
  height: calc(15px + 0.5vw) !important;
}

.sxbj {
  padding-top: calc(15px + 0.5vw);
  padding-bottom: calc(15px + 0.5vw);
}

.ckqbb:hover {
  transform: scale(1.001);
  /* 悬浮时放大1.1倍 */
  // padding: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.ckq:hover {
  transform: scale(1.001);
  /* 悬浮时放大1.1倍 */
  font-weight: 800;
}

.sbxfbd:hover {
  transform: scale(1.1);
}

.dropdown-menu {
  min-width: calc(25px + 0.5vw) !important;
  font-size: calc(8px + 0.4vw) !important;
  // left: -1rem;
}

/* 隐藏水平滚动条 */
.lieb::-webkit-scrollbar {
  display: none;
}

.ellipsis-multiline {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: calc(
    1em * 2 + 10px
  ); /* 1em 是行高，你可以根据实际的字体大小调整，10px 是额外的间距，比如内边距或边框等 */
  /* 为了确保在所有情况下都有正确的高度，你可能还需要设置一个固定的行高 */
  line-height: 1.5; /* 例如，1.5 倍的行高 */
  /* 设置一个最大高度，这个高度应该与 min-height 相同，以确保即使内容只有一行也保持两行的高度 */
  max-height: calc(1em * 2 * 1.5 + 10px); /* 这里乘以了 line-height */
  /* 为了在内容溢出时显示省略号 */
}
.rating span {
  display: inline-block;
  width: 30px;
  /* 根据你的星星图片大小调整 */
  height: 30px;
  /* 根据你的星星图片大小调整 */
  background-size: 30px 30px;
  /* 根据你的星星图片大小调整 */
  cursor: pointer;
}

.star-on {
  background-image: url("@/img/xzx.png");
  /* 点亮的星星图片路径 */
}

.star-off {
  background-image: url("@/img/wxzx.png");
  /* 未点亮的星星图片路径 */
}

.ckqbb:hover {
  transform: scale(1.001);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.ckqb:hover {
  transform: scale(1.101);
}

textarea.form-control {
  height: 10vh !important;
  overflow-y: auto !important;
}

.shancgjnk::-webkit-scrollbar {
  display: none;
}
.shanc {
  width: calc(20px + 0.4vw);
  font-size: calc(6px + 0.4vw);
}
.yinchez {
  top: 60%;
  right: 0;
  background: #fffaf0;
}
.yinchezimg {
  width: calc(20px + 0.4vw);
  height: calc(20px + 0.4vw);
}
.ddd {
  width: calc(25px + 0.4vw);
  height: calc(25px + 0.4vw);
}
</style>