<template>
	<div class="col-md-12 col-sm-12 col-lg-12 col-xl-12 baise pt-2" style="border-radius: 0 12px 12px 12px;">
		<div class="d-flex align-items-center">
			<img @click="fanhui(0)" class="mt-2 mb-2 curpo dcbd mr-2" style="width: calc(25px + 0.4vw);"
				src="@/img/login/icon_fanhui@2x.png" alt="" />
			<div style="font-size: calc(9px + 0.4vw);font-weight: 700;">{{ $t("申请参加活动") }}</div>
		</div>

		<div class="col-md-12 col-sm-12 col-lg-12 col-xl-12 pl-0 pr-0 pt-3 pb-3"
			style="font-size: calc(7px + 0.4vw);line-height: calc(15px + 0.5vw);">
			<form>
				<div class="form-group">
					<label style="font-weight: 500;"> <span style="color: red;">*</span>{{ $t("姓名") }}</label>
					<div>
						<input style="background: #F6F4F0;border: none;font-size: calc(7px + 0.4vw);"
							v-model="form.name" :disabled='isLoading' type="name" class="form-control srk pl-3"
							:placeholder="$t('输入全名')">
					</div>
				</div>
				<div class="form-group">
					<label style="font-weight: 500;">{{ $t("活动名称") }}</label>
					<div class="pl-0 pr-0 position-relative col-md-12">
						<!-- 触发Dropdown的按钮 -->
						<button class="d-flex ckqbb zhankai col-sm-3 dydpd pt-2 pb-2 pl-0 pr-0" type="button"
							id="dropdownMenuButton" @click="cllxfun()">
							<div class="d-flex align-items-center justify-content-between col-md-12">
								<div style="font-size: calc(6px + 0.4vw);">{{ jhuxa ? jhuxa : $t('请选择') }}
								</div>
								<div><img style="width: calc(7px + 0.4vw);"
										src="@/img/daohangl/icon_xlcd_xljt@2x (1).png" alt="" />
								</div>
							</div>
						</button>
						<!-- Dropdown内容，使用v-show控制显示 -->
						<div class="dropdown-menu yuanjiao xlcd position-absolute col-sm-3 text-center"
							aria-labelledby="dropdownMenuButton" v-show="cllxDropdownOpen"
							style="border-radius:7px;background: #FFFAF0;">
							<div style="border-radius: 12px;padding: 2px 0;"
								:style="{ 'background': jhuxa == item.name ? '#FFD672' : '' }"
								v-for="(item, index) in actList" :key="index" @click="goDian(item)">
								{{ item.name }}
							</div>
						</div>
					</div>
				</div>
				<div class="form-group">
					<label style="font-weight: 500;"> <span style="color: red;">*</span>{{ $t("参与形式") }}</label>
					<div>
						<div class="d-flex align-items-center mt-2 mb-2">
							<div @click="jkfs(item.id)" class="curpo toms yuanjiao mr-2 pt-1 pb-1"
								v-for="(item, index) in zflb" :key="index"
								style="width: calc(70px + 0.5vw);text-align: center;"
								:style="{ 'background': item.id == form.cyxs ? '#FEF7F6' : '#F6F4F0', 'border': item.id == form.cyxs ? '1px solid #FAB1AB' : 'none' }">
								<div class="toms-p">{{ item.name }}</div>
							</div>
						</div>
					</div>
				</div>
				<!-- 邮箱 -->
				<div class="form-group">
					<label style="font-weight: 500;">{{ $t('login.emailLoginButton') }}</label>
					<div>
						<input style="background: #F6F4F0;border: none;font-size: calc(7px + 0.4vw);"
							v-model="form.emil" :disabled='isLoading' @input="validatePhone()" type="text"
							class="form-control srk" :placeholder="$t('login.qsryx')">
						<small class="form-text" style="color: red;" v-if="emilError">{{ emilError }}</small>
					</div>
				</div>
				<!-- 验证码 -->
				<div class="form-group">
					<label style="font-weight: 500;">{{ $t('login.captchaLabel') }}</label>
					<div>
						<b-form-input @input="emyzmsd" style="background: #F6F4F0;border: none;font-size: calc(7px + 0.4vw);"
							v-model="form.use" :disabled='isLoading' type="number" class="form-control srk mb-3"
							:placeholder="$t('login.captchaPlaceholder')">
						</b-form-input>
						<div class="position-absolute yzm curpo pt-1 pb-1" v-if="emyzmqr==1">
              <div v-show="timeTrue" @click="obtainCode()">
                {{ $t('login.getCaptchaButton') }}
              </div>
              <div v-show="!timeTrue">
                {{ time }}{{ $t('login.resend_countdown') }}
              </div>
            </div>
            <div v-else class="position-absolute yzm pt-1 pb-1" style="background: #FAB1AB;">
              {{ $t("验证码正确") }}
            </div>
					</div>
				</div>

				<div class="form-group d-flex align-items-center">
					<img v-if="agreement" @click="agreement = !agreement" class="curpo mr-2"
						style="width: calc(10px + 0.4vw);" src="@/img/login/icon_dl_xy_xz@2x.png" alt="" />
					<img v-else @click="agreement = !agreement" class="curpo mr-2" style="width: calc(10px + 0.4vw);"
						src="@/img/login/icon_dl_xy_wxz@2x.png" alt="" />
					<span @click="agreement = !agreement" class="curpo" style="font-size: calc(7px + 0.4vw);">{{
						$t("勾选即表示您同意平台用户协议与隐私政策") }}</span>
				</div>
				<div @click="bulletframe2()" class="pl-4 pr-4 pt-2 pb-2 yuanjiao ckqbb curpo mt-3"
					style="font-size: calc(6px + 0.4vw);background: #FFD672;width: calc(120px + 0.5vw);text-align: center;">
					{{ $t("参与活动") }}
					<span v-if="isLoading" class="spinner-border spinner-border-sm ml-2" role="status"
						aria-hidden="true">
					</span>
				</div>
				<!-- <div @click="bulletframe()" class="pl-4 pr-4 pt-2 pb-2 yuanjiao ckqbb curpo mt-4"
					style="font-size: calc(6px + 0.4vw);background: #E5E5E5;width: calc(120px + 0.5vw);text-align: center;">
					{{ $t("已提交，请耐心等待") }}
					<span v-if="isLoading" class="spinner-border spinner-border-sm ml-2" role="status"
						aria-hidden="true">
					</span>
				</div> -->
			</form>
		</div>
	</div>
</template>

<script>
import {
	activity_apply,
	group_allactive
} from "@/request/hshd/index.js";
import {
	sendcodeemil,
	check_email
} from "@/request/api.js";
export default {
	components: {},
	data() {
		return {
			emyzmqr: 1,
			need_volunteer: "",
			id: "",
			isLoading: false,
			agreement: false,
			emilError: "",
			cllxDropdownOpen: false,
			time: "",
			timeTrue: true, //判断验证码
			zflb: [{
				id: 0,
				name: this.$t("普通用户"),
			},
			{
				id: 1,
				name: this.$t("志工"),
			},
			],
			form: {
				name: "",
				emil: "",
				use: "",
				cyxs: "0",
				hdlx: "",
			},


			// 组织机构id
			isid: null,
			// 活动名称
			actList: [],
			jhuxa: '',

		};
	},
	created() {

		this.isid = this.$route.query.i
	},
	mounted() {
		this.isid = this.$route.query.i

		this.getActi()
	},
	computed: {},
	methods: {
		async emyzmsd(e) {
      this.emyzmqr = 1;
      let numberArray = e.split("").map((char) => parseInt(char));
      console.log(numberArray.length);
      if (numberArray.length >= "6") {
        console.log(e.slice(0, 6));
        setTimeout(() => {
          this.form.use = e.slice(0, 6);
          check_email({ email: this.form.emil, code: this.form.use }).then(
            (res) => {
              if (res.code == 200) {
                this.$bvToast.toast(this.$t("验证码正确"), {
                  title: this.$t("login.notice"),
                  autoHideDelay: 2000,
                  delay: 5000,
                  appendToast: true,
                  variant: "success",
                });
                this.emyzmqr = 0;
              } else {
                this.$bvToast.toast(this.$t("验证码错误"), {
                  title: this.$t("login.warning"),
                  variant: "danger",
                  autoHideDelay: 5000,
                });
                this.form.use = "";
                this.emyzmqr = 1;
                this.timeTrue = true;
              }
            }
          );
        }, 100);
      }
    },
		// 选择活动名称
		goDian(e) {
			this.jhuxa = e.name
			this.cllxDropdownOpen = false
			this.form.hdlx = e.id
		},
		getActi() {
			let data = {
				id: this.isid
			}
			group_allactive(data).then(res => {
				// console.log(res, "职工活动")
				if (res.code == 200) {
					this.actList = res.data.data
				}
			})
		},


		// 选择活动名称
		cllxxuanz(e) {
			this.form.hdlx = e;
			this.cllxDropdownOpen = false;
		},
		// 活动名称
		cllxfun() {
			this.cllxDropdownOpen = !this.cllxDropdownOpen;
			// 如果需要，可以在这里添加Bootstrap Dropdown的额外处理逻辑
		},
		// 参与形式
		jkfs(e) {
			this.form.cyxs = e;
		},
		fanhui(e) {
			// this.$emit("custom", e);
			this.$router.push('/activity')

		},
		// 点击
		async bulletframe2() {
			if (!this.agreement) {
				this.$bvToast.toast(this.$t("请勾选协议！"), {
					title: this.$t("login.warning"),
					variant: "danger",
					autoHideDelay: 5000,
				});
				return;
			} else if (this.form.name == "") {
				this.$bvToast.toast(this.$t("请输入姓名"), {
					title: this.$t("login.warning"),
					variant: "danger",
					autoHideDelay: 5000,
				});
				return;
			} else if (this.form.hdlx == "") {
				this.$bvToast.toast(this.$t("请选择活动名称"), {
					title: this.$t("login.warning"),
					variant: "danger",
					autoHideDelay: 5000,
				});
				return;
			} else if (this.form.emil == "") {
				this.$bvToast.toast(this.$t("请输入信箱"), {
					title: this.$t("login.warning"),
					variant: "danger",
					autoHideDelay: 5000,
				});
				return;
			} else if (this.form.use == "") {
				this.$bvToast.toast(this.$t("请输入验证码"), {
					title: this.$t("login.warning"),
					variant: "danger",
					autoHideDelay: 5000,
				});
				return;
			}
			this.isLoading = true;
			let data = {
				id: this.isid,
				name: this.form.name,
				is_volunteer: this.form.cyxs,
				email: this.form.emil,
				email_code: this.form.use
			}
			activity_apply(data).then(res => {
				// console.log(res.code, "resy")
				this.isLoading = false; // 处理完成后关闭转圈
				this.$bvToast.toast(res.msg, {
					title: this.$t("login.notice"),
					autoHideDelay: 2000,
					delay: 5000,
					appendToast: true,
					variant: res.code == 200 ? "success" : "danger",
				});

				if (res.code == '200') {

					this.form.name = ''
					this.form.emil = ''

					this.form.use = ''

					this.form.cyxs = '0'

					this.form.hdlx = ''
				}


			})


		},
		// 校验验证码
		validatePhone() {
			this.form.use = "";
      this.emyzmqr = 1;
      this.timeTrue = true;
			const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
			if (!regex.test(this.form.emil)) {
				this.emilError = this.$t("login.invalid_emil_number");
			} else {
				this.emilError = "";
			}
			if (this.form.emil == "") {
				this.emilError = "";
			}
		},
		// 获取验证码
		async obtainCode() {
			if (this.emilError != "") {
				return;
			} else if (this.form.emil == "") {
				this.$bvToast.toast(this.$t("login.qsryx"), {
					title: this.$t("login.warning"),
					variant: "danger",
					autoHideDelay: 5000,
				});
				return;
			}
			this.$bvToast.toast(this.$t("login.verification_success"), {
					title: this.$t("login.notice"),
					autoHideDelay: 2000,
					delay: 5000,
					appendToast: true,
					variant: "success",
				});
				this.acquire();
			const {
				code,
				msg
			} = await sendcodeemil({
				email: this.form.emil
			})
			if (code == 200) {
				
			} else {
				this.$bvToast.toast(msg, {
					title: this.$t("login.warning"),
					variant: "danger",
					autoHideDelay: 5000,
				});
			}
		},
		// 验证码获取成功
		acquire() {
			this.timeTrue = false;
			this.time = 60;
			var setTimeoutS = setInterval(() => {
				this.time--;
				if (this.time <= 0) {
					clearInterval(setTimeoutS);
					this.timeTrue = true;
				}
			}, 1000);
		},
	},
};
</script>

<style scoped>
.dcbd:hover {
	transform: scale(1.2);
}

.ckqbb:hover {
	transform: scale(1.001);
	/* 悬浮时放大1.1倍 */
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.xlcd {
	display: block;
	background: #ffebd2;
	border: none;
}

.zhankai {
	background: #ffebd2;
	text-align: center;
	border-radius: 12px 12px 12px 12px;
	border: none;
	font-weight: 400;
	font-size: calc(6px + 0.4vw);
	color: #1a1a1a;
}

textarea.form-control {
	overflow-y: auto !important;
}

.yzm {
	height: 27px;
	line-height: 27px;
	text-align: center;
	font-weight: 400;
	font-size: 12px;
	color: #1a1a1a;
	background: #ffd672;
	border-radius: 12px;
	padding: 0 12px;
}
</style>