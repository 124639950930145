import request from "../index";

// 共运任务列表
export const todelivery = (params) => request.get("/delivery/list", { params });
//共运任务申请所需数据
export const todonate_base = (params) => request.get("/delivery/apply_base", { params });
// 共运任务申请
export const toapply = (params) => request.post("/delivery/apply", params);
// 申请工运申请所需数据
export const tovehicle = (params) => request.post("/delivery/vehicle_base", params);
//个人出发资讯
export const toindividual = (params) => request.post("/delivery/individual_vehicle", params);
// 企业出发资讯
export const tocompany = (params) => request.post("/delivery/company_vehicle", params);
//共运任务申请所需数据
export const delivery_place = (params) => request.get("/delivery/place", { params });
// 捐物基础数据
export const donate_supplies = (params) => request.get("/donate_supplies/base", { params });
// 捐赠
export const donate_supplies_donate = (params) => request.post("/donate_supplies/donate", params);
// 根据类别获取品名
export const donate_supplies_goods_base = (params) => request.get("/donate_supplies/goods_base", { params });

