<template>
	<div>
		<!-- 骨架屏 -->
		<skeleton-screen v-if="!dataLoaded"></skeleton-screen>
		<div v-if="dataLoaded" class="col-md-12 pl-0 pr-0">
			<div class="col-md-12 pl-0 pr-0">
				<div class="col-sm-12 pl-0 pr-0 d-flex justify-content-between align-items-center">
					<div class="d-flex align-items-center" style="font-size: calc(7px + 0.5vw);">
						<img v-if="dataLoaded" @click="xindfanhui(0)" class="curpo dcbd mr-2"
							style="width: calc(25px + 0.4vw);" src="@/img/login/icon_fanhui@2x.png" alt="" />
						<div class="mr-2" style="font-weight: 600;">{{ $t('管理组织列表') }}</div>
					</div>
					<div class="mr-3 d-flex align-items-center">
						<div class="mr-3 position-relative d-flex align-items-center">
							<input class="form-control sxbj col-md-12 inppt pr-5" type="text" :placeholder="this.$t('搜寻')"
								plaintext='true' aria-label="Username" aria-describedby="basic-addon1">
							<img style="right: 20px;width: calc(10px + 0.5vw);" class="position-absolute curpo sbxfbd"
								src="@/img/daohangl/icon_hssy_sousuo@2x.png" alt="" />
						</div>
						<div class=" position-relative mr-3">
							<!-- 触发Dropdown的按钮 -->
							<button class="d-flex ckqbb zhankai dydpd pt-2 pl-2 pr-2 pb-2 pl-0 pr-0" type="button"
								id="dropdownMenuButton" @click="toggleDropdown()">
								<div class="d-flex align-items-center justify-content-between col-md-12">
									<div style="font-size: calc(6px + 0.4vw);">{{ jhuxa ? jhuxa : $t('全部') }}
									</div>
									<div class="ml-3"><img style="width: calc(7px + 0.4vw);"
											src="@/img/daohangl/icon_xlcd_xljt@2x (1).png" alt="" /></div>
								</div>
							</button>
							<!-- Dropdown内容，使用v-show控制显示 -->
							<div class="dropdown-menu yuanjiao xlcd position-absolute  text-center"
								aria-labelledby="dropdownMenuButton" v-show="isDropdownOpen"
								style="border-radius:7px;background: #FFFAF0;margin-left: -25%;">
								<a class="dropdown-item xuazz yuanjiao mb-2"
									:style="{ 'background': jhuxa == $t('全部') ? '#FFD672' : '' }"
									@click="xuanz($t('全部'))" href="#">{{ $t('全部') }}</a>
								<a class="dropdown-item xuazz yuanjiao mb-2"
									:style="{ 'background': jhuxa == $t('操作1') ? '#FFD672' : '' }"
									@click="xuanz($t('操作1'))" href="#">{{ $t('操作1') }}</a>
								<a class="dropdown-item xuazz yuanjiao mb-2"
									:style="{ 'background': jhuxa == $t('操作2') ? '#FFD672' : '' }"
									@click="xuanz($t('操作2'))" href="#">{{ $t('操作2') }}</a>
								<a class="dropdown-item xuazz yuanjiao mb-2"
									:style="{ 'background': jhuxa == $t('操作3') ? '#FFD672' : '' }"
									@click="xuanz($t('操作3'))" href="#">{{ $t('操作3') }}</a>
							</div>
						</div>
						<div class=" position-relative">
							<!-- 触发Dropdown的按钮 -->
							<button class="d-flex ckqbb zhankai dydpd pt-2 pl-2 pr-2 pb-2 pl-0 pr-0" type="button"
								id="dropdownMenuButton" @click="zzlxtoggleDropdown()">
								<div class="d-flex align-items-center justify-content-between col-md-12">
									<div style="font-size: calc(6px + 0.4vw);">{{ zzlx ? zzlx : $t('组织类型') }}
									</div>
									<div class="ml-3"><img style="width: calc(7px + 0.4vw);"
											src="@/img/daohangl/icon_xlcd_xljt@2x (1).png" alt="" /></div>
								</div>
							</button>
							<!-- Dropdown内容，使用v-show控制显示 -->
							<div class="dropdown-menu yuanjiao xlcd position-absolute  text-center"
								aria-labelledby="dropdownMenuButton" v-show="zzlxisDropdownOpen"
								style="border-radius:7px;background: #FFFAF0;left: -25%;">
								<a class="dropdown-item xuazz yuanjiao mb-2"
									:style="{ 'background': zzlx == $t('操作1') ? '#FFD672' : '' }"
									@click="zzlxxuanz($t('操作1'))" href="#">{{ $t('操作1') }}</a>
								<a class="dropdown-item xuazz yuanjiao mb-2"
									:style="{ 'background': zzlx == $t('操作2') ? '#FFD672' : '' }"
									@click="zzlxxuanz($t('操作2'))" href="#">{{ $t('操作2') }}</a>
								<a class="dropdown-item xuazz yuanjiao mb-2"
									:style="{ 'background': zzlx == $t('操作3') ? '#FFD672' : '' }"
									@click="zzlxxuanz($t('操作3'))" href="#">{{ $t('操作3') }}</a>
							</div>
						</div>
					</div>
				</div>
				<div
					class="col-md-12 pl-0 pr-0 position-relative d-flex pl-0 pr-0 justify-content-between align-items-center">
					<div style="height: 82vh;overflow-y: auto;" class="col-md-12 parent pl-0 pr-0">
						<div class="row col-md-12 pl-0 pr-0 mt-3">
							<div class="col-sm-3 pl-0 pr-0 pl-3" v-for="subItem in items" :key="subItem.id">
								<div class="col-sm-12 baise mb-3 ckqbb p-3 yuanjiao pl-0 pr-0"
									style="width: 100%;text-align: center; cursor: not-allowed;">
									<img @click="tiaozhuan()" style="width: 100%"
										src="@/img/daohangl/pic_hssy_tw_one@2x.png" alt="" />
									<div @click="tiaozhuan()" class="mt-3"
										style="font-size: calc(6px + 0.4vw);color: #FF797A;">{{ $t('教育机构') }}</div>
									<div @click="tiaozhuan()" class="mt-2"
										style="font-size: calc(8px + 0.4vw);font-weight: 700;">
										{{ $t('社团法人台北市臻佶祥社会服务协会') }}</div>
									<div @click="tiaozhuan()" class="mt-2 ellipsis-multiline ellipsis-multiline"
										style="font-size: calc(6px + 0.4vw);">
										{{
											$t("司马光，字君实，号迂叟，陕州夏县涑水乡人，《宋史》，《辞海》等明确记载，世称涑水先生。生于河南省信阳市光山县。北宋史学家、文学家。历仕仁宗、英宗、神宗、哲…")
										}}
									</div>
									<div @click="tiaozhuan()" class="mt-3 mb-3"
										style="width: 100%;height: 1px;background: #E5E5E5;"></div>
									<div class="d-flex col-sm-12 pl-0 pr-0 justify-content-between align-items-center">
										<div @click="tiaozhuan()" class="ml-2">
											<img style="margin-left: -15px;width: calc(27px + 0.4vw);z-index: 99;"
												src="@/img/daohangl/pic_hssy_zdh_touxiang@2x.png" alt="" />
											<img style="margin-left: -15px;width: calc(27px + 0.4vw);z-index: 99;"
												src="@/img/daohangl/pic_hssy_tanbi@2x.png" alt="" />
											<img style="margin-left: -15px;width: calc(27px + 0.4vw);z-index: 99;"
												src="@/img/daohangl/pic_hssy_hdlb_tx_one@2x.png" alt="" />
											<img style="margin-left: -15px;width: calc(27px + 0.4vw);z-index: 99;"
												src="@/img/daohangl/pic_hssy_zdh_touxiang@2x.png" alt="" />
										</div>
										<div class="pl-3 pr-3 pt-2 pb-2 yuanjiao ckqbb"
											style="font-size: calc(6px + 0.4vw);background: #FFD672;">
											{{ $t('浏览组织') }}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

				</div>
			</div>
		</div>
	</div>
</template>

<script>
import SkeletonScreen from "./SkeletonScreen/glzzSkeletonScreen.vue";
export default {
	components: {
		SkeletonScreen
	},
	data() {
		return {
			jhuxa: $t("全部"),
			dataLoaded: false,
			show: 0,
			isDropdownOpen: false,
			zzlxisDropdownOpen: false,
			progress: 60,
			zzlx: "",
			items: [{
				name: 1
			}, {
				name: 2
			}, {
				name: 3
			}, {
				name: 4
			}, {
				name: 5
			}, {
				name: 6
			}, {
				name: 7
			}, {
				name: 8
			}],
		};
	},
	created() {
		this.updateVisibleItems();
	},
	mounted() {
		// 模拟数据加载
		setTimeout(() => {
			this.dataLoaded = true;
			// 在这里加载你的数据
		}, 2000);
	},
	computed: {

	},
	methods: {
		xuanz(e) {
			this.jhuxa = e
			this.isDropdownOpen = false
		},
		toggleDropdown() {
			this.isDropdownOpen = !this.isDropdownOpen;
			// 如果需要，可以在这里添加Bootstrap Dropdown的额外处理逻辑  
		},
		zzlxxuanz(e) {
			this.zzlx = e
			this.zzlxisDropdownOpen = false
		},
		zzlxtoggleDropdown() {
			this.zzlxisDropdownOpen = !this.zzlxisDropdownOpen;
			// 如果需要，可以在这里添加Bootstrap Dropdown的额外处理逻辑  
		},
		xindfanhui(e) {
			this.$emit('custom', e);
		},
		// 详情等页面返回按钮
		custom(e) {
			this.show = e
		},
		fun(e) {
			this.show = e
		},
	},
};
</script>

<style scoped lang="scss">
.ellipsis-multiline {
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 3;
	overflow: hidden;
	text-overflow: ellipsis;
}

.ckqbb:hover {
	transform: scale(1.001);
	/* 悬浮时放大1.1倍 */
	// padding: 12px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.dcbd:hover {
	transform: scale(1.2);
	/* 悬浮时放大1.1倍 */
	// padding: 12px;
}

.jdtt {
	height: 100%;
	background: #FFD672;
	color: #1A1A1A;
	text-align: right;
	font-size: calc(6px + 0.4vw);
}

.fangda:hover {
	transform: scale(1.02);
}

.xlcd {
	display: block;
	background: #FFEBD2;
	border: none;
}

.xuazz:hover {
	background: #FFEBD2;
}

.zhankai {
	background: #FFEBD2;
	text-align: center;
	border-radius: 12px 12px 12px 12px;
	border: none;
	font-weight: 400;
	font-size: calc(6px + 0.4vw);
	color: #1A1A1A;
}

.parent::-webkit-scrollbar {
	display: none;
}

.sxbj {
	padding-top: calc(15px + 0.5vw);
	padding-bottom: calc(15px + 0.5vw)
}

.inppt {
	background: white;
	border: none;
	border-radius: 13px;
	font-size: calc(7px + 0.4vw);
	padding-top: 22px;
	padding-bottom: 22px;
}

.sbxfbd:hover {
	transform: scale(1.1);
}
</style>