<template>
  <div class="col-md-12 pl-0 pr-0">
    <skeleton-screen class="mt-3" v-if="!dataLoaded"></skeleton-screen>
    <div v-if="dataLoaded" class="col-md-12 yuanjiao pt-3 pb-3 pl-0 pr-0 d-flex justify-content-between">
      <div v-if="show==0" class=" col-lg-12 pl-0 pr-0">
        <div class="col-md-12 baise yuanjiao pl-0 pr-0 pt-3 mt-3 pl-3 pr-3 yinc">
          <div class="mb-2 sjbzt" style="font-size: calc(8px + 0.5vw);font-weight: 600;">
            <div>{{ $t('影響力計劃列表') }}</div>
          </div>
          <div class="mb-3" style="height: 1px;background: #E5E5E5;"></div>
          <div v-if="chengList.length != 0" class="pb-1">
            <div v-for="(item,index) in chengList" :key="index" class="mb-3 p-3 yuanjiao d-flex align-items-center justify-content-between" style="background: #F6F4F0;">
              <div class="d-flex" style="width: 33%;">
                <div class="mr-3"><img class="tpxz" :src="item.cover_image" alt=""></div>
                <div style="font-size: 13px;">
                  <div>
                    <div class="d-flex" style="font-size: calc(10px + 0.4vw);font-weight: 600;">
                      <div class="mr-2">{{ item.name ?  item.name : '--' }}</div>
                      <div class="yuanjiao pt-1 pb-1 text-center" style="font-size: calc(5px + 0.4vw);background: #FFEAB8;width: calc(60px + 0.4vw);height: calc(20px + 0.4vw);">{{ $t('教育支持') }}</div>
                    </div>
                    <div class="huisea mt-1" style="font-size: 11px;display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 2;overflow: hidden;text-overflow: ellipsis;">{{ item.summary ? item.summary : '--' }}</div>
                  </div>
                </div>
              </div>
              <div class="d-flex align-items-center">
                <div class="d-flex align-items-center justify-content-between baise yuanjiao p-4 mr-3 text-center pczt2">
                  <div class="mr-3">
                    <div class="mb-3 huisea">{{ $t('活動總場次') }}</div>
                    <div>{{ item.activity_num ? item.activity_num + $t('場') : '--' }}</div>
                  </div>
                  <div class="mr-3">
                    <div class="mb-3 huisea">{{ $t('累計人數') }}</div>
                    <div>{{ item.questionnaire_num ? item.questionnaire_num : '--' }}</div>
                  </div>
                  <div>
                    <div class="mb-3 huisea">{{ $t('計畫時長') }}</div>
                    <div>{{ item.duration ? item.duration +  $t('年') : '--' }}</div>
                  </div>
                </div>
                <div class="baise yuanjiao p-4 text-center pczt2">
                  <div class="mb-3 d-flex">
                    <div class="huisea mr-4">{{ $t('開始時間') }}</div>
                    <div>{{ item.start_date ? item.start_date : '--' }}</div>
                  </div>
                  <div class="d-flex">
                    <div class="huisea mr-4">{{ $t('結束時間') }}</div>
                    <div>{{ item.end_date ? item.end_date : '--' }}</div>
                  </div>
                </div>
              </div>
              <div>
                <div v-if="item.is_impact==1" class="pt-2 pb-2 pl-3 pr-3 yuanjiao text-center pczt curpo mb-3" style="background: #FAB1AB;">{{ $t('影響力報告') }}</div>
                <div @click="jhxiangq(item.id)" class="pt-2 pb-2 pl-3 pr-3 yuanjiao text-center pczt curpo" style="background: #FFD672;">{{ $t('計畫詳情') }}</div>
              </div>
            </div>
          </div>

          <div v-else class="pb-3">
            <empty></empty>

          </div>
        </div>
        <!-- 手机 -->
        <div class="col-md-12 baise yuanjiao pl-0 pr-0 pt-3 mt-3 pl-3 pr-3 aaaa">
          <div class="mb-2 sjbzt" style="font-size: calc(8px + 0.5vw);font-weight: 600;">
            <div>{{ $t('影響力計劃列表') }}</div>
          </div>
          <div class="mb-3" style="height: 1px;background: #E5E5E5;"></div>
          <div v-if="chengList.length != 0" class="pb-1">
            <div v-for="(item,index) in chengList" :key="index" class="mb-3 p-3 yuanjiao" style="background: #F6F4F0;">
              <div class="d-flex">
                <div class="mr-3"><img class="tpxz" :src="item.cover_image" alt=""></div>
                <div style="font-size: 13px;">
                  <div>
                    <div class="d-flex" style="font-size: calc(10px + 0.4vw);font-weight: 600;">
                      <div class="mr-2">{{ item.name ?  item.name : '--' }}</div>
                      <div class="yuanjiao pt-1 pb-1 text-center" style="font-size: calc(5px + 0.4vw);background: #FFEAB8;width: calc(60px + 0.4vw);">{{ $t('教育支持') }}</div>
                    </div>
                    <div class="huisea mt-1" style="font-size: 11px;display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 2;overflow: hidden;text-overflow: ellipsis;">{{ item.summary ? item.summary : '--' }}</div>
                  </div>
                </div>
              </div>
              <div class="d-flex align-items-center justify-content-between mt-3 mb-3 asdasd">
                <div class="d-flex align-items-center justify-content-around baise yuanjiao p-3 text-center pczt2 sjbzt sjwidth mbstring" style="width: 50%;">
                  <div>
                    <div class="mb-3 sjbxzt huisea">{{ $t('活動總場次') }}</div>
                    <div>{{ item.activity_num ? item.activity_num +  $t('場') : '--' }}</div>
                  </div>
                  <div>
                    <div class="mb-3 sjbxzt huisea">{{ $t('累計人數') }}</div>
                    <div>{{ item.questionnaire_num ? item.questionnaire_num : '--' }}</div>
                  </div>
                  <div>
                    <div class="mb-3 sjbxzt huisea">{{ $t('計畫時長') }}</div>
                    <div>{{ item.duration ? item.duration +  $t('年') : '--' }}</div>
                  </div>
                </div>
                <div class="baise yuanjiao p-3 text-center pczt2 sjbzt sjwidth" style="width: 47%;">
                  <div class="mb-3 d-flex align-items-center justify-content-around">
                    <div class="huisea mr-2 sjbxzt">{{ $t('開始時間') }}</div>
                    <div>{{ item.start_date ? item.start_date : '--' }}</div>
                  </div>
                  <div class="d-flex align-items-center justify-content-around">
                    <div class="huisea mr-2 sjbxzt">{{ $t('結束時間') }}</div>
                    <div>{{ item.end_date ? item.end_date : '--' }}</div>
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-around">
                <div v-if="item.is_impact==1" class="pt-2 pb-2 pl-3 pr-3 yuanjiao text-center pczt curpo sjbxzt" style="background: #FAB1AB;width: 40%;">{{ $t('影響力報告') }}</div>
                <div @click="jhxiangq(item.id)" class="pt-2 pb-2 pl-3 pr-3 yuanjiao text-center pczt curpo sjbxzt" style="background: #FFD672;width: 40%;">{{ $t('計畫詳情') }}</div>
              </div>
            </div>
          </div>

          <div v-else class="pb-3">
            <empty></empty>

          </div>
        </div>
      </div>
      <jhxq v-if="show==1" :id="xzdid" @custom="custom"></jhxq>
    </div>
  </div>
</template>
  
  <script>
import { EventBus } from "@/views/event-bus.js";
import empty from "../empty/empty.vue";
import SkeletonScreen from "./tuabduihd/SkeletonScreen/SkeletonScreen.vue";
import { impact_list } from "@/request/api.js";
import { formatTimestamp } from "@/utils/common.js";
import jhxq from "./impaxq.vue";
export default {
  components: {
    SkeletonScreen,
    empty,
    jhxq,
  },
  props: {},
  data() {
    return {
      id: "",
      show: 0,
      xzdid:'',
      dataLoaded: true,
      chengList: [],
      currentPage: 1,
      last_page: "",
    };
  },
  created() {
    EventBus.$on("hsttimpactplan", (msg) => {
      if (this.currentPage < this.last_page) {
        this.currentPage += 1;
        this.getGroup_team();
      }
    });
  },
  beforeDestroy() {
    // 在组件销毁前移除事件监听器
    EventBus.$off("hsttimpactplan");
  },
  mounted() {
    this.id = this.$route.params.id;
    this.getGroup_team();
  },
  computed: {},
  methods: {
    custom(e){
       this.show=e;
    },
    jhxiangq(e) {
      this.xzdid=e;
      this.show = 1;
    },
    // 时间转换
    timeFilter(stringTime) {
      return formatTimestamp(stringTime * 1000);
    },
    getGroup_team() {
      impact_list({
        page: this.currentPage,
        organization_id: this.id,
      }).then((res) => {
        if (res.code == 200) {
          this.dataLoaded = true;
        }
        console.log(res, "团队成员");
        this.last_page = res.data.last_page;
        this.chengList = [...this.chengList, ...res.data.data];
      });
    },
  },
};
</script>
  
  <style scoped>
@media screen and (max-width: 1201px) {
  .aaaa {
    display: block !important;
  }
  .yinc {
    display: none !important;
  }
  .sjbzt {
    font-size: calc(11px + 0.4vw) !important;
  }
  .sjbxzt {
    font-size: calc(9px + 0.4vw) !important;
  }
}
@media screen and (max-width: 570px) {
  .sjwidth {
    width: 100% !important;
  }
  .asdasd {
    display: block !important;
  }
  .mbstring {
    margin-bottom: 1rem !important;
  }
}
.aaaa {
  display: none;
}
/* @media screen and (max-width: 1200px) {
          .yinc {
              display: none !important;
          }
      } */
.dcbd:hover {
  transform: scale(1.2);
}
.tpxz {
  width: calc(30px + 0.4vw);
  height: calc(30px + 0.4vw);
  border-radius: 8px;
  object-fit: cover;
}
.inppt {
  background: white;
  border: none;
  border-radius: 13px;
  font-size: calc(7px + 0.4vw);
}

.xunafuxgio:hover {
  transform: scale(1.001);
  /* 悬浮时放大1.1倍 */
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  /* 悬浮时添加阴影效果 */
}

.qieh {
  width: calc(100px + 0.4vw);
  text-align: center;
  border-radius: 12px 12px 0 0;
}

.sxbj {
  padding-top: calc(15px + 0.5vw);
  padding-bottom: calc(15px + 0.5vw);
}
/* 隐藏水平滚动条 */
.lieb::-webkit-scrollbar {
  display: none;
}
.pczt {
  font-size: calc(7px + 0.4vw);
}
.pczt2 {
  font-size: calc(7px + 0.4vw);
}
.huisea {
  color: #999999;
}
</style>