import Vue from 'vue'
import VueI18n from 'vue-i18n'

import zhCN from "./locales/zh_CN.json";
import enUS from "./locales/en_US.json";
import cht from "./locales/cht.json"

// 全局挂载
Vue.use(VueI18n);
//  获取本地存储 || 根据浏览器语言设置
// let language = localStorage.getItem("language") || ;
let language = ''
if (localStorage.getItem('language')) {
	language = localStorage.getItem("language")
} else {
	// let p = navigator.language
	let p = localStorage.getItem("language")

	switch (p) {
		case 'cn':
			language = 'cn'
			break;
		case 'en':
			language = 'en'
			break;
		default:
			language = 'cht'
			break;
	}
}


export const i18n = new VueI18n({
	locale: language,
	messages: {
		cht,
		"cn": zhCN,
		"en": enUS,
	},
});

export default i18n;