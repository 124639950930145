<!-- SkeletonScreen.vue -->
<template>
	<div>
		<!-- background-color: #F6F4F0; -->
		<div class="row pl-0 pr-0" style="width: 100%;margin: 0;">
			<div class="col-md-12 pl-0 pr-0">
				<!-- 模拟侧边栏 -->
				<div class="skeleton-wrapper">
					<div class="skeleton-item"
						style="height: 8vh; width: 100%; background-color: white; border-radius: 4px;"></div>
				</div>
			</div>
			<div class="col-md-12">
				<div class="col-md-12 d-flex" style="background-color: #F6F4F0;padding-top: 14px;">
					<div class="col-md-2">
						<!-- 模拟侧边栏 -->
						<div class="skeleton-wrapper">
							<div class="skeleton-item"
								style="height: 18vh; width: 100%; background-color: white; border-radius: 4px;margin-top: 20px;">
							</div>
							<div class="skeleton-item"
								style="height: 65vh; width: 100%; background-color: white; margin-top: 20px; border-radius: 4px;">
							</div>
						</div>
					</div>
					<div class="col-md-8">
						<!-- 模拟文章列表 -->
						<div class="skeleton-wrapper" style="margin-top: 20px;">
							<div class="skeleton-item"
								style="height: 88vh; width: 100%; background-color: white; border-radius: 4px;"></div>
							<!-- <div class="skeleton-item" style="height: 20px; width: 100%; background-color: #e9ecef; margin-top: 10px; border-radius: 4px;"></div>   -->
						</div>
					</div>
					<div class="col-md-2">
						<!-- 模拟侧边栏 -->
						<div class="skeleton-wrapper">
							<div class="skeleton-item"
								style="height: 36vh; width: 100%; background-color: white; border-radius: 4px;margin-top: 20px;">
							</div>
							<div class="skeleton-item"
								style="height: 40vh; width: 100%; background-color: white; margin-top: 20px; border-radius: 4px;">
							</div>
						</div>
					</div>
				</div>
			</div>

		</div>
	</div>
</template>

<script>
	export default {
		name: "SkeletonScreen",
	};
</script>

<style scoped>
	.skeleton-wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.skeleton-item {
		width: 100%;
		/* 根据需要调整 */
		height: 100%;
		/* 根据需要调整 */
		background-color: #e9ecef;
		border-radius: 4px;
		/* 圆角 */
		animation: pulse 1.5s ease-in-out infinite;
	}

	@keyframes pulse {
		0% {
			opacity: 1;
		}

		50% {
			opacity: 0.3;
		}

		100% {
			opacity: 1;
		}
	}
</style>