<template>
	<div class="col-md-12 pl-0 pr-0">
		<div class="col-md-12 yuanjiao pt-3 pb-3 pl-0 pr-0 sjbzt"
			style="font-size: calc(7px + 0.4vw);line-height: calc(15px + 0.5vw);">
			<div class="col-md-12 d-flex pl-0 pr-0">
				<div @click="fun(1)" class="pt-3 pb-3 curpo qieh"
					:style="{'background':show==1?'#FFFFFF':'#FFFAF0','font-weight':show==1?'700':'500','color':show==1?'':'#999999'}">
					{{$t('活动中数据')}}</div>
				<div @click="fun(2)" class="pt-3 pb-3 curpo qieh"
					:style="{'background':show==2?'#FFFFFF':'#FFFAF0','font-weight':show==2?'700':'500','color':show==2?'':'#999999'}">
					{{$t('活动后数据')}}</div>
			</div>
			<hdzsj ref="hdzsj" v-if="show==1"></hdzsj>
			<hdhsj ref="hdhsj" v-if="show==2"></hdhsj>
		</div>
	</div>
</template>

<script>
	import hdzsj from "./hdsj/hdzsj.vue";
	import hdhsj from "./hdsj/hdhsj.vue";
	export default {
		components: {
			hdzsj,
			hdhsj,
		},
		data() {
			return {
				show: 1,
				isLoading: false,
				id:"",
				name:""
			};
		},
		created() {
			this.id = this.$route.params.id;
				this.name = this.$route.params.name;
				setTimeout(() => {
					this.$refs.hdzsj.msg(this.id, this.name);
				}, 40);
		},
		mounted() {},
		computed: {},
		methods: {
			fun(e) {
				this.show = e;
				if (e == 1) {
					setTimeout(() => {
						this.$refs.hdzsj.msg(this.id, this.name);
					}, 40);
				} else {
					setTimeout(() => {
						this.$refs.hdhsj.msg(this.id, this.name);
					}, 40);
				}
			},
		},
	};
</script>

<style scoped>
@media screen and (max-width: 991px) {
  .sjbzt {
    font-size: calc(11px + 0.4vw) !important;
  }
  .sjbxzt {
    font-size: calc(9px + 0.4vw) !important;
  }
}
	.dcbd:hover {
		transform: scale(1.2);
	}

	.qieh {
		width: calc(100px + 0.4vw);
		text-align: center;
		border-radius: 12px 12px 0 0;
	}
</style>