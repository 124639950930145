<template>
	<div class="col-md-12 col-sm-12 col-lg-12 col-xl-12 pl-0 pr-0">
		<skeleton-screen v-if="!dataLoaded"></skeleton-screen>
		<img v-if="dataLoaded" @click="fanhui(0)" class="mt-1 mb-2 curpo dcbd" style="width: calc(25px + 0.4vw);"
			src="@/img/login/icon_fanhui@2x.png" alt="" />
		<div v-if="dataLoaded" class="col-md-12 col-sm-12 col-lg-12 col-xl-12 baise yuanjiao pt-3 pb-3"
			style="font-size: calc(7px + 0.4vw);line-height: calc(15px + 0.5vw);">
			<div v-html="incon"></div>
		</div>
	</div>
</template>

<script>
import { EventBus } from "@/views/event-bus.js";
	import {
		donation_Deta
	} from "@/request/aapi.js"
	import SkeletonScreen from "./SkeletonScreen/xinSkeletonScreen.vue";
	export default {
		components: {
			SkeletonScreen
		},
		data() {
			return {
				dataLoaded: false,
				incon: null,
			};
		},
		created() {
			this.istype=this.$route.params.isT
		},
		mounted() {
			this.getInfo()
		},
		computed: {

		},
		methods: {
			getInfo() {

				let data = {
					id: this.$route.params.ckxq
				}
				donation_Deta(data).then(res => {
					// console.log(res, "详情")
					if (res.code == 200) {
						this.incon = res.data.info.introduction
						this.dataLoaded = true;
					}
				})
			},

			fanhui(e) {
				this.$router.back()
				EventBus.$emit("tthdfh", "1");
			}
		},
	};
</script>

<style scoped>
	.dcbd:hover {
		transform: scale(1.2);
	}
</style>