<template>
	<div id="app">
		<router-view />
	</div>
</template>
<script>
export default {
	components: {},
	data() {
		return {

		};
	},
	mounted() {

	},
	methods: {

	},
};
</script>
<style lang="scss" scoped>
@import url("app.css");

@import url("imapp.css");
</style>