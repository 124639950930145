import request from '../index.js'

// 我的个人中心
export const my_center = (params) => request.get("/user/my_center", {params});
// 头像修改
export const update_avatar = (params) => request.post("/user/update_avatar", params);
// 修改背景封面图
export const update_cover = (params) => request.post("/user/update_cover", params);
// 上传视频
export const video = (params) => request.post("/upload/video", params);
// 涂鸦墙发布
export const momentssend = (params) => request.post("/moment/send", params);
// 发布所需基本数据
export const momentbase = (params) => request.get("/moment/base", {params});
// 涂鸦墙赞
export const momentlike = (params) => request.post("/moment/like", params);
// 涂鸦墙留言列表
export const message_list = (params) => request.get("/moment/message_list", {params});
// 涂鸦墙发布留言
export const momentmessage_list = (params) => request.post("/moment/message", params);
// 涂鸦墙留言赞
export const moment_message_like = (params) => request.post("/moment/message_like", params);
// 涂鸦墙留言删除
export const moment_message = (params) => request.post("/moment/message_del", params);
// 涂鸦墙删除
export const moment_del = (params) => request.post("/moment/del", params);
// 志工信息
export const volunteer_info = (params) => request.get("/user/volunteer_info", {params});
// 志工信息保存
export const user_volunteer_save = (params) => request.post("/user/volunteer_save", params);
// 别人的个人中心
export const center = (params) => request.get("/user/center", {params});
