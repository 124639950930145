<!-- SkeletonScreen.vue -->  
<template>
  <div>
    <!-- background-color: #F6F4F0; -->
    <div class="row pl-0 pr-0" style="width: 100%;margin: 0;">
      <div class=" pl-0 pr-0 sjiduankd sjitop" style="width: 69%;">
        <!-- 模拟侧边栏 -->
        <div class="skeleton-wrapper mb-3">
          <div class="skeleton-item" style="height: 5vh; width: 100%; background-color: white; border-radius: 4px;">
          </div>
        </div>
        <div class="skeleton-wrapper vvvv">
          <div class="skeleton-item p-2 d-flex " style="height: 35vh; width: 100%; background-color: white; border-radius: 4px;">
            <div class="skeleton-item mt-4 ml-3" style="height: 30vh; width: 48%; background: #F6F4F0; border-radius: 12px;"></div>
            <div class="skeleton-item mt-4 ml-3" style="height: 30vh; width: 48%; background: #F6F4F0; border-radius: 12px;"></div>
          </div>
          <div class="skeleton-item p-2 d-flex " style="height: 50vh; width: 100%; background-color: white; border-radius: 4px;">
            <div class="skeleton-item mt-4 ml-3" style="height: 30vh; width: 48%; background: #F6F4F0; border-radius: 12px;"></div>
            <div class="skeleton-item mt-4 ml-3" style="height: 30vh; width: 48%; background: #F6F4F0; border-radius: 12px;"></div>
          </div>
        </div>
        <div class=" aaaa">
          <div class="skeleton-item p-2" style="height: 95vh; width: 100%; background-color: white; border-radius: 4px;">
            <div class="skeleton-item mt-2" style="height: 30vh; width: 96%; background: #F6F4F0; border-radius: 12px;margin: auto;"></div>
            <div class="skeleton-item mt-3" style="height: 30vh; width: 96%; background: #F6F4F0; border-radius: 12px;margin: auto;"></div>
            <div class="skeleton-item mt-3" style="height: 30vh; width: 96%; background: #F6F4F0; border-radius: 12px;margin: auto;"></div>
          </div>
        </div>
      </div>
      <div class="pl-0 pr-0 sjyc" style="background-color: #F6F4F0;width: 31%;">
        <div class="col-md-12">
          <div class="skeleton-wrapper">
            <div class="skeleton-item mb-3" style="height: 40vh; width: 100%; background-color: white; border-radius: 4px;">
              <div class="skeleton-item mt-4 ml-3" style="height: 5vh; width: 90%; background: #F6F4F0; border-radius: 12px;"></div>
              <div class="skeleton-item mt-4 ml-3" style="height: 5vh; width: 90%; background: #F6F4F0; border-radius: 12px;"></div>
              <div class="skeleton-item mt-4 ml-3" style="height: 5vh; width: 90%; background: #F6F4F0; border-radius: 12px;"></div>
              <div class="skeleton-item mt-4 ml-3" style="height: 5vh; width: 90%; background: #F6F4F0; border-radius: 12px;"></div>
              <div class="skeleton-item mt-4 ml-3" style="height: 5vh; width: 90%; background: #F6F4F0; border-radius: 12px;"></div>
            </div>
            <div class="skeletonW-item" style="height: 40vh; width: 100%; background-color: white; border-radius: 4px;">
              <div class="skeleton-item mt-4 ml-3" style="height: 5vh; width: 90%; background: #F6F4F0; border-radius: 12px;"></div>
              <div class="skeleton-item mt-4 ml-3" style="height: 5vh; width: 90%; background: #F6F4F0; border-radius: 12px;"></div>
              <div class="skeleton-item mt-4 ml-3" style="height: 5vh; width: 90%; background: #F6F4F0; border-radius: 12px;"></div>
              <div class="skeleton-item mt-4 ml-3" style="height: 5vh; width: 90%; background: #F6F4F0; border-radius: 12px;"></div>
              <div class="skeleton-item mt-4 ml-3" style="height: 5vh; width: 90%; background: #F6F4F0; border-radius: 12px;"></div>
            </div>
          </div>
        </div>

      </div>

    </div>
  </div>
</template>  
    
  <script>
export default {
  name: "SkeletonScreen",
};
</script>  
    
  <style scoped>
@media screen and (max-width: 1208px) {
  .sjd {
    display: block !important;
    margin-top: 1rem !important;
  }
  .sjyc{
    display: none !important;
  }
  .sjiduankd{
    width: 100% !important;
  }
}
@media screen and (max-width: 991px) {
  .sjitop{
    margin-top: 1rem !important;
  }
}
@media screen and (max-width: 800px) {
  .aaaa{
    display:  block !important;
  }
  .vvvv{
    display: none !important;
  }
}
.aaaa{
  display: none;
}
.sjd {
  display: none;
}
.skeleton-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.skeleton-item {
  width: 100%; /* 根据需要调整 */
  height: 100%; /* 根据需要调整 */
  background-color: #e9ecef;
  border-radius: 4px; /* 圆角 */
  animation: pulse 1.5s ease-in-out infinite;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}
</style>