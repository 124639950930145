<template>
	<div class="col-md-12 col-sm-12 col-lg-12 col-xl-12 pb-3 mr-0 ml-0 pl-0 pr-0">
		<skeleton-screen v-if="!dataLoaded"></skeleton-screen>
		<div v-if="dataLoaded" class="col-md-12 col-sm-12 col-lg-12 col-xl-12 baise yuanjiao pt-3 pb-3 gdt"
			style="line-height: calc(15px + 0.5vw);height: 87vh;overflow-y: auto;">
			<div style="text-align: center;width: 100%;font-size: calc(9px + 0.4vw);font-weight: 600;" class="mb-3">
				{{ title }}</div>
			<div v-if="content" class="col-md-12 pl-0 pr-0" v-html="content"></div>
			<div v-else>{{ $t("暂无数据") }}</div>
		</div>
	</div>
</template>

<script>
import SkeletonScreen from "@/components/haosttd/wmuc/SkeletonScreen/xinSkeletonScreen.vue";
import {
	about_us,
	privacy_policy,
	terms_use,
	instructions_use
} from "@/request/api.js";
export default {
	components: {
		SkeletonScreen,
	},
	props: {},
	data() {
		return {
			dataLoaded: false,
			content: "",
			title: "",
		};
	},
	created() { },
	watch: {
		// 监听路由参数的变化
		"$route.params": {
			immediate: true,
			handler(newParams, oldParams) {
				// 当路由参数改变时，这里会被调用
				//    leix判断是关于我们进来的还是使用条款等
				// 关于我们
				if (this.$route.query.leix == 0) {
					this.content = "";
					this.title = this.$t("关于我们");
					this.about_us();
				} else if (this.$route.query.leix == 1) {
					this.content = "";
					this.title = this.$t("使用说明");
					this.instructions_use()
					// 调用使用说明接口
				} else if (this.$route.query.leix == 2) {
					this.content = "";
					this.title = this.$t("使用条款");
					this.terms_use()
					// 调用使用条款接口
				} else if (this.$route.query.leix == 3) {
					this.content = "";
					this.title = this.$t('隐私协议');
					this.privacy_policy()
					// 调用使用条款接口
				}
				// 你可以在这里根据新的参数执行相应的操作
			},
		}
	},
	mounted() {
	},
	computed: {},
	methods: {
		async terms_use() {
			const {
				data,
				code
			} = await terms_use({});
			if(code==200){
				this.dataLoaded = true;
			}
			if(data.data){
				this.content = data.data.content;
				this.title = data.data.title;
			}
		},
		async instructions_use() {
			const {
				data,
				code
			} = await instructions_use({});
			if(code==200){
				this.dataLoaded = true;
			}
			if(data.data){
				this.content = data.data.content;
				this.title = data.data.title;
			}
		},
		async privacy_policy() {
			const {
				data,
				code
			} = await privacy_policy({});
			if(code==200){
				this.dataLoaded = true;
			}
			if(data.data){
				this.content = data.data.content;
				this.title = data.data.title;
			}
		},
		async about_us() {
			const {
				data,
				code
			} = await about_us({});
			if(code==200){
				this.dataLoaded = true;
			}
			this.content = data.data.content;
			this.title = data.data.title;
		},
	},
};
</script>

<style scoped>
.dcbd:hover {
	transform: scale(1.2);
}

/* 针对Webkit内核浏览器的隐藏滚动条样式 */
.gdt::-webkit-scrollbar {
	display: none;
}
</style>