<template>
	<div class="col-md-12 pl-0 pr-0">
		<skeleton-screen class="mt-3" v-if="!dataLoaded"></skeleton-screen>
		<div v-if="dataLoaded" class="col-md-12 yuanjiao pt-3 pb-3 pl-0 pr-0 d-flex justify-content-between">
			<div class="col-md-12 pl-0 pr-0">
				<div class="col-md-12 baise yuanjiao pt-3 pb-2">
					<div class="col-sm-12 pl-0 pr-0"
					style="font-size: calc(7px + 0.4vw);line-height: calc(16px + 0.4vw);" v-html="data.introduction"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		activity_schedule
	} from "@/request/hshd/index.js";
	import SkeletonScreen from "./SkeletonScreen/SkeletonScreen.vue";
	export default {
		components: {
			SkeletonScreen,
		},
		data() {
			return {
				schedule: [],
				name: "",
				dataLoaded: false,
				hdlb: [{}, {}, {}],
				tdcy: [{}, {}, {}, {}, {}],
				data: {},
			};
		},
		created() {
			this.name = this.$route.params.name;
			this.activity_schedule(this.$route.params.id);
		},
		mounted() {
			
		},
		computed: {},
		methods: {
			async activity_schedule(e) {
				const {
					data,
					code
				} = await activity_schedule({
					id: e
				});
				if(code==200){
					this.dataLoaded = true;
				}
				this.data = data.data;
				this.schedule = data.schedule;
				for (let i = 0; i < this.schedule.length; i++) {
					const date = new Date(this.schedule[i].date * 1000); // 将时间戳转换为毫秒
					const year = date.getFullYear();
					const month = date.getMonth() + 1; // JavaScript中月份是从0开始的
					const day = date.getDate();
					this.schedule[i].date = year;
					this.$set(this.schedule[i], "dates", month + "-" + day);
					for (let j = 0; j < this.schedule[i].list.length; j++) {
						const dates = new Date(this.schedule[i].list[j].date * 1000); // 将时间戳转换为毫秒
						const years = dates.getFullYear();
						const months = dates.getMonth() + 1; // JavaScript中月份是从0开始的
						const days = dates.getDate();
						this.schedule[i].list[j].date = days + '-' + months + '-' + years
					}
				}
			},
		},
	};
</script>
<style scoped>
	/* @media screen and (max-width: 1200px) {
		.yinc {
			display: none !important;
		}
	} */
	.dcbd:hover {
		transform: scale(1.2);
	}
	.inppt {
		background: white;
		border: none;
		border-radius: 13px;
		font-size: calc(7px + 0.4vw);
	}

	.xunafuxgio:hover {
		transform: scale(1.001);
		/* 悬浮时放大1.1倍 */
		border-radius: 12px;
		box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
		/* 悬浮时添加阴影效果 */
	}

	.qieh {
		width: calc(100px + 0.4vw);
		text-align: center;
		border-radius: 12px 12px 0 0;
	}

	.sxbj {
		padding-top: calc(15px + 0.5vw);
		padding-bottom: calc(15px + 0.5vw);
	}
</style>