<!-- SkeletonScreen.vue -->  
<template>
	<div>
	  <!-- background-color: #F6F4F0; -->
	  <div class="row pl-0 pr-0 yinc" style="width: 100%;margin: 0;">
		<div class="col-md-8 pl-0 pr-0 asdasd">
		  <!-- 模拟侧边栏 -->
		  <div class="skeleton-wrapper mb-3">
			<div class="skeleton-item" style="height: 38vh; width: 100%; background-color: white; border-radius: 4px;">
			  <div class="skeleton-item mt-3 ml-3" style="height: 34vh; width: 94%; background: #F6F4F0; border-radius: 12px;"></div>
			</div>
		  </div>
		  <div class="skeleton-wrapper mb-3">
			<div class="skeleton-item" style="height: 15vh; width: 100%; background-color: white; border-radius: 4px;">
			  <div class="skeleton-item mt-2 ml-3" style="height: 5vh; width: 90%; background: #F6F4F0; border-radius: 12px;"></div>
			  <div class="skeleton-item mt-4 ml-3" style="height: 5vh; width: 40%; background: #F6F4F0; border-radius: 12px;"></div>
			</div>
		  </div>
		  <div class="skeleton-wrapper">
			<div class="skeleton-item p-2" style="height: 75vh; width: 100%; background-color: white; border-radius: 4px;">
			  <div class="skeleton-item mt-4 ml-3" style="height: 70vh; width: 96%; background: #F6F4F0; border-radius: 12px;"></div>
			</div>
		  </div>
		</div>
		<div class="col-md-4 pl-0 pr-0 yinc" style="background-color: #F6F4F0;">
		  <div class="col-md-12">
			<!-- 模拟侧边栏 -->
			<div class="skeleton-wrapper">
			  <div class="skeleton-item mb-3" style="height: 40vh; width: 100%; background-color: white; border-radius: 4px;">
				<div class="skeleton-item mt-4 ml-3" style="height: 5vh; width: 90%; background: #F6F4F0; border-radius: 12px;"></div>
				<div class="skeleton-item mt-4 ml-3" style="height: 5vh; width: 90%; background: #F6F4F0; border-radius: 12px;"></div>
				<div class="skeleton-item mt-4 ml-3" style="height: 5vh; width: 90%; background: #F6F4F0; border-radius: 12px;"></div>
				<div class="skeleton-item mt-4 ml-3" style="height: 5vh; width: 90%; background: #F6F4F0; border-radius: 12px;"></div>
				<div class="skeleton-item mt-4 ml-3" style="height: 5vh; width: 90%; background: #F6F4F0; border-radius: 12px;"></div>
			  </div>
			  <div class="skeletonW-item" style="height: 40vh; width: 100%; background-color: white; border-radius: 4px;">
				<div class="skeleton-item mt-4 ml-3" style="height: 5vh; width: 90%; background: #F6F4F0; border-radius: 12px;"></div>
				<div class="skeleton-item mt-4 ml-3" style="height: 5vh; width: 90%; background: #F6F4F0; border-radius: 12px;"></div>
				<div class="skeleton-item mt-4 ml-3" style="height: 5vh; width: 90%; background: #F6F4F0; border-radius: 12px;"></div>
				<div class="skeleton-item mt-4 ml-3" style="height: 5vh; width: 90%; background: #F6F4F0; border-radius: 12px;"></div>
				<div class="skeleton-item mt-4 ml-3" style="height: 5vh; width: 90%; background: #F6F4F0; border-radius: 12px;"></div>
			  </div>
			</div>
		  </div>
  
		</div>
  
	  </div>
	  <div class="row aaaaa">
		<div class="c pl-0 pr-0 asdasd" style="width: 96%;margin: auto;">
		  <!-- 模拟侧边栏 -->
		  <div class="skeleton-wrapper mb-3">
			<div class="skeleton-item" style="height: 30vh; width: 100%; background-color: white; border-radius: 4px;">
			  <div class="skeleton-item mt-3 ml-3" style="height: 26vh; width: 94%; background: #F6F4F0; border-radius: 12px;"></div>
			</div>
		  </div>
		  <div class="skeleton-wrapper mb-3">
			<div class="skeleton-item" style="height: 17vh; width: 100%; background-color: white; border-radius: 4px;">
			  <div class="skeleton-item mt-3 ml-3" style="height: 5vh; width: 90%; background: #F6F4F0; border-radius: 12px;"></div>
			  <div class="skeleton-item mt-4 ml-3" style="height: 5vh; width: 40%; background: #F6F4F0; border-radius: 12px;"></div>
			</div>
		  </div>
		  <div class="skeleton-wrapper">
			<div class="skeleton-item p-3" style="height: 78vh; width: 100%; background-color: white; border-radius: 4px;">
			  <div class="skeleton-item mt-2 " style="height: 72vh; width: 100%; background: #F6F4F0; border-radius: 12px;"></div>
			</div>
		  </div>
		</div>
	  </div>
	</div>
  </template>  
	  
	<script>
  export default {
	name: "SkeletonScreen",
  };
  </script>  
	  
	<style scoped>
  @media screen and (max-width: 980px) {
	.yinc {
	  display: none !important;
	}
	.aaaaa {
	  display: block !important;
	  margin-top: 1rem !important;
	}
  }
  .aaaaa {
	display: none;
  }
  .skeleton-wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
  }
  
  .skeleton-item {
	width: 100%; /* 根据需要调整 */
	height: 100%; /* 根据需要调整 */
	background-color: #e9ecef;
	border-radius: 4px; /* 圆角 */
	animation: pulse 1.5s ease-in-out infinite;
  }
  
  @keyframes pulse {
	0% {
	  opacity: 1;
	}
	50% {
	  opacity: 0.3;
	}
	100% {
	  opacity: 1;
	}
  }
  </style>