<template>
  <div class="col-md-12 col-sm-12 col-lg-12 col-xl-12 pl-0 pr-0" style="height: auto;">
    <skeleton-screen v-if="!dataLoaded"></skeleton-screen>
    <div v-if="dataLoaded">
      <!-- pc版 -->
      <div class="yinc">
        <div class="col-md-12 col-sm-12 col-lg-12 col-xl-12 baise yuanjiao pt-3 pb-3 mb-3 heggs position-relative sadca" :style="{backgroundImage:  `url(${info.bg_image})` }" style="background-size: cover;background-position: center;">
          <div class="curpo">
            <img class="mt-1 mb-2 curpo dcbd mr-3 position-relative" @click="fanhui(0)" style="width: calc(25px + 0.4vw);" src="@/img/login/icon_fanhui@2x.png" alt="" />
            <span class="curpo szdyyxg" @click="fanhui(0)" style="color: #FFFFFF;position: relative;">{{ $t("EventDetails") }}</span>
          </div>
          <div class="d-flex justify-content-between align-items-center position-relative">
            <div class="lieb gaodu yuanjiao asdax p-3">
              <div style="font-size: calc(7px + 0.4vw);line-height: calc(15px + 0.5vw);" v-html="incon">
              </div>
            </div>
            <div class="lieb gaodu yuanjiao" style="background: #ffffff;">
              <div class="huansge"></div>
              <div class="p-3 pt-4">
                <div class="tom">
                  <div class="pt-2 pb-2 pl-3 pr-3 yuanjiao" style="background: #FFD672;">1</div>
                  <div style="height: 1px;width: 40%;" :style="{background: hshow == 2 || hshow == 3 ? '#FFD672' : '#F6F4F0'}"></div>
                  <div class="pt-2 pb-2 pl-3 pr-3 yuanjiao" :style="{background: hshow == 2 || hshow == 3 ? '#FFD672' : '#F6F4F0'}">2</div>
                  <div style="height: 1px;width: 40%;" :style="{background: hshow == 3  ? '#FFD672' : '#F6F4F0'}"></div>
                  <div class="pt-2 pb-2 pl-3 pr-3 yuanjiao" :style="{background: hshow == 3  ? '#FFD672' : '#F6F4F0'}">3</div>
                </div>
                <div class="tom mt-2 mb-4" style="width: 84%;color: #3D3D3D;font-size: calc(7px + 0.4vw);">
                  <div>{{ $t("Donationamount") }}</div>
                  <div>{{ $t("DonorInformation") }}</div>
                  <div>{{ $t("Yourmessage") }}</div>
                </div>
                <div class="d-flex mb-3" v-if="hshow==1" style="font-size: calc(7px + 0.4vw);">
                  <div class="mr-3 pt-2 pb-2 pl-4 pr-4 curpo yuanjiao" @click="type=1,hshow=1" :style="{background : type == 1 ? '#FFD672' : '#F6F4F0' }">
                    {{ $t("Singlestroke") }}
                  </div>
                  <div class="mr-3 pt-2 pb-2 pl-4 pr-4 curpo yuanjiao" @click="type=2,hshow=1" :style="{background : type == 2 ? '#FFD672' : '#F6F4F0' }">
                    {{ $t("regular") }}
                  </div>
                  <div class="mr-3 pt-2 pb-2 pl-4 pr-4 curpo yuanjiao" v-if="is_bank==1" @click="type=3,hshow=1" :style="{background : type == 3 ? '#FFD672' : '#F6F4F0' }">
                    {{ $t("banktransfer") }}
                  </div>
                </div>
                <!-- 表單 -->
                <dqjk v-if="type==1" :remark="info.remark" @custom="custom"></dqjk>
                <div v-if="type==2">
                  <form v-if="hshow==1" class="sjbzt" style="font-size: calc(8px + 0.4vw);">
                    <div class="form-group">
                      <label style="font-weight: 500;"> <span style="color: red;">*</span>NT$</label>
                      <div>
                        <input style="background: #F6F4F0;border: none;font-size: calc(7px + 0.4vw);" v-model="form.amount" :disabled='zdyi' type="number" class="form-control srk pl-3 sjbxzt" :placeholder="$t('请输入')">
                        <div class="mt-2">
                          <label class="sjbxzt" style="font-weight: 700;">{{ $t('最低金额：') }}{{ low }}</label>
                        </div>
                        <div class="d-flex align-items-center mt-0 mb-3">
                          <div @click="ysxx(1, 'a1')" class="curpo toms yuanjiao mr-2 pt-1 pb-1" style="width: calc(50px + 0.5vw);text-align: center;" :style="{ 'background': ysxxid == 'a1' ? '#FEF7F6' : '#F6F4F0', 'border': ysxxid == 'a1' ? '1px solid #FAB1AB' : 'none' }">
                            <div class="toms-p sjbxzt" style="font-size: calc(6px + 0.4vw);">{{ $t('自定义') }}</div>
                          </div>
                          <div @click="ysxx(item.id, item.name)" class="curpo toms yuanjiao mr-2 pt-1 pb-1" v-for="(item, index) in list" :key="index" style="width: calc(50px + 0.5vw);text-align: center;" :style="{ 'background': item.id == ysxxid ? '#FEF7F6' : '#F6F4F0', 'border': item.id == ysxxid ? '1px solid #FAB1AB' : 'none' }">
                            <div class="toms-p sjbxzt" style="font-size: calc(6px + 0.4vw);">{{ item.name }}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <label style="font-weight: 500;"> <span style="color: red;">*</span>{{ $t('选择付款方式') }}</label>
                      <div>
                        <div class="d-flex align-items-center mt-2 mb-2">
                          <div @click="jkfs(item.id)" class="curpo toms yuanjiao mr-2 pt-1 pb-1" v-for="(item, index) in zflb" :key="index" style="width: calc(70px + 0.5vw);text-align: center;" :style="{ 'background': item.id == form.pay_type ? '#FEF7F6' : '#F6F4F0', 'border': item.id == form.pay_type ? '1px solid #FAB1AB' : 'none' }">
                            <div v-if="item.type==''" class="toms-p sjbxzt" style="font-size: calc(6px + 0.4vw);">{{ item.name }}</div>
                            <img v-if="item.type=='img'" :src="item.name" style="width: 34px;height: 13px;" alt="">
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style="width: 100%;height: 7rem;"></div>
                  </form>
                  <form v-if="hshow==2" style="font-size: calc(8px + 0.4vw);">
                    <div class="d-flex justify-content-between align-items-center mb-2">
                      <div style="width: 49%;">
                        <label class="d-flex justify-content-between align-items-center" style="font-weight: 500;width: 100%;">
                          <div><span style="color: red;">*</span>{{ $t('姓名') }}</div>
                          <div class="d-flex" style="font-size: calc(6px + 0.4vw);">
                            <div class="pt-1 pb-1 pl-2 pr-2 curpo" @click="form.is_sign=1" style="border-radius: 10px 0 0 10px;" :style="{background:form.is_sign==1?'#FFD672':'#F6F4F0'}">{{ $t('具名') }}</div>
                            <div class="pt-1 pb-1 pl-2 pr-2 curpo" @click="form.is_sign=0" style="border-radius: 0 10px 10px 0 ;" :style="{background:form.is_sign==0?'#FFD672':'#F6F4F0'}">{{ $t('不具名') }}</div>
                          </div>
                        </label>
                        <!-- is_sign -->
                        <div>
                          <input style="background: #F6F4F0;border: none;font-size: calc(7px + 0.4vw);" v-model="form.name" :disabled='isLoading' type="name" class="form-control sjbxzt srk pl-3" :placeholder="$t('输入全名')">
                        </div>
                        <small class="form-text" style="color: #FF797A;">{{ $t('*捐款名册将展示您的用户昵称') }}</small>
                      </div>
                      <div style="width: 49%;">
                        <label style="font-weight: 500;"> <span style="color: red;">*</span>{{ $t('手机号') }}</label>
                        <div>
                          <input v-model="form.phone" :disabled='isLoading' @input="validatePhone()" type="text" class="form-control srk sjbxzt" style="background: #F6F4F0;border: none;font-size: calc(7px + 0.4vw);" :placeholder="$t('login.phoneNumberPlaceholder')">
                          <small class="form-text sjbxzt" style="color: #FF797A;" v-if="phoneError">{{ phoneError }}</small>
                          <small class="form-text" v-else style="visibility: hidden;">123</small>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex justify-content-between align-items-center mb-2">
                      <div style="width: 49%;">
                        <label style="font-weight: 500;"> <span style="color: red;">*</span>{{ $t('邮箱') }}</label>
                        <div>
                          <input v-model="form.email" :disabled='isLoading' @input="emivalidatePhone()" type="text" class="form-control srk sjbxzt" style="background: #F6F4F0;border: none;font-size: calc(7px + 0.4vw);" :placeholder="$t('login.qsryx')">
                          <small class="form-text sjbxzt" style="color: #FF797A;" v-if="emilError">{{ emilError }}</small>
                          <small class="form-text" v-else style="visibility: hidden;">123</small>
                        </div>
                      </div>
                      <div v-if="form.receipt_type==1" style="width: 49%;">
                        <div class="pl-0 pr-0 position-relative col-md-12">
                          <label style="font-weight: 500;"> <span style="color: red;">*</span>{{ $t('身份证号') }}</label>
                          <div>
                            <input style="background: #F6F4F0;border: none;font-size: calc(7px + 0.4vw);" v-model="form.id_number" :placeholder="$t('请输入身份ID')" :disabled='isLoading' type="name" class="form-control srk pl-3 sjbxzt">
                          </div>
                          <small class="form-text" style="visibility: hidden;">123</small>
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <label style="font-weight: 500;"> <span style="color: red;">*</span>{{ $t('收据方式') }}</label>
                      <div>
                        <div class="d-flex align-items-center mt-1 mb-2">
                          <div @click="form.receipt_type=1,form.company_number='',form.company_name=''" class="curpo toms yuanjiao mr-2 pt-1 pb-1" style="width: calc(80px + 0.5vw);text-align: center;font-size: calc(6px + 0.4vw);" :style="{ 'background': 1 == form.receipt_type ? '#FEF7F6' : '#F6F4F0', 'border': 1 == form.receipt_type ? '1px solid #FAB1AB' : 'none' }">
                            <div class="toms-p sjbxzt">{{ $t('个人收据') }}</div>
                          </div>
                          <div @click="form.receipt_type=2,form.id_number=''" class="curpo toms yuanjiao mr-2 pt-1 pb-1" style="width: calc(80px + 0.5vw);text-align: center;font-size: calc(6px + 0.4vw);" :style="{ 'background': 2 == form.receipt_type ? '#FEF7F6' : '#F6F4F0', 'border': 2 == form.receipt_type ? '1px solid #FAB1AB' : 'none' }">
                            <div class="toms-p sjbxzt">{{ $t('公司发票') }}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-12 d-flex pl-0 pr-0 justify-content-between align-item-center">
                      <div class=" form-group col-sm-4 pl-0 pr-0">
                        <label style="font-weight: 500;"> <span style="color: red;">*</span>{{ $t('城市') }}</label>
                        <button class="d-flex ckqbb zhankai  dydpd pl-4 pr-3 pt-2 pb-2 pl-0 pr-0" type="button" id="dropdownMenuButton" style="width: 90%;" @click="sjfstoggleDropdown(1)">
                          <div class="d-flex align-items-center justify-content-between " style="flex: 1;">
                            <div class="mr-1 sjbxzt" style="font-size: calc(6px + 0.4vw);flex: 1;">{{ form.city_id ?
									form_city_id :
									$t('请选择') }}
                            </div>
                            <div><img style="width: calc(7px + 0.4vw);" src="@/img/daohangl/icon_xlcd_xljt@2x (1).png" alt="" /></div>
                          </div>
                        </button>
                        <div class=" yuanjiao xlcd position-absolute  text-center" aria-labelledby="dropdownMenuButton" v-show="sjfsisDropdownOpen" style="border-radius:7px;background: #FFFAF0;width: 89%;">
                          <a class="dropdown-item xuazz yuanjiao mb-2" v-for="(item, index) in guojialist" :key="index" :style="{ 'background': form.city_id == item.id ? '#FFD672' : '' }" @click="sjfsxuanz(item, 1)" href="#"> <span class="sjbxzt">{{ item.name }}</span> </a>
                        </div>
                      </div>
                      <div class="form-group col-sm-4 pl-0 pr-0">
                        <label style="font-weight: 500;"> <span style="color: red;">*</span>{{ $t('地区') }}</label>
                        <button class="d-flex ckqbb zhankai  dydpd pl-4 pr-3 pt-2 pb-2 pl-0 pr-0" type="button" id="dropdownMenuButton" style="width: 90%;" @click="sjfstoggleDropdown(2)">
                          <div class="d-flex align-items-center justify-content-between " style="flex: 1;">
                            <div class="mr-1 sjbxzt" style="font-size: calc(6px + 0.4vw);flex: 1;">{{ form.region_id ?
									form_region_id :
									$t('请选择') }}
                            </div>
                            <div><img style="width: calc(7px + 0.4vw);" src="@/img/daohangl/icon_xlcd_xljt@2x (1).png" alt="" /></div>
                          </div>
                        </button>
                        <div class="dropdown-menu yuanjiao xlcd position-absolute gdtyc text-center" aria-labelledby="dropdownMenuButton" v-show="sjfsisDropdownOpen2" style="border-radius:7px;background: #FFFAF0;width: 85%;margin-left: 3%;max-height: 200px;overflow-y: auto;">
                          <a class="dropdown-item xuazz yuanjiao mb-2" v-for="(item, index) in guojialist2" :key="index" :style="{ 'background': form.region_id == item.id ? '#FFD672' : '' }" @click="sjfsxuanz(item, 2)" href="#"> <span class="sjbxzt">{{ item.name }}</span> </a>

                        </div>
                      </div>
                      <div class="form-group  col-sm-4 pl-0 pr-0">
                        <label style="font-weight: 500;"><span style="color: red;">*</span>{{ $t('区域') }}</label>

                        <button class="d-flex ckqbb zhankai  dydpd pl-4 pr-3 pt-2 pb-2 pl-0 pr-0" type="button" id="dropdownMenuButton" style="width: 90%;" @click="sjfstoggleDropdown(3)">
                          <div class="d-flex align-items-center justify-content-between " style="flex: 1;">
                            <div class="mr-1 sjbxzt" style="font-size: calc(6px + 0.4vw);flex: 1;">{{ form.district_id ?
									form_district_id :
									$t('请选择') }}
                            </div>
                            <div><img style="width: calc(7px + 0.4vw);" src="@/img/daohangl/icon_xlcd_xljt@2x (1).png" alt="" /></div>
                          </div>
                        </button>
                        <div class="dropdown-menu yuanjiao xlcd position-absolute gdtyc text-center" aria-labelledby="dropdownMenuButton" v-show="sjfsisDropdownOpen3" style="border-radius:7px;background: #FFFAF0;width: 84%;margin-left: 3.5%;max-height: 200px;overflow-y: auto;">
                          <a class="dropdown-item xuazz yuanjiao mb-2" v-for="(item, index) in guojialist3" :key="index" :style="{ 'background': form.district_id == item.id ? '#FFD672' : '' }" @click="sjfsxuanz(item, 3)" href="#"> <span class="sjbxzt">{{ item.name }}</span> </a>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex justify-content-between align-items-center mb-3">
                      <div style="width: 49%;">
                        <label style="font-weight: 500;"> <span style="color: red;">*</span>{{ $t('邮政区号') }}</label>
                        <div>
                          <input v-model="form.postal_code" :disabled='isLoading' @input="emivalidatePhone()" type="text" class="form-control srk sjbxzt" style="background: #F6F4F0;border: none;font-size: calc(7px + 0.4vw);" :placeholder="$t('login.qsryxemail')">
                        </div>
                      </div>
                      <div style="width: 49%;">
                        <label style="font-weight: 500;"> <span style="color: red;">*</span>{{ $t('地址') }}</label>
                        <div>
                          <input style="background: #F6F4F0;border: none;font-size: calc(7px + 0.4vw);" v-model="form.address" :disabled='isLoading' type="name" class="form-control srk pl-3 sjbxzt" :placeholder="$t('请输入地址')">
                        </div>
                      </div>
                    </div>
                    <div class="d-flex justify-content-between align-items-center">
                      <div v-if="form.receipt_type==2" style="width: 49%;">
                        <div class="pl-0 pr-0 position-relative col-md-12">
                          <label style="font-weight: 500;"> <span style="color: red;">*</span>{{ $t('公司统编') }}</label>
                          <div>
                            <input :placeholder="$t('请输入公司统编')" style="background: #F6F4F0;border: none;font-size: calc(7px + 0.4vw);" v-model="form.company_number" :disabled='isLoading' type="name" class="form-control srk pl-3 sjbxzt">
                          </div>
                        </div>
                      </div>

                      <div v-if="form.receipt_type==2" style="width: 49%;">
                        <div class="pl-0 pr-0 position-relative col-md-12">
                          <label style="font-weight: 500;"> <span style="color: red;">*</span>{{ $t('公司抬头') }}</label>
                          <div>
                            <input :placeholder="$t('请输入公司抬头')" style="background: #F6F4F0;border: none;font-size: calc(7px + 0.4vw);" v-model="form.company_name" :disabled='isLoading' type="name" class="form-control srk pl-3 sjbxzt">
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- <div class="form-group">
                    <label style="font-weight: 500;"> <span style="color: red;">*</span>{{ $t('提醒') }}</label>
                    <div>
                      <b-form-textarea class="sjbxzt" style="border: none;background: #F6F4F0;font-size: calc(7px + 0.4vw);" :disabled='isLoading' id="textarea" v-model="form.remind" :placeholder="$t('写下你的心得')" rows="3" max-rows="6" no-resize></b-form-textarea>
                    </div>
                  </div> -->
                  </form>
                  <form v-if="hshow==3" style="font-size: calc(8px + 0.4vw);">
                    <div class="form-group">
                      <label style="font-weight: 500;">{{ $t('我想留言鼓励团体') }}</label>
                      <div>
                        <b-form-textarea class="sjbxzt" style="border: none;background: #F6F4F0;font-size: calc(7px + 0.4vw);height: 17rem;" :disabled='isLoading' id="textarea" v-model="form.remind" :placeholder="$t('写下你的心得')" rows="3" max-rows="6" no-resize></b-form-textarea>
                      </div>
                    </div>
                    <div class="pt-3 pb-3 text-center yuanjiao mt-5 mb-5" style="width: 100%;background: #FFEAB8;font-size: calc(7px + 0.4vw);">
                      <div class="mb-1">{{ $t('您的捐款金额为') }} {{ form.amount }}{{ $t('元') }}</div>
                      <div>{{ $t('感谢您的支持') }}</div>
                    </div>
                  </form>
                </div>
                <!-- 銀行轉正 -->
                <yhzz v-if="type==3&&is_bank==1"></yhzz>
                <!-- 銀行轉正 -->
                <!-- 表單結尾 -->
                <div v-if="type==2" class="">
                  <div v-if="hshow==1" class="mt-3" style="color: #999999;font-size: calc(7px + 0.4vw);">{{ $t("bzyhdbygg") }}{{ info.remark }}</div>
                  <div v-if="hshow==1" class="text-center mt-2 mb-2" style="width: 100%;">
                    <img class="mr-1" style="width: 15px;height: 15px;" src="@/img/icon_suo@1x.png" alt="">
                    <span style="color: #9E9E9E;font-size: calc(6px + 0.4vw);">{{ $t("aqfkndxykzl") }}</span>
                  </div>
                  <div v-if="hshow==2" class="mt-3 text-center" style="color: #FF797A;font-size: calc(6px + 0.4vw);">{{ $t("*请仔细核实您的身份信息，这将影响收据资讯的准确性") }}</div>
                  <div v-if="hshow==1" @click="dbjkdyb()" class="jkss pt-2 pb-2 yuanjiao" style="width: 84%;margin: auto;">{{ $t("nextstep") }}</div>
                  <div v-if="hshow==2" class="mt-2 d-flex justify-content-between mb-3">
                    <div @click="hshow=1" class="jkss pt-2 pb-2 yuanjiao" style="width: 49%;background: #FFEAB8;">{{ $t("Previousstep") }}</div>
                    <div @click="derdbjkdyb()" class="jkss pt-2 pb-2 yuanjiao" style="width: 49%;">{{ $t("nextstep") }}</div>
                  </div>
                  <div v-if="hshow==3" class="mt-4 d-flex justify-content-between">
                    <div @click="hshow=2" class="jkss pt-2 pb-2 yuanjiao" style="width: 49%;background: #FFEAB8;">{{ $t("Previousstep") }}</div>
                    <div @click="bulletframe()" class="jkss pt-2 pb-2 yuanjiao" style="width: 49%;">{{ $t("前往付款") }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex mb-3 mt-3">
          <div @click="zjsyqk()" class="jkss yuanjiao ckqbb pt-2 pb-2 pl-3 pr-3 mr-3 ml-3" style="background: #FAB1AB;">{{ $t("资金用途") }}</div>
          <div @click="jkmcqk()" class="jkss yuanjiao ckqbb pt-2 pb-2 pl-3 pr-3 mr-3" style="background: #FDBC94;">{{ $t("捐款芳名录") }}</div>
          <div @click="wypjia()" class="jkss yuanjiao ckqbb pt-2 pb-2 pl-3 pr-3">{{ $t("我要评价") }}</div>
        </div>
      </div>
      <!-- 手机版 -->
      <div class="aaaaa pl-0 pr-0 " style="width: 100%;">
        <div class="lieb pl-2 pr-2 baise pt-3 pb-3 mb-3 heggs position-relative sadca" :style="{backgroundImage:  `url(${info.bg_image})` }" style="background-size: cover;background-position: center;height: 76.5vh;overflow-y: auto;border-radius: 12px 12px 0 0;width: 100%;">
          <div class="curpo sjdwd mb-1" style="width: 65%;margin: auto;">
            <img class="mt-1  curpo dcbd mb-1 mr-2 position-relative" @click="fanhui(0)" style="width: calc(25px + 0.4vw);" src="@/img/login/icon_fanhui@2x.png" alt="" />
            <span class="curpo sjbzt szdyyxg" @click="fanhui(0)" style="color: #FFFFFF;position: relative;">{{ $t("EventDetails") }}</span>
          </div>
          <div class="position-relative sjdwd" style="width: 65%;margin: auto;">
            <div class=" yuanjiao asdax p-3 sjbplpr mb-3">
              <div :style="{height:zank==1?'18vh':'auto'}" style="overflow: hidden;">
                <div style="font-size: calc(7px + 0.4vw);line-height: calc(15px + 0.5vw);" v-html="incon">
                </div>
              </div>
              <div style="width: 100%;">
                <div v-if="zank==1" @click="zank=0" class="pt-2 mb-2 pb-2 pl-3 pr-3 mt-2 d-flex sjbzt justify-content-center yuanjiao align-items-center" style="margin: auto;width: 40%;background: #F6F4F0;color: #000;">
                  <span class="mr-2">{{ $t("展开更多") }}</span>
                  <img src="@/img/icon_jia@1x(1).png" alt="">
                </div>
                <div v-else @click="zank=1" class="pt-2 pb-2 pl-3 mb-2 pr-3 d-flex mt-2 sjbzt justify-content-center yuanjiao align-items-center" style="margin: auto;width: 40%;background: #F6F4F0;color: #000;">
                  <span class="mr-2">{{ $t("关闭") }}</span>
                  <img src="@/img/icon_jian@1x(1).png" alt="">
                </div>
              </div>
            </div>
            <div class="d-flex mb-3 mt-3 justify-content-center">
              <div @click="zjsyqk()" class="jkss yuanjiao pt-2 pb-2 pl-3 pr-3 mr-3 sjbzt ml-3" style="background: #FAB1AB;">{{ $t("资金用途") }}</div>
              <div @click="jkmcqk()" class="jkss yuanjiao pt-2 pb-2 pl-3 pr-3 sjbzt mr-3" style="background: #FDBC94;">{{ $t("捐款芳名录") }}</div>
              <div @click="wypjia()" class="jkss yuanjiao pt-2 pb-2 pl-3 sjbzt pr-3">{{ $t("我要评价") }}</div>
            </div>
            <div class="lieb yuanjiao" style="background: #ffffff;">
              <div class="huansge"></div>
              <div class="p-3 pt-4 sjbplpr">
                <div class="tom">
                  <div class="pt-2 pb-2 pl-3 pr-3 yuanjiao" style="background: #FFD672;">1</div>
                  <div style="height: 1px;width: 40%;" :style="{background: hshow == 2 || hshow == 3 ? '#FFD672' : '#F6F4F0'}"></div>
                  <div class="pt-2 pb-2 pl-3 pr-3 yuanjiao" :style="{background: hshow == 2 || hshow == 3 ? '#FFD672' : '#F6F4F0'}">2</div>
                  <div style="height: 1px;width: 40%;" :style="{background: hshow == 3  ? '#FFD672' : '#F6F4F0'}"></div>
                  <div class="pt-2 pb-2 pl-3 pr-3 yuanjiao" :style="{background: hshow == 3  ? '#FFD672' : '#F6F4F0'}">3</div>
                </div>
                <div class="tom mt-2 mb-4 sjbxzt" style="width: 84%;color: #3D3D3D;font-size: calc(7px + 0.4vw);">
                  <div>{{ $t("Donationamount") }}</div>
                  <div>{{ $t("DonorInformation") }}</div>
                  <div>{{ $t("Yourmessage") }}</div>
                </div>
                <div class="d-flex mb-3" v-if="hshow==1" style="font-size: calc(7px + 0.4vw);">
                  <div class="mr-2 pt-2 pb-2 pl-4 pr-4 curpo yuanjiao sjbxzt" @click="type=1,hshow=1" :style="{background : type == 1 ? '#FFD672' : '#F6F4F0' }">
                    {{ $t("Singlestroke") }}
                  </div>
                  <div class="mr-2 pt-2 pb-2 pl-4 pr-4 curpo yuanjiao sjbxzt" @click="type=2,hshow=1" :style="{background : type == 2 ? '#FFD672' : '#F6F4F0' }">
                    {{ $t("regular") }}
                  </div>
                  <div class="mr-2 pt-2 pb-2 pl-4 pr-4 curpo yuanjiao sjbxzt" v-if="is_bank==1" @click="type=3,hshow=1" :style="{background : type == 3 ? '#FFD672' : '#F6F4F0' }">
                    {{ $t("banktransfer") }}
                  </div>
                </div>
                <!-- 表單 -->
                <dqjk v-if="type==1" :remark="info.remark"  @custom="custom"></dqjk>
                <div v-if="type==2" @cusasdm="cusasdm">
                  <form v-if="hshow==1" class="sjbxzt" style="font-size: calc(8px + 0.4vw);">
                    <div class="form-group">
                      <label style="font-weight: 500;"> <span style="color: red;">*</span>NT$</label>
                      <div>
                        <input style="background: #F6F4F0;border: none;font-size: calc(7px + 0.4vw);" v-model="form.amount" :disabled='zdyi' type="number" class="form-control srk pl-3 sjbxzt" :placeholder="$t('请输入')">
                        <div class="mt-2">
                          <label class="sjbxzt" style="font-weight: 700;">{{ $t('最低金额：') }}{{ low }}</label>
                        </div>
                        <div class="d-flex align-items-center mt-0 mb-3">
                          <div @click="ysxx(1, 'a1')" class="curpo toms yuanjiao mr-2 pt-1 pb-1" style="width: calc(50px + 0.5vw);text-align: center;" :style="{ 'background': ysxxid == 'a1' ? '#FEF7F6' : '#F6F4F0', 'border': ysxxid == 'a1' ? '1px solid #FAB1AB' : 'none' }">
                            <div class="toms-p sjbxzt" style="font-size: calc(6px + 0.4vw);">{{ $t('自定义') }}</div>
                          </div>
                          <div @click="ysxx(item.id, item.name)" class="curpo toms yuanjiao mr-2 pt-1 pb-1" v-for="(item, index) in list" :key="index" style="width: calc(50px + 0.5vw);text-align: center;" :style="{ 'background': item.id == ysxxid ? '#FEF7F6' : '#F6F4F0', 'border': item.id == ysxxid ? '1px solid #FAB1AB' : 'none' }">
                            <div class="toms-p sjbxzt" style="font-size: calc(6px + 0.4vw);">{{ item.name }}</div>
                          </div>

                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <label style="font-weight: 500;"> <span style="color: red;">*</span>{{ $t('选择付款方式') }}</label>
                      <div>
                        <div class="d-flex align-items-center mt-2 mb-2">
                          <div @click="jkfs(item.id)" class="curpo toms yuanjiao mr-2 pt-1 pb-1" v-for="(item, index) in zflb" :key="index" style="width: calc(70px + 0.5vw);text-align: center;" :style="{ 'background': item.id == form.pay_type ? '#FEF7F6' : '#F6F4F0', 'border': item.id == form.pay_type ? '1px solid #FAB1AB' : 'none' }">
                            <div v-if="item.type==''" class="toms-p sjbxzt" style="font-size: calc(6px + 0.4vw);">{{ item.name }}</div>
                            <img v-if="item.type=='img'" :src="item.name" style="width: 34px;height: 13px;" alt="">
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style="width: 100%;height: 7rem;"></div>
                  </form>
                  <form v-if="hshow==2" class="sjbxzt" style="font-size: calc(8px + 0.4vw);">
                    <div class="d-flex justify-content-between align-items-center mb-2">
                      <div style="width: 49%;">
                        <label class="d-flex justify-content-between align-items-center" style="font-weight: 500;width: 100%;">
                          <div><span style="color: red;">*</span>{{ $t('姓名') }}</div>
                          <div class="d-flex" style="font-size: calc(6px + 0.4vw);">
                            <div class="pt-1 pb-1 pl-2 pr-2 curpo sjbxxzt" @click="form.is_sign=1" style="border-radius: 10px 0 0 10px;" :style="{background:form.is_sign==1?'#FFD672':'#F6F4F0'}">{{ $t('具名') }}</div>
                            <div class="pt-1 pb-1 pl-2 pr-2 curpo sjbxxzt" @click="form.is_sign=0" style="border-radius: 0 10px 10px 0 ;" :style="{background:form.is_sign==0?'#FFD672':'#F6F4F0'}">{{ $t('不具名') }}</div>
                          </div>
                        </label>
                        <!-- is_sign -->
                        <div>
                          <input style="background: #F6F4F0;border: none;font-size: calc(7px + 0.4vw);" v-model="form.name" :disabled='isLoading' type="name" class="form-control sjbxzt srk pl-3" :placeholder="$t('输入全名')">
                        </div>
                        <small class="form-text" style="color: #FF797A;">{{ $t('*捐款名册将展示您的用户昵称') }}</small>
                      </div>
                      <div style="width: 49%;">
                        <label style="font-weight: 500;"> <span style="color: red;">*</span>{{ $t('手机号') }}</label>
                        <div>
                          <input v-model="form.phone" :disabled='isLoading' @input="validatePhone()" type="text" class="form-control srk sjbxzt" style="background: #F6F4F0;border: none;font-size: calc(7px + 0.4vw);" :placeholder="$t('login.phoneNumberPlaceholder')">
                          <small class="form-text sjbxzt" style="color: #FF797A;" v-if="phoneError">{{ phoneError }}</small>
                          <small class="form-text" v-else style="visibility: hidden;">123</small>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex justify-content-between align-items-center mb-2">
                      <div style="width: 49%;">
                        <label style="font-weight: 500;"> <span style="color: red;">*</span>{{ $t('邮箱') }}</label>
                        <div>
                          <input v-model="form.email" :disabled='isLoading' @input="emivalidatePhone()" type="text" class="form-control srk sjbxzt" style="background: #F6F4F0;border: none;font-size: calc(7px + 0.4vw);" :placeholder="$t('login.qsryx')">
                          <small class="form-text sjbxzt" style="color: #FF797A;" v-if="emilError">{{ emilError }}</small>
                          <small class="form-text" v-else style="visibility: hidden;">123</small>
                        </div>
                      </div>
                      <div v-if="form.receipt_type==1" style="width: 49%;">
                        <div class="pl-0 pr-0 position-relative col-md-12">
                          <label style="font-weight: 500;"> <span style="color: red;">*</span>{{ $t('身份证号') }}</label>
                          <div>
                            <input style="background: #F6F4F0;border: none;font-size: calc(7px + 0.4vw);" v-model="form.id_number" :placeholder="$t('请输入身份ID')" :disabled='isLoading' type="name" class="form-control srk pl-3 sjbxzt">
                          </div>
                          <small class="form-text" style="visibility: hidden;">123</small>
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <label style="font-weight: 500;"> <span style="color: red;">*</span>{{ $t('收据方式') }}</label>
                      <div>
                        <div class="d-flex align-items-center mt-1 mb-2">
                          <div @click="form.receipt_type=1,form.company_number='',form.company_name=''" class="curpo toms yuanjiao mr-2 pt-1 pb-1" style="width: calc(80px + 0.5vw);text-align: center;font-size: calc(6px + 0.4vw);" :style="{ 'background': 1 == form.receipt_type ? '#FEF7F6' : '#F6F4F0', 'border': 1 == form.receipt_type ? '1px solid #FAB1AB' : 'none' }">
                            <div class="toms-p sjbxzt">{{ $t('个人收据') }}</div>
                          </div>
                          <div @click="form.receipt_type=2,form.id_number=''" class="curpo toms yuanjiao mr-2 pt-1 pb-1" style="width: calc(80px + 0.5vw);text-align: center;font-size: calc(6px + 0.4vw);" :style="{ 'background': 2 == form.receipt_type ? '#FEF7F6' : '#F6F4F0', 'border': 2 == form.receipt_type ? '1px solid #FAB1AB' : 'none' }">
                            <div class="toms-p sjbxzt">{{ $t('公司发票') }}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-12 d-flex pl-0 pr-0 justify-content-between align-item-center">
                      <div class=" form-group col-sm-4 pl-0 pr-0">
                        <label style="font-weight: 500;"> <span style="color: red;">*</span>{{ $t('城市') }}</label>
                        <button class="d-flex ckqbb zhankai  dydpd pl-4 pr-3 pt-2 pb-2 pl-0 pr-0" type="button" id="dropdownMenuButton" style="width: 90%;" @click="sjfstoggleDropdown(1)">
                          <div class="d-flex align-items-center justify-content-between " style="flex: 1;">
                            <div class="mr-1 sjbxzt" style="font-size: calc(6px + 0.4vw);flex: 1;">{{ form.city_id ?
									form_city_id :
									$t('请选择') }}
                            </div>
                            <div><img style="width: calc(7px + 0.4vw);" src="@/img/daohangl/icon_xlcd_xljt@2x (1).png" alt="" /></div>
                          </div>
                        </button>
                        <div class=" yuanjiao xlcd position-absolute  text-center" aria-labelledby="dropdownMenuButton" v-show="sjfsisDropdownOpen" style="border-radius:7px;background: #FFFAF0;width: 89%;">
                          <a class="dropdown-item xuazz yuanjiao mb-2" v-for="(item, index) in guojialist" :key="index" :style="{ 'background': form.city_id == item.id ? '#FFD672' : '' }" @click="sjfsxuanz(item, 1)" href="#"> <span class="sjbxzt">{{ item.name }}</span> </a>
                        </div>
                      </div>
                      <div class="form-group col-sm-4 pl-0 pr-0">
                        <label style="font-weight: 500;"> <span style="color: red;">*</span>{{ $t('地区') }}</label>
                        <button class="d-flex ckqbb zhankai  dydpd pl-4 pr-3 pt-2 pb-2 pl-0 pr-0" type="button" id="dropdownMenuButton" style="width: 90%;" @click="sjfstoggleDropdown(2)">
                          <div class="d-flex align-items-center justify-content-between " style="flex: 1;">
                            <div class="mr-1 sjbxzt" style="font-size: calc(6px + 0.4vw);flex: 1;">{{ form.region_id ?
									form_region_id :
									$t('请选择') }}
                            </div>
                            <div><img style="width: calc(7px + 0.4vw);" src="@/img/daohangl/icon_xlcd_xljt@2x (1).png" alt="" /></div>
                          </div>
                        </button>
                        <div class="dropdown-menu yuanjiao xlcd position-absolute gdtyc text-center" aria-labelledby="dropdownMenuButton" v-show="sjfsisDropdownOpen2" style="border-radius:7px;background: #FFFAF0;width: 85%;margin-left: 3%;max-height: 200px;overflow-y: auto;">
                          <a class="dropdown-item xuazz yuanjiao mb-2" v-for="(item, index) in guojialist2" :key="index" :style="{ 'background': form.region_id == item.id ? '#FFD672' : '' }" @click="sjfsxuanz(item, 2)" href="#"> <span class="sjbxzt">{{ item.name }}</span> </a>

                        </div>
                      </div>
                      <div class="form-group  col-sm-4 pl-0 pr-0">
                        <label style="font-weight: 500;"><span style="color: red;">*</span>{{ $t('区域') }}</label>

                        <button class="d-flex ckqbb zhankai  dydpd pl-4 pr-3 pt-2 pb-2 pl-0 pr-0" type="button" id="dropdownMenuButton" style="width: 90%;" @click="sjfstoggleDropdown(3)">
                          <div class="d-flex align-items-center justify-content-between " style="flex: 1;">
                            <div class="mr-1 sjbxzt" style="font-size: calc(6px + 0.4vw);flex: 1;">{{ form.district_id ?
									form_district_id :
									$t('请选择') }}
                            </div>
                            <div><img style="width: calc(7px + 0.4vw);" src="@/img/daohangl/icon_xlcd_xljt@2x (1).png" alt="" /></div>
                          </div>
                        </button>
                        <div class="dropdown-menu yuanjiao xlcd position-absolute gdtyc text-center" aria-labelledby="dropdownMenuButton" v-show="sjfsisDropdownOpen3" style="border-radius:7px;background: #FFFAF0;width: 84%;margin-left: 3.5%;max-height: 200px;overflow-y: auto;">
                          <a class="dropdown-item xuazz yuanjiao mb-2" v-for="(item, index) in guojialist3" :key="index" :style="{ 'background': form.district_id == item.id ? '#FFD672' : '' }" @click="sjfsxuanz(item, 3)" href="#"> <span class="sjbxzt">{{ item.name }}</span> </a>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex justify-content-between align-items-center mb-3">
                      <div style="width: 49%;">
                        <label style="font-weight: 500;"> <span style="color: red;">*</span>{{ $t('邮政区号') }}</label>
                        <div>
                          <input v-model="form.postal_code" :disabled='isLoading' @input="emivalidatePhone()" type="text" class="form-control srk sjbxzt" style="background: #F6F4F0;border: none;font-size: calc(7px + 0.4vw);" :placeholder="$t('login.qsryxemail')">
                        </div>
                      </div>
                      <div style="width: 49%;">
                        <label style="font-weight: 500;"> <span style="color: red;">*</span>{{ $t('地址') }}</label>
                        <div>
                          <input style="background: #F6F4F0;border: none;font-size: calc(7px + 0.4vw);" v-model="form.address" :disabled='isLoading' type="name" class="form-control srk pl-3 sjbxzt" :placeholder="$t('请输入地址')">
                        </div>
                      </div>
                    </div>
                    <div class="d-flex justify-content-between align-items-center">
                      <div v-if="form.receipt_type==2" style="width: 49%;">
                        <div class="pl-0 pr-0 position-relative col-md-12">
                          <label style="font-weight: 500;"> <span style="color: red;">*</span>{{ $t('公司统编') }}</label>
                          <div>
                            <input :placeholder="$t('请输入公司统编')" style="background: #F6F4F0;border: none;font-size: calc(7px + 0.4vw);" v-model="form.company_number" :disabled='isLoading' type="name" class="form-control srk pl-3 sjbxzt">
                          </div>
                        </div>
                      </div>

                      <div v-if="form.receipt_type==2" style="width: 49%;">
                        <div class="pl-0 pr-0 position-relative col-md-12">
                          <label style="font-weight: 500;"> <span style="color: red;">*</span>{{ $t('公司抬头') }}</label>
                          <div>
                            <input :placeholder="$t('请输入公司抬头')" style="background: #F6F4F0;border: none;font-size: calc(7px + 0.4vw);" v-model="form.company_name" :disabled='isLoading' type="name" class="form-control srk pl-3 sjbxzt">
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- <div class="form-group">
                    <label style="font-weight: 500;"> <span style="color: red;">*</span>{{ $t('提醒') }}</label>
                    <div>
                      <b-form-textarea class="sjbxzt" style="border: none;background: #F6F4F0;font-size: calc(7px + 0.4vw);" :disabled='isLoading' id="textarea" v-model="form.remind" :placeholder="$t('写下你的心得')" rows="3" max-rows="6" no-resize></b-form-textarea>
                    </div>
                  </div> -->
                  </form>
                  <form v-if="hshow==3" class="sjbxzt" style="font-size: calc(8px + 0.4vw);">
                    <div class="form-group">
                      <label style="font-weight: 500;">{{ $t('我想留言鼓励团体') }}</label>
                      <div>
                        <b-form-textarea class="sjbxzt" style="border: none;background: #F6F4F0;font-size: calc(7px + 0.4vw);height: 17rem;" :disabled='isLoading' id="textarea" v-model="form.remind" :placeholder="$t('写下你的心得')" rows="3" max-rows="6" no-resize></b-form-textarea>
                      </div>
                    </div>
                    <div class="pt-3 pb-3 text-center yuanjiao mt-5 mb-5 sjbxzt" style="width: 100%;background: #FFEAB8;font-size: calc(7px + 0.4vw);">
                      <div class="mb-1">{{ $t('您的捐款金额为') }} {{ form.amount }}{{ $t('元') }}</div>
                      <div>{{ $t('感谢您的支持') }}</div>
                    </div>
                  </form>
                </div>
                <!-- 銀行轉正 -->
                <yhzz v-if="type==3&&is_bank==1"></yhzz>
                <!-- 銀行轉正 -->
                <!-- 表單結尾 -->
                <div v-if="type==2" class="">
                  <div v-if="hshow==1" class="mt-3 sjbxxzt" style="color: #999999;font-size: calc(7px + 0.4vw);">{{ $t("bzyhdbygg") }}{{ info.remark }}</div>
                  <div v-if="hshow==1" class="text-center mt-2 mb-2" style="width: 100%;">
                    <img class="mr-1" style="width: 15px;height: 15px;" src="@/img/icon_suo@1x.png" alt="">
                    <span class="sjbxxzt" style="color: #9E9E9E;font-size: calc(6px + 0.4vw);">{{ $t("aqfkndxykzl") }}</span>
                  </div>
                  <div v-if="hshow==2" class="mt-3 text-center sjbxxzt" style="color: #FF797A;font-size: calc(6px + 0.4vw);">{{ $t("*请仔细核实您的身份信息，这将影响收据资讯的准确性") }}</div>
                  <div v-if="hshow==1" @click="dbjkdyb()" class="jkss pt-2 pb-2 yuanjiao sjbxzt" style="width: 84%;margin: auto;">{{ $t("nextstep") }}</div>
                  <div v-if="hshow==2" class="mt-2 d-flex justify-content-between mb-3">
                    <div @click="hshow=1" class="jkss pt-2 pb-2 yuanjiao sjbxzt" style="width: 49%;background: #FFEAB8;">{{ $t("Previousstep") }}</div>
                    <div @click="derdbjkdyb()" class="jkss pt-2 pb-2 yuanjiao sjbxzt" style="width: 49%;">{{ $t("nextstep") }}</div>
                  </div>
                  <div v-if="hshow==3" class="mt-4 d-flex justify-content-between">
                    <div @click="hshow=2" class="jkss pt-2 pb-2 yuanjiao sjbxzt" style="width: 49%;background: #FFEAB8;">{{ $t("Previousstep") }}</div>
                    <div @click="bulletframe()" class="jkss pt-2 pb-2 yuanjiao sjbxzt" style="width: 49%;">{{ $t("前往付款") }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <b-modal v-model="modalShow" modal-class="custom-modal-widths" :centered="true" hide-header hide-footer>
        <div style="width: 100%; text-align: center;" class="pb-2 position-relative ">
          <img @click="modalShow = false" class="position-absolute curpo sbxfbd" style="width: calc(15px + 0.5vw); right: 20px; top: -20px" src="@/img/details/cha.png" alt="" />
          <div class="mb-3 mt-4 sjbzt" style="font-size: calc(11px + 0.4vw); font-weight: 700">
            {{ $t("尚未登录") }}
          </div>
          <div class="p-3 yuanjiao" style="width: 90%; margin: auto; background: #f6f4f0">
            <div class="sjbxzt" style="font-size: calc(8px + 0.4vw)">
              {{ $t("需要先进行登录或注册") }}
            </div>
          </div>
          <div style="height: calc(10px + 1vw)"></div>
          <button :disabled="isLoading" @click="login()" class="pl-5 pr-5 pt-3 pb-3 sjbzt yuanjiao ckqbb annys">
            {{ $t("common.login") }}
            <span v-if="isLoading" class="spinner-border spinner-border-sm ml-2" role="status" aria-hidden="true">
            </span>
          </button>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { donation_Deta } from "@/request/aapi.js";
import yhzz from "@/components/jkhd/Bankcard";
import SkeletonScreen from "./SkeletonScreen/xinSkeletonScreen.vue";
import { region_list } from "@/request/zhbd/index.js";
import { todonate_base, donation_pay } from "@/request/jkhd/jkhd";
import dqjk from "@/components/jkhd/wmuc/dqjk";

export default {
  components: {
    SkeletonScreen,
    yhzz,
    dqjk,
  },
  data() {
    return {
      zank: 1,
      modalShow: false,
      dataLoaded: false,
      incon: null,
      show: 0,
      idds: "",
      is_bank: "",
      hshow: 1,
      type: 1,
      info: {},
      ysxxid: "1",
      isLoading: false,
      // 城市
      sjfsisDropdownOpen: false,
      // 地区
      sjfsisDropdownOpen2: false,
      // 区域
      sjfsisDropdownOpen3: false,
      emilError: "",
      phoneError: "",
      datams: {},
      zdyi: true,
      form: {
        is_sign: 1,
        id: "",
        receipt_type: 1,
        postal_code: "",
        amount: "",
        name: "",
        phone: "",
        email: "",
        region_id: "",
        city_id: "",
        district_id: "",
        address: "",
        id_number: "",
        remind: "",
        pay_type: 1,
        // 1:定期，2单笔
        type: 2,
        company_name: "",
        company_number: "",
      },
      zflb: [
        {
          id: 1,
          name: this.$t("信用卡"),
          type: "",
        },
        {
          id: 2,
          name: this.$t("ATM转账"),
          type: "",
        },
        {
          id: 3,
          name: require("@/img/pic_zffs_line@2x.png"),
          type: "img",
        },
        {
          id: 4,
          name: require("@/img/pic_zffs_apple@2x.png"),
          type: "img",
        },
      ],
      list: [],
      low: "",
      max: "",
      // 城市
      guojialist: [],
      guojialist2: [],
      guojialist3: [],
      form_city_id: "",
      form_region_id: "",
      form_district_id: "",
    };
  },
  created() {
    this.idds = this.$route.params.ckxq;
    this.form.id = this.$route.params.ckxq;
    this.gettodonate();
    this.getCity(0);
  },
  mounted() {
    this.getInfo();
  },
  computed: {},
  methods: {
    cusasdm(){
      this.modalShow = true;
    },
    login() {
      this.$router.push("/login");
    },
    custom(e) {
      console.log(e);
      if(e=='123'){
        this.modalShow = true;
        return;
      }
      this.hshow = e;
    },
    derdbjkdyb() {
      if (this.phoneError || this.emilError) {
        return;
      }
      if (this.form.name == "") {
        this.$bvToast.toast(this.$t("请输入姓名"), {
          title: this.$t("login.warning"),
          variant: "danger",
          autoHideDelay: 5000,
        });
        return;
      } else if (this.form.phone == "") {
        this.$bvToast.toast(this.$t("请输入手机号码"), {
          title: this.$t("login.warning"),
          variant: "danger",
          autoHideDelay: 5000,
        });
        return;
      } else if (this.form.email == "") {
        this.$bvToast.toast(this.$t("请输入信箱"), {
          title: this.$t("login.warning"),
          variant: "danger",
          autoHideDelay: 5000,
        });
        return;
      } else if (this.form.postal_code == "") {
        this.$bvToast.toast(this.$t("请输入邮政区号"), {
          title: this.$t("login.warning"),
          variant: "danger",
          autoHideDelay: 5000,
        });
        return;
      } else if (this.form.city_id == "") {
        this.$bvToast.toast(this.$t("请选择居住城市"), {
          title: this.$t("login.warning"),
          variant: "danger",
          autoHideDelay: 5000,
        });
        return;
      } else if (this.form.district_id == "") {
        this.$bvToast.toast(this.$t("请选择区域"), {
          title: this.$t("login.warning"),
          variant: "danger",
          autoHideDelay: 5000,
        });
        return;
      } else if (this.form.region_id == "") {
        this.$bvToast.toast(this.$t("请选择地区"), {
          title: this.$t("login.warning"),
          variant: "danger",
          autoHideDelay: 5000,
        });
        return;
      } else if (this.form.address == "") {
        this.$bvToast.toast(this.$t("请输入地址"), {
          title: this.$t("login.warning"),
          variant: "danger",
          autoHideDelay: 5000,
        });
        return;
      } else if (this.form.id_number == "" && this.form.receipt_type == 1) {
        this.$bvToast.toast(this.$t("请输入身份ID"), {
          title: this.$t("login.warning"),
          variant: "danger",
          autoHideDelay: 5000,
        });
        return;
      } else if (this.form.company_name == "" && this.form.receipt_type == 2) {
        this.$bvToast.toast(this.$t("请输入公司统编"), {
          title: this.$t("login.warning"),
          variant: "danger",
          autoHideDelay: 5000,
        });
        return;
      } else if (
        this.form.company_number == "" &&
        this.form.receipt_type == 2
      ) {
        this.$bvToast.toast(this.$t("请输入公司抬头"), {
          title: this.$t("login.warning"),
          variant: "danger",
          autoHideDelay: 5000,
        });
        return;
      }
      this.hshow = 3;
    },
    dbjkdyb() {
      if (!localStorage.getItem("token")) {
        this.modalShow = true;
        return;
      }
      if (!this.form.amount) {
        this.$bvToast.toast(this.$t("请输入NT$"), {
          title: this.$t("login.warning"),
          variant: "danger",
          autoHideDelay: 5000,
        });
        return;
      }
      if (this.form.amount < this.low) {
        this.$bvToast.toast(this.$t("输入金额不能小于") + this.low, {
          title: this.$t("login.warning"),
          variant: "danger",
          autoHideDelay: 5000,
        });
        return;
      }
      if (
        this.form.amount > this.datams.maximum_credit_amount &&
        this.form.pay_type == 1
      ) {
        this.$bvToast.toast(
          this.$t("信用卡最大限额") + this.datams.maximum_credit_amount,
          {
            title: this.$t("login.warning"),
            variant: "danger",
            autoHideDelay: 5000,
          }
        );
        return;
      } else if (
        this.form.amount > this.datams.maximum_atm_amount &&
        this.form.pay_type == 2
      ) {
        this.$bvToast.toast(
          this.$t("ATM最大限额") + this.datams.maximum_atm_amount,
          {
            title: this.$t("login.warning"),
            variant: "danger",
            autoHideDelay: 5000,
          }
        );
        return;
      }else if (
        this.form.amount > this.datams.maximum_line_amount &&
        this.form.pay_type == 3
      ) {
        this.$bvToast.toast(
          this.$t("line pay最大限额") + this.datams.maximum_atm_amount,
          {
            title: this.$t("login.warning"),
            variant: "danger",
            autoHideDelay: 5000,
          }
        );
        return;
      }else if (
        this.form.amount > this.datams.maximum_apple_amount &&
        this.form.pay_type == 4
      ) {
        this.$bvToast.toast(
          this.$t("apple pay最大限额") + this.datams.maximum_atm_amount,
          {
            title: this.$t("login.warning"),
            variant: "danger",
            autoHideDelay: 5000,
          }
        );
        return;
      }
      this.hshow = 2;
    },
    zjsyqk() {
      this.$router.push({
        name: "zzsy",
        params: {
          isT: 2,
          zjsy: this.idds,
          judge: "1",
        },
      });
    },
    jkmcqk() {
      this.$router.push({
        name: "jkmc",
        params: {
          isT: 2,
          zjsy: this.idds,
          judge: "1",
        },
      });
    },
    wypjia() {
      this.$router.push({
        path: "/donation/" + this.idds + "/donate-comment",
      });
    },
    getInfo() {
      let data = {
        id: this.$route.params.ckxq,
      };
      donation_Deta(data).then((res) => {
        // console.log(res, "详情")
        if (res.code == 200) {
          this.dataLoaded = true;
          this.incon = res.data.info.introduction;
          this.info = res.data.info;
          let f= res.data.info.bg_image
          let m=f.replace(' ','%20');
          this.info.bg_image = m;
          this.is_bank = res.data.bank.is_bank;
        }
      });
    },
    fanhui(e) {
      this.$router.go(-1);
    },
    // 城市列表
    async getCity(a, v) {
      await region_list({
        parent_id: v,
      }).then((res) => {
        if (res.code == 200) {
          if (a == 0) {
            this.guojialist = res.data.data;
            this.form.city_id = res.data.data[0].id;
            this.form_city_id = res.data.data[0].name;
            this.getCity(1, res.data.data[0].id);
          } else if (a == 1) {
            this.guojialist2 = res.data.data;
          } else if (a == 2) {
            this.guojialist3 = res.data.data;
          }
        }
      });
    },
    // 捐款基本信息
    gettodonate() {
      todonate_base({ donation_id: this.form.id }).then((res) => {
        const data = res.data.donation;
        this.datams = res.data.donation;
        if(res.data.donation.open_line_pay==0){
          this.zflb.splice(2, 1)
        }
        this.list = data.init_amount.map((item, index) => {
          return {
            ...item,
            id: index + 1,
            name: item,
          };
        });
        this.ysxx(1, "a1");
        this.low = data.minimum_amount;
        this.max = data.maximum_period;
      });
    },
    // 点击捐款
    bulletframe() {
      this.isLoading = true;
      // 模拟登录逻辑
      donation_pay(this.form).then((res) => {
        if (res.code == 200) {
          this.isLoading = false; // 处理完成后关闭转圈
          window.location.href =
            "https://api.gddao.com/web/pay/submit?order_no=" +
            res.data.data.order_no;
        } else {
          this.$bvToast.toast(res.msg, {
            title: this.$t("login.notice"),
            autoHideDelay: 2000,
            delay: 5000,
            appendToast: true,
            variant: "success",
          });
          this.isLoading = false; // 处理完成后关闭转圈
        }
      });
    },
    sjfsxuanz(e, b) {
      if (b == 1) {
        this.form.city_id = e.id;
        this.form_city_id = e.name;
        this.sjfsisDropdownOpen = false;
        this.getCity(1, e.id);
      } else if (b == 2) {
        this.form.district_id = "";
        this.form.region_id = e.id;
        this.form_region_id = e.name;
        this.sjfsisDropdownOpen2 = false;
        this.getCity(2, e.id);
      } else if (b == 3) {
        this.form.district_id = e.id;
        this.form_district_id = e.name;
        this.sjfsisDropdownOpen3 = false;
      }
    },
    sjfstoggleDropdown(a) {
      if (a == 1) {
        this.sjfsisDropdownOpen = !this.sjfsisDropdownOpen;
        this.sjfsisDropdownOpen2 = false;
        this.sjfsisDropdownOpen3 = false;
      } else if (a == 2) {
        this.sjfsisDropdownOpen2 = !this.sjfsisDropdownOpen2;
        this.sjfsisDropdownOpen = false;
        this.sjfsisDropdownOpen3 = false;
      } else if (a == 3) {
        this.sjfsisDropdownOpen3 = !this.sjfsisDropdownOpen3;
        this.sjfsisDropdownOpen = false;
        this.sjfsisDropdownOpen2 = false;
      }
      // 如果需要，可以在这里添加Bootstrap Dropdown的额外处理逻辑
    },
    // 校验手机号
    validatePhone() {
      const regex = /^09[0-9]{8}$/; // 简单的手机号正则表达式
      if (!regex.test(this.form.phone)) {
        this.phoneError = this.$t("login.invalid_phone_number");
      } else {
        this.phoneError = "";
      }
      if (this.form.phone == "") {
        this.phoneError = "";
      }
    },
    // 校验邮箱
    emivalidatePhone() {
      const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (!regex.test(this.form.email)) {
        this.emilError = this.$t("login.invalid_emil_number");
      } else {
        this.emilError = "";
      }
      if (this.form.email == "") {
        this.emilError = "";
      }
    },
    // 捐款
    jkfs(e) {
      this.form.pay_type = e;
    },
    // nts
    ysxx(e, v) {
      if (v == "a1") {
        this.zdyi = false;
        this.form.amount = null;
        this.ysxxid = "a1";
        // this.form.period = 0
      } else {
        this.form.amount = v;
        this.zdyi = true;
        this.ysxxid = e;
        // this.gettodonate();
      }
    },
  },
};
</script>
<style >
.custom-modal-widths .modal-dialog {
  position: relative;
  max-width: 50% !important;
  margin: auto;
  pointer-events: none;
}
@media screen and (max-width: 995px) {
  .custom-modal-widths .modal-dialog {
    position: relative;
    max-width: 90% !important;
    margin: auto;
    pointer-events: none;
  }
}
</style>
<style scoped>
@media screen and (max-width: 750px) {
  .sjdwd {
    width: 100% !important;
  }
  .sjbplpr {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

@media screen and (max-width: 995px) {
  .heggs {
    margin-top: 1rem !important;
  }
  .aaaaa {
    display: block !important;
  }
  .yinc {
    display: none !important;
  }
  .gaodu {
    height: 66vh !important;
  }
  .sjbzt {
    font-size: calc(12px + 0.4vw) !important;
  }
  .sjbxzt {
    font-size: calc(11px + 0.4vw) !important;
  }
  .sjbxxzt {
    font-size: calc(9px + 0.4vw) !important;
  }
}
.aaaaa {
  display: none;
}
.annys {
  background: #ffd672;
  border: none;
  font-size: calc(10px + 0.4vw);
}
.sadca::before {
  /* height: 80vh !important; */
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
  background-position: center;
  border-radius: 12px 12px 0 0;
}
.asdax {
  background: rgba(255,250,240,0.9);
}
.dcbd:hover {
  transform: scale(1.08);
}
.gaodu {
  width: 49%;
  height: 73vh;
  overflow-y: auto;
}
.huansge {
  width: 100%;
  border-radius: 12px 12px 0 0;
  height: 2rem;
  background: #ffeab8;
}
.tom {
  width: 80%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.jkss {
  background-color: #ffd672;
  text-align: center;
  font-size: calc(8px + 0.4vw);
  cursor: pointer;
}

.lieb::-webkit-scrollbar {
  display: none;
}
.dropdown-menu {
  min-width: calc(25px + 0.5vw) !important;
}
.ckqbb:hover {
  transform: scale(1.001);
  /* 悬浮时放大1.1倍 */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.szdyyxg {
  font-size: calc(12px + 0.4vw);
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); */
  text-shadow: 2px 2px 2px #000000;
}
.xlcd {
  display: block;
  background: #ffebd2;
  border: none;
}

.zhankai {
  background: #ffebd2;
  text-align: center;
  border-radius: 12px 12px 12px 12px;
  border: none;
  font-weight: 400;
  font-size: calc(6px + 0.4vw);
  color: #1a1a1a;
}

.xuazz:hover {
  background: #ffebd2;
}

textarea.form-control {
  overflow-y: auto !important;
}
</style>