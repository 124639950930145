// 时间转换
function timeFilter(stringTime, a, b, c, d, e) {
    stringTime = stringTime * 1000
    var minute = 1000 * 60;
    var hour = minute * 60;
    var day = hour * 24;
    var week = day * 7;
    var month = day * 30;
    var time1 = new Date().getTime();//当前的时间戳
    var time2 = Date.parse(new Date(stringTime));//指定时间的时间戳
    var time = time1 - time2;
    var result = null;
    if (time / week >= 1) {
        result = formatTimestamp(stringTime);
    } else if (time / day >= 1) {
        result = a + parseInt(time / day) + b;
    } else if (time / hour >= 1) {
        result = a + parseInt(time / hour) + c;
    } else if (time / minute >= 1) {
        result = a + parseInt(time / minute) + d;
    } else {
        result = e;
    }
    return result
}
// 时间戳转年月日时分秒
function formatTimestamp(timestamp) {
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = addZero(date.getMonth() + 1);
    const day = addZero(date.getDate());
    const hours = addZero(date.getHours());
    const minutes = addZero(date.getMinutes());
    const seconds = addZero(date.getSeconds());
    return `${year}-${month}-${day}`;
}
function addZero(value) {
    return value < 10 ? `0${value}` : value;
}


export {
    timeFilter,
    formatTimestamp
}