<template>
  <div class="col-md-12 col-sm-12 col-lg-12 col-xl-12 pl-0 pr-0">
    <skeleton-screen v-if="!dataLoaded"></skeleton-screen>
    <div v-if="dataLoaded" class="col-md-12 pb-3 pl-0 pr-0" style="font-size: calc(7px + 0.4vw);">
      <!-- pc版样式-->
      <div v-if="tyugyu == 0" class="none">
        <div class="pl-3 pt-3 pb-3 mb-3 baise yuanjiao col-md-12 pl-0 pr-3 d-flex justify-content-between asdfas">
          <div class="d-flex  pl-0 pr-0 align-items-center  pt-3 pb-3 ckqbb p_top" style="background: #FFFAF0;border-radius:12px;width: 49.4%;justify-content: space-between;position: relative;overflow: hidden;">
            <div class="d-flex align-items-center ml-3" style="z-index: 999;">
              <img class="mr-2 " style="width: 50px;font-size: calc(9px + 0.4vw);font-weight: 500;height: 50px;" src="@/img/daohangl/pic_hssy_haorenbi@2x.png" alt="" />
              <div class="">
                <div style="font-size: calc(6px + 0.4vw);" class="sjbxzt">{{ $t("平台总发行好人币") }}</div>
                <div style="font-size: calc(15px + 0.4vw);font-weight: 700;">{{ user.
                  good_coin ? user.
                    good_coin.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }}</div>
              </div>
            </div>

            <div class="bgheight" style="width: 100%;position: absolute;bottom: 0;height: 60%;background-color: #FDDDC9;display: flex;justify-content: flex-end;">
              <img style="height: 100%;width: 70%;" src="@/img/pic_kapianzhuangshi@2x.png" alt="" />
            </div>
          </div>
          <div class="d-flex  pl-0 pr-0 align-items-center  pt-3 pb-3 ckqbb p_top" style="background: #FFFAF0;border-radius:12px;width: 49.4%;justify-content: space-between;position: relative;overflow: hidden;">
            <div class="d-flex align-items-center ml-3 " style="z-index: 999;">
              <img class="mr-2" style="width: 50px;font-size: calc(9px + 0.4vw);font-weight: 500;height: 50px;" src="@/img/daohangl/pic_hssy_tanbi@2x.png" alt="" />
              <div class="">
                <div style="font-size: calc(6px + 0.4vw);" class="sjbxzt">{{ $t("平台总发行碳币") }}</div>
                <div style="font-size: calc(15px + 0.4vw);font-weight: 700;">{{ user.
                  carbon_coin ? user.
                    carbon_coin.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }}</div>
              </div>
            </div>
            <div class="bgheight" style="width: 100%;position: absolute;bottom: 0;height: 60%;background-color: #FDDDC9;display: flex;justify-content: flex-end;">
              <img style="height: 100%;width: 70%;" src="@/img/pic_kapianzhuangshi@2x.png" alt="" />
            </div>
          </div>
        </div>
        <div class="">
          <div class="col-md-12 pl-0 pr-0 d-flex justify-content-between">
            <div class="d-flex ">
              <div @click="hsjl()" class="pt-2 pb-2 baise pl-5 pr-5 curpo" :style="{ 'background': xuanz==1 ? '' : '#FFFAF0' }" style="font-size: calc(7px + 0.4vw);border-radius: 12px 12px 0 0;">{{ $t("全部记录") }}
              </div>
              <div @click="zgzm()" class="pt-2 pb-2 baise pl-5 pr-5 curpo" :style="{ 'background': xuanz==2 ? '' : '#FFFAF0' }" style="font-size: calc(7px + 0.4vw);border-radius: 12px 12px 0 0;">{{ $t("志工证明") }}
              </div>
              <div @click="jkjl()" class="pt-2 pb-2 baise pl-5 pr-5 curpo" :style="{ 'background': xuanz==3 ? '' : '#FFFAF0' }" style="font-size: calc(7px + 0.4vw);border-radius: 12px 12px 0 0;">{{ $t("捐款记录") }}
              </div>
            </div>
            <div class="d-flex pl-0 pr-0" style="justify-content: space-between;">
              <b-form-datepicker v-model="cfsj" aria-controls="example-input" class="a1 pl-3 pr-3" :label-no-date-selected="$t('请选择日期')" style="width: 100%;padding: 5px 0px;" @context="onContext"></b-form-datepicker>
            </div>
          </div>
          <div v-if="xuanz==1" class="baise p-3 col-md-12 pl-0 pr-0" style="border-radius: 0 0 12px 12px;">
            <div class="col-md-12 pt-3 pb-3  pl-0 pr-0 yuanjiao d-flex justify-content-between align-items-center" style="background: #FFFAF0;">
              <div class="col-md-1 ellipsis col-sm-1 col-lg-1 col-xl-1 pl-0 pr-0 ml-3" style="color: #999999;">{{
                $t("好事记录") }}</div>
              <div class="col-md-1 col-sm-2 col-lg-1 col-xl-1 pl-0 pr-0 " style="color: #999999;">{{ $t("日期") }}
              </div>
              <div class="col-md-1 col-sm-1 col-lg-1 col-xl-1 pl-0 pr-0" style="color: #999999;">{{ $t("好人积分") }}</div>
              <div class="col-md-1 col-sm-1 col-lg-1 col-xl-1 pl-0 pr-0" style="color: #999999;">{{ $t("碳积分") }}</div>
              <div class="col-md-2 col-sm-2 col-lg-1 col-xl-1 pl-0 pr-0" style="color: #999999;">{{ $t("活动单位") }}</div>
              <div class="col-md-1 ellipsis col-sm-1 col-lg-1 col-xl-1 pl-0 pr-0" style="color: #999999;">{{ $t("活动地点")
                }}
              </div>
              <div class="col-md-1 col-sm-1 col-lg-1 col-xl-1 pl-0 pr-0 mr-3" style="color: #999999;">{{ $t('活动花絮') }}
              </div>
            </div>
            <div style="height: 59vh !important;overflow-y: auto;" class="parent" @scroll="handleScroll">
              <div v-for="(item, index) in infoList" :key="index" class="col-md-12 pt-0 pb-1  pl-0 pr-0 yuanjiao d-flex justify-content-between align-items-center" :style="{ backgroundColor: index % 2 === 0 ? 'white' : '#F6F4F0' }">

                <div class="col-md-1 ellipsis col-sm-1 col-lg-1 col-xl-1 pl-0 pr-0  ml-3 text-overflow-hover" :title="item.name" @mouseenter="showFullText = true" @mouseleave="showFullText = false">{{ item.name ?
                    item.name : '--' }}</div>
                <div class="col-md-1 col-sm-2 col-lg-1 col-xl-1 pl-0 pr-0" style="color: #999999;">{{
                  timeFilter(item.create_time) }}</div>
                <div class="col-md-1 col-sm-1 col-lg-1 col-xl-1 pl-0 pr-0 d-flex" style="align-items: center;">
                  <img class="mr-2" style="width: 20px;font-weight: 500;height: 20px;" src="@/img/daohangl/pic_hssy_haorenbi@2x.png" alt="" />
                  {{ item.good_coin > 0 ? '+' : '' }}
                  {{
                    item.good_coin }}
                </div>
                <div class="col-md-1 col-sm-1 col-lg-1 col-xl-1 pl-0 pr-0 d-flex" style="align-items: center;">
                  <img class="mr-2" style="width: 20px;font-weight: 500;height: 20px;" src="@/img/daohangl/pic_hssy_tanbi@2x.png" alt="" />
                  {{ item.carbon_coin > 0 ? '+' : ''
                  }} {{
                    item.carbon_coin }}
                </div>
                <div class="col-md-2 col-sm-2 ellipsis col-lg-1 col-xl-1 pl-0 pr-0">{{ item.organization_name }}</div>
                <div class="col-md-1 ellipsis col-sm-1 col-lg-1 col-xl-1 pl-0 pr-0">{{ item.address ? item.address :
                  '--'
                  }}</div>

                <img v-if="item.image" @click="tiaozhu(item.category, item)" class="col-md-1 col-sm-1 col-lg-1 col-xl-1 pl-0 pr-0 mr-3 curpo" style="height: 100px;object-fit: contain;" :src="item.image" alt="" />
                <div v-else @click="tiaozhu(item.category, item)" class="col-md-1 col-sm-1 col-lg-1 col-xl-1 pl-0 pr-0 mr-3 curpo" style="height: 100px;"></div>

              </div>
              <!-- 加载更多提示 -->
              <div class="d-flex justify-content-center">
                <div v-if="infoList.length == 0">
                  <empty></empty>
                </div>
              </div>
            </div>
          </div>
          <div v-if="xuanz==2" class="baise p-3 col-md-12 pl-0 pr-0" style="border-radius: 0 0 12px 12px;">
            <div class="col-md-12 pt-3 pb-3 pl-0 pr-0 yuanjiao d-flex justify-content-between align-items-center" style="background: #FFFAF0;">
              <div class="col-md-1 ellipsis col-sm-1 col-lg-1 col-xl-1 pl-0 pr-0 ml-3" style="color: #999999;">{{
                $t("参与单位") }}</div>
              <div class="col-md-1 col-sm-2 col-lg-1 col-xl-1 pl-0 pr-0 " style="color: #999999;">{{ $t("参与单位统编") }}
              </div>
              <div class="col-md-1 col-sm-1 col-lg-1 col-xl-1 pl-0 pr-0" style="color: #999999;">{{ $t("参与时间") }}</div>
              <div class="col-md-1 col-sm-1 col-lg-1 col-xl-1 pl-0 pr-0" style="color: #999999;">{{ $t("参与地点") }}</div>
              <div class="col-md-2 col-sm-2 col-lg-1 col-xl-1 pl-0 pr-0" style="color: #999999;">{{ $t("参与时长") }}</div>
              <div class="col-md-1 col-sm-1 col-lg-1 col-xl-1 pl-0 pr-0" style="color: #999999;">{{ $t("志工证明照片") }}
              </div>
              <div class="col-md-1 col-sm-1 col-lg-1 col-xl-1 pl-0 pr-0 mr-3" style="color: #999999;">
              </div>
            </div>
            <div style="height: 59vh;overflow-y: auto;" class="parent" @scroll="handleScroll">
              <div v-for="(item, index) in zgzminfoList" :key="index" class="col-md-12 pt-1 pb-1  pl-0 pr-0 yuanjiao d-flex justify-content-between align-items-center" :style="{ backgroundColor: index % 2 === 0 ? 'white' : '#F6F4F0' }">
                <div class="col-md-1 ellipsis col-sm-1 col-lg-1 col-xl-1 pl-0 pr-0 ml-3" style="color: #999999;">{{
                  item.organization_name }}</div>
                <div class="col-md-1 col-sm-2 col-lg-1 col-xl-1 pl-0 pr-0 " style="color: #999999;">{{
                  item.business_number }}
                </div>
                <div class="col-md-1 col-sm-1 col-lg-1 col-xl-1 pl-0 pr-0" style="color: #999999;">{{ item.join_year
                  }}-{{
                    item.join_month }}-{{ item.join_day }}</div>
                <div class="col-md-1 col-sm-1 col-lg-1 col-xl-1 pl-0 pr-0" style="color: #999999;">{{ item.address }}
                </div>
                <div class="col-md-2 col-sm-2 col-lg-1 col-xl-1 pl-0 pr-0" style="color: #999999;">{{ item.time_length
                  }}
                </div>
                <img v-if="item.certificate_image.length" class="col-md-1 col-sm-1 col-lg-1 col-xl-1 pl-0 pr-0" style="height: 100px;object-fit: contain;" :src="item.certificate_image[0]" alt="" />
                <div v-if="!item.certificate_image.length" class="col-md-1 col-sm-1 col-lg-1 col-xl-1 pl-0 pr-0 mr-3" style="height: 100px;"></div>
                <div class="col-md-1 col-sm-1 col-lg-1 col-xl-1 pl-0 pr-0 mr-3 curpo">
                  <div @click="xiazzghdzp(item.certificate_image,item.certificate_pdf)" class="pt-2 pb-2 yuanjiao text-center" style="background: #FFD672;">{{ $t("下載") }}</div>
                </div>
              </div>
              <!-- 加载更多提示 -->
              <div class="d-flex justify-content-center">
                <div v-if="zgzminfoList.length == 0">
                  <empty></empty>
                </div>
              </div>
            </div>
          </div>
          <div v-if="xuanz==3" class="baise p-3 col-md-12 pl-0 pr-0" style="border-radius: 0 0 12px 12px;">
            <div>
              <div class="d-flex align-items-center justify-content-between">
                <div @click="idqieh(0)" :style="{border: jinde=='0'? '2px solid #FAB1AB':''}" class="d-flex align-items-center justify-content-between curpo pl-3 pr-3 pt-2 pb-2 yuanjiao mb-3" style="background: #FEF7F6;">
                  <img style="width: calc(25px + 0.4vw);height: calc(25px + 0.4vw);" src="@/img/pic_hssy_haorenbi@2x (1).png" alt="">
                  <div class="ml-2" style="font-size: calc(7px + 0.4vw);">
                    <div>{{ $t("捐款总金额") }}</div>
                    <div style="font-weight: 600;">{{ jkjlamount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</div>
                  </div>
                </div>
                <!-- 中间部分 -->
                <div class="pl-0 pr-0 items yinc" @mouseenter="showButtons = true" @mouseleave="showButtons = false" style="width: 58%;">
                  <div class="col-sm-12 pl-0 pr-0 position-relative d-flex justify-content-between align-items-center">
                    <div v-show="showButtons" class="col-auto position-absolute pl-0 pr-0 dcbd curpo xsdann" style="z-index: 999;left: 0;bottom: 40%;" @click="move(-1)">
                      <img style="width: calc(12px + 0.5vw);" src="@/img/details/icon_jklb_xiangzuo@2x.png" alt="" />
                    </div>
                    <div class="col-sm-12 d-flex pl-0 pr-0 d-flex justify-content-between">
                      <div class=" ckqbb  curpo yuanjiao position-relative" style="background: #FFFFFF;width: 24%;" v-for="(item, index) in visibleItems" :key="index">
                        <!-- 假设每个item是一个对象，包含图标和文字 -->
                        <!-- <img class="mb-1" style="width: calc(13px + 0.5vw);" :src="item.icon" alt="" /> -->
                        <div @click="idqieh(item.donation_id)" :style="{border: jinde==item.donation_id? '2px solid #FAB1AB':''}" class="d-flex align-items-center curpo pl-3 pr-3 pt-2 pb-2 yuanjiao mb-3" style="background: #FEF7F6;">
                          <img style="width: calc(25px + 0.4vw);height: calc(25px + 0.4vw);" src="@/img/pic_hssy_haorenbi@2x (2).png" alt="">
                          <div class="ml-2" style="font-size: calc(7px + 0.4vw);">
                            <div class="ellipsis" style="width: calc(30px + 1.5vw);">{{ item.donation_name }}</div>
                            <div style="font-weight: 600;">{{ item.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-show="showButtons" class="col-auto position-absolute pl-0 pr-0 dcbd curpo xsdann" style="z-index: 999;right: 0;bottom: 40%;" @click="move(1)">
                      <img style="width: calc(12px + 0.5vw);" src="@/img/details/icon_jklb_xiangyou@2x.png" alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 pt-3 pb-3 pl-0 pr-0 yuanjiao d-flex justify-content-between align-items-center" style="background: #FFFAF0;">
                <div class="col-md-2 pl-0 pr-0 ml-3" style="color: #999999;">{{
                $t("活动名称") }}</div>
                <div class="col-md-3 pl-0 pr-0 " style="color: #999999;">{{ $t("团体名称") }}
                </div>
                <div class="col-md-2 pl-0 pr-0" style="color: #999999;">{{ $t("捐款金额") }}</div>
                <div class="col-md-2 pl-0 pr-0" style="color: #999999;">{{ $t("参与时间") }}</div>
                <div class="col-md-2 pl-0 pr-0" style="color: #999999;">{{ $t("付款方式") }}
                </div>
              </div>
              <div style="height: 51vh;overflow-y: auto;" class="parent" @scroll="handleScroll">
                <div v-for="(item, index) in jkjlinfoList" :key="index" class="col-md-12 pt-3 pb-3  pl-0 pr-0 yuanjiao d-flex justify-content-between align-items-center" :style="{ backgroundColor: index % 2 === 0 ? 'white' : '#F6F4F0' }">
                  <div class=" ellipsis col-md-2 pl-0 pr-0 ml-3 text-overflow-hover" style="color: #999999;" :title="item.donation_name" @mouseenter="showFullText = true" @mouseleave="showFullText = false">{{ item.donation_name ?
                    item.donation_name : '--' }}</div>
                  <div class="col-md-3 pl-0 pr-0 " style="color: #999999;">{{ item.organization_name }}
                  </div>
                  <div class="col-md-2 pl-0 pr-0" style="color: #999999;">{{ item.type==1 ? $t("定期") + item.amount + "(" + item.period_already_times + "/" + item.period_total_times + ')' : $t("单笔") + item.amount }}</div>
                  <div class="col-md-2 pl-0 pr-0" style="color: #999999;">{{ timeFilter(item.pay_time) }}</div>
                  <div class="col-md-2 pl-0 pr-0" style="color: #999999;">{{ item.pay_type == 1 ?  $t("信用卡") :  item.pay_type == 2 ? 'ATM' : item.pay_type == 3 ? 'LinePay' :  item.pay_type == 4 ? 'ApplePay' : item.pay_type == 5 ? $t("银行转账") : '' }}
                  </div>
                </div>
                <!-- 加载更多提示 -->
                <div class="d-flex justify-content-center">
                  <div v-if="jkjlinfoList.length == 0">
                    <empty></empty>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 手机版样式 -->
      <div v-if="tyugyu == 0" style="height: 79.4vh;overflow-y: auto;" class="parent pcnone" @scroll="handleScroll">
        <div class="pl-3 pt-3 pb-3 mb-3 baise yuanjiao col-md-12 pl-0 pr-3 d-flex justify-content-between asdfas">
          <div class="d-flex  pl-0 pr-0 align-items-center  pt-3 pb-3 ckqbb p_top" style="background: #FFFAF0;border-radius:12px;width: 49.4%;justify-content: space-between;position: relative;overflow: hidden;">
            <div class="d-flex align-items-center ml-3" style="z-index: 999;">
              <img class="mr-2 img" style="width: 50px;font-size: calc(9px + 0.4vw);font-weight: 500;height: 50px;" src="@/img/daohangl/pic_hssy_haorenbi@2x.png" alt="" />
              <div class="">
                <div style="font-size: calc(6px + 0.4vw);" class="sjbxzt">{{ $t("平台总发行好人币") }}</div>
                <div style="font-size: calc(15px + 0.4vw);font-weight: 700;">{{ user.
                  good_coin ? user.
                    good_coin.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }}</div>
              </div>
            </div>
            <div class="bgheight" style="width: 100%;position: absolute;bottom: 0;height: 60%;background-color: #FDDDC9;display: flex;justify-content: flex-end;">
              <img style="height: 100%;width: 70%;" src="@/img/pic_kapianzhuangshi@2x.png" alt="" />
            </div>
          </div>
          <div class="d-flex  pl-0 pr-0 align-items-center  pt-3 pb-3 ckqbb p_top" style="background: #FFFAF0;border-radius:12px;width: 49.4%;justify-content: space-between;position: relative;overflow: hidden;">
            <div class="d-flex align-items-center ml-3 " style="z-index: 999;">
              <img class="mr-2 img" style="width: 50px;font-size: calc(9px + 0.4vw);font-weight: 500;height: 50px;" src="@/img/daohangl/pic_hssy_tanbi@2x.png" alt="" />
              <div class="">
                <div style="font-size: calc(6px + 0.4vw);" class="sjbxzt">{{ $t("平台总发行碳币") }}</div>
                <div style="font-size: calc(15px + 0.4vw);font-weight: 700;">{{ user.
                  carbon_coin ? user.
                    carbon_coin.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }}</div>
              </div>
            </div>
            <div class="bgheight" style="width: 100%;position: absolute;bottom: 0;height: 60%;background-color: #FDDDC9;display: flex;justify-content: flex-end;">
              <img style="height: 100%;width: 70%;" src="@/img/pic_kapianzhuangshi@2x.png" alt="" />
            </div>
          </div>
        </div>
        <div class="">
          <div class="d-flex pl-0 pr-0 mb-3 " style="justify-content: space-between;">
            <b-form-datepicker v-model="cfsj" aria-controls="example-input" class="a1 pl-3 pr-2 yuanjiao pt-1 pb-1" :label-no-date-selected="$t('请选择日期')" style="width: 100%;margin-left: 3px;border-radius: 12px;" @context="onContext"></b-form-datepicker>
          </div>
          <div class="d-flex align-items-center" style="vertical-align: middle;justify-content: space-between;">
            <div class="d-flex">
              <div @click="hsjl()" class="pt-2 pb-2 baise pl-3 pr-3 curpo sjbzt" :style="{ 'background': xuanz==1 ? '' : '#FFFAF0' }" style="font-size: calc(7px + 0.4vw);border-radius: 12px 0 0 0;">{{ $t("好事记录") }}
              </div>
              <div @click="zgzm()" class="pt-2 pb-2 baise pl-3 pr-3 curpo sjbzt" :style="{ 'background': xuanz==2 ? '' : '#FFFAF0' }" style="font-size: calc(7px + 0.4vw);border-radius: 0 12px 0 0;">{{ $t("志工证明") }}
              </div>
              <div @click="jkjl()" class="pt-2 pb-2 baise pl-3 pr-3 curpo sjbzt" :style="{ 'background': xuanz==3 ? '' : '#FFFAF0' }" style="font-size: calc(7px + 0.4vw);border-radius: 12px 12px 0 0;">{{ $t("捐款记录") }}
              </div>
            </div>
          </div>

          <div v-if="xuanz==1" class="baise p-3 col-md-12 pl-0 pr-0" style="border-radius: 0 0 12px 12px;margin-top: -2px">
            <div>
              <div v-for="(item, index) in infoList" :key="index" class="col-md-12 pt-1 pb-1 pl-3 pr-3 yuanjiao d-flex flexcolumn  top justify-content-between align-items-center" :style="{ backgroundColor: index % 2 === 0 ? '#F6F4F0' : '#F6F4F0' }">
                <div class=" borders ellipsis  col-lg-1 col-xl-1 pl-0 pr-0 P_left ml-3 text-overflow-hover sjbzt" :title="item.name" @mouseenter="showFullText = true" @mouseleave="showFullText = false">{{ item.name ?
                    item.name : '--' }}</div>
                <div class=" d-flex top justify-content-between flexstart" style="width: 100%;">
                  <div style="width: 50%;">
                    <div class="sjbzt">
                      {{ $t("日期") }}
                    </div>
                    <div class="col-lg-1 col-xl-1 pl-0 pr-0 sjbxzt small_top" style="color: #999999;">
                      {{
                        timeFilter(item.create_time) }}</div>
                  </div>
                  <div style="width: 50%;">
                    <div class="sjbzt">
                      {{ $t("好人积分") }}
                    </div>
                    <div class=" col-lg-1 col-xl-1 pl-0 pr-0 d-flex sjbxzt small_top" style="align-items: center;">
                      <img class="mr-2" style="width: 20px;font-weight: 500;height: 20px;" src="@/img/daohangl/pic_hssy_haorenbi@2x.png" alt="" />
                      {{ item.good_coin > 0 ? '+' : '' }}
                      {{
                        item.good_coin }}
                    </div>
                  </div>
                </div>
                <div class="top">
                  <div class="sjbzt">
                    {{ $t("碳积分") }}
                  </div>
                  <div class="col-md-1 col-sm-1 col-lg-1 col-xl-1 pl-0 pr-0 d-flex sjbxzt small_top" style="align-items: center;">
                    <img class="mr-2" style="width: 20px;font-weight: 500;height: 20px;" src="@/img/daohangl/pic_hssy_tanbi@2x.png" alt="" />
                    {{ item.carbon_coin > 0 ? '+' : ''
                    }} {{
                      item.carbon_coin }}
                  </div>
                </div>
                <div class="top1">
                  <div class="sjbzt">
                    {{ $t("活动单位") }}
                  </div>
                  <div class="ellipsis col-lg-1 col-xl-1 pl-0 pr-0 sjbxzt small_top sjbxzt">{{
                    item.organization_name
                  }}</div>
                </div>
                <div class="top1">
                  <div class="sjbzt">
                    {{ $t("活动地点") }}
                  </div>
                  <div class="col-md-1 ellipsis col-sm-1 col-lg-1 col-xl-1 pl-0 pr-0 small_top sjbxzt">{{ item.address ?
                    item.address :
                    '--'
                    }}</div>
                </div>
                <div class="top1">
                  <div class="sjbzt">
                    {{ $t("活动花絮") }}
                  </div>
                  <img v-if="item.image" @click="tiaozhu(item.category, item)" class="col-md-1 col-sm-1 col-lg-1 col-xl-1 pl-0 pr-0  curpo small_top" style="height: 100px;object-fit: contain;" :src="item.image" alt="" />
                  <div v-else @click="tiaozhu(item.category, item)" class="col-md-1 col-sm-1 col-lg-1 col-xl-1 pl-0 pr-0  curpo small_top" style="height: 100px;"></div>
                </div>

              </div>
              <!-- 加载更多提示 -->
              <div class="d-flex justify-content-center">
                <div v-if="infoList.length == 0">
                  <empty></empty>
                </div>
              </div>
            </div>
          </div>
          <div v-if="xuanz==2" class="baise p-3 col-md-12 pl-0 pr-0" style="border-radius: 0 0 12px 12px;margin-top: -2px">
            <div class="parent">
              <div v-for="(item, index) in zgzminfoList" :key="index" class="col-md-12 pt-1 pb-1  pl-3 pr-3 yuanjiao d-flex top justify-content-between align-items-center flexcolumn" :style="{ backgroundColor: index % 2 === 0 ? '#F6F4F0' : '#F6F4F0' }">
                <div class="col-md-1 ellipsis sjbzt col-sm-1 col-lg-1 col-xl-1 pl-0 pr-0 ml-3 borders P_left text-overflow-hover" style="color: #999999;">{{ item.organization_name }}</div>
                <div class=" d-flex top justify-content-between flexstart" style="width: 100%;">

                  <div style="width: 50%;">
                    <div class="sjbzt">
                      {{ $t("参与单位统编") }}
                    </div>
                    <div class="col-md-1 col-sm-2 col-lg-1 col-xl-1 pl-0 pr-0 small_top sjbxzt " style="color: #999999;">{{
                        item.business_number }}
                    </div>
                  </div>
                  <div style="width: 50%;">
                    <div class="sjbzt">
                      {{ $t("参与时间") }}
                    </div>
                    <div class="col-md-1 col-sm-1 col-lg-1 col-xl-1 pl-0 pr-0 small_top sjbxzt" style="color: #999999;">
                      {{
                        item.join_year
                      }}-{{
                        item.join_month }}-{{ item.join_day }}</div>
                  </div>
                </div>
                <div class="top1">
                  <div class="sjbzt">
                    {{ $t("参与地点") }}
                  </div>
                  <div class="col-md-1 col-sm-1 col-lg-1 col-xl-1 pl-0 pr-0 small_top" style="color: #999999;">{{
                    item.address }}
                  </div>
                </div>
                <div class="top1">
                  <div class="sjbzt">
                    {{ $t("参与时长") }}
                  </div>
                  <div class="col-md-2 col-sm-2 col-lg-1 col-xl-1 pl-0 pr-0 small_top sjbxzt" style="color: #999999;">{{
                    item.time_length
                  }}
                  </div>
                </div>
                <div class="top1">
                  <div class="sjbzt">
                    {{ $t("志工证明照片") }}
                  </div>
                  <img v-if="item.certificate_image.length" class="small_top col-md-1 col-sm-1 col-lg-1 col-xl-1 pl-0 pr-0 curpo" style="height: 100px;object-fit: contain;" :src="item.activity_image[0]" alt="" />
                  <div v-if="!item.certificate_image.length" class=" small_top col-md-1 col-sm-1 col-lg-1 col-xl-1 pl-0 pr-0 mr-3 curpo small_top sjbxzt" style="height: 100px;"></div>
                </div>
                <div class="pl-0 pr-0 mr-3 mt-3 mb-2 curpo" style="width: 100%;">
                  <div @click="xiazzghdzp(item.certificate_image,item.certificate_pdf)" class="pt-2 pb-2 yuanjiao text-center" style="background: #FFD672;width: 60%;margin: auto;">{{ $t("下載") }}</div>
                </div>
              </div>
              <div class="d-flex justify-content-center">
                <div v-if="zgzminfoList.length == 0">
                  <empty></empty>
                </div>
              </div>
            </div>
          </div>
          <div v-if="xuanz==3" class="baise p-3 col-md-12 pl-0 pr-0" style="border-radius: 0 0 12px 12px;margin-top: -2px">
            <div class="d-flex align-items-center justify-content-between">
              <div @click="idqieh(0)" :style="{border: jinde=='0'? '2px solid #FAB1AB':''}" class="d-flex align-items-center justify-content-between curpo pl-3 pr-3 pt-2 pb-2 yuanjiao" style="background: #FEF7F6;">
                <img style="width: calc(25px + 0.4vw);height: calc(25px + 0.4vw);" src="@/img/pic_hssy_haorenbi@2x (1).png" alt="">
                <div class="ml-2 sjbxzt" style="font-size: calc(7px + 0.4vw);">
                  <div>{{ $t("捐款总金额") }}</div>
                  <div style="font-weight: 600;">{{ jkjlamount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</div>
                </div>
              </div>
            </div>
            <div class="items mt-3 aaaa mb-3" style="width: 100%;position: relative;">
              <div class="col-auto position-absolute pl-0 pr-0 dcbd curpo xsdann" style="z-index: 999;left: 0;top: 34%;">
                <img style="width: calc(12px + 0.5vw);" src="@/img/details/icon_jklb_xiangzuo@2x.png" alt="" />
              </div>
              <div class="d-flex align-items-center parent" style="width: 100%;overflow-x: auto;white-space: nowrap;">
                <div style="width: 100%;display: inline-block;">
                  <div @click="idqieh(item.donation_id)" :style="{border: jinde==item.donation_id? '2px solid #FAB1AB':''}" v-for="(item, index) in allItems" :key="index" class="mr-3 yuanjiao pt-3 pb-3 sjdsdsax" style="background: #FEF7F6;">
                    <div class="d-flex pl-3">
                      <img style="width: calc(25px + 0.4vw);height: calc(25px + 0.4vw);" src="@/img/pic_hssy_haorenbi@2x (2).png" alt="">
                      <div class="ml-2 sjbxzt" style="font-size: calc(7px + 0.4vw);">
                        <div class="ellipsis" style="width: calc(60px + 1.5vw);">{{ item.donation_name }}</div>
                        <div style="font-weight: 600;">{{ item.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-auto position-absolute pl-0 pr-0 dcbd curpo xsdann" style="z-index: 999;right: 0;bottom: 34%;">
                <img style="width: calc(12px + 0.5vw);" src="@/img/details/icon_jklb_xiangyou@2x.png" alt="" />
              </div>
            </div>
            <div v-for="(item, index) in jkjlinfoList" :key="index" class="col-md-12 pt-0 pb-1  pl-3 pr-3 yuanjiao d-flex top justify-content-between align-items-center flexcolumn" :style="{ backgroundColor: index % 2 === 0 ? '#F6F4F0' : '#F6F4F0' }">
              <div class="col-md-1 ellipsis sjbzt col-sm-1 col-lg-1 col-xl-1 pl-0 pr-0 ml-3 borders P_left text-overflow-hover" style="color: #999999;">{{ item.organization_name }}</div>
              <div class=" d-flex top justify-content-between flexstart" style="width: 100%;">

                <div style="width: 50%;">
                  <div class="sjbzt">
                    {{ $t("活动名称") }}
                  </div>
                  <div class="col-md-1 col-sm-2 col-lg-1 col-xl-1 pl-0 pr-0 small_top sjbxzt " style="color: #999999;">{{
                        item.donation_name }}
                  </div>
                </div>
                <div style="width: 50%;">
                  <div class="sjbzt">
                    {{ $t("团体名称") }}
                  </div>
                  <div class="col-md-1 col-sm-1 col-lg-1 col-xl-1 pl-0 pr-0 small_top sjbxzt" style="color: #999999;">
                    {{
                        item.organization_name
                      }}</div>
                </div>
              </div>
              <div class=" d-flex top justify-content-between flexstart" style="width: 100%;">

                <div style="width: 50%;">
                  <div class="sjbzt">
                    {{ $t("捐款金额") }}
                  </div>
                  <div class="col-md-1 col-sm-2 col-lg-1 col-xl-1 pl-0 pr-0 small_top sjbxzt " style="color: #999999;">{{item.type==1 ? $t("定期") + item.amount + "(" + item.period_already_times + "/" + item.period_total_times + ')' : $t("单笔") + item.amount }}
                  </div>
                </div>
                <div style="width: 50%;">
                  <div class="sjbzt">
                    {{ $t("参与时间") }}
                  </div>
                  <div class="col-md-1 col-sm-1 col-lg-1 col-xl-1 pl-0 pr-0 small_top sjbxzt" style="color: #999999;">{{timeFilter(item.pay_time)}}</div>
                </div>
              </div>

              <div class="top1">
                <div class="sjbzt">
                  {{ $t("付款方式") }}
                </div>
                <div class="col-md-2 col-sm-2 col-lg-1 col-xl-1 pl-0 pr-0 small_top sjbxzt" style="color: #999999;">{{
                    item.pay_type == 1 ?  $t("信用卡") :  item.pay_type == 2 ? 'ATM' : item.pay_type == 3 ? 'LinePay' :  item.pay_type == 4 ? 'ApplePay' : item.pay_type == 5 ? $t("银行转账") : ''
                  }}
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-center">
              <div v-if="jkjlinfoList.length == 0">
                <empty></empty>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ckxq v-if="tyugyu == 1" :ckidss="idss" :istype="isT" @custom='custom'></ckxq>
  </div>

</template>

<script>
import ckxq from "@/components/jkhd/wmuc/ckxq.vue";
import empty from "@/components/empty/empty.vue";
import { timeFilter, formatTimestamp } from "@/utils/common.js";

import {
  activ_Lis,
  merit_list,
  voluntee,
  merit_donate_base,
  merit_donate,
} from "@/request/aapi.js";
import SkeletonScreen from "./SkeletonScreen/hsgdbSkeletonScreen.vue";
export default {
  components: {
    SkeletonScreen,
    empty,
    ckxq,
  },
  data() {
    return {
      showButtons: false,
      fullText:
        "这是一个非常长的文本，当鼠标悬浮时，我希望能够展示全部的内容。",
      truncatedText: "这是一个非常长的文本...", // 这里放置截断后的文本
      showFullText: false, // 控制是否展示全部文本
      zgzminfoList: [],
      xuanz: "1",
      tyugyu: 0,
      wauto: "1",
      user: {},
      // visibleItems: [{id:1},{id:2},{id:3},{id:4},{id:5},{id:6},{id:7},{id:8}],
      allItems: [],
      isT: null,
      idss: "",
      jinde: "",
      dataLoaded: false,
      list: [{}, {}, {}, {}],
      nianDropdownOpen: false,
      youDropdownOpen: false,
      riDropdownOpen: false,
      nian: "",
      you: "",
      ri: "",
      // 获取币
      bicon: {},
      page: 1,
      pages: 1,
      year: null,
      month: null,
      day: null,

      infoList: [],
      // 当前显示的元素索引范围
      startIndex: 0,
      // 假设一次显示5个元素
      visibleCount: 4,
      isLoading: false, // 是否正在加载数据
      noMore: false, // 是否没有更多数据
      noMores: false,
      cfsj: "",
      last_page: "",
      last_pages: "",
      last_pagejkjl: "",
      pagejk: "",
      jkjlinfoList: [],
      jkjlamount: "",
    };
  },
  created() {},
  mounted() {
    if (localStorage.getItem("token")) {
      this.getmerit();
    } else {
      this.$router.push("/login");
    }
    if (this.$route.params.isT) {
      if (this.$route.params.id) {
        this.merit_donate_base();
        this.idqieh(this.$route.params.id);
      } else {
        this.jkjl();
      }
      return;
    }
    this.getCoin();
    // 模拟数据加载
  },
  computed: {
    // 计算当前应该显示的元素列表
    visibleItems() {
      return this.allItems.slice(
        this.startIndex,
        this.startIndex + this.visibleCount
      );
    },
  },
  methods: {
    xiazzghdzp(e,v) {
      console.log(e)
      let c = e;
      for (let i = 0; i < c.length; i++) {
        const x = new window.XMLHttpRequest();
        x.open("GET", c[i], true);
        x.responseType = "blob";
        x.onload = () => {
          const url = window.URL.createObjectURL(x.response);
          const a = document.createElement("a");
          a.href = url;
          a.download = "fileName";
          a.click();
        };
        x.send();
      }
      let m=v
      for (let i = 0; i < m.length; i++) {
        const x = new window.XMLHttpRequest();
        x.open("GET", m[i], true);
        x.responseType = "blob";
        x.onload = () => {
          const url = window.URL.createObjectURL(x.response);
          const a = document.createElement("a");
          a.href = url;
          a.download = "fileName";
          a.click();
        };
        x.send();
      }
    },
    wautobutt(e) {
      this.wauto = e;
    },
    // 切换显示元素的方法
    move(direction) {
      if (direction === -1 && this.startIndex > 0) {
        this.startIndex--;
      } else if (
        direction === 1 &&
        this.startIndex + this.visibleCount < this.allItems.length
      ) {
        this.startIndex++;
      }
    },
    hsjl() {
      this.page = 1;
      this.infoList = [];
      this.xuanz = 1;
      this.year = "";
      this.month = "";
      this.day = "";
      this.cfsj = "";
      this.getmerit();
    },
    zgzm() {
      this.pages = 1;
      this.zgzminfoList = [];
      this.xuanz = 2;
      this.year = "";
      this.month = "";
      this.day = "";
      this.cfsj = "";
      this.voluntee();
    },
    jkjl() {
      this.pagejk = 1;
      this.jkjlinfoList = [];
      this.xuanz = 3;
      this.year = "";
      this.month = "";
      this.cfsj = "";
      this.day = "";
      this.merit_donate_base();
      this.jkdfdf();
    },
    merit_donate_base() {
      merit_donate_base().then((res) => {
        if (res.code == 200) {
          this.jkjlamount = res.data.amount;
          this.allItems = res.data.list;
        }
      });
    },
    idqieh(e) {
      this.jinde = e;
      console.log(e, this.jinde);
      this.pagejk = 1;
      this.jkjlinfoList = [];
      this.xuanz = 3;
      this.year = "";
      this.month = "";
      this.cfsj = "";
      this.day = "";
      this.pagejk = 1;
      this.jkdfdf();
    },
    jkdfdf(e) {
      let data = {
        page: this.pagejk,
        year: this.year,
        month: this.month,
        day: this.day,
        donation_id: this.jinde,
      };
      merit_donate(data).then((res) => {
        if (res.code == 200) {
          this.dataLoaded = true;
          if (e == "xx") {
            this.last_pagejkjl = res.data.last_page;
            this.jkjlinfoList = res.data.data;
            return;
          }
          this.last_pagejkjl = res.data.last_page;
          this.jkjlinfoList = [...this.jkjlinfoList, ...res.data.data];
        }
      });
    },
    voluntee(e) {
      let data = {
        page: this.pages,
        year: this.year,
        month: this.month,
        day: this.day,
      };
      voluntee(data).then((res) => {
        if (res.code == 200) {
          if (e == "xx") {
            this.last_pages = res.data.last_page;
            this.zgzminfoList = res.data.data;
            return;
          }
          this.last_pages = res.data.last_page;
          this.zgzminfoList = [...this.zgzminfoList, ...res.data.data];
        }
      });
    },
    custom() {
      this.tyugyu = 0;
    },
    tiaozhu(e, v) {
      if (e == 4) {
        this.tyugyu = 1;
        this.isT = 1;
        this.idss = v;
      } else {
        console.log(v, "11");
        let a = v.identity == 2 ? "groups" : "enterprises";
        this.$router.push(
          "/activity/" + a + "/" + v.slug + "/" + v.activity_id + "/info"
        );
      }
    },
    getclear() {
      this.cfsj = "";
      this.year = "";
      this.month = "";
      this.day = "";
      this.page = 1;
      this.getmerit();
    },
    onContext(ctx) {
      if (new Date(ctx.selectedYMD).getTime()) {
        this.getDa(new Date(ctx.selectedYMD).getTime());
      }
    },

    // 返回年月日
    getDa(timestamp) {
      const date = new Date(timestamp);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();

      this.year = year;
      this.month = month;
      this.day = day;
      this.page = 1;
      this.pages = 1;
      if (this.xuanz == 1) {
        this.getmerit("xx");
      } else if (this.xuanz == 2) {
        this.voluntee("xx");
      } else if (this.xuanz == 3) {
        console.log(this.jinde);
        this.jinde = "";
        this.jkdfdf("xx");
      }
    },
    // 滚动
    handleScroll(event) {
      console.log(event);
      const target = event.target;
      const scrollDistance =
        target.scrollHeight - target.scrollTop - target.clientHeight;

      if (scrollDistance < 10) {
        if (this.xuanz == 1) {
          if (this.page < this.last_page) {
            this.page += 1;
            this.getmerit();
          }
        } else if (this.xuanz == 2) {
          if (this.pages < this.last_pages) {
            this.pages += 1;
            this.voluntee();
          }
        } else if (this.xuanz == 3) {
          if (this.pagejk < this.last_pagejkjl) {
            this.pagejk += 1;
            this.jkdfdf();
          }
        }
      }
    },
    // 时间转换
    timeFilter(stringTime) {
      return formatTimestamp(stringTime * 1000);
    },
    // 获取币
    getCoin() {
      let data = {
        is_coin: 1,
        is_user: 1,
      };
      activ_Lis(data).then((res) => {
        if (res.code == 200) {
          this.dataLoaded = true;
          this.bicon = res.data.coin;
          this.user = res.data.user;
        }
      });
    },
    getmerit(e) {
      let data = {
        page: this.page,
        year: this.year,
        month: this.month,
        day: this.day,
      };
      merit_list(data).then((res) => {
        console.log(res.data.data);
        if (res.code == 200) {
          if (e == "xx") {
            this.infoList = res.data.data;
            this.last_page = res.data.last_page;
            return;
          }
          this.infoList = [...this.infoList, ...res.data.data];
          this.last_page = res.data.last_page;
        }
      });
    },
    nianDropdown() {
      this.nianDropdownOpen = !this.nianDropdownOpen;
      // 如果需要，可以在这里添加Bootstrap Dropdown的额外处理逻辑
    },
    youDropdown() {
      this.youDropdownOpen = !this.youDropdownOpen;
      // 如果需要，可以在这里添加Bootstrap Dropdown的额外处理逻辑
    },
    riDropdown() {
      this.riDropdownOpen = !this.riDropdownOpen;
      // 如果需要，可以在这里添加Bootstrap Dropdown的额外处理逻辑
    },
    nianxuanz(e) {
      this.nian = e;
      this.nianDropdownOpen = false;
    },
    youxuanz(e) {
      this.you = e;
      this.youDropdownOpen = false;
    },
    rixuanz(e) {
      this.ri = e;
      this.riDropdownOpen = false;
    },
  },
};
</script>

<style scoped>
@media screen and (max-width: 991px) {
  .asdfas {
    margin-top: 1rem !important;
  }
  .aaaa {
    display: block !important;
  }
  .yinc {
    display: none !important;
  }
  .sjdsdsax {
    position: relative;
    background: #ffffff;
    text-align: center;
    width: 40% !important;
    display: inline-block;
  }
  ::v-deep .b-form-btn-label-control.form-control > .btn {
    padding: 0px;
  }

  ::v-deep .form-control {
    border: none !important;
  }
  .none {
    display: none;
  }

  .margin_r {
    margin: 10px;
  }

  .flexstart {
    align-items: flex-start;
  }

  .flexcolumn {
    flex-direction: column;
    align-items: flex-start !important;
  }

  .small_top {
    margin-top: 5px;
  }

  .top {
    margin-top: 10px;
  }

  .top1 {
    margin-top: 15px;
  }

  .pcnone {
    display: block !important;
  }

  .p_top {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .img {
    width: calc(25px + 0.5vw) !important;
    height: calc(25px + 0.5vw) !important;
  }

  .bgheight {
    height: 50% !important;
  }

  .sjbzt {
    font-weight: bolder;
    font-size: calc(11px + 0.4vw) !important;
  }

  .borders {
    width: 100% !important;
    border-bottom: 1px solid #999999;
  }

  .P_left {
    margin-left: 0px !important;
  }

  .sjbxzt {
    font-size: calc(9px + 0.4vw) !important;
  }
}

.pcnone {
  display: none;
}
.aaaa {
  display: none;
}
.text-overflow-hover {
  width: 200px;
  /* 设置一个固定的宽度以触发文本截断 */
  padding: 10px;
  /* 可选的内边距 */
  cursor: pointer;
  /* 鼠标悬浮时显示为手指形状 */
  transition: all 0.2s ease;
  /* 添加过渡效果 */
}

.a1 {
  background-color: #ffebd2;
  border: none;
  font-size: 12px;
  color: #000;
  padding: 5px;
  border-radius: 12px 12px 0 0;
}

.dcbd:hover {
  transform: scale(1.2);
}

.xlcd {
  display: block;
  background: #ffebd2;
  border: none;
}

.dydpd {
  padding-top: calc(4px + 0.4vw);
  padding-bottom: calc(4px + 0.4vw);
  padding-left: calc(10px + 0.5vw);
  padding-right: calc(10px + 0.5vw);
}

.zhankai {
  background: #ffebd2;
  text-align: center;
  border-radius: 12px 12px 0 0;
  border: none;
  font-weight: 400;
  font-size: calc(6px + 0.4vw);
  color: #1a1a1a;
}

.dropdown-item:hover {
  background-color: #ffebd2;
  color: #b66d00;
  border-radius: 12px;
}

/* 隐藏垂直滚动条 */
.parent::-webkit-scrollbar {
  display: none;
}
</style>