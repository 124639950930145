import request from '../index.js'

// 我的个人中心
export const region_list = (params) => request.get("/region/list", {params});
// 组织机构信息
export const organization_info = (params) => request.get("/user/organization_info", {params});
// 组织机构个人信息保存
export const organization_step1_save = (params) => request.post("/user/organization_step1_save", params);
// 组织机构企业信息保存
export const organization_step2_save = (params) => request.post("/user/organization_step2_save", params);
// 组织机构企业信息保存
export const user_save = (params) => request.post("/user/save", params);
// 组织机构信息
export const user_info = (params) => request.get("/user/info", {params});



