<template>
  <div>
    <!-- 骨架屏 -->
    <skeleton-screen v-show="!dataLoaded"></skeleton-screen>
    <xzcydhd @custom="custom" v-show="show==1"></xzcydhd>
    <bdlogin ref="bdlogin" v-show="show==2"></bdlogin>
  </div>
</template>
  
  <script>
import SkeletonScreen from "@/views/index/SkeletonScreen/groupSkeletonScreen.vue";
import xzcydhd from "@/components/hdl/xzcydhd.vue";
import bdlogin from "@/components/hdl/bdlogin.vue";
import {} from "@/request/api.js";
import { timeFilter, formatTimestamp } from "@/utils/common.js";
export default {
  components: {
    SkeletonScreen,
    xzcydhd,
    bdlogin
  },
  data() {
    return {
      dataLoaded: true,
      show: 1,
    };
  },
  mounted() {
    // 模拟数据加载
  },
  methods: {
    custom(e){
      console.log(e)
       this.show=e.name;
       this.$refs.bdlogin.msg(e.activeid)
    }
  },
};
</script>
<style scoped lang="scss">

</style>