<template>
  <div>
    <div v-show="show == 0">
      <!-- 骨架屏 -->
      <skeleton-screen v-show="!dataLoaded"></skeleton-screen>
      <div v-if="dataLoaded" class="col-md-12 col-sm-12 col-lg-12 col-xl-12 d-flex pl-0 pr-0 ml-0 justify-content-between sjtop">
        <div class="parent heggs" style="height: 89.5vh;overflow-y: auto;width: 100%;" @scroll="handleScroll">
          <!-- 上半部分 -->
          <div class="col-sm-12 baise yuanjiao pl-0 pr-0">
            <!-- <img v-if="bannerLis.length == 0" style="width: 100%;" src="" alt="" /> -->

            <b-carousel id="carousel-fade" v-if="bannerLis.length > 0" style="text-shadow: 0px 0px 2px #000;height: 400px;border-radius: 12px 12px 0 0;" fade indicators img-width="1024" img-height="100" class="imase  sjbgaodu yinc">
              <b-carousel-slide v-for="(item, index) in bannerLis" :key="index" caption="" :img-src="item"></b-carousel-slide>
            </b-carousel>
            <b-carousel id="carousel-fade" v-if="bannerLis.length > 0" style="text-shadow: 0px 0px 2px #000;border-radius: 12px 12px 0 0;object-fit: cover;" fade indicators img-width="1024" img-height="100" class="aaaa">
              <b-carousel-slide v-for="(item, index) in bannerLis" :key="index" caption="" :img-src="item"></b-carousel-slide>
            </b-carousel>

            <div class="p-4 sjiduand1">
              <div class="col-sm-12 pl-0 pr-0 d-flex justify-content-between">
                <div class="col-sm-12 col-lg-8 col-xl-9 pl-0 pr-0">
                  <!-- pc -->
                  <div class="d-flex justify-content-between pl-0 pr-0 col-sm-12 mb-3 yinc">
                    <div class="col-sm-8 col-md-8 col-lg-7 col-xl-9 pl-0 pr-0">
                      <div class="mb-3" style="font-size: calc(12px + 0.4vw);font-weight: 700;">
                        {{ detailInfo.name }}
                      </div>
                      <span class="mt-2 mb-2 pl-3 pr-3 pt-2 pb-2" style="font-size: calc(7px + 0.4vw);background-color: #FFFAF0;">
                        {{ detailInfo.category_name }}
                      </span>
                    </div>
                    <div class="d-flex col-sm-4 mt-2 col-md-4 col-lg-5 col-xl-4 pl-0 pr-0">
                      <div class="jhsad col-sm-4 mr-2">
                        <div>
                          <div style="font-size: calc(8px + 0.4vw);font-weight: 700;">
                            {{ detailInfo.moment_num ? detailInfo.moment_num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }}
                          </div>
                          <div style="font-size: calc(7px + 0.4vw);">{{ $t("贴文") }}</div>
                        </div>
                      </div>
                      <div class="jhsad col-sm-4 mr-2" style="background: #FDDDC9;">
                        <div>
                          <div style="font-size: calc(8px + 0.4vw);font-weight: 700;">
                            {{ detailInfo.member_num ? detailInfo.member_num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }}
                          </div>
                          <div style="font-size: calc(7px + 0.4vw);">{{ $t("成员") }}</div>
                        </div>
                      </div>
                      <div class="jhsad col-sm-4" style="background: #FFEBD2;">
                        <div>
                          <div style="font-size: calc(8px + 0.4vw);font-weight: 700;">
                            {{ detailInfo.volunteer_num ? detailInfo.volunteer_num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }}
                          </div>
                          <div style="font-size: calc(7px + 0.4vw);">{{ $t("志工") }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class=" mb-2 aaaa" style="width: 100%;">
                    <div class="d-flex justify-content-between" style="width: 100%;">
                      <div style="width: 40%;">
                        <div class="mb-2 sjtoto" style="font-size: calc(12px + 0.4vw);font-weight: 700;">
                          {{ detailInfo.name }}
                        </div>

                      </div>
                      <div class="d-flex">
                        <div class="jhsad pl-3 pr-3 mr-2">
                          <div>
                            <div style="font-size: calc(8px + 0.4vw);font-weight: 700;">
                              {{ detailInfo.moment_num ? detailInfo.moment_num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }}
                            </div>
                            <div style="font-size: calc(7px + 0.4vw);">{{ $t("贴文") }}</div>
                          </div>
                        </div>
                        <div class="jhsad pl-3 pr-3 mr-2" style="background: #FDDDC9;">
                          <div>
                            <div style="font-size: calc(8px + 0.4vw);font-weight: 700;">
                              {{ detailInfo.member_num ? detailInfo.member_num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }}
                            </div>
                            <div style="font-size: calc(7px + 0.4vw);">{{ $t("成员") }}</div>
                          </div>
                        </div>
                        <div class="jhsad pl-3 pr-3" style="background: #FFEBD2;">
                          <div>
                            <div style="font-size: calc(8px + 0.4vw);font-weight: 700;">
                              {{ detailInfo.volunteer_num ? detailInfo.volunteer_num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }}
                            </div>
                            <div style="font-size: calc(7px + 0.4vw);">{{ $t("志工") }}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="aaaa"> <span class=" mb-2 pl-3 pr-3 pt-2 pb-2 sjbxzt" style="font-size: calc(7px + 0.4vw);background-color: #FFFAF0;">
                      {{ detailInfo.category_name }}
                    </span></div>
                  <div style="height: 1rem;width: 100%;"></div>

                  <!-- 手机 -->
                  <div class="sjbxzt" style="color: #666666;font-size: calc(6px + 0.4vw);">
                    {{ detailInfo.introduction }}
                  </div>
                  <div class="d-flex justify-content-between mt-3">
                    <button @click="bulletframe()" class="mr-3 pl-4 pr-4 pt-3 pb-3 yuanjiao curpo ckqbb mt-3 mb-3 sjbzt" style="font-size: calc(7px + 0.4vw);background: #FFD672;border: none;" v-if="detailInfo.is_member == 0">
                      {{ $t("加入团体") }}
                    </button>
                    <button @click="backrame()" class="mr-3 pl-4 pr-4 pt-3 pb-3 yuanjiao curpo ckqbb mt-3 mb-3 sjbzt" style="font-size: calc(7px + 0.4vw);background: #F6F4F0;border: none;" v-if="detailInfo.is_member == 1">
                      {{ $t("离开团体") }}
                    </button>
                    <!-- <button @click='show=1' class="pl-4 pr-4 pt-3 pb-3 yuanjiao curpo ckqbb mt-4"
												style="font-size: calc(6px + 0.4vw);background: #FAB1AB;border: none;">
												{{ $t("关联组织") }}
											</button> -->
                    <div class="aaaa pl-0 pr-0">
                      <img style="width: 100px;object-fit: cover;height: 100%;" class="yuanjiao" v-if="detailInfo.logo" :src="detailInfo.logo" alt="" />
                      <img style="width: 100%;" v-else src="" alt="" />
                    </div>
                  </div>

                </div>
                <div class="col-md-4 col-lg-3 col-xl-2 tpiand pl-0 pr-0">
                  <img style="width: 100%;height: 200px;object-fit: cover;" class="yuanjiao" :src="detailInfo.logo || ''" alt="" />
                </div>
              </div>
            </div>
          </div>
          <!-- 中间部分 -->
          <div class="col-sm-12 pl-0 pr-0 items mt-3 yinc" @mouseenter="showButtons = true" @mouseleave="showButtons = false">
            <div class="col-sm-12 pl-0 pr-0 position-relative d-flex justify-content-between align-items-center">
              <div v-show="showButtons" class="col-auto position-absolute pl-0 pr-0 dcbd curpo xsdann" style="z-index: 999;left: 0;" @click="move(-1)">
                <img style="width: calc(12px + 0.5vw);" src="@/img/details/icon_jklb_xiangzuo@2x.png" alt="" />
              </div>
              <div class="col-sm-12 d-flex pl-0 pr-0 d-flex justify-content-between">
                <div @click="wautobutt(item.id)" class=" ckqbb  curpo yuanjiao pt-3 pb-3 position-relative" style="background: #FFFFFF;text-align: center;width: 19%;" v-for="(item, index) in visibleItems" :key="index">
                  <!-- 假设每个item是一个对象，包含图标和文字 -->
                  <img class="mb-1" style="width: calc(13px + 0.5vw);" :src="item.icon" alt="" />
                  <div style="text-align: center;width: 100%;font-size: calc(8px + 0.4vw);">
                    {{ item.text }}
                  </div>
                  <div v-show="item.id == wauto" class="position-absolute" style="height: 2px;width: 74%;bottom: 0;background: #FFD672;left: 13%;"></div>
                </div>
              </div>
              <div v-show="showButtons" class="col-auto position-absolute pl-0 pr-0 dcbd curpo xsdann" style="z-index: 999;right: 0;" @click="move(1)">
                <img style="width: calc(12px + 0.5vw);" src="@/img/details/icon_jklb_xiangyou@2x.png" alt="" />
              </div>
            </div>
          </div>
          <div class="items mt-3 aaaa" style="width: 100%;position: relative;">
            <div class="col-auto position-absolute pl-0 pr-0 dcbd curpo xsdann" style="z-index: 999;left: 0;top: 34%;">
              <img style="width: calc(12px + 0.5vw);" src="@/img/details/icon_jklb_xiangzuo@2x.png" alt="" />
            </div>
            <div class="d-flex align-items-center parent" style="width: 100%;overflow-x: auto;white-space: nowrap;">

              <div style="width: 100%;display: inline-block;">
                <div @click="wautobutt(item.id)" class=" mr-3  yuanjiao pt-3 pb-3 sjdsdsax" v-for="(item, index) in allItems" :key="index">
                  <!-- 假设每个item是一个对象，包含图标和文字 -->
                  <img class="mb-1" style="width: calc(16px + 0.5vw);" :src="item.icon" alt="" />
                  <div style="text-align: center;width: 100%;font-size: calc(10px + 0.4vw);">
                    {{ item.text }}
                  </div>
                  <div v-show="item.id == wauto" class="position-absolute" style="height: 2px;width: 74%;bottom: 0;background: #FFD672;left: 13%;"></div>
                </div>
              </div>

            </div>
            <div class="col-auto position-absolute pl-0 pr-0 dcbd curpo xsdann" style="z-index: 999;right: 0;bottom: 34%;">
              <img style="width: calc(12px + 0.5vw);" src="@/img/details/icon_jklb_xiangyou@2x.png" alt="" />
            </div>
          </div>
          <!-- 切换的下半部分  拼音 -->
          <router-view />
          <!-- <wzzc :isinfozao="infozao" :isids="id" v-if="wauto == 8"></wzzc> -->

        </div>
        <!-- 加入团体弹框 -->
        <b-modal v-model="modalShow" modal-class="custom-modal-width" :no-close-on-backdrop='isLoading' :no-close-on-esc="isLoading" :centered='true' hide-header hide-footer>
          <div style="width: 100%;text-align: center;" class="pb-2 position-relative">
            <img @click="modalShow = false" class="position-absolute curpo dcbd" style="width: calc(15px + 0.5vw);right: 20px;top: -20px;" src="@/img/details/cha.png" alt="" />
            <div class="mb-3 mt-4 sjbzt" style="font-size: calc(9px + 0.4vw);font-weight: 700;">{{ $t('确认加入该团体吗')
							}}</div>
            <div class="p-3 yuanjiao" style="width: 90%;margin: auto;background: #F6F4F0;">
              <div class="sjbxzt" style="font-size: calc(5px + 0.4vw);">
                {{ detailInfo.name }}
              </div>
            </div>
            <div style="height: calc(10px + 1vw);"></div>
            <button :disabled="isLoading" @click="qrbulletframe()" class="pl-5 sjbzt pr-5 pt-3 pb-3 yuanjiao ckqbb curpo annys" style="font-size: calc(6px + 0.4vw);background: #FFD672;border: none;">
              {{ $t('加入团体') }}
              <span v-if="isLoading" class="spinner-border spinner-border-sm ml-2" role="status" aria-hidden="true">
              </span>
            </button>
          </div>
        </b-modal>
      </div>
    </div>
    <glzz v-if="show == 1" @custom="custom"></glzz>

  </div>
</template>

<script>
import SkeletonScreen from "./SkeletonScreen/groupSkeletonScreen.vue";
// import wzzc from "@/components/haosttd/wzzc.vue";
import { EventBus } from "@/views/event-bus.js";
import glzz from "./glzz.vue";
import { team_grounp } from "@/request/aapi.js";
import {
  group_detail,
  group_team,
  group_join,
  group_leave,
} from "@/request/api.js";
export default {
  components: {
    SkeletonScreen,
    // esgphb,
    glzz,
    // wzzc,
  },
  data() {
    return {
      show: 0,
      wauto: 1,
      showButtons: false,
      // 假设这是你的所有元素列表
      allItems: [
        {
          id: 1,
          icon: require("@/img/details/icon_ttxq_tuyaqiang@2x.png"),
          value: "moment",
          text: this.$t("涂鸦墙"),
        },
        {
          id: 10,
          icon: require("@/img/details/icon_ttxq_aixinqiye@2x.png"),
          value: "caring-enterprise",
          text: this.$t("爱心企业"),
        },
        {
          id: 2,
          value: "donation",
          icon: require("@/img/details/icon_ttxq_juankuan@2x.png"),
          text: this.$t("捐款"),
        },
        {
          id: 9,
          value: "donate-supplies",
          icon: require("@/img/details/icon_juanwu@1x.png"),
          text: this.$t("捐物"),
        },
        {
          id: 3,
          value: "delivery",
          icon: require("@/img/details/icon_ttxq_yuyuegongyun_hui@2x.png"),
          text: this.$t("预约共运"),
        },
        {
          value: "activity",
          id: 4,
          icon: require("@/img/details/icon_ttxq_jitihuodong@2x.png"),
          text: this.$t("团体活动"),
        },
        {
          id: 5,
          icon: require("@/img/details/icon_ttxq_guanyuwomen@2x.png"),
          value: "about",
          text: this.$t("关于我们"),
        },
        {
          id: 11,
          icon: require("@/img/icon_yxljh_hei@1x.png"),
          value: "impact-plan",
          text: this.$t("影響力計畫"),
        },
        {
          id: 6,
          icon: require("@/img/details/icon_ttxq_tuantichengyuan@2x.png"),
          value: "member",
          text: this.$t("团体成员"),
        },

        // 假设还有更多元素

        {
          id: 7,
          icon: require("@/img/details/icon_rank@2x.png"),
          text: this.$t("ESG排行榜"),
          value: "ranking",
        },
        {
          id: 8,
          icon: require("@/img/details/icon_ttxq_wuzizaoce@2x.png"),
          value: "material",
          text: this.$t("物资造册"),
        },
      ],
      // 当前显示的元素索引范围
      startIndex: 0,
      // 假设一次显示5个元素
      visibleCount: 5,
      dataLoaded: false,
      modalShow: false,
      isLoading: false,
      detailInfo: {},
      id: 0,
      aboutUs: {},
      chengList: [],
      guanliList: [],
      bannerLis: [],
      infozao: {},
    };
  },
  created() {
    EventBus.$on("call-sibling-b-methods", (msg) => {
      // 执行方法，处理接收到的数据
      // this.message = msg;
      // 如果需要，可以在这里调用其他方法
      this.id = this.$route.params.slug;
      this.getDetail(this.id);
    });
  },
  mounted() {
    this.id = this.$route.params.slug;
    this.getDetail(this.id);
    if (this.$route.path.includes("/donation")) {
      this.wauto = 2;
    } else if (this.$route.path.includes("/the_wall")) {
      this.wauto = 1;
    } else if (this.$route.path.includes("/share-car")) {
      this.wauto = 3;
    } else if (this.$route.path.includes("/activity")) {
      this.wauto = 4;
    } else if (this.$route.path.includes("/about-us")) {
      this.wauto = 5;
    } else if (this.$route.path.includes("/members")) {
      this.wauto = 6;
    } else if (this.$route.path.includes("/rank")) {
      this.wauto = 7;
    } else if (this.$route.path.includes("/apply-supplies")) {
      this.wauto = 8;
    } else if (this.$route.path.includes("/donate-supplies")) {
      this.wauto = 9;
    } else if (this.$route.path.includes("/caring-enterprise")) {
      this.wauto = 10;
    }else if (this.$route.path.includes("/impact-plan")) {
      this.wauto = 11;
    }else {
      setTimeout(() => {
        this.wautobutt(1)
      }, 500);
    }

    // 模拟数据加载
  },
  computed: {
    // 计算当前应该显示的元素列表
    visibleItems() {
      return this.allItems.slice(
        this.startIndex,
        this.startIndex + this.visibleCount
      );
    },
  },
  beforeDestroy() {
    // 组件销毁前移除监听器，防止内存泄漏
    EventBus.$off("call-sibling-b-methods");
  },
  methods: {
    // 触发分页
    handleScroll(event) {
      const target = event.target;
      const scrollDistance =
        target.scrollHeight - target.scrollTop - target.clientHeight;
      if (scrollDistance < 10) {
        console.log(22222);
        // 当滚动条距离底部小于10px时，认为是滚动到底部
        if (this.$route.path.includes("/the_wall")) {
          EventBus.$emit("hsttthe_wall", "tthd");
        } else if (this.$route.path.includes("/donation")) {
          EventBus.$emit("hsttdonation", "tthd");
        } else if (this.$route.path.includes("/share-car")) {
          EventBus.$emit("hsttshare-car", "tthd");
        } else if (this.$route.path.includes("/activity")) {
          EventBus.$emit("hsttactivity", "tthd");
        } else if (this.$route.path.includes("/members")) {
          EventBus.$emit("hsttmembers", "tthd");
        } else if (this.$route.path.includes("/about-us")) {
        } else if (this.$route.path.includes("/rank")) {
        } else if (this.$route.path.includes("/apply-supplies")) {
        } else if (this.$route.path.includes("/donate-supplies")) {
        }else if (this.$route.path.includes("/caring-enterprise")) {
          EventBus.$emit("hsttenterprice", "tthd");
        }else if (this.$route.path.includes("/impact-plan")) {
          EventBus.$emit("hsttimpactplan", "tthd");
        }else{
          EventBus.$emit("hsttthe_wall", "tthd");
        }
      }
    },
    tiewenshul(e) {
      console.log(e);
      if (e == 1) {
        this.detailInfo.moment_num++;
      } else {
        this.detailInfo.moment_num--;
      }
    },
    // 获取团体详情
    getDetail(id) {
      group_detail({
        slug: id,
      }).then((res) => {
        if (res.code == 200) {
          this.dataLoaded = true;
        }
        // console.log("团体详情", res);
        this.detailInfo = res.data.info;
        this.bannerLis = res.data.info.banner;

        let menu = res.data.info.menu;
        // 筛选显示目录
        let filteredArray = this.allItems.filter((obj) =>
          menu.includes(obj.value)
        );
        this.allItems = filteredArray;
        setTimeout(() => {
          EventBus.$emit("ttchuanrusffb",this.detailInfo.is_member);
        }, 100);
        // this.wautobutt(1)
      });
    },

    // 获取团队成员
    getGroup_team(account) {
      group_team({
        id: this.id,
        is_admin: -1,
        account: account,
      }).then((res) => {
        console.log(res, "团队成员");
        this.chengList = res.data.data;
      });
    },

    // 获取团队管理员
    getGroup_team_guanli() {
      group_team({
        id: this.id,
        is_admin: 1,
      }).then((res) => {
        // console.log(res, "团队管理员");
        this.guanliList = res.data.data;
      });
    },

    custom() {
      this.show = 0;
    },
    wautobutt(e) {
      this.wauto = e;
      if (e == 2) {
        this.$router.push({
          name: "ttdonation",
          params: {
            id: this.detailInfo.id,
          },
        });
      } else if (e == 1) {
        this.$router.push({
          name: "tttuyaqiang",
          params: {
            id: this.detailInfo.id,
            is_member: this.detailInfo.is_member,
          },
        });
      } else if (e == 4) {
        this.$router.push({
          name: "tuabduihd",
          params: {
            id: this.detailInfo.id,
          },
        });
      } else if (e == 3) {
        this.$router.push({ name: "yuygy" });
      } else if (e == 5) {
        this.$router.push({
          name: "guanywm",
          params: {
            id: this.detailInfo.id,
          },
        });
      } else if (e == 6) {
        this.$router.push({
          name: "tuanduicy",
          params: {
            id: this.detailInfo.id,
          },
        });
      } else if (e == 7) {
        this.$router.push({
          name: "esgphb",
          params: {
            id: this.detailInfo.id,
          },
        });
      } else if (e == 8) {
        this.$router.push({
          name: "wzzc",
          params: {
            id: this.detailInfo.id,
          },
        });
      } else if (e == 9) {
        this.$router.push({
          name: "supplies",
          params: {
            id: this.detailInfo.id,
          },
        });
      }else if (e == 10) {
        this.$router.push({
          name: "caringEnterprise",
          params: {
            id: this.detailInfo.id,
          },
        });
      }else if (e == 11) {
        this.$router.push({
          name: "impactplan",
          params: {
            id: this.detailInfo.id,
          },
        });
      }

      // } else if (e == 8) {

      // }
    },
    // 切换显示元素的方法
    move(direction) {
      if (direction === -1 && this.startIndex > 0) {
        this.startIndex--;
      } else if (
        direction === 1 &&
        this.startIndex + this.visibleCount < this.allItems.length
      ) {
        this.startIndex++;
      }
    },
    // 点击确认加入团体
    qrbulletframe() {
      this.isLoading = true;
      this.toGroup_join(this.detailInfo.id);
    },
    // 点击加入团体
    bulletframe() {
      this.modalShow = true;
    },
    // 加入团体
    toGroup_join(id) {
      group_join({
        id: id,
      })
        .then((res) => {
          if (res.code == 200) {
            this.$bvToast.toast(this.$t("加入成功"), {
                title: this.$t("login.notice"),
                autoHideDelay: 2000,
                delay: 5000,
                appendToast: true,
                variant: "success",
              });
            setTimeout(() => {
              this.isLoading = false;
              this.modalShow = false;
              this.getDetail(this.id);
            }, 100);
          }
        })
        .catch(() => {});
    },
    // 退出团队
    backrame() {
      this.backGroup_leave();
    },
    // 退出团队接口
    backGroup_leave() {
      group_leave({
        id: this.detailInfo.id,
      }).then((res) => {
        // console.log(res, "离开团队");
        this.getDetail(this.id);
      });
    },
  },
};
</script>
<style scoped lang="scss">
@media screen and (max-width: 751px) {
}
@media screen and (max-width: 991px) {
  .heggs {
    height: 79vh !important;
  }
  .sjdsdsax {
    position: relative;
    background: #ffffff;
    text-align: center;
    width: 40% !important;
    display: inline-block;
  }
  .tpiand {
    display: none;
  }
  .sjtop {
    margin-top: 1rem !important;
  }
  .sjbgaodu {
    height: 200px !important;
    overflow: hidden !important;
  }
  .sjbzt {
    font-size: calc(11px + 0.4vw) !important;
  }
  .sjtoto {
    font-size: calc(13px + 0.4vw) !important;
  }
  .sjbxzt {
    font-size: calc(9px + 0.4vw) !important;
  }
  .aaaa {
    display: block !important;
  }
  .yinc {
    display: none !important;
  }
  .sjiduand1 {
    padding: 12px !important;
  }
}
.aaaa {
  display: none;
}
.ckqbb:hover {
  transform: scale(1.001);
  /* 悬浮时放大1.1倍 */
  // padding: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.dcbd:hover {
  transform: scale(1.2);
  /* 悬浮时放大1.1倍 */
  // padding: 12px;
}
.sjdsdsax {
  position: relative;
  background: #ffffff;
  text-align: center;
  width: 30%;
  display: inline-block;
}
.item {
  width: 30%;
  background-color: red;
}

.jhsad {
  text-align: center;
  background: #fcd7d5;
  border-radius: 12px;
  height: calc(50px + 0.4vw);
  display: flex;
  justify-content: center;
  align-items: center;
}

.parent::-webkit-scrollbar {
  display: none;
}

.imase {
  ::v-deep .img-fluid {
    height: 408px !important;
    object-fit: cover !important;
  }
}
</style>