<template>
  <div>
    <div class="">
      <div class="yuanjiao baise pl-3 pr-3 pt-3 mb-3 pb-3 position-relative sjbyj lieb heggs" style="height: 87.5vh;overflow-y: auto">
        <div class="text-center col-sm-8 col-lg-9 col-xl-6 pl-0 pr-0" style="margin: auto;background: #FFFAF0;">
          <div class="yuanjiao p-3 sjbdkds" style="width: 65%;margin: auto;">
            <img class="sjbdtp" style="width: calc(200px + 0.4vw);height: 80px;" src="@/img/icon_phone_logo@1x.png" alt="">
            <div class="mt-3 swkd sjbzt">臻信祥食物银行影響力計劃</div>
            <div class="mt-3 sjbdkd sjbxzt" style="font-size: 12px;color: #666666;display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 2;overflow: hidden;text-overflow: ellipsis;width: 60%;margin: auto;">
              此處為計劃概述，此處為計劃概述
              此處為計劃概述</div>
            <div class="pt-2 pb-2 mt-3 ckhdxq sjbxzt yuanjiao curpo">{{ $t("查看活動詳情") }}</div>
          </div>
        </div>
        <div class="text-center mt-4 mb-3 sjbzt" style="font-size: calc(13px + 0.4vw);font-weight: 600;">{{ $t("影響力问卷调查") }}</div>
        <div class="col-sm-8 col-lg-9 col-xl-6 pl-0 pr-0" style="margin: auto;">
          <div class="sjbzt" style="font-size: calc(9px + 0.4vw);">
            <div class="mb-3" v-for="(item,index) in list" :key="index">
              <div v-if="item.type=='radio'">
                <div class="mb-2">{{ index + 1 }}.<span style="color: #FF797A;">{{ $t("[單選]") }}</span>{{ item.title }}</div>
                <div @click="danxuan(items.value,index)" v-for="(items,indexs) in item.option" :key="indexs" class="d-flex justify-content-between align-items-center curpo pl-3 pr-3 pt-2 pb-2 mb-2" :style="{background: form[index].answer==items.value?'#FFFAF0':'#F6F4F0',border: form[index].answer==items.value?'1px solid #FFD672':''}" style="border-radius: 5px">
                  <div class="sjbxzt">{{ items.name }}</div>
                  <div v-if="form[index].answer==items.value"><img style="width: 16px;height: 16px;" src="@/img/duihhujioj.png" alt=""></div>
                </div>
              </div>
              <div v-if="item.type=='checkbox'">
                <div class="mb-2">{{ index + 1 }}.<span style="color: #FF797A;">{{ $t("[多選]") }}</span>{{ item.title }}</div>
                <div @click="duoxuan(items.value,index)" v-for="(items,indexs) in item.option" :key="indexs" class="d-flex justify-content-between align-items-center curpo pl-3 pr-3 pt-2 pb-2 mb-2" :style="{background: form[index].answer.includes(items.value)?'#FFFAF0':'#F6F4F0',border: form[index].answer.includes(items.value)?'1px solid #FFD672':''}" style="border-radius: 5px">
                  <div class="sjbxzt">{{ items.name }}</div>
                  <div v-if="form[index].answer.includes(items.value)"><img style="width: 16px;height: 16px;" src="@/img/duihhujioj.png" alt=""></div>
                </div>
              </div>
              <div v-if="item.type=='input'">
                <div class="mb-2">{{ index + 1 }}.<span style="color: #FF797A;">{{ $t("[簡答]") }}</span>{{ item.title }}</div>
                <div class="d-flex justify-content-between align-items-center yuanjiao curpo">
                  <b-form-input style="font-size: calc(8px + 0.5vw);" v-model="form[index].answer" type="text" class="form-control srkddx yuanjiao sjbxzt" :placeholder="$t('請輸入')">
                  </b-form-input>
                </div>
              </div>
              <div v-if="item.type=='textarea'">
                <div class="mb-2">{{ index + 1 }}.<span style="color: #FF797A;">{{ $t("[詳答]") }}</span>{{ item.title }}</div>
                <div class="d-flex justify-content-between align-items-center yuanjiao curpo">
                  <b-form-textarea class="form-control srkddx yuanjiao sjbxzt" style="font-size: calc(8px + 0.5vw);height:100px;" id="textarea" v-model="form[index].answer" :placeholder="$t('請輸入')" rows="3" max-rows="6" no-resize></b-form-textarea>
                </div>
              </div>
              <div v-if="item.type=='score'">
                <div class="mb-2">{{ index + 1 }}.{{ item.title }}</div>
                <div class="d-flex yuanjiao srkddx pt-3 pl-3 pr-3">
                  <div class="mr-5 sjbxzt sjbdmr">{{ $t("評估分數") }}</div>
                  <div class="d-flex text-center sjdasxwd" style="width: 60%;flex-wrap: wrap;margin: auto;">
                    <div class="curpo mr-3 mb-3 pjfs sjbxzt" @click="danxuan(items.value,index)" v-for="(items,indexs) in item.option" :key="indexs" :style="{background: form[index].answer==items.value?'#FFD672':'#F6F4F0',border: form[index].answer==items.value?'':'1px solid #b5b9c1'}">{{ items.name }}</div>
                  </div>
                </div>
              </div>
              <div v-if="item.type=='likert'">
                <div class="mb-2">{{ index + 1 }}.{{ item.title }}</div>
                <div class="d-flex justify-content-between align-items-center pl-3 pr-3 text-center">
                  <div style="visibility: hidden;">123</div>
                  <div class="sjbxzt" v-for="(items,indexs) in item.option" :key="indexs">{{ items.name }}</div>
                </div>
                <div class="d-flex justify-content-between align-items-center pl-3 pr-3 mt-3 text-center yuanjiao pt-3 pb-3" style="background: #F6F4F0;">
                  <div class="sjbxzt">{{ $t("參與前") }}</div>
                  <div class="mr-3 curpo" @click="lktlb(items.value,index,'dy')" v-for="(items,indexs) in item.option" :key="indexs">
                    <img v-if="form[index].answer[0] === items.value" style="width: 20px;height: 20px;" src="@/img/icon_dl_xy_xz@2x (1).png" alt="">
                    <img v-else style="width: 20px;height: 20px;" src="@/img/icon_dl_xy_wxz@2x (1).png" alt="">
                  </div>
                </div>
                <div class="d-flex justify-content-between align-items-center pl-3 pr-3 mt-3 text-center yuanjiao pt-3 pb-3" style="background: #F6F4F0;">
                  <div class="sjbxzt">{{ $t("參與後") }}</div>
                  <div class="mr-3 curpo" @click="lktlb(items.value,index)" v-for="(items,indexs) in item.option" :key="indexs">
                    <img v-if="form[index].answer[1] === items.value" style="width: 20px;height: 20px;" src="@/img/icon_dl_xy_xz@2x (1).png" alt="">
                    <img v-else style="width: 20px;height: 20px;" src="@/img/icon_dl_xy_wxz@2x (1).png" alt="">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div @click="tjwjsa()" class="col-sm-8 col-lg-9 col-xl-6 pl-0 pr-0 yuanjiao text-center curpo pt-2 pb-2 tijwj sjbzt">{{ $t("提交問卷") }}</div>
      </div>
    </div>
  </div>
</template>
    
    <script>
import {} from "@/request/api.js";
import {} from "@/request/api.js";
import { timeFilter, formatTimestamp } from "@/utils/common.js";
export default {
  components: {},
  data() {
    return {
      form: [],
      list: [
        {
          id: 1,
          type: "radio",
          title: "单选题目",
          option: [
            {
              name: "选项1",
              value: "1",
            },
            {
              name: "选项2",
              value: "2",
            },
          ],
        },
        {
          id: 2,
          type: "checkbox",
          title: "多选题目",
          option: [
            {
              name: "选项1",
              value: "1",
            },
            {
              name: "选项2",
              value: "2",
            },
            {
              name: "选项3",
              value: "3",
            },
            {
              name: "选项4",
              value: "4",
            },
          ],
        },
        {
          id: 3,
          type: "input",
          title: "简答题目",
          option: [],
        },
        {
          id: 4,
          type: "textarea",
          title: "详答题目",
          option: [],
        },
        {
          id: 5,
          type: "score",
          title: "重要性题目",
          option: [
            {
              name: "1",
              value: 1,
            },
            {
              name: "2",
              value: 2,
            },
            {
              name: "3",
              value: 3,
            },
            {
              name: "4",
              value: 4,
            },
            {
              name: "5",
              value: 5,
            },
            {
              name: "6",
              value: 6,
            },
            {
              name: "7",
              value: 7,
            },
          ],
        },
        {
          id: 6,
          type: "likert",
          title: "李克特量表题目",
          option: [
            {
              name: "完全不符合",
              value: 1,
            },
            {
              name: "不符合",
              value: 2,
            },
            {
              name: "符合",
              value: 3,
            },
            {
              name: "非常符合",
              value: 4,
            },
          ],
        },
      ],
    };
  },
  mounted() {
    this.formlist();
  },
  methods: {
    tjwjsa() {
      console.log(this.form);
    },
    danxuan(e, v) {
      this.$set(this.form[v], "answer", e);
      console.log(this.form);
    },
    duoxuan(e, v) {
      const index = this.form[v].answer.indexOf(e);
      if (this.form[v].answer.includes(e)) {
        this.form[v].answer.splice(index, 1);
      } else {
        this.form[v].answer.push(e);
      }
    },
    lktlb(e, v, m) {
      if (m) {
        // this.form[v].answer[0] = e;
        let f = [e,this.form[v].answer[1]];
        this.$set(this.form[v], "answer", f);
      } else {
        let q = [this.form[v].answer[0],e];
        this.$set(this.form[v], "answer", q);
        // this.form[v].answer[1] = e;
      }
    },
    formlist() {
      let forlist = [];
      for (let i = 0; i < this.list.length; i++) {
        forlist.push({
          id: this.list[i].id,
          answer:
            this.list[i].type == "checkbox" || this.list[i].type == "likert"
              ? []
              : "",
        });
      }
      this.form = forlist;
    },
  },
};
</script>
    <style scoped lang="scss">
@media screen and (max-width: 992px) {
  .yinc {
    display: none !important;
  }
  .sjbdtp{
    width: calc(150px + 0.4vw) !important;
    height: 60px !important;
  }
  .sjbyj {
    margin-top: 1rem;
  }
  .sjbdkds{
    width: 75% !important;
  }
  .sjdasxwd{
    width: 70% !important;
  }
  .sjbdmr{
    margin-right: 1rem !important;
  }
  .sjbdkd {
    width: 85% !important;
  }
  .sjbzt {
    font-size: calc(13px + 0.4vw) !important;
  }
  .sjbxzt {
    font-size: calc(10px + 0.4vw) !important;
  }
  .aaaaa {
    display: block !important;
  }
  .heggs {
    height: 77vh !important;
  }
}
.aaaaa {
  display: none;
}
.anbutton {
  background: #ffd672;
  font-size: 13px;
}
.srkddx {
  background: #f6f4f0;
  border: none;
}
.tijwj {
  margin: auto;
  background: #ffd672;
  font-size: calc(8px + 0.5vw);
}
.yzm {
  height: 27px;
  line-height: 27px;
  text-align: center;
  font-weight: 400;
  font-size: 12px;
  color: #1a1a1a;
  background: #ffd672;
  border-radius: 12px;
  padding: 0 12px;
}

.srkzt {
  font-size: 13px;
}

.srkxzt {
  font-size: 11px;
}

.yhxyi {
  font-size: calc(10px);

  span {
    color: #b66d00;
  }
}

.xian {
  width: 30%;
  height: 2px;
  background: #ffd672;
  margin: auto;
}

.ckhdxq {
  background: #ffeab8;
  font-size: 13px;
  width: 55%;
  margin: auto;
}

.ztnjj {
  font-size: 14px;
  font-weight: 600;
}

.content {
  font-size: calc(10px + 0.4vw);
  line-height: calc(20px + 0.5vw);
}
.pjfs {
  border-radius: 6px;
  padding: 6px 10px 6px 10px;
}
.heggt {
  height: 76vh;
  overflow-y: auto;
  width: 100%;
}

.swkd {
  font-size: 15px;
  font-weight: 700;
}
/* 隐藏水平滚动条 */
.lieb::-webkit-scrollbar {
  display: none;
}
.sysr {
  font-size: 12px;
  color: #b66d00;
}

.srkleft {
  width: calc(17px + 0.4vw);
  margin-left: calc(8px + 0.4vw);
}

.rigmima {
  width: calc(17px + 0.4vw);
  right: 10px;
}

.denglu {
  background: #ffd672;
  border-radius: 13px !important;
  border: none;
  color: #1a1a1a;
  font-size: calc(7px + 0.4vw);
}
textarea.form-control {
  overflow-y: auto !important;
}
.sjyz {
  width: 48%;
  height: 36px;
  line-height: 30px;
  border-radius: 12px;
  text-align: center;
  font-weight: 400;
  font-size: calc(7px + 0.5vw);
  color: #1a1a1a;
  border: none;
}

.dzyxyz {
  width: 48%;
  height: 36px;
  line-height: 30px;
  border-radius: 12px;
  text-align: center;
  font-weight: 400;
  font-size: calc(7px + 0.5vw);
  color: #1a1a1a;
  border: none;
}
.scys {
  background: #f6f4f0;
  border: none;
  border-radius: 12px;
  height: calc(35px + 0.4vw);
}
</style>
    