import request from '../index.js'

// 资金使用列表
export const todonation = (params) => request.get("/donation/used_list", { params });
//捐款基本数据
export const todonate_base = (params) => request.get("/donation/donate_base", { params });

// 捐款-捐款
export const donation_pay = (params) => request.post("/donation/donate", params);
//捐款基本数据
export const donate_list = (params) => request.get("/donation/donate_list", { params });
