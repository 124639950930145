<template>
  <div>
    <div class="col-md-12 pl-0 pr-0 yinc">
      <img @click="fanhui(0)" class="mt-1 mb-2 curpo dcbd" style="width: calc(25px + 0.4vw);" src="@/img/login/icon_fanhui@2x.png" alt="" />
      <div class="col-md-12 yuanjiao pt-3 pb-3 pl-0 pr-0" style="font-size: calc(7px + 0.4vw);line-height: calc(15px + 0.5vw);">
        <div class="col-md-12 d-flex pl-0 pr-0">
          <div @click="fun(1)" class="pt-3 pb-3 curpo qieh" :style="{ 'background': show == 1 ? '#FFFFFF' : '#FFFAF0', 'font-weight': show == 1 ? '700' : '500', 'color': show == 1 ? '' : '#999999' }">
            {{ $t('定期捐款') }}</div>
          <div @click="fun(2)" class="pt-3 pb-3 curpo qieh" :style="{ 'background': show == 2 ? '#FFFFFF' : '#FFFAF0', 'font-weight': show == 2 ? '700' : '500', 'color': show == 2 ? '' : '#999999' }">
            {{ $t('单笔捐款') }}</div>
        </div>
        <dqjk v-if="show == 1" :ckidss="isid" @custom="custom"></dqjk>
        <dbjk v-if="show == 2" :ckidss="isid" @custom="custom"></dbjk>
      </div>
    </div>
    <div class="col-md-12 pl-0 pr-0 sjyinc">
      <img @click="fanhui(0)" class="mt-1 mb-2 curpo dcbd" style="width: calc(25px + 0.4vw);" src="@/img/login/icon_fanhui@2x.png" alt="" />
      <div class="col-md-12 yuanjiao pt-2 pb-3 pl-0 pr-0" style="font-size: calc(7px + 0.4vw);line-height: calc(15px + 0.5vw);">
        <div class="col-md-12 d-flex pl-0 pr-0">
          <div @click="fun(1)" class="pt-3 pb-3 curpo qieh" :style="{ 'background': show == 1 ? '#FFFFFF' : '#FFFAF0', 'font-weight': show == 1 ? '700' : '500', 'color': show == 1 ? '' : '#999999' }">
            {{ $t('定期捐款') }}</div>
          <div @click="fun(2)" class="pt-3 pb-3 curpo qieh" :style="{ 'background': show == 2 ? '#FFFFFF' : '#FFFAF0', 'font-weight': show == 2 ? '700' : '500', 'color': show == 2 ? '' : '#999999' }">
            {{ $t('单笔捐款') }}</div>
        </div>
        <dqjk v-if="show == 1" :ckidss="isid" @custom="custom"></dqjk>
        <dbjk v-if="show == 2" :ckidss="isid" @custom="custom"></dbjk>
      </div>
    </div>
  </div>
</template>

<script>
import dqjk from "./dqjk.vue";
import dbjk from "./dbjk.vue";
export default {
  components: {
    dqjk,
    dbjk,
  },
  data() {
    return {
      show: 1,
      isLoading: false,
    };
  },
  props: {
    isid: {
      type: Number,
      default: null,
    },
  },
  created() {
    
  },
  mounted() {},
  computed: {},
  methods: {
    custom(e) {
      this.$emit("custom", e);
    },
    fanhui(e) {
      this.$emit("custom", e);
    },
    fun(e) {
      this.show = e;
    },
  },
};
</script>

<style scoped>
@media screen and (max-width: 995px) {
  .sjyinc {
    display: block !important;
  }
  .yinc {
    display: none !important;
  }
}
.sjyinc {
  display: none;
}
.dcbd:hover {
  transform: scale(1.2);
}

.qieh {
  width: calc(100px + 0.4vw);
  text-align: center;
  border-radius: 12px 12px 0 0;
}
</style>