<template>
  <div class="col-md-12 col-sm-12 col-lg-12 col-xl-12 pl-0 pr-0">

    <skeleton-screen v-if="!dataLoaded"></skeleton-screen>
    <img v-if="dataLoaded" @click="fanhui(0)" class="mt-1 mb-2 curpo dcbd" style="width: calc(25px + 0.4vw);" src="@/img/login/icon_fanhui@2x.png" alt="" />
    <div v-if="dataLoaded" class="col-md-12 col-sm-12 col-lg-12 col-xl-12 baise yuanjiao pt-3 pb-3 gundt" style="font-size: calc(7px + 0.4vw);border-radius: 0 12px 12px 12px; " >
      <empty v-if="show"></empty>
      <div v-if="!show">
        <div class="col-md-12 yinc mb-3 pl-0 pr-0 yuanjiao d-flex justify-content-between align-items-center text-center">
          <div class="col-md-2 col-sm-1 col-lg-2 col-xl-2 p-3 yuanjiao" style="width:calc(150px + 0.4vw);">
            {{ $t('zzssyt') }}
          </div>
          <div class="col-md-2 col-sm-2 col-lg-2 col-xl-1 pl-0 pr-0 text-center">{{ $t('ytmc') }}</div>
          <div class="col-md-3 col-sm-3 col-lg-2 col-xl-2 pl-0 pr-0" style="text-align: center !important">
            {{ $t('zjytxxjs') }}
          </div>
          <div class="col-md-1 col-sm-2 col-lg-2 col-xl-1 text-center pl-0 pr-0">
            {{ $t('syjjsl') }}
          </div>
          <div class="col-md-1 col-sm-2 col-lg-2 col-xl-2 text-center pl-0 pr-0" style="color: #999999;">
            {{ $t('riqi') }}
          </div>
        </div>
        <div v-for="(item, index) in list" :key="index" class="col-md-12 yinc mb-3 pl-0 pr-0 yuanjiao d-flex justify-content-between align-items-center" style="background: #F6F4F0;">
          <div class="col-md-2 col-sm-1 col-lg-2 col-xl-2 p-3 yuanjiao">
            <img class="yuanjiao" style="height: calc(120px + 0.4vw);object-fit: cover;width:calc(150px + 0.4vw);" :src="item.image" alt="" />
          </div>

          <div class="col-md-2 col-sm-2 col-lg-2 col-xl-1 pl-0 pr-0 text-center">{{ item.title }}</div>
          <div class="col-md-3 col-sm-3 col-lg-2 col-xl-2 pl-0 pr-0" style="text-align: center !important">
            {{ item.content }}
          </div>
          <div class="col-md-1 col-sm-2 col-lg-2 col-xl-1 text-center pl-0 pr-0" style="font-weight: 700;">NT$
            {{ item.amount }}
          </div>
          <div class="col-md-1 col-sm-2 col-lg-2 col-xl-2 text-center pl-0 pr-0" style="color: #999999;">
            {{ timeFilter(item.create_time) }}
          </div>
        </div>
        <!-- 手机版 -->
        <div v-for="(item, index) in list" :key="index" class="aaaa mb-3 yuanjiao p-3" style="background: #F6F4F0;">
          <div>
            <div class="sjbzt" style="font-weight: 600;">{{ item.title }}</div>
            <div class="mt-3 mb-3" style="background: #E5E5E5;height: 1px;"></div>
			<div class="d-flex justify-content-between mb-1">
              <div class=" text-center pl-0 pr-2 sjbzt" style="width: 50%;text-align: left !important;font-weight: 600;">
                {{ $t('riqi') }}
              </div>
              <div class=" text-center pl-0 pr-0 sjbzt" style="width: 50%;text-align: left !important;font-weight: 600;">
				{{ $t('syjjsl') }}
              </div>
            </div>
            <div class="d-flex justify-content-between">
              <div class=" text-center pl-0 pr-2 sjbxzt" style="width: 50%;text-align: left !important;font-weight: 600;">
                {{ timeFilter(item.create_time) }}
              </div>
              <div class=" text-center pl-0 pr-0 sjbxzt" style="font-weight: 600;width: 50%;text-align: left !important;">NT$
                {{ item.amount }}
              </div>
            </div>
			<div class="sjbzt mt-3 mb-1" style="font-weight: 600;">{{ $t('zjytxxjs') }}</div>
			<div class="sjbxzt mb-3" >{{ item.content }}</div>
			<div class="sjbzt mb-2" style="font-weight: 600;">{{ $t('zzssyt') }}</div>
			<div>
              <img class="yuanjiao " style="height: calc(120px + 0.4vw);object-fit: cover;width:calc(120px + 0.4vw);" :src="item.image" alt="" />
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { EventBus } from "@/views/event-bus.js";
import empty from "../../empty/empty.vue";
import { todonation } from "../../../request/jkhd/jkhd";
import { formatTimestamp } from "@/utils/common.js";
import SkeletonScreen from "./SkeletonScreen/zzsySkeletonScreen.vue";
export default {
  components: {
    SkeletonScreen,
    empty,
  },
  data() {
    return {
      dataLoaded: false,
      list: [],
      page: 1,
      show: false,
      isLoadMore: false,
      limit: "",
    };
  },
  created() {
    EventBus.$on("hsttdonation", (msg) => {
      // 执行方法，处理接收到的数据
      // this.message = msg;
      // 如果需要，可以在这里调用其他方法
      if (!this.isLoadMore) {
          //此处判断，上锁，防止重复请求
          this.isLoadMore = true;
          this.page += 1;
          this.getdonation();
        }
    });
  },
  beforeDestroy() {
    EventBus.$off("hsttdonation");
  },
  mounted() {
    this.getdonation();
  },
  computed: {},
  methods: {
    // 时间转换
    timeFilter(stringTime) {
      return formatTimestamp(stringTime * 1000);
    },
    // handleScroll(event) {
    //   const target = event.target;
    //   const scrollDistance =
    //     target.scrollHeight - target.scrollTop - target.clientHeight;
    //   if (scrollDistance < 10) {
    //     if (!this.isLoadMore) {
    //       //此处判断，上锁，防止重复请求
    //       this.isLoadMore = true;
    //       this.page += 1;
    //       this.getdonation();
    //     }
    //   }
    // },
    getdonation() {
      const data = {
        page: this.page,
        id: this.$route.params.zjsy,
      };
      todonation(data).then((res) => {
        if (res.code == 200) {
          this.dataLoaded = true;
        }
        this.limit = res.data.per_page;
        if (res.data.total == 0) {
          this.ishow = true;
        } else {
          this.ishow = false;
        }
        if (this.page * this.limit >= res.data.total) {
          if (this.page == 1) {
            this.list = res.data.data;
          } else {
            this.list.push(...res.data.data);
          }
          this.isLoadMore = true;
        } else {
          this.list.push(...res.data.data);
          this.isLoadMore = false;
        }
      });
    },
    fanhui(e) {
      this.$router.back();
      EventBus.$emit("tthdfh", "1");
    },
  },
};
</script>

<style scoped>
@media screen and (max-width: 750px) {
	.asdasd{
	width:calc(60px + 0.4vw) !important;
	height: calc(60px + 0.4vw) !important;
  }
}

@media screen and (max-width: 991px) {
  .aaaa {
    display: block !important;
  }
  .yinc {
    display: none !important;
  }
  .sjbzt {
    font-size: calc(11px + 0.4vw) !important;
  }
  .sjbxzt {
    font-size: calc(9px + 0.4vw) !important;
  }
}
.aaaa {
  display: none;
}
.dcbd:hover {
  transform: scale(1.2);
}
.gundt::-webkit-scrollbar {
  display: none;
}
</style>