<template>
  <div class="col-md-12 col-sm-12 col-lg-12 col-xl-12 pl-0 pr-0 pt-2">
    <div v-if="type==1">
      <div class="jiazhongzt mb-3 sjbzt">{{ $t("银行转帐信息：") }}</div>
      <div class="d-flex justify-content-between align-items-center sjbxzt bjzhst mb-2">
        <div>{{ $t("银行：") }}</div>
        <div>{{ $t("分行:") }}</div>
      </div>
      <div class="d-flex justify-content-between align-items-center jiazhongzt sjbzt mb-3">
        <div>{{ bank.bank_name }}</div>
        <div>{{ bank.bank_branch }}</div>
      </div>
      <div class="d-flex justify-content-between align-items-center sjbxzt bjzhst mb-2">
        <div>{{ $t("户名:") }}</div>
        <div>{{ $t("账号:") }}</div>
      </div>
      <div class="d-flex justify-content-between align-items-center jiazhongzt sjbzt">
        <div>{{ bank.bank_account_name }}</div>
        <div>{{ bank.bank_account_number }}</div>
      </div>
      <div class="xian mt-4 mb-4"></div>
      <div class="bjzhst mb-4 sjbxzt">{{ $t("*转账时请在备注字段填写：") }}</div>
      <div class="jiazhongzt sjbxzt">{{ $t("备注信息：「好事道捐款-") }}{{ name }}」</div>
      <div class="bjzhst mt-5 mb-5 sjbxxzt" style="color: #FF797A;">{{ $t("*完成转账后，烦请填写下方信息表单，以利会计核对捐款纪录") }}</div>
      <div style="height: 1rem;width: 100%;"></div>
      <div class="jkss pt-2 pb-2 yuanjiao sjbxzt" @click="pandautk()" style="width: 84%;margin: auto;">{{ $t("填写表单") }}</div>
    </div>
    <div v-if="type==2">
      <div class="mb-3 bjzhst sjbxzt">{{ $t("wcasdxx") }}</div>
      <div class="d-flex mb-3" style="font-size: calc(7px + 0.4vw);">
        <div class="mr-3 pt-2 pb-2 pl-4 pr-4 curpo sjbxzt yuanjiao" @click="hshow=1" :style="{background : hshow == 1 ? '#FFD672' : '#F6F4F0' }">
          {{ $t("个人") }}
        </div>
        <div class="pt-2 pb-2 pl-4 pr-4 curpo sjbxzt yuanjiao" @click="hshow=2" :style="{background : hshow == 2 ? '#FFD672' : '#F6F4F0' }">
          {{ $t("企业") }}
        </div>
      </div>
      <!-- 个人 -->
      <form v-if="hshow==1" class="sjbxzt" style="font-size: calc(8px + 0.4vw);">
        <div class="d-flex justify-content-between align-items-center mb-1">
          <div class="" style="width: 49%;">
            <label class="sjbzt" style="font-weight: 500;"><span style="color: red;">*</span>{{ $t('捐款姓名')
							}}</label>
            <div class="position-relative d-flex align-items-center">
              <input v-model="form.jkxm" :disabled='isLoading' type="text" class="xxskm form-control pdinglef sjbxzt pdinglef sjbxzt srk" :placeholder="$t('请输入捐款姓名')">
            </div>
            <small class="form-text" style="visibility: hidden;">123</small>
          </div>
          <!-- 手机号 -->
          <div class="" style="width: 49%;">
            <label style="font-weight: 500;"><span style="color: red;">*</span>{{ $t('login.phoneNumberLabel')
							}}</label>
            <div class="position-relative d-flex align-items-center">
              <img class="position-absolute srkleft" src="@/img/login/icon_dl_sjhm@2x.png" alt="" />
              <input v-model="form.phone" :disabled='isLoading' @input="Phonevalidate()" type="text" class="xxskm sjpl form-control srk pl-5" :placeholder="$t('login.phoneNumberPlaceholder')">
            </div>
            <small class="form-text" style="color: red;" v-if="phoneError">{{ phoneError }}</small>
            <small style="visibility: hidden;" v-else>123</small>
          </div>
        </div>
        <div class="d-flex justify-content-between align-items-center mb-1">
          <!-- 邮箱 -->
          <div style="width: 49%;">
            <label class="sjbzt" style="font-weight: 500;"><span style="color: red;">*</span>{{ $t('login.emailLoginButton')
							}}</label>
            <div class="position-relative d-flex align-items-center">
              <img class="position-absolute srkleft" src="@/img/login/yxiang.png" alt="" />
              <input v-model="form.emil" :disabled='isLoading' @input="validatePhone()" type="text" class="xxskm form-control pdinglef sjbxzt sjpl pdinglef sjbxzt srk pl-5" :placeholder="$t('login.qsryx')">
            </div>
            <small class="form-text sjbxzt" style="color: red;" v-if="emilError">{{ emilError }}</small>
            <small style="visibility: hidden;" v-else>123</small>
          </div>
          <div style="width: 49%;">
            <label class="sjbzt" style="font-weight: 500;"><span style="color: red;">*</span>{{ $t('身份证号')
							}}</label>
            <div class="position-relative d-flex align-items-center">
              <input v-model="form.sfzzh" :disabled='isLoading' type="text" class="xxskm form-control pdinglef sjbxzt pdinglef sjbxzt srk" :placeholder="$t('请输入身份证号')">
            </div>
            <small class="form-text" style="visibility: hidden;">123</small>
          </div>
        </div>
        <div class="form-group">
          <label class="sjbzt" style="font-weight: 500;"><span style="color: red;">*</span>{{ $t('银行卡后5位')
							}}</label>
          <div class="position-relative d-flex align-items-center">
            <input v-model="form.yhkhww" :disabled='isLoading' type="number" class="xxskm form-control pdinglef sjbxzt pdinglef sjbxzt srk" :placeholder="$t('请输入银行卡后5位')">
          </div>
        </div>

      </form>
      <!-- 企业 -->
      <form v-if="hshow==2" class="sjbxzt" style="font-size: calc(8px + 0.4vw);">
        <div class="d-flex justify-content-between align-items-center mb-1">
          <div class="" style="width: 49%;">
            <label class="sjbzt" style="font-weight: 500;"><span style="color: red;">*</span>{{ $t('企业抬头')
							}}</label>
            <div class="position-relative d-flex align-items-center">
              <input v-model="forms.dwtt" :disabled='isLoading' type="text" class="xxskm form-control pdinglef sjbxzt pdinglef sjbxzt srk" :placeholder="$t('请输入企业抬头')">
            </div>
            <small class="form-text" style="visibility: hidden;">123</small>
          </div>
          <!-- 手机号 -->
          <div class="" style="width: 49%;">
            <label class="sjbzt" style="font-weight: 500;"><span style="color: red;">*</span>{{ $t('企业统编')
							}}</label>
            <div class="position-relative d-flex align-items-center">
              <input v-model="forms.dwtb" :disabled='isLoading' type="text" class="xxskm form-control pdinglef sjbxzt pdinglef srk" :placeholder="$t('请输入企业统编')">
            </div>
            <small class="form-text" style="visibility: hidden;">123</small>
          </div>
        </div>
        <div class="d-flex justify-content-between align-items-center mb-1">
          <div class="" style="width: 49%;">
            <label style="font-weight: 500;"><span style="color: red;">*</span>{{ $t('login.phoneNumberLabel')
							}}</label>
            <div class="position-relative d-flex align-items-center">
              <img class="position-absolute srkleft" src="@/img/login/icon_dl_sjhm@2x.png" alt="" />
              <input v-model="forms.phone" :disabled='isLoading' @input="Phonevalidates()" type="text" class="xxskm sjbxzt sjpl form-control srk pl-5" :placeholder="$t('login.phoneNumberPlaceholder')">
            </div>
            <small class="form-text" style="color: red;" v-if="phoneErrors">{{ phoneErrors }}</small>
            <small style="visibility: hidden;" v-else>123</small>
          </div>
          <!-- 邮箱 -->
          <div style="width: 49%;">
            <label class="sjbzt" style="font-weight: 500;"><span style="color: red;">*</span>{{ $t('login.emailLoginButton')
							}}</label>
            <div class="position-relative d-flex align-items-center">
              <img class="position-absolute srkleft" src="@/img/login/yxiang.png" alt="" />
              <input v-model="forms.emil" :disabled='isLoading' @input="validatePhones()" type="text" class="xxskm form-control pdinglef sjbxzt sjpl pdinglef sjbxzt srk pl-5" :placeholder="$t('login.qsryx')">
            </div>
            <small class="form-text sjbxzt" style="color: red;" v-if="emilErrors">{{ emilErrors }}</small>
            <small style="visibility: hidden;" v-else>123</small>
          </div>
        </div>
        <div class="form-group">
          <label class="sjbzt" style="font-weight: 500;"><span style="color: red;">*</span>{{ $t('银行卡后5位')
							}}</label>
          <div class="position-relative d-flex align-items-center">
            <input v-model="forms.yhkhww" :disabled='isLoading' type="number" class="xxskm form-control pdinglef sjbxzt pdinglef sjbxzt srk" :placeholder="$t('请输入银行卡后5位')">
          </div>
        </div>

      </form>
      <!-- 企业尾 -->
      <div @click="qrsx()" class="jkss pt-2 pb-2 yuanjiao mt-5 sjbxzt" style="width: 55%;margin: auto;">{{ $t("送出") }}</div>
    </div>
  </div>
</template>
  
  <script>
import { EventBus } from "@/views/event-bus.js";
import { donation_Deta, donation_donate_bank } from "@/request/aapi.js";
export default {
  components: {},
  data() {
    return {
      isLoading: false,
      bank: {},
      name: "",
      type: 1,
      hshow: 1,
      emilError: "",
      phoneError: "",
      emilErrors: "",
      phoneErrors: "",
      form: {
        emil: "",
        phone: "",
        yhkhww: "",
        sfzzh: "",
        jkxm: "",
      },
      forms: {
        emil: "",
        phone: "",
        dwtt: "",
        dwtb: "",
        yhkhww: "",
      },
    };
  },
  created() {
    this.getInfo();
  },
  mounted() {},
  computed: {},
  methods: {
    pandautk() {
      if (!localStorage.getItem("token")) {
        this.$emit("cusasdm", "123");
        return;
      }
      this.type = 2;
    },
    getInfo() {
      let data = {
        id: this.$route.params.ckxq,
      };
      donation_Deta(data).then((res) => {
        // console.log(res, "详情")
        if (res.code == 200) {
          this.bank = res.data.bank;
          this.name = res.data.info.name;
        }
      });
    },
    qrsx() {
      //   判断个人还是企业
      if (this.hshow == 1) {
        this.gerbutton();
      } else {
        this.qiyebutton();
      }
    },
    qiyebutton() {
      if (this.forms.dwtt == "") {
        this.$bvToast.toast(this.$t("请输入企业抬头"), {
          title: this.$t("login.warning"),
          variant: "danger",
          autoHideDelay: 5000,
        });
        return;
      } else if (this.forms.dwtb == "") {
        this.$bvToast.toast(this.$t("请输入企业统编"), {
          title: this.$t("login.warning"),
          variant: "danger",
          autoHideDelay: 5000,
        });
        return;
      } else if (this.forms.phone == "") {
        this.$bvToast.toast(this.$t("请输入手机号"), {
          title: this.$t("login.warning"),
          variant: "danger",
          autoHideDelay: 5000,
        });
        return;
      } else if (this.forms.emil == "") {
        this.$bvToast.toast(this.$t("请输入信箱"), {
          title: this.$t("login.warning"),
          variant: "danger",
          autoHideDelay: 5000,
        });
        return;
      } else if (this.forms.yhkhww == "") {
        this.$bvToast.toast(this.$t("请输入银行卡后5位"), {
          title: this.$t("login.warning"),
          variant: "danger",
          autoHideDelay: 5000,
        });
        return;
      }else if (this.forms.yhkhww.length != 5) {
        this.$bvToast.toast(this.$t("银行卡后5位输入错误"), {
          title: this.$t("login.warning"),
          variant: "danger",
          autoHideDelay: 5000,
        });
        return;
      }
      let a = {
        id: this.$route.params.ckxq,
        type: 2,
        enterprise_name: this.forms.dwtt,
        enterprise_number: this.forms.dwtb,
        phone: this.forms.phone,
        email: this.forms.emil,
        card_no: this.forms.yhkhww,
      };
      donation_donate_bank(a).then((res) => {
        if (res.code == 200) {
          this.isLoading = false; // 处理完成后关闭转圈
          this.$bvToast.toast(this.$t("成功"), {
            title: this.$t("login.notice"),
            autoHideDelay: 2000,
            delay: 5000,
            appendToast: true,
            variant: "success",
          });
          setTimeout(() => {
            this.$router.go();
          }, 700);
        } else {
          this.$bvToast.toast(res.msg, {
            title: this.$t("login.warning"),
            variant: "danger",
            autoHideDelay: 5000,
          });
          this.isLoading = false; // 处理完成后关闭转圈
        }
      });
    },
    gerbutton() {
      if (this.form.jkxm == "") {
        this.$bvToast.toast(this.$t("请输入捐款姓名"), {
          title: this.$t("login.warning"),
          variant: "danger",
          autoHideDelay: 5000,
        });
        return;
      } else if (this.form.phone == "") {
        this.$bvToast.toast(this.$t("请输入手机号"), {
          title: this.$t("login.warning"),
          variant: "danger",
          autoHideDelay: 5000,
        });
        return;
      } else if (this.form.emil == "") {
        this.$bvToast.toast(this.$t("请输入信箱"), {
          title: this.$t("login.warning"),
          variant: "danger",
          autoHideDelay: 5000,
        });
        return;
      } else if (this.form.sfzzh == "") {
        this.$bvToast.toast(this.$t("请输入身份证号"), {
          title: this.$t("login.warning"),
          variant: "danger",
          autoHideDelay: 5000,
        });
        return;
      } else if (this.form.yhkhww == "") {
        this.$bvToast.toast(this.$t("请输入银行卡后5位"), {
          title: this.$t("login.warning"),
          variant: "danger",
          autoHideDelay: 5000,
        });
        return;
      }else if (this.form.yhkhww.length != 5) {
        this.$bvToast.toast(this.$t("银行卡后5位输入错误"), {
          title: this.$t("login.warning"),
          variant: "danger",
          autoHideDelay: 5000,
        });
        return;
      }
      let a = {
        id: this.$route.params.ckxq,
        type: 1,
        donor_name: this.form.jkxm,
        id_number: this.form.sfzzh,
        phone: this.form.phone,
        email: this.form.emil,
        card_no: this.form.yhkhww,
      };
      donation_donate_bank(a).then((res) => {
        if (res.code == 200) {
          this.isLoading = false; // 处理完成后关闭转圈
          this.$bvToast.toast(this.$t("成功"), {
            title: this.$t("login.notice"),
            autoHideDelay: 2000,
            delay: 5000,
            appendToast: true,
            variant: "success",
          });
          setTimeout(() => {
            this.$router.go();
          }, 1000);
        } else {
          this.$bvToast.toast(res.msg, {
            title: this.$t("login.warning"),
            variant: "danger",
            autoHideDelay: 5000,
          });
          this.isLoading = false; // 处理完成后关闭转圈
        }
      });
    },
    validatePhone() {
      const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (!regex.test(this.form.emil)) {
        this.emilError = this.$t("login.invalid_emil_number");
      } else {
        this.emilError = "";
      }
      if (this.form.emil == "") {
        this.emilError = "";
      }
    },
    Phonevalidate() {
      const regex = /^09[0-9]{8}$/; // 简单的手机号正则表达式
      if (!regex.test(this.form.phone)) {
        this.phoneError = this.$t("login.invalid_phone_number");
      } else {
        this.phoneError = "";
      }
      if (this.form.phone == "") {
        this.phoneError = "";
      }
    },
    validatePhones() {
      const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (!regex.test(this.forms.emil)) {
        this.emilErrors = this.$t("login.invalid_emil_number");
      } else {
        this.emilErrors = "";
      }
      if (this.forms.emil == "") {
        this.emilErrors = "";
      }
    },
    Phonevalidates() {
      const regex = /^09[0-9]{8}$/; // 简单的手机号正则表达式
      if (!regex.test(this.forms.phone)) {
        this.phoneErrors = this.$t("login.invalid_phone_number");
      } else {
        this.phoneErrors = "";
      }
      if (this.forms.phone == "") {
        this.phoneErrors = "";
      }
    },
  },
};
</script>
  
  <style scoped>
@media screen and (max-width: 995px) {
  .heggs {
    margin-top: 1rem !important;
  }
  .gaodu {
    height: 66vh !important;
  }
  .sjbzt {
    font-size: calc(12px + 0.4vw) !important;
  }
  .sjbxzt {
    font-size: calc(11px + 0.4vw) !important;
  }
  .sjpl {
    padding-left: 33px !important;
  }
  .sjbxxzt {
    font-size: calc(9px + 0.4vw) !important;
  }
}

.xxskm {
  font-size: calc(8px + 0.4vw);
}
.bjzhst {
  font-size: calc(7px + 0.4vw);
  color: #666666;
}
.jiazhongzt {
  font-size: calc(9px + 0.4vw);
  font-weight: 600;
}
.xian {
  width: 100%;
  height: 1px;
  background: #1a1a1a;
}
.sadca::before {
  /* height: 80vh !important; */
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("@/img/mbz.png"); /* 图片路径 */
  background-size: 100% 100%;
  background-size: cover; /* 背景图片覆盖整个容器 */
  background-position: center; /* 背景图片居中 */
  border-radius: 12px 12px 0 0;
}
.asdax {
  background-color: rgba(0, 0, 0, 0.6);
}
.dcbd:hover {
  transform: scale(1.08);
}
.gaodu {
  width: 49%;
  height: 73vh;
  overflow-y: auto;
}
.huansge {
  width: 100%;
  border-radius: 12px 12px 0 0;
  height: 2rem;
  background: #ffeab8;
}
.tom {
  width: 80%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.jkss {
  background-color: #ffd672;
  text-align: center;
  font-size: calc(8px + 0.4vw);
  cursor: pointer;
}
.srkleft {
  width: 20px;
  height: 20px;
}
</style>