import { render, staticRenderFns } from "./zhbd.vue?vue&type=template&id=a35c0ea8&scoped=true"
import script from "./zhbd.vue?vue&type=script&lang=js"
export * from "./zhbd.vue?vue&type=script&lang=js"
import style0 from "./zhbd.vue?vue&type=style&index=0&id=a35c0ea8&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a35c0ea8",
  null
  
)

export default component.exports