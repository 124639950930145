<template>
  <div>
    <div class="">
      <div class="yuanjiao baise pl-3 pr-3 pt-3 mb-3 pb-3 position-relative sjbyj">
        <div class="text-center col-sm-8 col-lg-9 col-xl-6 pl-0 pr-0" style="margin: auto;background: #FFFAF0;">
          <div class="yuanjiao p-3" style="width: 65%;margin: auto;">
            <img style="width: calc(200px + 0.4vw);height: 80px;" src="@/img/icon_phone_logo@1x.png" alt="">
            <div class="mt-3 swkd">臻信祥食物银行影響力計劃</div>
            <div class="mt-3 sjbdkd" style="font-size: 12px;color: #666666;display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 2;overflow: hidden;text-overflow: ellipsis;width: 60%;margin: auto;">
              此處為計劃概述，此處為計劃概述
              此處為計劃概述</div>
            <div class="pt-2 pb-2 mt-3 ckhdxq yuanjiao curpo">{{ $t("查看活動詳情") }}</div>
          </div>
        </div>
        <div class="mt-3 mb-3 d-flex justify-content-center">
          <div class="d-flex align-items-center">
            <div class="mr-2"><img style="width: calc(25px + 0.4vw);height: calc(25px + 0.4vw);" src="@/img/kbbylj.png" alt=""></div>
            <div style="font-size: calc(9px + 0.4vw);font-weight: 500;">{{ $t("拷貝編輯鏈接") }}</div>
          </div>
        </div>
        <div class="text-center" style="font-size: calc(12px + 0.4vw);font-weight: 500;">{{ $t("計畫紀錄表-訪談") }}</div>
        <div class="col-sm-8 col-lg-9 col-xl-6 pl-0 pr-0" style="margin: auto;">
          <!-- <div style="font-size: calc(7px + 0.5vw);">
            <div class="mb-3">
                <div class="mb-2">1.<span style="color: #FF797A;">[單選]</span>整體來說，我滿意自己</div>
                 <div class="d-flex justify-content-between align-items-center yuanjiao pl-3 pr-3 pt-2 pb-2" style="background: #F6F4F0;">
                    <div>很同意</div>
                    <div><img style="width: 16px;height: 16px;" src="@/img/duihhujioj.png" alt=""></div>
                 </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>
    
    <script>
import {} from "@/request/api.js";
import {} from "@/request/api.js";
import { timeFilter, formatTimestamp } from "@/utils/common.js";
export default {
  components: {},
  data() {
    return {
      form: {
        name: "",
        lanw: "",
        sszb: "",
      },
    };
  },
  mounted() {},
  methods: {},
};
</script>
    <style scoped lang="scss">
@media screen and (max-width: 992px) {
  .yinc {
    display: none !important;
  }
  .sjbyj{
    margin-top: 1rem;
  }
  .sjbdkd{
    width: 85% !important;
  }
  .sjbzt {
    font-size: calc(11px + 0.4vw) !important;
  }
  .sjbxzt {
    font-size: calc(9px + 0.4vw) !important;
  }
  .aaaaa {
    display: block !important;
  }
}
.aaaaa {
  display: none;
}
.anbutton {
  background: #ffd672;
  font-size: 13px;
}

.yzm {
  height: 27px;
  line-height: 27px;
  text-align: center;
  font-weight: 400;
  font-size: 12px;
  color: #1a1a1a;
  background: #ffd672;
  border-radius: 12px;
  padding: 0 12px;
}

.srkzt {
  font-size: 13px;
}

.srkxzt {
  font-size: 11px;
}

.yhxyi {
  font-size: calc(10px);

  span {
    color: #b66d00;
  }
}

.xian {
  width: 30%;
  height: 2px;
  background: #ffd672;
  margin: auto;
}

.ckhdxq {
  background: #ffeab8;
  font-size: 13px;
  width: 55%;
  margin: auto;
}

.ztnjj {
  font-size: 14px;
  font-weight: 600;
}

.content {
  font-size: calc(10px + 0.4vw);
  line-height: calc(20px + 0.5vw);
}

.heggt {
  height: 76vh;
  overflow-y: auto;
  width: 100%;
}

.swkd {
  font-size: 15px;
  font-weight: 700;
}

.sysr {
  font-size: 12px;
  color: #b66d00;
}

.srkleft {
  width: calc(17px + 0.4vw);
  margin-left: calc(8px + 0.4vw);
}

.rigmima {
  width: calc(17px + 0.4vw);
  right: 10px;
}

.denglu {
  background: #ffd672;
  border-radius: 13px !important;
  border: none;
  color: #1a1a1a;
  font-size: calc(7px + 0.4vw);
}
.sjyz {
  width: 48%;
  height: 36px;
  line-height: 30px;
  border-radius: 12px;
  text-align: center;
  font-weight: 400;
  font-size: calc(7px + 0.5vw);
  color: #1a1a1a;
  border: none;
}

.dzyxyz {
  width: 48%;
  height: 36px;
  line-height: 30px;
  border-radius: 12px;
  text-align: center;
  font-weight: 400;
  font-size: calc(7px + 0.5vw);
  color: #1a1a1a;
  border: none;
}
.scys {
  background: #f6f4f0;
  border: none;
  border-radius: 12px;
  height: calc(35px + 0.4vw);
}
</style>
    