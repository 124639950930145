<template>
  <div>
    <div v-show="txbiaod == 1">
      <!-- 骨架屏 -->
      <skeleton-screen v-show="!dataLoaded"></skeleton-screen>
      <div v-if="dataLoaded" class="col-md-12 col-sm-12 col-lg-12 col-xl-12 d-flex pl-0 pr-0 ml-0 justify-content-between sjtop">
        <div class="col-sm-12 parent pl-0 pr-0 heggs" style="height: 89.5vh;overflow-y: auto;" @scroll="handleScroll">
          <!-- 上半部分 -->
          <div class="col-sm-12 baise yuanjiao pl-0 pr-0" style="overflow: hidden;">
            <b-carousel id="carousel-fade" v-if="info.banner.length > 0" style="text-shadow: 0px 0px 2px #000;height: 400px;border-radius: 12px 12px 0 0;" fade indicators img-width="1024" img-height="100" class="imase  sjbgaodu yinc">
              <b-carousel-slide v-for="(item, index) in info.banner" :key="index" caption="" :img-src="item"></b-carousel-slide>
            </b-carousel>
            <b-carousel id="carousel-fade" v-if="info.banner.length > 0" style="text-shadow: 0px 0px 2px #000;border-radius: 12px 12px 0 0;object-fit: cover;" fade indicators img-width="1024" img-height="100" class="aaaa">
              <b-carousel-slide v-for="(item, index) in info.banner" :key="index" caption="" :img-src="item"></b-carousel-slide>
            </b-carousel>
            <div class="p-4 sjiduand1">
              <div class="col-sm-12 pl-0 pr-0 d-flex justify-content-between yinc">
                <div class="col-sm-12 col-lg-8 col-xl-9 pl-0 pr-0">
                  <div class="d-flex justify-content-between pl-0 pr-0 col-sm-12 mb-3">
                    <div class="col-sm-8 col-md-8 col-lg-8 col-xl-9 pl-0 pr-0">
                      <div class="mb-3" style="font-size: calc(11px + 0.4vw);font-weight: 700;">{{ info.name }}
                      </div>
                      <span class="mt-2 mb-2 pl-3 pr-3 pt-2 pb-2" style="font-size: calc(6px + 0.4vw);background-color: #FFFAF0;">
                        {{ info.organization_name }}</span>
                    </div>
                    <div class="d-flex col-sm-4  col-md-4 col-lg-4 col-xl-4 pl-0 pr-0">
                      <div class="jhsad col-sm-4 mr-2">
                        <div>
                          <div style="font-size: calc(7px + 0.4vw);font-weight: 700;">
                            {{ info.moment_num ? info.moment_num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }}
                          </div>
                          <div style="font-size: calc(6px + 0.4vw);">{{ $t("贴文") }}</div>
                        </div>
                      </div>
                      <div class="jhsad col-sm-4 mr-2" style="background: #FDDDC9">
                        <div>
                          <div style="font-size: calc(7px + 0.4vw);font-weight: 700;">
                            {{ info.member_num ? info.member_num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }}
                          </div>
                          <div style="font-size: calc(6px + 0.4vw);">{{ $t("参与人数") }}</div>
                        </div>
                      </div>
                      <div class="jhsad col-sm-4" style="background: #FFEBD2;">
                        <div>
                          <div style="font-size: calc(7px + 0.4vw);font-weight: 700;">
                            {{ info.rating }}
                          </div>
                          <div style="font-size: calc(6px + 0.4vw);">{{ $t("活动评分") }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style="color: #666666;font-size: calc(5px + 0.4vw);">
                    {{ info.description }}
                  </div>
                  <button v-if="info.is_member == 0" @click="chanyughd(id, info.name)" class="pl-4 pr-4 pt-3 pb-3 yuanjiao curpo ckqbb mt-4" style="font-size: calc(6px + 0.4vw);background: #FFD672;border: none;">
                    {{ $t("加入活动") }}
                  </button>
                  <button v-else class="pl-4 pr-4 pt-3 pb-3 yuanjiao curpo ckqbb mt-4" style="font-size: calc(6px + 0.4vw);background: #E5E5E5;border: none;">
                    {{ $t("已报名") }}
                  </button>
                </div>
                <div class="col-md-4 col-lg-3 col-xl-2 tpiand pl-0 pr-0">
                  <img style="width: 100%;height: 200px;object-fit: cover;" class="yuanjiao" :src="info.image" alt="" />
                </div>
              </div>
              <div class=" mb-3 aaaa" style="width: 100%;">
                <div class="d-flex justify-content-between" style="width: 100%;">
                  <div style="width: 38%;">
                    <div class="mb-3 sjtoto" style="font-size: calc(11px + 0.4vw);font-weight: 700;">{{ info.name }}
                    </div>
                  </div>
                  <div class="pl-0 pr-0" style="width: 60%;">
                    <div class="d-flex justify-content-between pl-0 pr-0  mb-3">

                      <div class="d-flex" style="width: 100%;">
                        <div class="jhsad mr-1" style="width: 30%;">
                          <div>
                            <div style="font-size: calc(7px + 0.4vw);font-weight: 700;">
                              {{ info.moment_num ? info.moment_num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }}
                            </div>
                            <div style="font-size: calc(6px + 0.4vw);">{{ $t("贴文") }}</div>
                          </div>
                        </div>
                        <div class="jhsad mr-1" style="background: #FDDDC9;width: 34%;">
                          <div>
                            <div style="font-size: calc(7px + 0.4vw);font-weight: 700;">
                              {{ info.member_num ? info.member_num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }}
                            </div>
                            <div style="font-size: calc(6px + 0.4vw);">{{ $t("参与人数") }}</div>
                          </div>
                        </div>
                        <div class="jhsad" style="background: #FFEBD2;width: 34%;">
                          <div>
                            <div style="font-size: calc(7px + 0.4vw);font-weight: 700;">
                              {{ info.rating }}
                            </div>
                            <div style="font-size: calc(6px + 0.4vw);">{{ $t("活动评分") }}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div><span class=" pl-2 pr-2 pt-2 pb-2 sjbxzt" style="font-size: calc(6px + 0.4vw);background-color: #FFFAF0;">
                    {{ info.organization_name }}</span></div>
                <div style="height: 1rem;width: 100%;"></div>
                <div class="sjbxzt" style="color: #666666;font-size: calc(5px + 0.4vw);">
                  {{ info.description }}
                </div>
                <div class="d-flex justify-content-between mt-3">
                  <button v-if="info.is_member == 0" @click="chanyughd(id, info.name)" class="pl-4 pr-4 pt-3 pb-3 yuanjiao curpo ckqbb mt-3 mb-3 sjbzt" style="font-size: calc(6px + 0.4vw);background: #FFD672;border: none;">
                    {{ $t("加入活动") }}
                  </button>
                  <button v-else class="pl-4 pr-4 pt-3 pb-3 yuanjiao curpo ckqbb mt-3 mb-3 sjbzt" style="font-size: calc(6px + 0.4vw);background: #E5E5E5;border: none;">
                    {{ $t("已报名") }}
                  </button>
                  <div class="aaaa pl-0 pr-0">
                    <img style="width: 100px;object-fit: cover;height: 100%;" class="yuanjiao" v-if="info.image" :src="info.image" alt="" />
                    <img style="width: 100%;" v-else src="" alt="" />
                  </div>
                </div>
                <div class="col-md-4 col-lg-3 col-xl-2 tpiand pl-0 pr-0">
                  <img style="width: 100%;height: 200px;object-fit: cover;" class="yuanjiao" :src="info.image" alt="" />
                </div>
              </div>
            </div>
          </div>
          <!-- 中间部分 -->
          <div class="col-sm-12 pl-0 pr-0 items mt-3 yinc" @mouseenter="showButtons = true" @mouseleave="showButtons = false">
            <div class="col-sm-12 pl-0 pr-0 position-relative d-flex justify-content-between align-items-center">
              <div v-show="showButtons" class="col-auto position-absolute pl-0 pr-0 dcbd curpo xsdann" style="z-index: 99999;left: 0;" @click="move(-1)">
                <img style="width: calc(12px + 0.5vw);" src="@/img/details/icon_jklb_xiangzuo@2x.png" alt="" />
              </div>
              <div class="col-sm-12 d-flex pl-0 pr-0 d-flex justify-content-between">
                <div @click="wautobutt(item.id)" class=" ckqbb  curpo yuanjiao pt-3 pb-3 position-relative" style="background: #FFFFFF;text-align: center;width: 19%;" v-for="(item, index) in visibleItems" :key="index">
                  <!-- 假设每个item是一个对象，包含图标和文字 -->
                  <img class="mb-1" style="width: calc(13px + 0.5vw);" :src="item.icon" alt="" />
                  <div style="text-align: center;width: 100%;font-size: calc(8px + 0.4vw);">
                    {{ item.text }}
                  </div>
                  <div v-if="item.id == wauto" class="position-absolute" style="height: 2px;width: 74%;bottom: 0;background: #FFD672;left: 13%;"></div>
                </div>
              </div>
              <div v-show="showButtons" class="col-auto position-absolute pl-0 pr-0 dcbd curpo xsdann" style="z-index: 99999;right: 0;" @click="move(1)">
                <img style="width: calc(12px + 0.5vw);" src="@/img/details/icon_jklb_xiangyou@2x.png" alt="" />
              </div>
            </div>
          </div>
          <div class="items mt-3 aaaa" style="width: 100%;position: relative;">
            <div class="col-auto position-absolute pl-0 pr-0 dcbd curpo xsdann" style="z-index: 999;left: 0;top: 34%;">
              <img style="width: calc(12px + 0.5vw);" src="@/img/details/icon_jklb_xiangzuo@2x.png" alt="" />
            </div>
            <div class="d-flex align-items-center parent" style="width: 100%;overflow-x: auto;white-space: nowrap;">

              <div style="width: 100%;display: inline-block;">
                <div @click="wautobutt(item.id)" class=" mr-3  yuanjiao pt-3 pb-3 sjdsdsax" v-for="(item, index) in allItems" :key="index">
                  <!-- 假设每个item是一个对象，包含图标和文字 -->
                  <img class="mb-1" style="width: calc(16px + 0.5vw);" :src="item.icon" alt="" />
                  <div style="text-align: center;width: 100%;font-size: calc(10px + 0.4vw);">
                    {{ item.text }}
                  </div>
                  <div v-show="item.id == wauto" class="position-absolute" style="height: 2px;width: 74%;bottom: 0;background: #FFD672;left: 13%;"></div>
                </div>
              </div>

            </div>
            <div class="col-auto position-absolute pl-0 pr-0 dcbd curpo xsdann" style="z-index: 999;right: 0;bottom: 34%;">
              <img style="width: calc(12px + 0.5vw);" src="@/img/details/icon_jklb_xiangyou@2x.png" alt="" />
            </div>
          </div>
          <router-view />
          <!-- 切换的下半部分  拼音 -->
          <!-- <hdzx ref="htnd" v-if="wauto == 1"></hdzx>
          <tuyaqiang ref="tuyaqiang" @tiewenshul="tiewenshul" v-if="wauto == 2"></tuyaqiang>
          <hdsj ref="hdsj" v-if="wauto == 3"></hdsj>
          <hduicy ref="hdcy" v-if="wauto == 4"></hduicy>
          <hdhk ref="hdfk" v-if='wauto == 5'></hdhk>
          <hdsjian ref="hdsjian" v-if="wauto == 6"></hdsjian> -->
        </div>

      </div>
    </div>
    <cjhdbd v-show="txbiaod == 2" ref="childRef" @custom="custom" @hdfh="hdfh"></cjhdbd>

  </div>
</template>

<script>
import { EventBus } from "@/views/event-bus.js";
import { activity_detail } from "@/request/hshd/index.js";
import SkeletonScreen from "./SkeletonScreen/groupSkeletonScreen.vue";
// import hdzx from "@/components/hodong/hdzx.vue";
// import hduicy from "@/components/hodong/hduicy.vue";
// import hdsj from "@/components/hodong/hdsj.vue";
// import hdhk from "@/components/hodong/hdhk.vue";
// import tuyaqiang from "@/components/hodong/tuyaqiang.vue";
// import hdsjian from "@/components/hodong/hdsjian.vue";

import cjhdbd from "../index/cjhdbd.vue";
export default {
  components: {
    SkeletonScreen,
    cjhdbd,
    // hduicy,
    // tuyaqiang,
    // hdzx,
    // hdsj,
    // hdhk,
    // hdsjian,
  },
  data() {
    return {
      info: {},
      id: "",
      txbiaod: 1,
      wauto: 1,
      showButtons: false,
      // 假设这是你的所有元素列表
      allItems: [
        {
          id: 1,
          icon: require("@/img/details/icon_ttxq_huodongzixun@2x.png"),
          text: this.$t("活动资讯"),
        },

        {
          id: 6,
          icon: require("@/img/details/icon_hdsjb@1x.png"),
          text: this.$t("活动时间"),
        },
        {
          id: 2,
          icon: require("@/img/details/icon_ttxq_tuyaqiang@2x.png"),
          text: this.$t("涂鸦墙"),
        },
        {
          id: 3,
          icon: require("@/img/details/icon_ttxq_huodongshujju@2x.png"),
          text: this.$t("活动数据"),
        },
        {
          id: 4,
          icon: require("@/img/details/icon_ttxq_tuantichengyuan@2x.png"),
          text: this.$t("活动成员"),
        },
        {
          id: 5,
          icon: require("@/img/details/icon_ttxq_huodongshujju@2x.png"),
          text: this.$t("活动反馈"),
        },
      ],
      // 当前显示的元素索引范围
      startIndex: 0,
      // 假设一次显示5个元素
      visibleCount: 5,
      dataLoaded: false,
      modalShow: false,

      isLoading: false,
    };
  },
  mounted() {
    if (this.$route.path.includes("/info")) {
      this.wauto = 1;
      this.wautobutt(1);
    } else if (this.$route.path.includes("/the_wall")) {
        this.wautobutt(2);
        
    } else if (this.$route.path.includes("/schedule")) {
      this.wautobutt(6);
    } else if (this.$route.path.includes("/data-analysis")) {
      this.wautobutt(3);
    } else if (this.$route.path.includes("/members")) {
      this.wautobutt(4);
    } else if (this.$route.path.includes("/feedback")) {
      this.wautobutt(5);
    } else {
      this.wauto = 1;
      this.wautobutt(1);
    }
  },
  computed: {
    // 计算当前应该显示的元素列表
    visibleItems() {
      return this.allItems.slice(
        this.startIndex,
        this.startIndex + this.visibleCount
      );
    },
  },
  beforeDestroy() {
    // 组件销毁前移除监听器，防止内存泄漏
    EventBus.$off("hdonmgh-call-sibling");
  },
  created() {
    EventBus.$on("hdonmgh-call-sibling", (msg) => {
      // 执行方法，处理接收到的数据
      // this.message = msg;
      // 如果需要，可以在这里调用其他方法
      this.id = this.$route.params.id;
      this.activity_detail();
    });
    this.id = this.$route.params.id;
    this.activity_detail();
  },
  methods: {
    // 触发分页
    handleScroll(event) {
      const target = event.target;
      const scrollDistance =
        target.scrollHeight - target.scrollTop - target.clientHeight;
      if (scrollDistance < 10) {
        // 当滚动条距离底部小于10px时，认为是滚动到底部
        if (this.$route.path.includes("/info")) {
        } else if (this.$route.path.includes("/the_wall")) {
          EventBus.$emit("tdhdthe_wall", "tthd");
        } else if (this.$route.path.includes("/schedule")) {
        } else if (this.$route.path.includes("/data-analysis")) {
        } else if (this.$route.path.includes("/members")) {
          EventBus.$emit("tdhdmembers", "tthd");
        } else if (this.$route.path.includes("/feedback")) {
        } else {
        }
      }
    },
    // 切换显示元素的方法
    move(direction) {
      if (direction === -1 && this.startIndex > 0) {
        this.startIndex--;
      } else if (
        direction === 1 &&
        this.startIndex + this.visibleCount < this.allItems.length
      ) {
        this.startIndex++;
      }
    },
    tiewenshul(e) {
      if (e == 1) {
        this.activity_detail();
      } else {
        this.activity_detail();
      }
    },
    async activity_detail() {
      const { data, code } = await activity_detail({
        id: this.id,
        slug: this.$route.params.slug,
      });
      if (code == 200) {
        this.dataLoaded = true;
      }
      this.info = data.info;
      setTimeout(() => {
          EventBus.$emit("chuanrusffb",this.info.is_member);
        }, 100);
    },
    hdfh(e) {
      this.txbiaod = 1;
      this.info.is_member = 1;
    },
    chanyughd(e, v) {
      this.txbiaod = 2;
      setTimeout(() => {
        this.$refs.childRef.msg(e, v);
      }, 1000);
    },
    custom() {
      this.txbiaod = 1;
    },
    wautobutt(e) {
      this.wauto = e;
      if (e == 1) {
        this.$router.push({
          name: "hshdhdzx",
          params: {
            id: this.id,
            name: this.info.name,
          },
        });
      } else if (e == 3) {
        this.$router.push({
          name: "hdsj",
          params: {
            id: this.id,
            name: this.info.name,
          },
        });
        // setTimeout(() => {
        //   this.$refs.hdsj.msg(this.id, this.info.name);
        // }, 1040);
      } else if (e == 5) {
        this.$router.push({
          name: "hdhk",
          params: {
            id: this.id,
          },
        });
        // setTimeout(() => {
        //   this.$refs.hdfk.msg(this.id);
        // }, 1040);
      } else if (e == 4) {
        this.$router.push({
          name: "hdhduicy",
          params: {
            id: this.id,
            organization_id: this.info.organization_id,
          },
        });
        // setTimeout(() => {
        //   this.$refs.hdcy.msg(this.id, this.info.organization_id);
        // }, 2040);
      } else if (e == 2) {
        this.$router.push({
          name: "hdtuyaqiang",
          params: {
            id: this.id,
            is_member: this.info.is_member,
          },
        });
      }
      if (e == 6) {
        this.$router.push({
          name: "hdsjian",
          params: {
            id: this.id,
            name: this.info.name,
          },
        });
      }
    },
  },
};
</script>
<style scoped lang="scss">
@media screen and (max-width: 751px) {
}
@media screen and (max-width: 991px) {
  .heggs {
    height: 79vh !important;
  }
  .sjdsdsax {
    position: relative;
    background: #ffffff;
    text-align: center;
    width: 40% !important;
    display: inline-block;
  }
  .tpiand {
    display: none;
  }
  .sjtop {
    margin-top: 1rem !important;
  }
  .sjbgaodu {
    height: 200px !important;
    overflow: hidden !important;
  }
  .sjbzt {
    font-size: calc(11px + 0.4vw) !important;
  }
  .sjtoto {
    font-size: calc(13px + 0.4vw) !important;
  }
  .sjbxzt {
    font-size: calc(9px + 0.4vw) !important;
  }
  .aaaa {
    display: block !important;
  }
  .yinc {
    display: none !important;
  }
  .sjiduand1 {
    padding: 12px !important;
  }
}
.aaaa {
  display: none;
}

.ckqbb:hover {
  transform: scale(1.001);
  /* 悬浮时放大1.1倍 */
  // padding: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.dcbd:hover {
  transform: scale(1.2);
  /* 悬浮时放大1.1倍 */
  // padding: 12px;
}

.item {
  width: 30%;
  background-color: red;
}

.jhsad {
  text-align: center;
  background: #fcd7d5;
  border-radius: 12px;
  height: calc(50px + 0.4vw);
  display: flex;
  justify-content: center;
  align-items: center;
}

.parent::-webkit-scrollbar {
  display: none;
}
.imase {
  ::v-deep .img-fluid {
    height: 408px !important;
    object-fit: cover !important;
  }
}
</style>